import { AvForm } from "availity-reactstrap-validation"
import { Check, CustomButton } from "components/Common"
import { CustomAvField, CustomSelect } from "components/Common/Input"
import { ModuleIds, parameterCode, permissionType } from "constant"
import { postWithFormData } from "helpers/api_helper2"
import {
  getInvalidMessageI18n,
  getUserInfoStorage,
  phoneRegex
} from "helpers/utilities"
import PropTypes from "prop-types"
import { useEffect, useMemo, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Col, Label, Row } from "reactstrap"
import { getUserDetail } from "store/actions"
import {
  getImageById,
  updateUserProfile
} from "store/users/user-profiles/actions"
import upload_image from "../../../../assets/images/users/image_upload.png"
import "../../../../assets/scss/custom/pages/_userProfile.scss"
const RESOURCE = ModuleIds.User

const UserProfilesTable = ({
  t,
  user,
  onGetUserDetail,
  onUpdateUserProfiles,
  onGetImageByUserId,
  image,
}) => {
  let userId = getUserInfoStorage("sub")
  const roleNames = user?.role
    ?.map(_r => _r.roleName)
    .join(", ")
    .toUpperCase()
  const roleIds = useMemo(() => user?.role?.map(_r => _r.roleId), [user?.role])
  const [selectedImage, setSelectedImage] = useState(null)
  const [avatarPath, setAvatarPath] = useState(null)
  const handleImageChange = async e => {
    const file = e.target.files[0]
    setSelectedImage(file)
    setAvatarPath(file)
    if (file) {
      const formData = new FormData()
      formData.append("file", file)
      formData.append("userId", userId)
      const type = "User"
      try {
        const response = await postWithFormData(
          `${process.env.REACT_APP_MASTER_SETUP_ENDPOINT}/users/${userId}/upload-avatar/${type}`,
          formData
        )
      } catch (error) {
        console.error("Error uploading file:", error)
      }
    }
    onGetImageByUserId()
  }
  const isPasswordRequired = field =>
    !true
      ? {
        required: true,
        errorMessage: getInvalidMessageI18n(t, field),
        validate: {
          required: { value: true },
        },
      }
      : {}
  const onFetchDetail = () => {
    if (userId) {
      onGetUserDetail(userId)
    }
  }
  const handleImageDelete = async () => {
    if (selectedImage) {
      const formData = new FormData()
      formData.append("file", null)
      formData.append("userId", userId)
      const type = "User"
      try {
        await postWithFormData(
          `${process.env.REACT_APP_MASTER_SETUP_ENDPOINT}/users/${userId}/upload-avatar/${type}`,
          formData
        )
      } catch (error) {
        console.error("Error uploading file:", error)
      }
    }
    onGetImageByUserId()
  }
  useEffect(() => {
    onGetImageByUserId()
  }, [])
  useEffect(() => {
    const url =
      image &&
      `${process.env.REACT_APP_MASTER_SETUP_ENDPOINT}/${image.filePath}`
    setSelectedImage(url)
  }, [image])
  useEffect(() => {
    onFetchDetail()
  }, [userId])

  const onValidSubmit = (e, values) => {
    const { id, givenName, familyName, telephone, email } = values
    onUpdateUserProfiles({
      user: { id: userId, givenName, familyName, telephone, email },
      callback: afterUpdate,
    })
  }
  const afterUpdate = () => {
    if (userId) {
      onGetUserDetail(userId)
    }
  }
  return (
    <AvForm onValidSubmit={onValidSubmit} model={user} autoComplete="off">
      <Row md={12} className="px-2 card mb-3">
        <Label
          size="lg"
          style={{
            color: "rgb(116, 120, 147)",
            fontWeight: "bold",
            fontSize: "18px",
          }}
          className="border-bottom border-secondary mb-3"
        >
          {t(roleNames)}
        </Label>
        <div
          style={{
            height: "0px",
            overflow: "hidden",
            background: "transparent",
          }}
        >
          <input autoComplete="new-password" name="email" type="text"></input>
          <input
            autoComplete="new-password"
            name="password"
            type="password"
          ></input>
        </div>
        <div className="d-none">
          <CustomAvField name="id" type="hidden" value={1 || ""} />
        </div>
        <Col>
          <div className="container">
            <div className="picture-container">
              <div className="avatar-edit">
                {selectedImage && (
                  <>
                    <button
                      id="imageUpload"
                      className="delete-button"
                      title="Delete Image"
                    ></button>
                    <label>
                      <i
                        onClick={handleImageDelete}
                        className="bx bx-x"
                        style={{ fontSize: "20px" }}
                      ></i>
                    </label>
                  </>
                )}
              </div>
              <div className="picture">
                <img
                  src={
                    !selectedImage
                      ? `${upload_image}`
                      : avatarPath
                        ? URL.createObjectURL(avatarPath)
                        : selectedImage
                  }
                  className="picture-src"
                  id="wizardPicturePreview"
                  title=""
                />
                <input
                  type="file"
                  id="wizard-picture"
                  className=""
                  name="image"
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </div>
            </div>
          </div>
        </Col>
        {/* <Col className="col-12">
          <div className="mb-3">
            <CustomAvField
              label={t("common:Last Name")}
              name="givenName"
              type="text"
              required
              errorMessage={getInvalidMessageI18n(t, "common:Last Name")}
              validate={{
                required: { value: true },
              }}
              value={user.givenName}
            />
          </div>
        </Col> */}
        <Col className="col-12">
          <div className="mb-3">
            <CustomAvField
              name="familyName"
              type="text"
              required
              errorMessage={getInvalidMessageI18n(t, "common:Full Name")}
              validate={{
                required: { value: true },
              }}
              value={`${user.familyName}` + (user.givenName ? (' ' + user.givenName) : "")}
              label={t("common:Full Name")}
            />
          </div>
        </Col>
        <Col className="col-12">
          <div className="mb-3">
            <CustomAvField
              name="userName"
              autoComplete="off"
              type="text"
              {...isPasswordRequired("common:User Name")}
              value={user.userName}
              label={t("common:User Name")}
              disabled
            />
          </div>
        </Col>
        <Col className="col-12">
          <div className="mb-3">
            <CustomAvField
              name="email"
              autoComplete="off"
              type="email"
              value={user.email}
              label={t("common:Email")}
            />
          </div>
        </Col>
        <Col className="col-12">
          <div className="mb-3">
            <CustomAvField
              name="telephone"
              type="text"
              label={t("common:Telephone")}
              value={user.telephone}
              errorMessage={getInvalidMessageI18n(t, "Phone")}
              validate={{
                pattern: {
                  value: phoneRegex,
                },
              }}
            />
          </div>
        </Col>
        <Col md={12}>
          <div className="mb-3">
            <CustomSelect
              name="companyId"
              required
              errorMessage={getInvalidMessageI18n(t, "common:Company")}
              validate={{
                required: { value: true },
              }}
              value={user.companyId}
              valueName={user.companyName}
              code={parameterCode.COMPANIES}
              label={t("common:Company")}
              readOnly
            />
          </div>
        </Col>
        <Col md={12}>
          <div className="mb-3">
            <CustomSelect
              name="departmentId"
              value={user.departmentId}
              valueName={user.departmentName}
              code={parameterCode.DEPARTMENTS}
              label={t("common:Department")}
              required={true}
              readOnly
            />
          </div>
        </Col>
        <Col md={12}>
          <div className="mb-3">
            <CustomSelect
              name="positionCode"
              value={user.positionCode}
              code={parameterCode.TITLE_CODE}
              label={t("Position")}
              readOnly
            />
          </div>
        </Col>
        <Col md={12}>
          <div className="mb-3">
            <CustomSelect
              name="role"
              value={roleIds}
              code={parameterCode.ROLES}
              isMulti
              label={t("Role")}
              readOnly
            />
          </div>
        </Col>
        <Col md={12}>
          <div className="mb-3">
            <CustomSelect
              name="status"
              value={user.status ?? true}
              code={parameterCode.USER_STATUS_CODE}
              label={t("common:Status")}
              readOnly
            />
          </div>
        </Col>
        <Col>
          <div className="d-flex flex-wrap gap-2 modal-footer justify-content-center mb-4">
            {/* <Check resource={RESOURCE} permission={permissionType.U}> */}
              <CustomButton
                type="submit"
                color="primary"
                className="save-user button-width"
                text={t("common:Save")}
                isEdit
              />
            {/* </Check> */}
          </div>
        </Col>
      </Row>
    </AvForm>
  )
}

UserProfilesTable.propTypes = {
  user: PropTypes.object,
  onGetUserDetail: PropTypes.func,
  onUpdateUserProfiles: PropTypes.func,
  onGetImageByUserId: PropTypes.func,
  t: PropTypes.any,
}

const mapStateToProps = ({ user, userProfiles }) => ({
  user: user.user,
  image: userProfiles.image,
})

const mapDispatchToProps = dispatch => ({
  onGetUserDetail: id => dispatch(getUserDetail(id)),
  onUpdateUserProfiles: user => dispatch(updateUserProfile(user)),
  onGetImageByUserId: () => dispatch(getImageById()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["userPage", "common"])(UserProfilesTable)))
