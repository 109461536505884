import { ModuleIds, ProfileId, parameterCode } from "constant/utility"
import { useEffect, useState } from "react"
import { Col, Row } from "reactstrap"
import { AvForm } from "availity-reactstrap-validation"
import { getIndividualById } from "helpers/app-backend"
import {
  getInputChangedValue,
  getInvalidMessageI18n,
  getNumber,
  isEmptyValues,
} from "helpers/utilities"
import { withTranslation } from "react-i18next"
import { connect, useDispatch } from "react-redux"
import { withRouter } from "react-router-dom"
import { FieldFormModal } from "../../../../PartyManagement/Individuals/Modal"

import {
  CustomAutoComplete,
  CustomAvField,
  CustomButton,
  CustomButtonGroup,
  CustomDatePicker,
  CustomSelect,
  CustomSelectAsync
} from "components/Common"
import FormSwitchForm from "components/Common/FormSwitchForm"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import { Label } from "reactstrap"
import { addNewPhysician, getTestRequestDetailSuccess } from "store/actions"
import { getGeneralSetting } from "store/setting/generalSetting/actions"

const RESOURCE = ModuleIds.TestRequest

const EditMode = ({
  onValidSubmit,
  data,
  onCancel,
  t,
  isEdit,
  formEl,
  billingTypes,
  onAddNewPhysician,
  generalSetting,
  onGetGeneralSetting
}) => {
  const [physicianModal, setPhysicianModal] = useState(false)
  const [patientModal, setPatientModal] = useState(false)

  const [companyId, setCompanyId] = useState(null)

  const [physicianId, setPhysicianId] = useState(null)
  const [physicianValueName, setPhysicianValueName] = useState(null)
  const [departmentId, setDepartmentId] = useState(null)

  const [patientId, setPatientId] = useState(null)
  const [patientValueName, setPatientValueName] = useState(null)

  const [billingTypeList, setBillingTypeList] = useState(null)
  const dispatch = useDispatch()
  const [rowAddNew, setRowAddNew] = useState({})
  const userInfo = JSON.parse(localStorage.getItem("userInfo"))

  useEffect(
    () => {
      if (isEmpty(generalSetting)) {
        onGetGeneralSetting(userInfo?.company);
      }
      dispatch(getTestRequestDetailSuccess({ testRequest: { samples: [] } }))
    },
    []
  )
  useEffect(() => {
    if (data.patientId) {
      setPatientId(data.patientId)
    }

    if (data.physicianId) {
      setPhysicianId(data.physicianId)
    }
  }, [data])

  useEffect(() => {
    setBillingTypeList(billingTypes)
  }, [billingTypes])

  const togglePhysicianModal = () => {
    setPhysicianModal(prev => !prev)
  }

  const togglePatientModal = () => {
    setPatientModal(prev => !prev)
  }

  const handlePhysicalValidSubmit = (e, values) => {
    values.ManagementCompanyId = getNumber(values["ManagementCompanyId"])

    const newPhysician = {
      ...values,
    }
    delete newPhysician.Id
    // save new Physician
    onAddNewPhysician({
      physician: newPhysician,
      callback: afterPhysicianSubmit,
    })
  }

  const afterPhysicianSubmit = ({ id, name }) => {
    setPhysicianId(id)
    setPhysicianValueName(name)
    togglePhysicianModal()
  }

  const handlePatientValidSubmit = (e, values) => {
    values.FirstVisitCompanyId = getNumber(values["FirstVisitCompanyId"])
    values.ManagementComrpanyId = getNumber(values["ManagementCompanyId"])

    const newIndividual = {
      ...values,
    }
    delete newIndividual.Id
    // save new Individual
    onAddNewIndividual({
      individual: newIndividual,
      callback: afterPatientSubmit,
    })
  }

  const afterPatientSubmit = ({ id, name, patientId }) => {
    setPatientId(patientId)
    setPatientValueName(name)
    togglePatientModal()
  }

  const onSelectChanged = e => {
    const { name, value, type } = getInputChangedValue(e)

    if (isEmptyValues(name)) return
    if (type !== "select-one") return

    let updatedState = {}
    if (name === "ManagementCompanyId") updatedState = { Department: "" }
    // check if edit mode
    setRowAddNew(prev => ({ ...prev, ...updatedState, [name]: value }))
  }

  const onPhysicianIdChange = async (name, value) => {
    await fetchIndividualById(value)
  }

  const fetchIndividualById = async id => {
    const res = await getIndividualById(id)

    setCompanyId(res.ManagementCompanyId)

    setDepartmentId(res.Department)
  }
  // if (isEdit && !data.id) return null


  const displayInPatient = generalSetting.find(item => item.fieldName === 'InPatient')?.fieldValue === 'True';
  const displayEmergency = generalSetting.find(item => item.fieldName === 'Emergency')?.fieldValue === 'True';

  return (
    <>
      <AvForm
        ref={formEl}
        id="generalForm"
        onValidSubmit={onValidSubmit}
      // model={data}
      >
        <CustomAvField name="id" type="hidden" value={data.id || ""} />
        <CustomAvField
          name="companyId"
          type="hidden"
          value={companyId || data.companyId || 1}
        />
        <Row>
          <Col xs="6">
            <div className="mb-1">
              <CustomAvField
                name="alternateId"
                value={data.alternateId || ""}
                label={t("Alternate Request ID")}
                errorMessage={getInvalidMessageI18n(t, "Alternate Request ID")}
                detected={isEdit}
                type="text"
                placeholder={t("Mã thay thế/Quét Barcode")}
              />
            </div>
          </Col>

          <Col xs="6">
            <div className="mb-1">
              <CustomAvField
                name="medicalId"
                value={data.medicalId || ""}
                label={t("Medical ID")}
                errorMessage={getInvalidMessageI18n(t, "Medical ID")}
                detected={isEdit}
                type="text"
                placeholder={"Mã y tế"}
              />
            </div>
          </Col>

          <Col xs="6">
            <div className="mb-1">
              <CustomAvField
                name="insuranceNumber"
                value={data.insuranceNumber || ""}
                label={t("Insurance Number")}
                errorMessage={getInvalidMessageI18n(t, "Insurance Number")}
                detected={isEdit}
                type="text"
                placeholder={"Số bảo hiểm"}
              />
            </div>
          </Col>
          <Col xs="6">
            <div className="mb-1">
              <CustomSelect
                name="serviceType"
                value={data.serviceType || ""}
                code={parameterCode.SERVICE_TYPE}
                label={t("Type")}
                detected={isEdit}
              />
            </div>
          </Col>
          <Col xs="6" className="d-flex mb-1">
            <div className="w-100">
              <CustomSelectAsync
                name="physicianId"
                value={physicianId || data.physicianId || ""}
                valueName={physicianValueName || data.physicianName || ""}
                code={parameterCode.PHYSICIANS}
                label={t("Requesting Physician")}
                detected={isEdit}
                required
                errorMessage={getInvalidMessageI18n(t, "Requesting Physician")}
                onChange={onPhysicianIdChange}
              />
            </div>
            <div style={{ marginLeft: 5 }}>
              <Label className="w-100">&nbsp;</Label>
              <CustomButton
                color="primary"
                onClick={() => {
                  togglePhysicianModal()
                }}
              >
                {t("+")}
              </CustomButton>
            </div>
          </Col>
          <Col xs="6">
            <div className="mb-1">
              <CustomDatePicker
                name="requestDate"
                label={t("Request Date")}
                placeholder={"DD/MM/YYYY"}
                format={"DD/MM/YYYY"}
                value={data.requestDate || new Date()}
                detected={isEdit}
                required
              />
            </div>
          </Col>

          <Col xs="6">
            <div className="mb-1">
              <CustomSelect
                name="departmentId"
                value={departmentId || data.departmentId || 0}
                code={parameterCode.DEPARTMENTS}
                group={companyId || ""}
                label={t("Department")}
                detected={isEdit}
                errorMessage={getInvalidMessageI18n(t, "Department")}
              />
            </div>
          </Col>
          <Col xs="6">
            <div className="mb-1">
              <CustomAutoComplete
                name="diagnosis"
                value={data.diagnosis || ""}
                type="text"
                code={parameterCode.DIAGNOSIS_KEY}
                label={t("Diagnosis")}
                detected={isEdit}
                errorMessage={getInvalidMessageI18n(t, "Diagnosis")}
              />
            </div>
          </Col>
          <Col className="d-flex" sm="6" style={{ alignSelf: 'center', gap: '36px' }}>
            {displayInPatient && <div className="mb-3">
              <FormSwitchForm
                name="inPatient"
                label={`${t("common:InPatient")} :`}
                value={data.inPatient}
              />
            </div>}
            {displayEmergency && <div className="mb-3">
              <FormSwitchForm
                name="emergency"
                color="eb3c27"
                label={`${t("common:Emergency")} :`}
                value={data.emergency}
              />
            </div>}
          </Col>
          <Col xs="6">
            <div className="mb-1">
              <CustomAvField
                label={t("Remark")}
                name="remark"
                type="textarea"
                rows={1}
                value={data.remark || ""}
                detected={isEdit}
              />
            </div>
          </Col>
        </Row>

        <Row className="mt-2">
          <Col>
            <CustomButtonGroup className="justify-content-center">
              <CustomButton
                text={t("common:Cancel")}
                type="button"
                onClick={onCancel}
                className="button-width"
              />
              <CustomButton
                text={t("common:Save")}
                type="submit"
                color="primary"
                className="save-user button-width"
                isEdit
              />
            </CustomButtonGroup>
          </Col>
        </Row>
      </AvForm>

      <FieldFormModal
        modal={physicianModal}
        toggle={togglePhysicianModal}
        profileId={ProfileId.PHYSICIAN}
        profileName={"Bác Sĩ"}
        onSubmit={handlePhysicalValidSubmit}
        onSelectChanged={onSelectChanged}
        isEdit={false}
        isClone={false}
        //data={{}}
        data={rowAddNew}
      />

      <FieldFormModal
        modal={patientModal}
        toggle={togglePatientModal}
        profileId={ProfileId.PATIENT}
        profileName={"Bệnh nhân"}
        onSubmit={handlePatientValidSubmit}
        isEdit={false}
        isClone={false}
        data={{}}
      />
    </>
  )
}

EditMode.propTypes = {
  onAddNewIndividual: PropTypes.func,
  billingTypes: PropTypes.array,
}

const mapStateToProps = ({ testRequest, generalSetting }) => {
  return {
    billingTypes: testRequest.billingTypes,
    generalSetting: generalSetting.generalSetting
  }
}

const mapDispatchToProps = dispatch => ({
  onAddNewIndividual: (individual, callback) =>
    dispatch(addNewIndividual(individual, callback)),
  onAddNewPhysician: (physician, callback) =>
    dispatch(addNewPhysician(physician, callback)),
  onGetGeneralSetting: payload => dispatch(getGeneralSetting(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(
    withTranslation(["testRequestPage", "common", "message"])(EditMode)
  )
)
