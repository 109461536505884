import i18n from "i18next"
import { all, call, put, select, takeEvery } from "redux-saga/effects"

//setting redux states
import {
  ADD_INSTRUMENT_TRANSLATOR,
  ADD_MACHINE,
  ADD_NEW_MACHINE_MAPPING_CODE,
  DELETE_INSTRUMENT_TRANSLATOR,
  DELETE_MACHINE,
  DELETE_MACHINE_MAPPING_CODES,
  GET_INSTRUMENT_TRANSLATORS,
  GET_INSTRUMENT_TRANSLATORS_DETAIL,
  GET_MACHINES,
  GET_MACHINE_CODE_MAPPING,
  GET_MACHINE_DETAIL,
  SAVE_MACHINE_CODE_MAPPING,
  UPDATE_INSTRUMENT_TRANSLATOR,
  UPDATE_MACHINE,
  UPDATE_MACHINE_MAPPING_CODE
} from "./actionTypes"

import {
  addNewInstrumentTranslatorFail,
  addNewInstrumentTranslatorSuccess,
  addNewMachineFail,
  addNewMachineMappingCodeFail,
  addNewMachineMappingCodeSuccess,
  addNewMachineSuccess,
  deleteInstrumentTranslatorsFail,
  deleteInstrumentTranslatorsSuccess,
  deleteMachineMappingCodeFail,
  deleteMachineMappingCodesSuccess,
  deleteMachinesFail,
  deleteMachinesSuccess,
  getInstrumentTranslatorDetailFail,
  getInstrumentTranslatorDetailSuccess,
  getInstrumentTranslatorsFail,
  getInstrumentTranslatorsSuccess,
  getMachineCodeMappingFail,
  getMachineCodeMappingSuccess,
  getMachineDetailFail,
  getMachineDetailSuccess,
  getMachinesFail,
  getMachinesSuccess,
  saveMachineCodeMappingFail,
  saveMachineCodeMappingSuccess,
  setInstrumentMappingCodeSearchQuery,
  setInstrumentTranslatorSearchQuery,
  setMachineSearchQuery,
  updateInstrumentTranslatorFail,
  updateInstrumentTranslatorSuccess,
  updateMachineFail,
  updateMachineMappingCodeFail,
  updateMachineMappingCodeSuccess,
  updateMachineSuccess
} from "./actions"

import { getAllParameters } from "helpers/app-backend"

//call api thật
import {
  createInstrumentTranslator,
  createMachine,
  createMachineMappingCode,
  deleteInstrumentTranslatorById,
  deleteMachineById,
  deleteMachineMappingCodeById,
  getAllInstrumentTranslator,
  getAllMachine,
  getInstrumentTranslatorById,
  getMachineById,
  getMachineMappingCodes,
  updateInstrumentTranslator,
  updateMachineById,
  updateMachineMappingCodeById,
  updateMachineMappingCodes,
} from "helpers/app-backend/machines_backend_helper"


import { getAllCompanies } from "helpers/app-backend/companies_backend_helper"

//call api fake
// import {
//     createMachine, deleteMachineById, getAllMachine, getMachineById, updateMachineById
// } from "helpers/fakebackend_helper"
import { showToast } from "components/Common"
const t = (msg, param) => i18n.t(msg, param)

function* fetchMachines({ payload }) {
  try {
    const searchQuery = yield select(state => {
      return state.machine.searchQuery
    })
    payload = { ...searchQuery, ...payload }
    const response = yield call(getAllMachine, payload)
    yield put(getMachinesSuccess(response))
    yield put(setMachineSearchQuery(payload))
  } catch (error) {
    console.log(error)
    yield put(getMachinesFail(error))
  }
}

function* fetchMachineDetail({ id }) {
  try {
    const { response, companies, parameters } = yield all({
      response: call(getMachineById, id),
      companies: call(getAllCompanies, { size: 100 }),
      parameters: call(getAllParameters, { size: 999 }),
    })

    response.companyName = companies?.data.find(
      x => `${x.id}` === `${response.companyId}`
    )?.name
    let parameter = parameters?.data.find(
      x => `${x.parameterKey}` === `${response.mappingKey}`
    )?.name
    if (parameter) {
      response.mappingKeyName = `${parameter} (${response.mappingKey})`
    }

    yield put(getMachineDetailSuccess(response))
  } catch (error) {
    yield put(getMachineDetailFail(error))
  }
}

function* onAddNewMachine({ payload: { machine, history } }) {
  try {
    //call api thật
    const response = yield call(createMachine, machine)
    const id = response?.id
    yield put(addNewMachineSuccess(id))
    const url = `/Instrument/${id}/view`
    showToast(
      `${t("message:CreatedMessage", {
        field: `${t(
          "machinePage:Instrument"
        )} <span class='text-decoration-underline fw-bold'>${machine["name"]
          }</span>`,
      })}`
    )
    history.push(url)
  } catch (error) {
    console.log(error)
    yield put(addNewMachineFail(error))
  }
}

function* onUpdateMachine({ payload: { machine, callback } }) {
  try {
    const response = yield call(updateMachineById, machine)
    yield put(updateMachineSuccess(machine))

    showToast(
      `${t("message:UpdatedMessage", {
        field: `${t(
          "machinePage:Instrument"
        )} <span class='text-decoration-underline fw-bold'>${machine["name"]
          }</span>`,
      })}`
    )
    if (callback) callback()
  } catch (error) {
    console.log(error)
    yield put(updateMachineFail(error))
  }
}

function* onDeleteMachine({ payload: { machines, callback } }) {
  try {
    const response = yield call(deleteMachineById, machines)
    yield put(deleteMachinesSuccess(machines))
    showToast(
      `${t("message:DeletedMessage", {
        field: `${t("machinePage:Instrument")}`,
      })}`
    )
    if (callback) callback()
  } catch (error) {
    console.log(error)
    yield put(deleteMachinesFail(error))
  }
}

function* fetchMachineCodeMappings({ payload, callback }) {
  try {
    const { instrumentId, query } = payload

    const searchQuery = yield select(
      state => state.machine.searchQueryMappingCode
    )
    const newQuery = { ...searchQuery, ...query }

    const instrumentMappingCodes = yield call(
      getMachineMappingCodes,
      instrumentId,
      newQuery
    )

    const mappingFromkey = instrumentMappingCodes.map((element, index) => {
      element.id = index
      element.instrumentSubCode = element?.instrumentSubCode?.replaceAll("|", ",")
      if (element.testName === null) {
        element.testName = ""
      }
      return element
    })
    yield put(getMachineCodeMappingSuccess(mappingFromkey))
    yield put(setInstrumentMappingCodeSearchQuery(newQuery))
    if (callback) callback()
  } catch (error) {
    console.log(error)
    yield put(getMachineCodeMappingFail(error))
  }
}

function* saveMachineCodeMappings({ payload }) {
  try {
    const { machineId, mappingCodes, active, callback } = payload

    yield call(updateMachineMappingCodes, machineId, active, mappingCodes)
    yield put(saveMachineCodeMappingSuccess(mappingCodes))
    showToast(
      `${t("message:UpdatedMessage", {
        field: `${t(
          "machinePage:InstrumentMappingCode"
        )} <span class='text-decoration-underline fw-bold'></span>`,
      })}`
    )

    if (callback) callback()
  } catch (error) {
    console.log(error)
    yield put(saveMachineCodeMappingFail(error))
  }
}

function* onAddMachineMappingCode({ payload: { code, callback } }) {
  try {
    const response = yield call(createMachineMappingCode, code)
    const id = response?.id
    yield put(addNewMachineMappingCodeSuccess(id))

    showToast(
      `${t("message:CreatedMessage", {
        field: `${t(
          "machinePage:InstrumentMappingCode"
        )} <span class='text-decoration-underline fw-bold'>${code["testCode"]
          }</span>`,
      })}`
    )
    if (callback) callback()
  } catch (error) {
    console.log(error)
    yield put(addNewMachineMappingCodeFail(error))
  }
}

// update address
function* onUpdateMachineMappingCode({ payload: { code, callback } }) {
  try {
    const response = yield call(updateMachineMappingCodeById, code)
    yield put(updateMachineMappingCodeSuccess(code))

    showToast(
      `${t("message:UpdatedMessage", {
        field: `${t(
          "machinePage:InstrumentMappingCode"
        )} <span class='text-decoration-underline fw-bold'>${code["testCode"]
          }</span>`,
      })}`
    )
    if (callback) callback()
  } catch (error) {
    console.log(error)
    yield put(updateMachineMappingCodeFail(error))
  }
}

function* onDeleteMachineMappingCodes({
  payload: { codes, instrumentId, callback },
}) {
  try {
    const response = yield call(
      deleteMachineMappingCodeById,
      codes,
      instrumentId
    )
    yield put(deleteMachineMappingCodesSuccess(codes))

    showToast(
      `${t("message:DeletedMessage", {
        field: `${t("machinePage:InstrumentMappingCode")}`,
      })}`
    )
    if (callback) callback()
  } catch (error) {
    console.log(error)
    yield put(deleteMachineMappingCodeFail(error))
  }
}

function* fetchInstrumentTranslators({ payload }) {
  try {
    const searchQuery = yield select(state => {
      return state.machine.searchQueryInstrumentTranslator
    })
    payload = { ...searchQuery, ...payload }
    const response = yield call(getAllInstrumentTranslator, payload)
    yield put(getInstrumentTranslatorsSuccess(response))
    yield put(setInstrumentTranslatorSearchQuery(payload))
  } catch (error) {
    console.log(error)
    yield put(getInstrumentTranslatorsFail(error))
  }
}

function* onAddNewInstrumentTranslator({ payload: { machine, callback } }) {
  try {
    //call api thật
    const response = yield call(createInstrumentTranslator, machine)
    yield put(addNewInstrumentTranslatorSuccess(id))
    showToast(
      `${t("message:CreatedMessage", {
        field: `${t(
          "machinePage:Instrument Translator"
        )} <span class='text-decoration-underline fw-bold'></span>`,
      })}`
    )
    callback && callback()
  } catch (error) {
    console.log(error)
    yield put(addNewInstrumentTranslatorFail(error))
  }
}

function* onUpdateInstrumentTranslator({ payload: { machine, callback } }) {
  try {
    const response = yield call(updateInstrumentTranslator, machine)
    yield put(updateInstrumentTranslatorSuccess(machine))

    showToast(
      `${t("message:UpdatedMessage", {
        field: `${t(
          "machinePage:Instrument Translator"
        )} <span class='text-decoration-underline fw-bold'></span>`,
      })}`
    )
    if (callback) callback()
  } catch (error) {
    console.log(error)
    yield put(updateInstrumentTranslatorFail(error))
  }
}

function* onDeleteInstrumentTranslator({ payload: { instrumentTranslators, callback } }) {
  try {
    const response = yield call(deleteInstrumentTranslatorById, instrumentTranslators)
    yield put(deleteInstrumentTranslatorsSuccess(instrumentTranslators))
    showToast(
      `${t("message:DeletedMessage", {
        field: `${t("machinePage:Instrument Translator")}`,
      })}`
    )
    if (callback) callback()
  } catch (error) {
    console.log(error)
    yield put(deleteInstrumentTranslatorsFail(error))
  }
}

function* fetchInstrumentTranslatorDetail({ id }) {
  try {
    const { response } = yield all({
      response: call(getInstrumentTranslatorById, id),
    })
    yield put(getInstrumentTranslatorDetailSuccess(response))
  } catch (error) {
    console.log(error);
    yield put(getInstrumentTranslatorDetailFail(error))
  }
}

function* machineSaga() {
  yield takeEvery(GET_MACHINES, fetchMachines)
  yield takeEvery(GET_MACHINE_DETAIL, fetchMachineDetail)
  yield takeEvery(ADD_MACHINE, onAddNewMachine)
  yield takeEvery(UPDATE_MACHINE, onUpdateMachine)
  yield takeEvery(DELETE_MACHINE, onDeleteMachine)
  yield takeEvery(GET_MACHINE_CODE_MAPPING, fetchMachineCodeMappings)
  yield takeEvery(SAVE_MACHINE_CODE_MAPPING, saveMachineCodeMappings)
  yield takeEvery(ADD_NEW_MACHINE_MAPPING_CODE, onAddMachineMappingCode)
  yield takeEvery(UPDATE_MACHINE_MAPPING_CODE, onUpdateMachineMappingCode)
  yield takeEvery(DELETE_MACHINE_MAPPING_CODES, onDeleteMachineMappingCodes)

  yield takeEvery(GET_INSTRUMENT_TRANSLATORS, fetchInstrumentTranslators)
  yield takeEvery(ADD_INSTRUMENT_TRANSLATOR, onAddNewInstrumentTranslator)
  yield takeEvery(UPDATE_INSTRUMENT_TRANSLATOR, onUpdateInstrumentTranslator)
  yield takeEvery(DELETE_INSTRUMENT_TRANSLATOR, onDeleteInstrumentTranslator)
  yield takeEvery(GET_INSTRUMENT_TRANSLATORS_DETAIL, fetchInstrumentTranslatorDetail)
}
export default machineSaga
