import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"

import { CustomBootstrapTable } from "components/Common"
import { convertDateFormat, indexCalculator } from "helpers/utilities"

import FilterForm from "./FilterForm"

//i18n
import { CustomTooltipButton } from "components/Common/Button"
import IconStatus from "components/Common/IconStatus"
import { ModuleIds, statusTypes } from "constant"
import { withTranslation } from "react-i18next"
import { useHistory } from "react-router-dom/cjs/react-router-dom"

const RESOURCE = ModuleIds.Profile
const ProfileTable = ({
  onSelect,
  onSelectAll,
  profiles,
  onSearch,
  onSort,
  onRefresh,
  onPageChange,
  paging,
  onSizePerPageChange,
  onSubmitFilter,
  onEdit,
  onDelete,
  onClone,
  loading,
  t,
  updatedTime,
}) => {
  const [data, setData] = useState([])
  const [model, setModel] = useState({
    search: "",
    type: [],
    isSystem: "",
  })
  const history = useHistory()

  const searchQuery = useSelector(state => state.profile.searchQuery)

  const onResetHandler = () => {
    const initModel = {
      search: "",
      type: [],
      isSystem: "",
    }
    let x = document.getElementsByName("type")
    for (let i = 0; i < x.length; i++) {
      x[i].checked = false
    }
    setModel(initModel)
  }

  useEffect(() => {
    setModel(searchQuery)
  }, [searchQuery])

  useEffect(() => {
    setData(profiles)
  }, [profiles])

  const columns = [
    {
      dataField: "id",
      text: "#",
      style: { width: 50 },
      formatter: (cellContent, user, index) => {
        return indexCalculator(paging.page, paging.size, index + 1)
      },
    },
    {
      dataField: "id",
      text: "#",
      hidden: true,
      formatter: (cellContent, user, index) => {
        return cellContent
      },
    },
    {
      dataField: "name",
      text: t("Name"),
      sort: true,
      formatter: (cellContent, user, index) => {
        return <Link to={`/Profile/${user.id}/view?tab=1`}>{user.name}</Link>
      },
    },
    {
      dataField: "type",
      text: t("Type"),
      sort: true,
      formatter: (cellContent, user, index) => {
        return user.typeName
      },
    },
    {
      dataField: "description",
      text: t("Description"),
      sort: true,
    },
    {
      dataField: "createdDate",
      text: t("Created Date"),
      sort: true,
      formatter: (cellContent, user, index) =>
        convertDateFormat(cellContent, "YYYY-MM-DD hh:mm:ss"),
    },
    {
      dataField: "status",
      text: t("Status"),
      sort: true,
      align: "center",
      headerAlign: "center",
      formatter: (cellContent, user, index) => {
        return (
          <IconStatus
            isRound={true}
            type={cellContent ? statusTypes.ACTIVE : statusTypes.INACTIVE}
          />
        )
      },
    },
    {
      dataField: "action",
      text: "",
      isDummyField: true,
      style: { width: 50 },
      formatter: (cellContent, user, index) => {
        return (
          <CustomTooltipButton
            index={index}
            component={user}
            onClone={onClone}
            onEdit={onEdit}
            onDelete={onDelete}
            t={t}
          />
        )
      },
    },
  ]

  return (
    <CustomBootstrapTable
      columns={columns}
      search
      data={data}
      paging={paging}
      onSelect={onSelect}
      onSelectAll={onSelectAll}
      onSearch={onSearch}
      onSort={onSort}
      onRefresh={onRefresh}
      filterForm={() => <FilterForm model={model} />}
      onReset={onResetHandler}
      onPageChange={onPageChange}
      onSizePerPageChange={onSizePerPageChange}
      onSubmitFilter={onSubmitFilter}
      searchText={model.search}
      loading={loading}
      updatedTime={updatedTime}
      resource={RESOURCE}
    />
  )
}

export default withTranslation(["common", "profilePage"])(ProfileTable)
