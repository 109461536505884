import React from "react"
import PropTypes from "prop-types"
import { Col, Row } from "reactstrap"

import { TitleAndInformation, LabelAndValue } from "components/Common"

//i18n
import { withTranslation } from "react-i18next"
import { concatObjectValueToString } from "helpers/utilities"
import { Link } from "react-router-dom"
const TitleAndInfo = ({ onEdit, onClone, data, resource, t, ...rest }) => {
  const { FamilyName, GivenName } = data
  const physicianName =
    (data.Title ? data.Title : "") + " " + concatObjectValueToString({ FamilyName, GivenName }, " ")
  return (
    <TitleAndInformation
      onEdit={onEdit}
      onClone={onClone}
      resource={resource}
      {...rest}
    >
      <Row>
        <Col xs="12" md="12">
          <Row>
            <Col xs="auto" className="pr-6">
              <LabelAndValue
                label={t("Physician Name")}
                value={physicianName}
              />
            </Col>
            <Col xs="auto" className="pr-6">
              <LabelAndValue label={t("Identifier")} value={data.Identifier} />
            </Col>
            <Col xs="auto" className="pr-6">
              <LabelAndValue
                label={t("Department")}
                value={data.departmentName}
              />
            </Col>
            <Col xs="auto" className="pr-6">
              <LabelAndValue
                label={t("Managed Code")}
                value={data.ManagedCode}
              />
            </Col>
            <Col xs="auto">
              <LabelAndValue
                label={t("Hospital/Clinic Mngt.")}
                value={data.ManagementCompanyName}
                linkTo={`/Company/${data.ManagementCompanyId}/view?tab=1`}
              />
            </Col>
            <Col xs="auto" className="d-flex justify-content-end ms-auto">
              <i className="bx bx-user-plus" style={{ fontSize: '48px', color: '#adb5bd' }}></i>
            </Col>
          </Row>
        </Col>
      </Row>
    </TitleAndInformation>
  )
}

TitleAndInfo.propTypes = {
  onEdit: PropTypes.func,
  onClone: PropTypes.func,
  parameter: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation(["partyPage", "common"])(TitleAndInfo)
