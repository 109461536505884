import { AvForm } from "availity-reactstrap-validation"
import PropTypes from "prop-types"
import { useEffect, useRef, useState } from "react"

import { Col, ModalBody, ModalFooter, Row } from "reactstrap"

import { CustomButton, CustomModal, CustomSelect } from "components/Common"
import { PartyTypeText } from "constant/utility"

import { getAllProfiles } from "helpers/app-backend"
import { isEmptyArray } from "helpers/utilities"

import FieldFormModal from "./FieldFormModal"

//i18n
import { withTranslation } from "react-i18next"

const SelectProfileModal = ({
  modal,
  toggle,
  isEdit,
  t,
  type,
  onSubmit,
  data,
  onSelectChanged,
  resource
}) => {
  const [profiles, setProfiles] = useState([])
  const [fieldModalOpen, setFieldModalOpen] = useState(false)
  const [profileId, setProfileId] = useState(0)
  const [profileLabel, setProfileLabel] = useState("")

  const formEl = useRef(null)


  const title =
    (!!isEdit ? t("common:Select") : t("common:Select")) +
    " " +
    t(
      `${type === PartyTypeText.Organization ? "Organization" : "Individual"
      } Profile`
    )

  const fetchProfiles = async type => {
    const query = { type, status: true, isSystem: false, internal: false }
    const res = await getAllProfiles(query)
    const result = []

    if (res.data)
      res.data.map(_prof =>
        result.push({ label: _prof.name, value: "" + _prof.id })
      )
    setProfiles(result)
  }

  useEffect(() => {
    if (profiles.length == 1) {
      onValidSubmit(null, { profileId: profiles[0].value })
    }
  }, [profiles])


  const toggleFieldModal = () => {
    setFieldModalOpen(prev => !prev)
  }

  const onValidSubmit = (e, values) => {
    const profileId = +values.profileId
    setProfileLabel(getProfileLabel(profileId))
    setProfileId(profileId)
    toggle()
    toggleFieldModal()
  }
  const getProfileLabel = profileId => {
    const idx = profiles.findIndex(_profile => +_profile.value === profileId)
    return profiles[idx].label || ""
  }

  useEffect(() => {
    if (modal && isEmptyArray(profiles)) fetchProfiles(type)
  }, [modal, type, profiles])

  useEffect(() => {
    if (modal === true) {
      if (profiles.length == 1) {
        onValidSubmit(null, { profileId: profiles[0].value })
      }
    }
  }, [modal])


  return (
    <>
      {fieldModalOpen && (
        <FieldFormModal
          modal={fieldModalOpen}
          toggle={toggleFieldModal}
          profileId={profileId}
          profileName={profileLabel}
          onSubmit={onSubmit}
          onSelectChanged={onSelectChanged}
          data={data}
          isAdd
          resource={resource}
        />
      )}
      <CustomModal modal={modal} title={title} onToggle={toggle}>
        <ModalBody>
          <AvForm
            ref={formEl}
            id="selectProfileForm"
            onValidSubmit={onValidSubmit}
          >
            <Row form>
              <Col className="col-12">
                <div className="mb-3">
                  <CustomSelect
                    label={t("Patient Profile")}
                    name="profileId"
                    required
                    options={profiles}
                    value={profiles?.[0]?.value}
                  />
                </div>
              </Col>
            </Row>
            <button type="submit" className="d-none" />
          </AvForm>
        </ModalBody>
        <ModalFooter>
          <CustomButton
            text={t("common:Cancel")}
            type="button"
            onClick={toggle}
            data-dismiss="modal"
            className="button-width"
          />

          <CustomButton
            text={t("common:Continue")}
            type="submit"
            color="primary"
            onClick={() => formEl?.current?.submit()}
            isEdit
          />
        </ModalFooter>
      </CustomModal>
    </>
  )
}

SelectProfileModal.propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  type: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
}

SelectProfileModal.defaultProps = {
  type: PartyTypeText.Individual,
  onSubmit: () => { },
}

export default withTranslation(["partyPage", "common"])(SelectProfileModal)
