import {
    ADD_MACHINE, ADD_MACHINE_FAIL, ADD_MACHINE_SUCCESS,
    DELETE_MACHINE, DELETE_MACHINE_FAIL, DELETE_MACHINE_SUCCESS,
    GET_MACHINES, GET_MACHINES_FAIL, GET_MACHINES_SUCCESS,
    GET_MACHINE_DETAIL, GET_MACHINE_DETAIL_FAIL, GET_MACHINE_DETAIL_SUCCESS,
    RESET_MACHINE_SEARCH_QUERY,
    SET_MACHINE_SEARCH_QUERY,
    UPDATE_MACHINE, UPDATE_MACHINE_FAIL, UPDATE_MACHINE_SUCCESS,
    GET_MACHINE_CODE_MAPPING, GET_MACHINE_CODE_MAPPING_FAIL, GET_MACHINE_CODE_MAPPING_SUCCESS, SAVE_MACHINE_CODE_MAPPING, SAVE_MACHINE_CODE_MAPPING_FAIL, SAVE_MACHINE_CODE_MAPPING_SUCCESS,
    GET_INSTRUMENT_TRANSLATORS,
    GET_INSTRUMENT_TRANSLATORS_FAIL,
    GET_INSTRUMENT_TRANSLATORS_SUCCESS,
    SET_INSTRUMENT_TRANSLATOR_SEARCH_QUERY,
    RESET_INSTRUMENT_TRANSLATOR_SEARCH_QUERY,
    ADD_INSTRUMENT_TRANSLATOR,
    ADD_INSTRUMENT_TRANSLATOR_FAIL,
    UPDATE_INSTRUMENT_TRANSLATOR,
    UPDATE_INSTRUMENT_TRANSLATOR_SUCCESS,
    DELETE_INSTRUMENT_TRANSLATOR,
    DELETE_INSTRUMENT_TRANSLATOR_FAIL,
    GET_INSTRUMENT_TRANSLATORS_DETAIL,
    GET_INSTRUMENT_TRANSLATORS_DETAIL_SUCCESS,
    GET_INSTRUMENT_TRANSLATORS_DETAIL_FAIL,
} from "./actionTypes"

const INIT_STATE = {
    machines: [],
    paging: {},
    loadingMachine: false,
    updatingMachine: false,
    searchQuery: {},
    updateMachineTime: new Date(),
    error: {},
    machine: {},
    codeMappings: [],
    loadingCodeMapping: false,
    updateCodeMappingTime: new Date(),
    searchQueryMappingCode: {},

    instrumentTranslators: [],
    pagingInstrumentTranslator: {},
    loadingInstrumentTranslator: false,
    updatingInstrumentTranslator: false,
    searchQueryInstrumentTranslator: {},
    updateInstrumentTranslatorTime: new Date(),
    instrumentTranslator: {},
}

const Machine = (state = INIT_STATE, action) => {
    switch (action.type) {
        //get machine list
        case GET_MACHINES:
            return {
                ...state,
                //machines: [],
                error: {},
                loadingMachine: true,
            }

        case GET_MACHINES_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingMachine: false,
            }

        case GET_MACHINES_SUCCESS:
            const { data, totalElements, ...rest } = action.payload
            return {
                ...state,
                machines: data,
                paging: { ...rest, dataSize: totalElements },
                loadingMachine: false,
                updateMachineTime: new Date()
            }
        //set search query
        case SET_MACHINE_SEARCH_QUERY:
            return {
                ...state,
                searchQuery: { ...action.payload },
            }
        //reset search query
        case RESET_MACHINE_SEARCH_QUERY:
            return {
                ...state,
                searchQuery: {},
            }
        //get machine detail
        case GET_MACHINE_DETAIL:
            return {
                ...state,
                machine: {},
                error: {},
            }
        case GET_MACHINE_DETAIL_SUCCESS:
            return {
                ...state,
                machine: action.payload,
            }

        case GET_MACHINE_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        //add machine
        case ADD_MACHINE:
            return {
                ...state,
            }

        case ADD_MACHINE_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        //update machine
        case UPDATE_MACHINE:
            return {
                ...state,
                updatingMachine: true,
                error: {},
            }

        case UPDATE_MACHINE_SUCCESS:
            return {
                ...state,
                machines: state.machines.map(machine =>
                    machine.id?.toString() === action.payload.id.toString()
                        ? { ...machine, ...action.payload }
                        : machine
                ),
                updatingMachine: false,
            }

        case UPDATE_MACHINE_FAIL:
            return {
                ...state,
                error: action.payload,
                updatingMachine: false,
            }

        case DELETE_MACHINE:
            const payload = action.payload.machines
            const itemUpdate = state.machines.filter(
                machine => payload.findIndex(_payload => machine.id === _payload.id) < 0
            )
            const statePaging = { ...state.paging }
            statePaging.dataSize = statePaging.dataSize - payload.length
            if (statePaging.dataSize < 0) statePaging = 0

            return {
                ...state,
                machines: itemUpdate,
                paging: statePaging,
            }

        case DELETE_MACHINE_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        //get machine list
        case GET_MACHINE_CODE_MAPPING:
            return {
                ...state,
                error: {},
                loadingCodeMapping: true,
            }

        case GET_MACHINE_CODE_MAPPING_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingCodeMapping: false,
            }

        case GET_MACHINE_CODE_MAPPING_SUCCESS:
            const codes = action.payload
            return {
                ...state,
                codeMappings: [...codes],
                loadingCodeMapping: false,
                updateCodeMappingTime: new Date()
            }

        case SAVE_MACHINE_CODE_MAPPING:
            return {
                ...state,
                error: {},
                loadingCodeMapping: true,
            }

        case SAVE_MACHINE_CODE_MAPPING_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingCodeMapping: false,
            }

        case SAVE_MACHINE_CODE_MAPPING_SUCCESS:
            const mappingCodes = action.payload
            return {
                ...state,
                codeMappings: [...mappingCodes],
                loadingCodeMapping: false,
                updateCodeMappingTime: new Date()
            }


        case GET_INSTRUMENT_TRANSLATORS:
            return {
                ...state,
                error: {},
                loadingInstrumentTranslator: true,
            }

        case GET_INSTRUMENT_TRANSLATORS_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingInstrumentTranslator: false,
            }

        case GET_INSTRUMENT_TRANSLATORS_SUCCESS:
            {
                const { data, totalElements, ...rest } = action.payload
                return {
                    ...state,
                    instrumentTranslators: data,
                    pagingInstrumentTranslator: { ...rest, dataSize: totalElements },
                    loadingInstrumentTranslator: false,
                    updateInstrumentTranslatorTime: new Date()
                }
            }
        case SET_INSTRUMENT_TRANSLATOR_SEARCH_QUERY:
            return {
                ...state,
                searchQueryInstrumentTranslator: { ...action.payload },
            }
        //reset search query
        case RESET_INSTRUMENT_TRANSLATOR_SEARCH_QUERY:
            return {
                ...state,
                searchQueryInstrumentTranslator: {},
            }

        //
        case ADD_INSTRUMENT_TRANSLATOR:
            return {
                ...state,
            }

        case ADD_INSTRUMENT_TRANSLATOR_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        //update machine
        case UPDATE_INSTRUMENT_TRANSLATOR:
            return {
                ...state,
                updatingInstrumentTranslator: true,
                error: {},
            }

        case UPDATE_INSTRUMENT_TRANSLATOR_SUCCESS:
            return {
                ...state,
                updatingInstrumentTranslator: false,
            }

        case UPDATE_INSTRUMENT_TRANSLATOR_SUCCESS:
            return {
                ...state,
                error: action.payload,
                updatingInstrumentTranslator: false,
            }

        case DELETE_INSTRUMENT_TRANSLATOR:
            return {
                ...state
            }

        case DELETE_INSTRUMENT_TRANSLATOR_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case GET_INSTRUMENT_TRANSLATORS_DETAIL:
            return {
                ...state,
                instrumentTranslator: {},
                error: {},
            }
        case GET_INSTRUMENT_TRANSLATORS_DETAIL_SUCCESS:
            return {
                ...state,
                instrumentTranslator: action.payload,
            }

        case GET_INSTRUMENT_TRANSLATORS_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        default:
            return state
    }
}

export default Machine;