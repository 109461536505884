import { TitleAndTable } from "components/Common"
import { ModuleIds } from "constant"

import PropTypes from "prop-types"
import React, { useRef, useState } from "react"
//i18n
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import {
    editTestRequestSampleId,
    getTestRequestSample,
    getTestrequestGeneralDetail,
    getTestrequestSamplesDetail,
    getTestrequestTestsDetail,
    reloadStateTestRequestDetail,
    updateRequestSample
} from "store/laboratory/testRequest/actions"

import DetailSampleTabTable from "./DetailSampleTabTable"
import HeaderButtons from "./HeaderButtons"
import TimeUpdateModal from "./TimeUpdateModal"
const RESOURCE = ModuleIds.TestRequest

const DetailSampleTab = ({
    onGetTestRequestSample,
    testRequestSamples,
    onUpdateRequestSample,
    id,
    loadingRequestSample,
    updateRequestSampleTime,
    onRefreshDetail,
    t,
    requestDate,
    onRefreshSamplesDetail,
    onEditTestRequestSampleId,
    onGetTestRequestDetail,
    generalSetting,
    onTestrequestGeneralDetail,
}) => {
    const [modal, setModal] = useState(false)
    const [isSaveSample, setIsSaveSample] = useState(true);
    const [isLoading, setIsLoading] = useState(false)
    const onRefreshHandler = () => {
        setIsLoading(true)
        setIsSaveSample(true)
        onRefreshDetail(id)
        onTestrequestGeneralDetail(id)
        onRefreshSamplesDetail(id, () => {
            setIsLoading(false)
        })
        refModal && refModal.current.UpdateInitStatus()
    }
    const handleSaveRequestSampleSubmit = () => {
        let values = {}
        values.id = id;
        let samples = [];
        testRequestSamples.forEach(item => {
            samples.push({
                sampleType: item.sampleType,
                sampleColor: item.sampleColor,
                numberOfLabels: `${item.numberOfLabels}`,
                collectionTime: (item.isClear === false || item.isCollected  === false) ? null : (item.collectionTime ? `${item.collectionTime}` : null),
                quality: item.quality ? `${item.quality}` : "",
                collectorUserId:   (item.isClear === false || item.isCollected  === false) ? null : (item.collectorUserId ? `${item.collectorUserId}` : null),
                receivedTime:  (item.isClear === false || item.isReceived === false) ? null : (item.receivedTime ? `${item.receivedTime}` : null),
                receiverUserId:  (item.isClear === false || item.isReceived === false)   ? null : (item.receiverUserId ? `${item.receiverUserId}` : null),
                sID: item.sid ? `${item.sid}` : 0,
            });
        });
        if (testRequestSamples.length > 0) {
            values.isCollected = testRequestSamples[0].isCollected || false;
            values.isReceived = testRequestSamples[0].isReceived || false;
        }

        values.samples = samples;
        onUpdateRequestSample({ request: values, callback: afterUpdate })
    }

    const afterUpdate = () => {
        onRefreshHandler();
    }
    const onChangeModal = () => {
        setIsSaveSample(false)
        setModal(!modal)
    }
    const onRefreshToggle = () => {
        setModal(!modal)
        onRefreshHandler();
    }
    const refModal = useRef();

    const displayCollected = generalSetting?.find(item => item.fieldName === 'Collected')?.fieldValue === 'True';
    const displayReceived = generalSetting?.find(item => item.fieldName === 'Received')?.fieldValue === 'True';
    return (
        <React.Fragment>
            <div className="sampleTabTable">
                <TitleAndTable
                    resource={RESOURCE}
                    buttons={() => (
                        <HeaderButtons
                            resource={RESOURCE}
                            isLoading={isLoading}
                            onRefreshClick={onRefreshHandler}
                            onSaveClick={handleSaveRequestSampleSubmit}
                            onOpenModal={onChangeModal}
                            isSaveSample={isSaveSample}
                            displayTimeUpdate={displayCollected || displayReceived}
                        />
                    )}
                    table={() => (
                        <DetailSampleTabTable
                            data={testRequestSamples || []}
                            loading={loadingRequestSample}
                            updatedTime={updateRequestSampleTime}
                            requestDate={requestDate}
                            onChangeData={() => {
                                setIsSaveSample(false)
                            }}
                            onEditTestRequestSampleId={(val) => {
                                onEditTestRequestSampleId({
                                    ...val, callback: () => {
                                        setIsSaveSample(false)
                                    }
                                })
                            }}
                        />
                    )}
                    external
                    subtitle={t("testRequestPage:Sample details")}
                    onEdit={() => { }}
                    onDelete={() => { }}
                    onClone={() => { }}
                    icon={false}
                />
                <TimeUpdateModal
                    ref={refModal}
                    modal={modal}
                    toggle={onChangeModal}
                    onRefreshToggle={onRefreshToggle}
                    id={id}
                    isSaveSample={isSaveSample}
                    onRefreshDetail={() => {
                        afterUpdate()
                    }}
                />
            </div>

        </React.Fragment>
    )
}

DetailSampleTab.propTypes = {
    onRefreshToggle: PropTypes.func,
    t: PropTypes.any,
    onGetTestRequestSample: PropTypes.func,
    testRequestSamples: PropTypes.array,
    onEditSampleTab: PropTypes.func,
    onUpdateRequestSample: PropTypes.func,
    loadingRequestSample: PropTypes.bool,
    updateRequestSampleTime: PropTypes.object,
    onTestrequestGeneralDetail: PropTypes.func,
}

DetailSampleTab.defaultProps = {}

const mapStateToProps = ({ testRequest, generalSetting }) => ({
    testRequest: testRequest.testRequest,
    testRequestSamples: testRequest.testRequest.samples || [],
    loadingRequestSample: testRequest.loadingRequestSample,
    updateRequestSampleTime: testRequest.updateRequestSampleTime,
    generalSetting: generalSetting.generalSetting
})

const mapDispatchToProps = dispatch => ({
    onGetTestRequestSample: payload => dispatch(getTestRequestSample(payload)),
    onUpdateRequestSample: ({ request, callback }) =>
        dispatch(updateRequestSample({ request, callback })),
    onRefreshDetail: payload => dispatch(getTestrequestTestsDetail(payload)),
    onGetTestRequestDetail: requestId => dispatch(reloadStateTestRequestDetail(requestId)),
    onEditTestRequestSampleId: payload => dispatch(editTestRequestSampleId(payload)),
    onRefreshSamplesDetail: (payload, callback) => dispatch(getTestrequestSamplesDetail(payload, callback)),
    onTestrequestGeneralDetail: requestId => dispatch(getTestrequestGeneralDetail(requestId)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["message", "common", "testRequestPage"])(DetailSampleTab))
