import React, { Fragment, useState } from "react"
import Information from "./Information"
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import TabTestInternal from "./TabTestInternal"

const LeftTab = ({}) => {
  const [activeTab1, setactiveTab1] = useState("1")
  const toggleTab = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }
  return (
    <Fragment>
      <Information />
      <Nav
        className="navtab-bg nav-justified"
        style={{ borderBottom: "1px solid #ccc" }}
      >
        <NavItem>
          <NavLink
            style={{
              cursor: "pointer",
              color: activeTab1 === "1" ? "#556ee6" : "black",
            }}
            className={classnames({
              active: activeTab1 === "1",
            })}
            onClick={() => {
              toggleTab("1")
            }}
          >
            XN Nội Viện
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{
              cursor: "pointer",
              color: activeTab1 === "2" ? "#556ee6" : "black",
            }}
            className={classnames({
              active: activeTab1 === "2",
            })}
            onClick={() => {
              toggleTab("2")
            }}
          >
            XN Gửi
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab1} className="p-1 text-muted">
        <TabPane tabId="1">
          <TabTestInternal />
        </TabPane>
        <TabPane tabId="2"></TabPane>
      </TabContent>
    </Fragment>
  )
}
export default LeftTab
