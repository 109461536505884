import { Col, Label, Row } from "reactstrap"
import { AvForm } from "availity-reactstrap-validation"

import { ModuleIds, parameterCode } from "constant/utility"
//i18n
import { withTranslation } from "react-i18next"
import { getInvalidMessageI18n } from "helpers/utilities"
import {
    CustomButton,
    CustomButtonGroup,
    CustomAvField,
    CustomCheckbox,
    CustomSelect,
    CustomTagsInput,
    CustomSelectGroup,
} from "components/Common"
import FormSwitchForm from "components/Common/FormSwitchForm"
import { useState } from "react"

//const RESOURCE = ModuleIds.TestDetail

const EditMode = ({ onValidSubmit, data, onCancel, t, isEdit }) => {

    const [lowerLimit, setLowerLimit] = useState(data.lowerLimit || '');
    const [upperLimit, setUpperLimit] = useState(data.upperLimit || '');

    const handleChangeNormalRange = (e) => {
        const [lower, upper] = e.split('-').map(item => item.trim());
        setLowerLimit(lower);
        setUpperLimit(upper);
    }

    return (
        <Row>
            <Col sm="12">
                <AvForm onValidSubmit={onValidSubmit} model={data}>
                    <Row>
                        <CustomAvField name="testCode" type="hidden" value={data.testCode || ""} />
                        <Col sm="6">
                            <div className="mb-3">
                                <CustomAvField
                                    name="unit"
                                    type="text"
                                    value={data.unit || ""}
                                    label={t("testConfig:Unit")}
                                    detected={isEdit}
                                />
                            </div>
                        </Col>
                        <Col sm="6">
                            <div className="mb-3">
                                <CustomAvField
                                    name="normalRange"
                                    type="text"
                                    value={data.normalRange || ""}
                                    label={t("testConfig:Normal Range")}
                                    detected={isEdit}
                                    onChange={e => handleChangeNormalRange(e)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="6">
                            <div className="mb-3">
                                <CustomAvField
                                    name="lowerLimit"
                                    type="text"
                                    value={lowerLimit}
                                    label={t("testConfig:Lower Limit")}
                                    detected={isEdit}
                                />
                            </div>
                        </Col>
                        <Col sm="6">
                            <div className="mb-3">
                                <CustomAvField
                                    name="upperLimit"
                                    type="text"
                                    value={upperLimit}
                                    label={t("testConfig:Upper Limit")}
                                    detected={isEdit}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-6">
                            <div className="mb-3">
                                <CustomAvField
                                    name="lowerAlert"
                                    type="text"
                                    value={data.lowerAlert || ""}
                                    label={t("testConfig:Lower Alert")}
                                    detected={isEdit}
                                />
                            </div>
                        </Col>
                        <Col sm="6">
                            <div className="mb-3">
                                <CustomAvField
                                    name="upperAlert"
                                    type="text"
                                    value={data.upperAlert || ""}
                                    label={t("testConfig:Upper Alert")}
                                    detected={isEdit}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="6">
                            <div className="mb-3">
                                <CustomAvField
                                    name="defaultResult"
                                    type="text"
                                    value={data.defaultResult || ""}
                                    label={t("testConfig:Default Result")}
                                    detected={isEdit}
                                />
                            </div>
                        </Col>
                        <Col className="col-6">
                            <div className="mb-3">
                                <Label for={'enable'}>
                                    {`${t("Enable")}`}
                                </Label>

                                <FormSwitchForm
                                    name="enable"
                                    label=''
                                    value={data.enable}
                                    style={{ margin: 3 }}
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row className="mt-2">
                        <Col>
                            <CustomButtonGroup className="justify-content-center">
                                <CustomButton
                                    text={t("common:Cancel")}
                                    type="button"
                                    onClick={onCancel}
                                    className="button-width"
                                />
                                <CustomButton
                                    text={t("common:Save")}
                                    type="submit"
                                    color="primary"
                                    className="save-user button-width"
                                    isEdit
                                />
                            </CustomButtonGroup>
                        </Col>
                    </Row>
                </AvForm>
            </Col>

        </Row>
    )
}

export default withTranslation(["testPage", "common", "message", "testConfig"])(EditMode)
