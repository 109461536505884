import {
    insertUrlParam
} from "helpers/utilities"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import {
    TitleAndTable,
    WarningModal
} from "components/Common"
import { selectCheckboxHandler } from "helpers/utilities"

import { ModuleIds } from "constant"
import { withTranslation } from "react-i18next"
import { getQcResults, resetSearchQuery } from "store/analysis-process/qcresult/actions"
import QCResultTable from "./QCResultTable"

const RESOURCE = ModuleIds.AnalyticalProcess

const QCResult = ({
    history,
    tests,
    paging,
    searchQuery,
    onGetQCResults,
    onResetQCResultSearchQuery,
    loadingTests,
    updatedTestTime,
    t,
}) => {
    const [isSelected, setIsSelected] = useState(false)
    const [row, setRow] = useState(false)
    const [rows, setRows] = useState([])
    const [warningModal, setWarningModal] = useState(false)
    const resetState = () => {
        setRow({})
        setRows([])
    }

    const onSelectCheckbox = (row, isSelected) => {
        const { rowsState, currentRow } = selectCheckboxHandler(
            rows,
            row,
            isSelected
        )
        setRows(rowsState)
        setRow(currentRow)
    }

    const onSelectAllCheckbox = rows => {
        setRows(rows)
        if (rows.length < 1) setRow({})
        else setRow(rows[rows.length - 1])
    }

    const onGetQCReusultList = payload => {
        insertUrlParam(payload)
        onGetQCResults(payload)
    }

    const fetchTests = () => {
        onGetQCReusultList({ page: 1, sort: "requestDate:desc,createdDate:desc" })
    }

    const onRefreshHandler = () => {
        resetState()
        fetchTests()
    }

    const onSearch = searchText => {
        onGetQCReusultList({ page: 1, search: searchText })
    }

    const onSizePerPageChange = size => {
        onGetQCReusultList({ page: 1, size })
    }

    const onPageChange = page => {
        onGetQCReusultList({ page })
    }

    const onSubmitFilter = values => {
        onGetQCReusultList({ page: 1, ...values })
    }

    const onSortHandler = (field, order) => {
        const sortString = `${field}:${order}`
        onGetQCReusultList({ page: 1, sort: sortString })
    }

    /** ---------CYCLE-------- **/
    useEffect(() => {
        onResetQCResultSearchQuery()
    }, [])

    useEffect(() => {
        fetchTests()
    }, [])

    return (
        <React.Fragment>
            <TitleAndTable
                table={() => (
                    <QCResultTable
                        tests={tests}
                        onSelect={onSelectCheckbox}
                        onSelectAll={onSelectAllCheckbox}
                        onSearch={onSearch}
                        onSort={onSortHandler}
                        onRefresh={onRefreshHandler}
                        isSelected={isSelected}
                        onPageChange={onPageChange}
                        paging={paging}
                        onSizePerPageChange={onSizePerPageChange}
                        onSubmitFilter={onSubmitFilter}
                        onEdit={() => { }}
                        onDelete={() => { }}
                        loading={loadingTests}
                        updatedTime={updatedTestTime}
                    />
                )}
                external
                resource={RESOURCE}
                onEdit={() => { }}
                onDelete={() => { }}
                onClone={() => { }}
                title={t("Analytical Process")}
                subtitle={t("QC Results List")}
            />
            <WarningModal
                modal={warningModal}
                onToggle={() => setWarningModal(prev => !prev)}
                message={t("message:SelectRowWarning")}
            />
        </React.Fragment>
    )
}

QCResult.propTypes = {
    tests: PropTypes.array,
    paging: PropTypes.object,
    onGetQCResults: PropTypes.func,
    onResetQCResultSearchQuery: PropTypes.func,
    onGetTestDetail: PropTypes.func,
    test: PropTypes.object,
    loadingTests: PropTypes.bool,
    updatedTestTime: PropTypes.any,
    t: PropTypes.any,
}

const mapStateToProps = ({ qcResult }) => ({
    tests: qcResult.tests,
    test: qcResult.test,
    paging: qcResult.paging,
    loadingTests: qcResult.loadingTests,
    updatedTestTime: qcResult.updatedTime,
    searchQuery: qcResult.searchQuery,
})

const mapDispatchToProps = dispatch => ({
    onGetQCResults: payload => dispatch(getQcResults(payload)),
    onResetQCResultSearchQuery: () => dispatch(resetSearchQuery()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["qcResultPage", "message"])(QCResult)))
