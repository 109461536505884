import { CustomSID } from "components/Common"
import CustomBootstrapTableAsync from "components/Common/CustomTable/CustomBootstrapTableAsync"
import { ModuleIds } from "constant/utility"
import { convertDateFormat } from "helpers/utilities"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import ReactTooltip from "react-tooltip"

const RESOURCE = ModuleIds.DeliveryReceive

const QuickAddDeliveryReceiptTable = ({
  onSelect,
  deliveries,
  onRefresh,
  currentTab,
  t,
}) => {
  const [data, setData] = useState([])
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(9)

  const onResetHandler = () => {
    onRefresh()
  }

  useEffect(() => {
    setData(deliveries)
    // setPage(1)
  }, [deliveries])

  useEffect(() => {
    setPage(1)
  }, [currentTab])

  const onPageChange = page => {
    setPage(page)
  }

  const columns = [
    {
      dataField: "id",
      text: "#",
      style: { width: 50 },
      formatter: (cellContent, request, index) => {
        return (
          <span>{((page - 1) * 9) + (index + 1)}</span>
        )
      },
    },
    {
      dataField: "id",
      text: "#",
      hidden: true,
    },
    {
      style: { width: 100 },
      dataField: "requestDate",
      text: t("Date"),
      sort: true,
      formatter: (cellContent, request, index) => {
        return (
          <span>{convertDateFormat(request.createdDate, "YYYY-MM-DD")}</span>
        )
      },
    },
    {
      dataField: "sid",
      text: t("SID"),
      formatter: (cellContent, item, index) => {
        return <CustomSID
          key={`sampleType-${index}`}
          keyToolTip={`sampleType-${index}`}
          isToolTip={true}
          toolTip={item.sampleTypeName}
          sid={`${item.sid}${item.subSID ? ` - ${item.subSID}` : ''}`}
        // emergency={item?.emergency}
        />
      }
    },
    {
      dataField: "sampleType",
      text: t("common:Code"),
      formatter: (cellContent, item, index) => {
        return (
          <label>
            {cellContent}
          </label>
        )
      }
    },
    {
      dataField: "patientName",
      text: t("Name"),
      formatter: (cellContent, request, index) => {
        return (
          <Link to={'#'}>{cellContent}</Link>
        )
      },
    },
  ]

  const pagingData = data.filter((item, index) => {
    return ((page - 1) * size) <= index && index < ((page - 1) * size + size)
  })

  return (
    <div className="result-right-frame-table" style={{ paddingLeft: '10px' }}>
      <CustomBootstrapTableAsync
        TableTitle={t('Sid List')}
        columns={columns}
        data={pagingData}
        keyField="id"
        onRowClick={onSelect}
        isEnableExport={false}
        showSelectRow={false}
        resource={RESOURCE}
        updatedTime={new Date()}
        onPageChange={onPageChange}
        paging={{
          page,
          dataSize: data.length,
          size: size,
          totalPages: data.length / size
        }}
        onRefresh={onResetHandler}
        style={{ width: 'calc(100% - 8px)' }}
      />
    </div>
  )
}

export default withTranslation(["deliveryPage", "common"])(QuickAddDeliveryReceiptTable)
