import i18n from "i18next";
import { call, put, select, takeEvery } from "redux-saga/effects";
//setting redux states
import { CREATE_RESULT_NOTIFY, DELETE_RESULTS, GET_RESULTS, GET_RESULTS_WITHOUT_LOADING, GET_RESULT_NOTIFY, GET_RESULT_PENDING, GET_TESTRESULT_DETAIL, GET_TESTRESULT_HISTORY, UPDATE_RESULT_PENDING, UPDATE_RESULT_PROFILE_VENDOR, UPDATE_RESULT_TEST_VENDOR } from "./actionTypes";

import { createNotifyFail, createNotifySuccess, deleteResultsFail, deleteResultsSuccess, getNotificationsFail, getNotificationsSuccess, getResultPendingFail, getResultPendingSuccess, getResultsFail, getResultsSuccess, getResultsWithoutLoadingFail, getResultsWithoutLoadingSuccess, getTestResultDetailFail, getTestResultDetailSuccess, getTestResultHistoryFail, getTestResultHistorySuccess, setSearchQuery, updateResultPendingFail, updateResultPendingSuccess, updateResultTestVendorFail, updateResultTestVendorSuccess } from "./actions";

import { } from "helpers/app-backend/testRequest_backend_helper";

import {
    createResultNotify,
    getResultNotify,
    getResultPending,
    getTestResultDetailById,
    updateResultPending,
    updateResultProfileVendor,
    updateResultTestVendor
} from "helpers/app-backend/testResult_backend_helper";

import { showToast } from "components/Common";
import {
    deleteResultsById, getAllFullResults
} from "helpers/app-backend/testResult_backend_helper";
import { getAllPhysicians, getAllUsers, updateTestVendor } from "helpers/app-backend";
import { ifNull } from "helpers/utilities";
const t = (msg, param) => i18n.t(msg, param)

function* fetchRequests({ payload }) {
    try {
        const searchQuery = yield select(state => {
            return state.testResult.searchQuery
        })
        payload = { ...searchQuery, ...payload }
        const response = yield call(getAllFullResults, payload)
        yield put(getResultsSuccess(response))
        yield put(setSearchQuery(payload))
    } catch (error) {
        console.log(error);
        yield put(getResultsFail(error))
    }
}

function* fetchRequestsWithoutLoading({ payload, callback }) {
    try {
        const searchQuery = yield select(state => {
            return state.testResult.searchQuery
        })
        payload = { ...searchQuery, ...payload }
        const response = yield call(getAllFullResults, payload)
        yield put(getResultsWithoutLoadingSuccess(response))
        yield put(setSearchQuery(payload))
        callback && callback()
    } catch (error) {
        console.log(error);
        yield put(getResultsWithoutLoadingFail(error))
        callback && callback()
    }
}

function* fetchTestResultDetail({ id }) {
    try {
        let idUpdate = id;
        if (id.id) {
            idUpdate = id.id
        }
        const testResult = yield call(getTestResultDetailById, idUpdate)
        yield put(getTestResultDetailSuccess({ testResult }))
        id.callback && id.callback()
    } catch (error) {
        yield put(getTestResultDetailFail(error))
    }
}

function* fetchTestResultHistory(id) {
    try {
        //const history = yield call(getTestresultHistory, id)
        //yield put(getTestResultHistorySuccess(history))
    } catch (error) {
        console.log(error);
        yield put(getTestResultHistoryFail(error))
    }
}

//delete result list
function* onDeleteResults({ payload: { results, callback } }) {
    try {
        console.log(results)
        yield call(deleteResultsById, results)
        yield put(deleteResultsSuccess(results))

        showToast(
            `${t("message:DeletedMessage", {
                field: `${t("testRequestPage:Request")}`,
            })}`
        )
        if (callback) callback()
    } catch (error) {
        console.log(error)
        yield put(deleteResultsFail(error))
    }
}

function* fetchNotifications({ payload }) {
    try {
        const response = yield call(getResultNotify, payload, payload.id)
        let data = response.data || []
        if (data.length > 0) {
            const arrUserCreate = data.filter(x => x.createdBy).map(x => x.createdBy) || [];
            const arrUserReceiver = data.filter(x => x.receiver).map(x => x.receiver) || [];
            // const arrUser = [...arrUserCreate, ...arrUserReceiver]
            // const arrUserIdDistinct = arrUser.filter((x, index) => !arrUser.includes(x, index + 1)) || []
            let resUserCreate = [];
            let resUserReceiver = [];
            if (arrUserCreate.length > 0) {
                resUserCreate = (yield call(getAllUsers, { id: arrUserCreate })) || []
            }
            if (arrUserReceiver.length > 0) {
                resUserReceiver = (yield call(getAllPhysicians, { id: arrUserReceiver })) || []
            }
            data.forEach((element, _index) => {
                const itemUserCreate = resUserCreate.data?.find(x => x.id == element.createdBy)
                const itemUserReceiver = resUserReceiver.data?.find(x => x.id == element.receiver)
                if (itemUserCreate)
                    element.createdByName = "".concat(ifNull(itemUserCreate.familyName), ifNull(" " + itemUserCreate.givenName))
                if (itemUserReceiver)
                    element.receiverName = `${itemUserReceiver.remark} ${itemUserReceiver.name}`
            })
        }
        yield put(getNotificationsSuccess(data))
    } catch (error) {
        console.log(error);
        yield put(getNotificationsFail(error))
    }
}

function* onAddNewResultNotify({ payload, callback }) {
    try {
        const response = yield call(createResultNotify, payload)
        yield put(createNotifySuccess())
        showToast(
            `${t("message:CreatedMessage", {
                field: `${t("Notify")}`,
            })}`
        )
        callback && callback()
    } catch (error) {
        callback && callback()
        console.log(error)
        yield put(createNotifyFail(error))
    }
}

function* onUpdateResultTestVendor({ payload, callback }) {
    try {
        const response = yield call(updateResultTestVendor, payload)
        yield put(updateResultTestVendorSuccess(response))

        showToast(
            `${t("message:UpdatedMessage", {
                field: `${t(
                    "testPage:Test"
                )}`,
            })}`
        )
        callback()
    } catch (error) {
        yield put(updateResultTestVendorFail(error))
    }
}

function* onUpdateResultProfileVendor({ payload, callback }) {
    try {
        const response = yield call(updateResultProfileVendor, payload)
        yield put(updateResultTestVendorSuccess(response))

        showToast(
            `${t("message:UpdatedMessage", {
                field: `${t(
                    "testPage:Test"
                )}`,
            })}`
        )
        callback()
    } catch (error) {
        yield put(updateResultTestVendorFail(error))
    }
}

function* fetchResultPending({ payload }) {
    try {
        const response = yield call(getResultPending, payload)
        yield put(getResultPendingSuccess(response))
    } catch (error) {
        console.log(error);
        yield put(getResultPendingFail(error))
    }
}

function* onUpdateResultPending({ payload, callback }) {
    try {
        const response = yield call(updateResultPending, payload)
        yield put(updateResultPendingSuccess(response))
        if (callback)
            callback()
    } catch (error) {
        console.log(error)
        yield put(updateResultPendingFail(error))
    }
}

function* testResultSaga() {
    yield takeEvery(GET_RESULTS, fetchRequests)
    yield takeEvery(GET_RESULTS_WITHOUT_LOADING, fetchRequestsWithoutLoading)
    yield takeEvery(GET_TESTRESULT_DETAIL, fetchTestResultDetail)
    yield takeEvery(GET_TESTRESULT_HISTORY, fetchTestResultHistory)
    yield takeEvery(DELETE_RESULTS, onDeleteResults)
    yield takeEvery(GET_RESULT_NOTIFY, fetchNotifications)
    yield takeEvery(CREATE_RESULT_NOTIFY, onAddNewResultNotify)
    yield takeEvery(UPDATE_RESULT_TEST_VENDOR, onUpdateResultTestVendor)
    yield takeEvery(UPDATE_RESULT_PROFILE_VENDOR, onUpdateResultProfileVendor)
    yield takeEvery(GET_RESULT_PENDING, fetchResultPending)
    yield takeEvery(UPDATE_RESULT_PENDING, onUpdateResultPending)

}
export default testResultSaga 