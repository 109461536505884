import i18n from "i18next";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
//setting redux states
import { GET_CATEGORY_COMMENT, GET_RESULT_COMMENT, GET_TESTRESULT_COMMENT, UPDATE_CATEGORY_COMMENT, UPDATE_RESULT_COMMENT, UPDATE_TESTRESULT_COMMENT } from "./actionTypes";

import { ResultCommentType, TestRequest_Test_Type } from "constant";
import { getAllUsers, getFullTestProfileById, getTestById } from "helpers/app-backend";
import { createCategoryCommentById, getCategoryCommentByResultId, getResultCommentByResultId, getTestResultProfilesByResultId, getTestResultTestsByResultId, updateCategoryCommentById, updateResultCommentById, updateResultCommentProfileById, updateResultCommentTestById } from "helpers/app-backend/testResult_backend_helper";
import { getCategoryCommentFail, getCategoryCommentSuccess, getResultCommentFail, getResultCommentSuccess, getTestResultCommentFail, getTestResultCommentSuccess, updateCategoryCommentFail, updateCategoryCommentSuccess, updateResultCommentFail, updateResultCommentSuccess, UpdateTestResultCommentFail, UpdateTestResultCommentSuccess } from "./actions";
import { GetSelectedRole } from "helpers/utilities";

const t = (msg, param) => i18n.t(msg, param)

function* fetchTestResultComment({ payload }) {
    try {
        const id = payload.id;
        let { tests, profiles } = yield all({
            tests: call(getTestResultTestsByResultId, id),
            profiles: call(getTestResultProfilesByResultId, id),
        })
        let lstUserId = [];
        if (tests && tests.length > 0) {
            lstUserId = tests.filter(x => x.updatedBy != null).map(x => Number(x.updatedBy))
        }
        let treeData = [];
        if (profiles) {
            for (let item of profiles) {
                if (item.updatedBy != null) {
                    lstUserId.push(item.updatedBy)
                }
                const data = yield fetchTestRequestProfileById(item.profileCode)
                if (data)
                    treeData = CheckTreeDataProfile(treeData, data, item)
            }
        }
        let result = treeData || [];
        if (result && result.length > 0) {
            result = result.map(item => {
                if (item.type == TestRequest_Test_Type.PROFILE_GROUP) {
                    item.children = item.children.filter(x =>
                        (x.type === TestRequest_Test_Type.PROFILE
                            && (profiles.filter(e => e.profileCode === x.code).length > 0))
                        || (x.type === TestRequest_Test_Type.TEST
                            && (tests.filter(y => y.testCode === x.code).length > 0))
                    )
                    item.children = item.children.map(child => {
                        if (child.type == TestRequest_Test_Type.PROFILE) {

                            child.children = child.children.filter(x =>
                            (x.type === TestRequest_Test_Type.TEST
                                && (tests.filter(y => y.testCode === x.code).length > 0))
                            )

                            child.children = child.children.map(test => {
                                let testTemp = tests.find(t => t.testCode == test.code);
                                test.sampleType = testTemp.sampleType;
                                test.sampleTypeName = testTemp.sampleTypeName;
                                test.sampleId = testTemp?.sampleId;
                                test.comment = testTemp?.comment;
                                test.finalComment = testTemp?.finalComment;
                                test.commentid = testTemp?.resultTestId;
                                test.updatedBy = testTemp?.updatedBy;
                                return test;
                            })
                        }
                        if (child.type == TestRequest_Test_Type.TEST) {
                            let testTemp = tests.find(t => t.testCode == child.code);
                            child.commentid = testTemp?.resultTestId;
                            child.sampleType = testTemp.sampleType;
                            child.sampleTypeName = testTemp.sampleTypeName;
                            child.sampleId = testTemp?.sampleId;
                            child.comment = testTemp?.comment;
                            child.finalComment = testTemp?.finalComment;
                            child.updatedBy = testTemp?.updatedBy;
                        }

                        return child
                    })
                }

                if (item.type == TestRequest_Test_Type.PROFILE) {
                    item.children = item.children.filter(x =>
                    (x.type === TestRequest_Test_Type.TEST
                        && (tests.filter(y => y.testCode === x.code).length > 0))
                    );
                    item.children = item.children.map(test => {
                        let testTemp = tests.find(t => t.testCode == test.code);
                        test.sampleType = testTemp.sampleType;
                        test.sampleTypeName = testTemp.sampleTypeName;
                        test.sampleId = testTemp?.sampleId;
                        test.comment = testTemp?.comment;
                        test.finalComment = testTemp?.finalComment;
                        test.commentid = testTemp?.resultTestId;
                        test.updatedBy = testTemp?.updatedBy;
                        return test;
                    })
                }

                return item;
            })
            result.forEach(item => {
                if (item.type === TestRequest_Test_Type.PROFILE) {
                    result.filter(g => g.type === TestRequest_Test_Type.PROFILE_GROUP).forEach(gr => {
                        if (gr.children.find(x => x.code === item.code)) {
                            result = result.filter(pr => pr.code != item.code);
                        }
                    });
                }
            })
        }
        if (tests) {
            for (let item of tests.filter(test => !test.profileCode)) {
                const response = yield fetchTestRequestTestById(item.testCode)
                result = CheckTreeDataTest(result, item, response)
            }
        }
        let userCreate = [];
        if (lstUserId.length > 0) {
            let { users } = yield all({
                users: call(getAllUsers, { id: lstUserId })
            })
            if (users.data.length > 0)
                userCreate = users.data;
        }
        getUserUpdate(userCreate, result)
        let countNum = 1;
        if (result && result.length > 0) {
            result = InsertNo(result, countNum)
        }
        yield put(getTestResultCommentSuccess(result))
    } catch (error) {
        console.log(error);
        yield put(getTestResultCommentFail(error))
    }
}

function getUserUpdate(users, comments) {
    for (let index = 0; index < comments.length; index++) {
        let element = comments[index];
        element.commentOwner = "";
        if (element.updatedBy != null) {
            const _item = users.find(x => x.id == element.updatedBy);
            element.commentOwner = _item ? `${_item.familyName}${_item.givenName ? ' ' + _item.givenName : ''}` : ''
        }
        if (element.children && element.children.length > 0) {
            getUserUpdate(users, element.children)
        }
    }

}

function* fetchTestRequestProfileById(code) {
    const response = yield call(getFullTestProfileById, code)
    return response;
}

function* fetchTestRequestTestById(code) {
    const response = yield call(getTestById, code)
    return response;
}

function CheckTreeDataProfile(treeData, response, itemComment) {
    let result = [...treeData]
    if (response.type === TestRequest_Test_Type.PROFILE_GROUP) {
        if (!result.find(x => x.type === TestRequest_Test_Type.PROFILE_GROUP && x.code === response.code)) {//chưa có profile_group nào trong list
            response.commentid = itemComment?.resultProfileId;
            response.comment = itemComment?.comment;
            response.finalComment = itemComment?.finalComment;
            response.updatedBy = itemComment?.updatedBy;
            response.sampleId = itemComment?.sampleId;
            result.push(response)
        }
    }
    if (response.type === TestRequest_Test_Type.PROFILE) {
        let parents = result.filter(x => x.type === TestRequest_Test_Type.PROFILE_GROUP);

        if (!parents.length) {
            response.commentid = itemComment?.resultProfileId;
            response.comment = itemComment?.comment;
            response.finalComment = itemComment?.finalComment;
            response.updatedBy = itemComment?.updatedBy;
            response.sampleId = itemComment?.sampleId;
            result.push(response)
        } else {
            parents.forEach(parent => {
                let item = parent?.children.find(x => x.type === TestRequest_Test_Type.PROFILE
                    && x.code === response.code)
                if (!item) {
                    response.commentid = itemComment?.resultProfileId;
                    response.comment = itemComment?.comment;
                    response.finalComment = itemComment?.finalComment;
                    response.updatedBy = itemComment?.updatedBy;
                    response.sampleId = itemComment?.sampleId;
                    result.push(response)
                } else {
                    item.commentid = itemComment?.resultProfileId;
                    item.comment = itemComment?.comment;
                    item.finalComment = itemComment?.finalComment;
                    item.updatedBy = itemComment?.updatedBy;
                    response.sampleId = itemComment?.sampleId;
                }
            })
        }
    }

    if (response.type === TestRequest_Test_Type.TEST) {
        // tìm bên ngoài
        let isExist = false;
        let tests = result.filter(x => x.type === TestRequest_Test_Type.TEST && x.code == response.code);
        if (tests.length) isExist = true;

        // tìm trong profile
        let profiles = result.filter(x => x.type === TestRequest_Test_Type.PROFILE);
        if (profiles.length) {
            profiles.forEach(pro => {
                let tst = pro.children.find(x => x.type === TestRequest_Test_Type.TEST
                    && x.code == response.code);
                if (tst) {
                    isExist = true;
                }
            })
        }
        // tìm trong group
        let groups = result.filter(x => x.type === TestRequest_Test_Type.PROFILE_GROUP);
        if (groups.length) {
            groups.forEach(x => {
                let tst = x.children.find(x => x.type === TestRequest_Test_Type.TEST
                    && x.code == response.code);
                if (tst) {
                    isExist = true;
                }
                //tìm trong profile của group
                let profs = x.children.filter(x => x.type === TestRequest_Test_Type.PROFILE);
                if (profs.length) {
                    profs.forEach(pro => {
                        let ts = pro.children.find(x => x.type === TestRequest_Test_Type.TEST
                            && x.code == response.code);
                        if (ts) {
                            isExist = true;
                        }
                    })
                }
            })
        }

        if (!isExist) {
            response.id = itemComment?.id;
            response.comment = itemComment?.comment;
            response.finalComment = itemComment?.finalComment;
            response.updatedBy = itemComment?.updatedBy;
            response.sampleId = itemComment?.sampleId;
            result.push(response)
        }

    }
    return result;
}

function CheckTreeDataTest(treeData, test, response) {
    let result = [...treeData]
    if (result.length > 0) {
        // tìm bên ngoài
        let isExist = false;
        let tests = result.filter(x => x.type === TestRequest_Test_Type.TEST && x.code == response.testCode);
        if (tests.length) isExist = true;

        // tìm trong profile
        let profiles = result.filter(x => x.type === TestRequest_Test_Type.PROFILE);
        if (profiles.length) {
            profiles.forEach(pro => {
                let tst = pro.children.find(x => x.type === TestRequest_Test_Type.TEST
                    && x.code == response.testCode);
                if (tst) {
                    isExist = true;
                }
            })
        }
        // tìm trong group
        let groups = result.filter(x => x.type === TestRequest_Test_Type.PROFILE_GROUP);
        if (groups.length) {
            groups.forEach(x => {
                let tst = x.children.find(x => x.type === TestRequest_Test_Type.TEST
                    && x.code == response.testCode);
                if (tst) {
                    isExist = true;
                }
                //tìm trong profile của group
                let profs = x.children.filter(x => x.type === TestRequest_Test_Type.PROFILE);
                if (profs.length) {
                    profs.forEach(pro => {
                        let ts = pro.children.find(x => x.type === TestRequest_Test_Type.TEST
                            && x.code == response.testCode);
                        if (ts) {
                            isExist = true;
                        }
                    })
                }
            })
        }

        if (!isExist) {
            result.push({
                commentid: test?.resultTestId,
                sid: test?.sid,
                name: response.testName,
                code: response.testCode,
                category: response.category,
                categoryName: response.categoryName.split('>')[1].trim(),
                sampleType: test?.sampleType || response.sampleType,
                sampleTypeName: test?.sampleTypeName || response.sampleTypeName,
                type: TestRequest_Test_Type.TEST,
                comment: test?.comment,
                finalComment: test?.finalComment,
                updatedBy: test?.updatedBy,
            })
        }
    }
    else {
        result = [{
            commentid: test?.resultTestId,
            sid: test?.sid,
            name: response.testName,
            code: response.testCode,
            sampleType: test?.sampleType || response.sampleType,
            sampleTypeName: test?.sampleTypeName || response.sampleTypeName,
            category: response.category,
            categoryName: response.categoryName.split('>')[1].trim(),
            type: TestRequest_Test_Type.TEST,
            comment: test?.comment,
            finalComment: test?.finalComment,
            updatedBy: test?.updatedBy,
        }]
    }
    return result;
}

function* onUpdateTestResultComment({ payload }) {
    try {
        const { data, finalComment, item } = payload;
        const testResult = yield select(state => {
            return state.testResult.testResult || {}
        })
        let response = {};
        if (item.type == TestRequest_Test_Type.TEST) {
            response = yield call(updateResultCommentTestById, {
                resultTestId: item.commentid,
                finalComment: finalComment,
                selectedRole: GetSelectedRole(),
                name: item.name,
                resultId: testResult.id,
            })
        } else {
            response = yield call(updateResultCommentProfileById, {
                resultProfileId: item.commentid,
                finalComment: finalComment,
                selectedRole: GetSelectedRole(),
                name: item.name,
                resultId: testResult.id,
            })
        }
        yield put(UpdateTestResultCommentSuccess([...data]))
        if (payload.callback)
            payload.callback()
    } catch (error) {
        console.log(error);
        yield put(UpdateTestResultCommentFail(error))
    }
}

function* fetchResultComment({ payload }) {
    try {
        const id = payload.id;
        let result = yield call(getResultCommentByResultId, id)
        let lstUserId = []
        if (result) {
            lstUserId.push(result.updatedBy ? Number(result.updatedBy) : Number(result.createdBy))
        }

        let _item = {}
        if (lstUserId.length > 0) {
            let { users } = yield all({
                users: call(getAllUsers, { id: lstUserId })
            })
            if (users.data.length > 0) {
                _item = users.data[0]
            }
        }
        result.commentOwner = _item ? `${_item.familyName}${_item.givenName ? ' ' + _item.givenName : ''}` : ''
        yield put(getResultCommentSuccess(result))
        payload.callback && payload.callback()
    } catch (error) {
        console.log(error);
        payload.callback && payload.callback()
        yield put(getResultCommentFail(error))
    }
}

function* fetchCategoryComment({ payload }) {
    try {
        const id = payload.id;
        let result = yield call(getCategoryCommentByResultId, id)
        let lstUserId = []
        if (result && result.length > 0) {
            lstUserId = result.map(x => x.updatedBy ? Number(x.updatedBy) : Number(x.createdBy))
        }

        let userCreate = [];
        if (lstUserId.length > 0) {
            let { users } = yield all({
                users: call(getAllUsers, { id: lstUserId })
            })
            if (users.data.length > 0)
                userCreate = users.data;
        }
        result = result.map(x => {
            const userid = x.updatedBy ? Number(x.updatedBy) : Number(x.createdBy)
            const _item = userCreate.find(x => x.id == userid);
            const commentOwner = _item ? `${_item.familyName}${_item.givenName ? ' ' + _item.givenName : ''}` : ''
            return {
                ...x,
                isCategory: true,
                name: `${x.testCategory} - ${x.testCategoryName}`,
                code: `${x.sampleId}-${x.testCategory}`,
                typeComment: ResultCommentType.CATEGORY,
                resultId: id,
                commentOwner: commentOwner
            }
        })
        yield put(getCategoryCommentSuccess(result))
    } catch (error) {
        console.log(error);
        yield put(getCategoryCommentFail(error))
    }
}

function* onUpdateResultComment({ payload }) {
    try {
        const { data, finalComment, id } = payload;
        let response = {};
        response = yield call(updateResultCommentById, {
            id: id,
            finalComment: finalComment,
        })
        data.finalComment = finalComment
        yield put(updateResultCommentSuccess({ ...data }))
        if (payload.callback)
            payload.callback()
    } catch (error) {
        console.log(error);
        yield put(updateResultCommentFail(error))
    }
}

function* onUpdateCategoryComment({ payload }) {
    try {
        const { data, finalComment, item } = payload;
        let response = {};
        const param = {
            resultCategoryId: item.resultCategoryId,
            finalComment: finalComment,
            selectedRole: GetSelectedRole(),
            resultId: item.resultId,
            sampleId: item.sampleId,
            requestId: item.requestId,
            testCategory: item.testCategory,
            name: item.testCategoryName,
        }
        if (item.resultCategoryId > 0 || item.isCreate == true) {
            response = yield call(updateCategoryCommentById, param)
        }
        else {
            response = yield call(createCategoryCommentById, param)
            let itemNew = data.find(x => x.code === item.code)
            itemNew.resultCategoryId = response
        }
        yield put(updateCategoryCommentSuccess([...data]))

        if (payload.callback)
            payload.callback()
    } catch (error) {
        console.log(error);
        yield put(updateCategoryCommentFail(error))
    }
}

function InsertNo(data, countNo = 1) {
    for (let index = 0; index < data.length; index++) {
        const element = data[index];
        let countParent = countNo;
        element.no = countNo++;
        element.isPrint = false;

        if (data.length > 0 && index - 1 >= 0) {
            if (data[index - 1].children && data[index - 1].children.length > 0) {
                countParent = countParent + data[index - 1].children.length;
                element.no = countParent++;
                countNo = countParent
            }
        }
        if (element.children && element.children.length > 0) {
            InsertNo(element.children, countNo)
        }
    }
    return data
}

function* testResultCommentSaga() {
    yield takeEvery(GET_TESTRESULT_COMMENT, fetchTestResultComment)
    yield takeEvery(GET_RESULT_COMMENT, fetchResultComment)
    yield takeEvery(GET_CATEGORY_COMMENT, fetchCategoryComment)
    yield takeEvery(UPDATE_TESTRESULT_COMMENT, onUpdateTestResultComment)
    yield takeEvery(UPDATE_RESULT_COMMENT, onUpdateResultComment)
    yield takeEvery(UPDATE_CATEGORY_COMMENT, onUpdateCategoryComment)
}
export default testResultCommentSaga