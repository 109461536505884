import { Col, Row } from "reactstrap"
import { TextField, SystemInformation } from "components/Common"
import { textFieldTypes, ModuleIds } from "constant/utility"
//i18n
import { withTranslation } from "react-i18next"

const RESOURCE = ModuleIds.Test

const ViewMode = ({
    data: {
        unit,
        normalRange,
        defaultResult,
        lowerLimit,
        upperLimit,
        lowerAlert,
        upperAlert,
        enable
    },
    data,
    t
}) => {

    return (
        <Row>
            <Col sm="12">
                <Row>
                    <Col sm="6">
                        <TextField
                            label={t("testConfig:Unit")}
                            textField={unit}
                            resource={RESOURCE}
                            isEdit={true}
                        />
                    </Col>
                    <Col sm="6">
                        <TextField
                            label={t("testConfig:Normal Range")}
                            textField={normalRange}
                            resource={RESOURCE}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm="6">
                        <TextField
                            label={t("testConfig:Lower Limit")}
                            textField={lowerLimit}
                            resource={RESOURCE}
                        />
                    </Col>
                    <Col sm="6">
                        <TextField
                            label={t("testConfig:Upper Limit")}
                            textField={upperLimit}
                            resource={RESOURCE}
                        />
                    </Col>
                </Row>
                <Row>

                    <Col sm="6">
                        <TextField
                            label={t("testConfig:Lower Alert")}
                            textField={lowerAlert}
                            resource={RESOURCE}
                        />
                    </Col>
                    <Col sm="6">
                        <TextField
                            label={t("testConfig:Upper Alert")}
                            textField={upperAlert}
                            resource={RESOURCE}
                        />
                    </Col>
                </Row>
                <Row>

                    <Col sm="6">
                        <TextField
                            label={t("testConfig:Default Result")}
                            textField={defaultResult}
                            resource={RESOURCE}
                        />
                    </Col>
                    <Col sm="6">
                        <TextField
                            label={t("testConfig:Enable")}
                            checked={enable}
                            type={textFieldTypes.SWITCH}
                            resource={RESOURCE}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default withTranslation(["testPage", "common", "testConfig"])(ViewMode)
