import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import {
  Card,
  CardBody,
  Col,
  Row
} from "reactstrap"
import {
  getTestrequestSamplesDetail
} from "store/actions"
import BarcodeFrame from "./BarcodeFrame"

const BarcodeSampleFrame = ({
  t,
  patientVisit,
  samples,
  onGetTestRequestSample,
  patientDetail,
  toggleBarcodeSetting
}) => {
  const [spin, setSpin] = useState(false)

  const fetchTestRequestSample = () => {
    setSpin(true)
    patientDetail.id && onGetTestRequestSample(patientDetail.id)
    setTimeout(() => {
      setSpin(false)
    }, 1000)
  }

  useEffect(() => {
    fetchTestRequestSample()
  }, [patientDetail.id])

  return (
    <Card className="mt-1 mb-0" style={{ maxWidth: '98%' }}>
      <CardBody className="p-0">
        {/* <TabContent activeTab={activeTab} className="text-muted">
          <TabPane tabId="1"> */}
            <Row>
              <Col sm="12">
                <BarcodeFrame
                  patientVisit={patientVisit}
                  patientDetail={patientDetail}
                  samples={samples?.map((obj, index) => ({ ...obj, id: `${index + 1}-${obj?.sid}` }))}
                  spin={spin}
                  fetchTestRequestSample={fetchTestRequestSample}
                  toggleBarcodeSetting={toggleBarcodeSetting}
                />
              </Col>
            </Row>
          {/* </TabPane>
        </TabContent> */}
      </CardBody>
    </Card>
  )
}

const mapStateToProps = ({ testRequest }) => ({
  patientVisit: testRequest.patientVisit,
  samples: testRequest.testRequest.samples,
})
const mapDispatchToProps = dispatch => ({
  onGetTestRequestSample: id => dispatch(getTestrequestSamplesDetail(id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["partyPage", "message", "common"])(BarcodeSampleFrame))
