import { CustomBootstrapInlineTable, CustomButton, CustomSelectAsync } from "components/Common"
import { GroupTestRequestOption, ProfileId, parameterCode, statusTypes } from "constant"
import { downloadImportPatientGroupTemplate, getAllPatients, getAllTestProfiles, getCodesByParameterId } from "helpers/app-backend"
import { convertDateFormat, getAgeFromDOB, getI18nextLng, getJsDateFromExcel, selectCheckboxHandler } from "helpers/utilities"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Editable from "react-bootstrap-editable"
import ReactExport from "react-export-excel"
import { withTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"
import { read, utils } from "xlsx"
const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

let dataTmp = [];
let isSetdefaultCheck = false;

const dataTemplate = [
    { 'Mã phiếu': '2402010000', 'PID': '2024BNTEST', 'Họ tên': 'Tên bệnh nhân', 'Tuổi': 24, 'Ngày sinh': '2020-01-01', Phone: '0912345678', 'Địa chỉ': '', 'Gói khám': 'GK01', 'Giới tính': 'M' }
]

let lang = getI18nextLng()
let genderListGlobal = []
const GroupEmployee = ({
    t,
    onChange,
    type,
    his,
    resetData,
    companySelected,
}) => {
    const [companyId, setCompanyId] = useState('')
    const [columnDisplay, setColumnDisplay] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [fileName, setFileName] = useState("Upload")
    const [updatedTime, setUpdatedTime] = useState(new Date())
    const [defaultSelected, setDefaultSelected] = useState([])
    const [rowsSelected, setRowsSelected] = useState([])
    const [genderList, setGenderList] = useState([])
    const [profiles, setProfiles] = useState([])
    const fields = [
        { label: t('Medical ID'), value: 'MedicalId', isEdit: true, style: { maxWidth: 100, width: 100 }, maxLength: 10 },
        { label: t('PID'), value: 'PID', isEdit: false },
        { label: t('common:Name'), value: 'FullName', isEdit: false },
        { label: t('Age'), value: 'Age', isEdit: false },
        { label: t('DOB'), value: 'DOB', isEdit: false },
        { label: t('common:Gender'), value: 'Gender', isEdit: true },
        { label: t('Group Code'), value: 'GroupCode', isEdit: false },
        { label: t('Group Name'), value: 'GroupName', isEdit: false },
        { label: t('Package Code'), value: 'PackageCode', isEdit: false },
        { label: t('Package Name'), value: 'PackageName', isEdit: false },
    ]
    useEffect(() => {
        setColumn()
        getGenderList()
    }, [])
    useEffect(() => {
        setDefaultSelected([])
        setDataSource([]);
    }, [type])
    const getGenderList = async () => {
        const query = { lang }
        let res = await getCodesByParameterId(parameterCode.GENDER, query);
        res?.map(_item => {
            _item.value = _item.code
            _item.label = _item.message
            return _item
        })
        setGenderList(res)
    }
    useEffect(() => {
        genderListGlobal = genderList
    }, [genderList])

    const cancelElement = (
        <button type="button"
            className="btn btn-outline-primary editable-cancel btn-sm me-3 d-none">
            <i className="mdi mdi-close"></i></button>
    );

    const onEditData = (val, col, id) => {
        let newRow = dataTmp.find(x => x.id == id);
        if (newRow) {
            newRow[col] = val
            setDataSource([...dataTmp]);
        }
    }
    useEffect(() => {
        getProfiles()
        getPatients()
        setCompanyId('')
    }, [companyId])

    const getProfiles = async () => {
        let res = await getAllTestProfiles({ size: 0, status: statusTypes.ACTIVE })
        setProfiles(res.data || [])

    }
    const getPatients = async () => {
        resetData && resetData();
        let res = await getAllPatients({ size: 0, profileId: ProfileId.PATIENT, managementCompanyId: companySelected, ParentId: companyId || '-1' })
        isSetdefaultCheck = true;
        let data = [];
        if (res.data.length == 0) {
            setDataSource([])
        }
        for (let index = 0; index < res.data.length; index++) {
            const pa = res.data[index];
            try {
                const contactFields = JSON.parse(pa.contactFields)
                const furtherValue = JSON.parse(pa.furtherValue)
                data.push({
                    id: pa.id,
                    'VisitCode': "",
                    'PID': pa.patientId,
                    'patientId': pa.patientId,
                    'FullName': pa.name || "".concat(ifNull(pa.FamilyName), ifNull(" " + pa.GivenName)),
                    'DOB': convertDateFormat(pa.dob, "YYYY-MM-DD"),
                    'Age': getAgeFromDOB(convertDateFormat(pa.dob, "YYYY-MM-DD")),
                    'Gender': pa.gender,
                    'PIN': furtherValue.length > 0 ? furtherValue[0].PIN : '',
                    'PhoneNumber': pa.phoneNumber,
                    'EmailAddress': contactFields.find(x => x.FieldCode == "PIN")?.FurtherValue,
                    'Title': furtherValue.find(x => x.FieldCode == "Title")?.FurtherValue,
                    'Address': pa.fullAddress,
                    "ProfileId": ProfileId.PATIENT,
                    'Nationality': 'VN',
                    'companyId': companyId
                })
                isSetdefaultCheck = true
                setDataSource([...data])
            } catch (error) {
                console.log(error);
            }
        }

    }
    const handleImport = e => {
        resetData && resetData();
        const files = e.target.files
        if (files.length) {
            let rowChoose = []
            const file = files[0]
            const reader = new FileReader()
            reader.onload = event => {
                const wb = read(event.target.result)
                const sheets = wb.SheetNames
                if (sheets.length) {
                    const datas = utils.sheet_to_formulae(wb.Sheets[sheets[0]]) || []
                    let dataSheet = datas.map(x => {
                        let keySplit = `=`;
                        if (x.includes(`='`))
                            keySplit = `='`;
                        let splitData = x.split(keySplit)
                        let s = {};
                        s.key = splitData[0]
                        s.value = splitData[1]
                        return s;
                    })
                    let currentRow = 8;
                    let isDone = true;
                    let index = 0;
                    do {
                        let colA = dataSheet.find(x => x.key == `A${currentRow}`)
                        if (colA == undefined || colA == null || colA == '') {
                            isDone = false
                        } else {
                            let item = {}
                            item.id = index
                            item.MedicalId = dataSheet.find(x => x.key == `G${currentRow}`)?.value
                            item.medicalId = dataSheet.find(x => x.key == `G${currentRow}`)?.value
                            item.PID = dataSheet.find(x => x.key == `B${currentRow}`)?.value
                            item.patientId = dataSheet.find(x => x.key == `B${currentRow}`)?.value
                            item.FullName = dataSheet.find(x => x.key == `C${currentRow}`)?.value
                            // item.PackageCode = values[7]
                            item.Gender = dataSheet.find(x => x.key == `D${currentRow}`)?.value == "Nam" ? "M" : "F"
                            if (item.PackageCode) {
                                let itemServices = profiles.find(x => x.code == item.PackageCode)
                                if (itemServices) {
                                    item.PackageName = itemServices.profileName
                                    item.itemServices = itemServices
                                    item.value = itemServices.code
                                    item.label = itemServices.profileName
                                }
                            }
                            let dob = dataSheet.find(x => x.key == `E${currentRow}`)?.value
                            if (dob && dob.length < 10) {
                                item.DOB = convertDateFormat(getJsDateFromExcel(dob), "YYYY-MM-DD")
                                item.Age = moment().year() - moment(item.DOB).year()
                            }
                            else {
                                item.DOB = moment(dob, "DD-MM-YYYY").format("YYYY-MM-DD")
                                item.Age = moment().year() - moment(item.DOB).year()
                            }
                            if(!item.DOB || `${item.DOB}` =='Invalid date' )
                            {
                                item.DOB = moment(dob).format("YYYY-MM-DD")
                                item.Age = moment().year() - moment(item.DOB).year()
                            }
                            rowChoose.push({ ...item, id: index })
                            index++
                            currentRow++;
                        }
                    } while (isDone);
                    isSetdefaultCheck = true
                    setDataSource([...rowChoose])
                }
            }
            reader.readAsArrayBuffer(file)
        }
    }

    useEffect(() => {
        setUpdatedTime(new Date())
        dataTmp = dataSource
        if (isSetdefaultCheck) {
            isSetdefaultCheck = false
            setDefaultSelected([dataSource.map(x => x.id)])
        }
        onChange && onChange(rowsSelected)
    }, [dataSource])

    useEffect(() => {
        onChange && onChange(rowsSelected)
    }, [rowsSelected])

    const onSelectCheckbox = (row, isSelected) => {
        const { rowsState, currentRow } = selectCheckboxHandler(
            rowsSelected,
            row,
            isSelected
        )
        setRowsSelected(rowsState)
    }

    const onSelectAllCheckbox = rows => {
        setRowsSelected(rows)
    }

    const getGender = (val) => {
        const tmp = val.toLocaleLowerCase()
        const res = genderListGlobal.find(x => `${x.value}`.toLocaleLowerCase() == tmp)?.label || ''
        return res
    }
    const setColumn = () => {
        let columns = [
            {
                dataField: "id",
                text: "id",
                hidden: true,
                headerAttrs: {
                    hidden: true
                }
            },
        ]
        fields.forEach(element => {
            const ob = {
                dataField: element.value,
                text: t(element.label),
                // headerStyle: { minWidth: 150, maxWidth: 150, width: 150, padding: 10 },
                style: element.style || {},
                formatter: (cellContent, item, index) => {
                    if (element.isEdit == true && element.value != "Gender") {
                        const idHTML = `groupRequest${element.value}${index}`
                        return (<React.Fragment>
                            <div className="d-flex  align-items-center">
                                <div id={idHTML + '_'} className={'groupRequestBlurItemFocus'} tabIndex={9999}
                                    onKeyDown={e => {
                                        if (e.key === 'Enter' || e.key === "Tab") {
                                            e.preventDefault()
                                            const fields =
                                                Array.from(document.getElementsByClassName('groupRequestBlurItemFocus')) ||
                                                []
                                            const position = fields.findIndex(x => x.id == `${idHTML}_`)
                                            if (position >= 0) {
                                                setTimeout(() => {
                                                    let el = fields[position + 1]
                                                    if (el) {
                                                        const parent = el.firstElementChild
                                                        if (parent) {
                                                            parent.firstElementChild && parent.firstElementChild.click()
                                                        }
                                                    }
                                                    el = document.getElementById(`${idHTML}`)
                                                    el && el.click()
                                                }, 100);

                                            }
                                        }
                                        e.stopPropagation()
                                    }}
                                    onFocus={() => {
                                        let el = document.getElementById(`${idHTML + '_'}`)
                                        if (el) {
                                            const parent = el.firstElementChild
                                            if (parent) {
                                                try {
                                                    parent && parent.click()
                                                } catch (error) {
                                                    console.log(error);
                                                }
                                            }
                                            if (element.maxLength) {
                                                let tmp = document.querySelector(`#${idHTML + '_'} input`)
                                                if (tmp) tmp.maxLength = element.maxLength
                                            }
                                        }
                                    }}
                                    onBlur={() => {
                                        let el = document.getElementById(`${idHTML}`)
                                        el && el.click()

                                    }}
                                    style={{ width: 120 }} onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    <Editable
                                        key={idHTML}
                                        alwaysEditing={false}
                                        disabled={false}
                                        editText={`${(item[element.value] == null || item[element.value] == '') ? '__' : item[element.value]}`}
                                        initialValue={`${(item[element.value] == null || item[element.value] == '') ? '' : item[element.value]}`}
                                        id={item.code + idHTML}
                                        isValueClickable={false}
                                        validate={(value) => {
                                            // if (value.length > 5) {
                                            //     return "Numbers not allowed"
                                            // }
                                        }}
                                        mode="inline"
                                        placement="top"
                                        showText={false}
                                        type="textfield"
                                        renderConfirmElement={<button type="button" id={idHTML}
                                            className="btn btn-outline-primary editable-submit btn-sm ms-2 me-1 d-none"
                                        ><i className="mdi mdi-check"></i></button>}
                                        renderCancelElement={cancelElement}
                                        onSubmit={(value) => {
                                            onEditData(value, element.value, item.id)
                                            if (element.value == 'MedicalId') {
                                                onEditData(value, 'medicalId', item.id)
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                        )
                    }
                    else
                        return <span>
                            {element.value != "Gender" ?
                                <span>
                                    {cellContent}
                                    {element.value == "FullName" && <span><br />{item.DOB}</span>}
                                </span>
                                :
                                <span>
                                    {getGender(`${cellContent}`)}
                                </span>
                            }

                        </span>
                },
            }
            columns.push(ob)
        });
        setColumnDisplay(columns)
    }
    const filterColumns = data => {
        const columns = Object.keys(data[0])
        return columns
    }

    const DownloadTemplate = async () => {
        const fileData = await downloadImportPatientGroupTemplate()
        const blob = new Blob([fileData])
        saveAs(blob, "PatientGroupTemplate.xlsx")
    }

    return (
        <Row className="px-2 h-100">
            <Col className="col-12 position-relative">
                <div className="mb-2 text-center">
                    {type == GroupTestRequestOption.Company &&
                        <CustomSelectAsync
                            name="companyIdGroup"
                            value={''}
                            valueName={''}
                            code={parameterCode.ORGANIZATIONS}
                            label={t("Company")}
                            group={`${companySelected}`}
                            // isDependent={true}
                            onChange={(e, values, name) => {
                                setCompanyId(values[0])
                            }}
                        />
                    }
                    {type == GroupTestRequestOption.UploadFile &&
                        <React.Fragment>
                            <input
                                id="importExcel"
                                name="reportTemplateName"
                                type="file"
                                className=" d-none"
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                onChange={e => {
                                    setFileName(e.target.files[0]?.name || "Upload")
                                    handleImport(e)
                                }}
                            />
                            <div className="d-flex justify-content-center">
                                <div style={{ marginRight: '10px' }}>
                                    <CustomButton
                                        color="primary"
                                        outline
                                        onClick={() => {
                                            $(`#importExcel`).click()
                                        }}
                                    >{t("Import")}</CustomButton>
                                </div>
                                <div>
                                    <CustomButton color="primary" outline onClick={() => {
                                        DownloadTemplate()
                                    }}>
                                        <i className="fa fa-download"></i>
                                        {t("Template")}
                                    </CustomButton>
                                </div>
                            </div>
                        </React.Fragment>
                    }
                </div>
                <div className="position-absolute strong text-primary" style={{ top: `${type == GroupTestRequestOption.Company ? '0px' : 'calc(50% - 14px)'}`, right: 10 }}>HIS:
                    <span style={{ color: '#000', marginRight: '10px' }}>{his?.label}</span>
                    <i className="fa fa-check-circle text-success"></i>
                </div>
            </Col>
            <Col className="col-12 h-100 overflow-auto group-patient-patient-list-table">
                <div className="mb-3">
                    {columnDisplay.length > 0 &&
                        <CustomBootstrapInlineTable
                            defaultSelected={defaultSelected}
                            columns={[...columnDisplay]}
                            showSelectRow={true}
                            data={dataSource || []}
                            updatedTime={updatedTime}
                            onSelect={onSelectCheckbox}
                            onSelectAll={onSelectAllCheckbox}
                            isScrollable
                            isEnableLoadInfo={true}
                            searchText={""}
                            draggable
                            keyField={"id"}
                            isTableScrollX={true}
                        />
                    }
                </div>
            </Col>
        </Row>
    )
}

export default withTranslation(["patientVisitPage", "common"])(GroupEmployee)
