import React, { useEffect, useState, useRef } from "react"
import { connect, useSelector } from "react-redux"
import { Row, Col, Card, CardBody, TabContent, TabPane } from "reactstrap"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { isEmpty } from "lodash"
import { AuditLogIdentifier, ModuleIds, RuleManagementType } from "constant"
import { ConfirmModal, CustomNav, CustomNavLink } from "components/Common"
import TestModal from "../Modal/TestModal"
import TitleAndInfo from "./TitleAndInfo"
import TabDetails from "./TabDetails"
import TabConfig from "../../TestConfig/TabConfig"



//Import actions
import {
    getTestDetail,
    addNewTest,
    updateTest,
    deleteTests,
} from "store/laboratory/tests/actions"

import { withTranslation } from "react-i18next"
import TabRuleBased from "../../TestConfig/TabRuleBased"
import TabAdditional from "pages/Laboratory/TestConfig/TabAdditional"
import TabNormalRange from "pages/Laboratory/TestConfig/TabNormalRange"
import TabResultTime from "pages/Laboratory/TestConfig/TabResultTime"
import { getUrlParamByKey, insertUrlParam } from "helpers/utilities"
import CustomResultAuditLog from "components/Common/CustomResultAuditLog"
import CustomTestAuditLog from "components/Common/CustomTestAuditLog"
import { getNormalRuleByTestCode, updateRuleManagement } from "store/actions"
import TabRule from "./TabRule"

const RESOURCE = ModuleIds.Test

const CardDetail = ({
    isEdit,
    history,
    match,
    test,
    onGetTestDetail,
    onUpdateTest,
    onAddNewTest,
    t,
    onDeleteTests,
    sticky,
    onUpdateRuleManagement,
    onGetNormalRuleByTestCode,
    ruleTest
}) => {
    const [isClone, setIsClone] = useState(false)
    const [modal, setModal] = useState(false)
    const formEl = useRef(null)

    const [rowEdit, setRowEdit] = useState({})
    const [ruleEdit, setRuleEdit] = useState({})
    const { params } = match
    const [confirmModal, setConfirmModal] = useState(false);
    const [currentTab, setCurrentTab] = useState(getUrlParamByKey('tab') || '1')
    const { currentPage } = useSelector(state => ({
        currentPage: state.test?.paging?.page,
    }))

    useEffect(() => {
        const tab = getUrlParamByKey('tab')
        if (tab && tab != currentTab)
            setCurrentTab(getUrlParamByKey('tab'))
    }, [window.location.search])

    const toggleModal = () => {
        setModal(prev => !prev)
    }

    const onEditHandler = () => {
        setIsClone(false)
        toggleModal()
    }

    const onCloneHandler = () => {
        setIsClone(true)
        toggleModal()
    }

    const onValidSubmit = async (e, values) => {
        const first = values?.testName.indexOf('[')
        const last = values?.testName.lastIndexOf(']')
        let customName = ''
        if (first !== -1 && last !== -1) {
            customName = values?.testName.slice(first + 1, last)
        }
        if (isClone) {
            const newTest = {
                ...values,
                testCode: values["testCode"],
                testName: values["testName"].replace(`[${customName}]`, '').trimEnd(),
                shortName: values["shortName"],
                quickCode: values["quickCode"],
                sampleType: values["sampleType"],
                category: values["category"],
                type: values["reportType"],
                displayOrder: values["displayOrder"] || 0,
                tags: values["tags"],
                remark: values["remark"],
                inUse: values["inUse"],
                customName: customName
            }
            onAddNewTest({ test: newTest, history });
        } else {
            const updateTest = {
                ...values,
                id: values['id'],
                testCode: values["testCode"],
                testName: values["testName"].replace(`[${customName}]`, '').trimEnd(),
                shortName: values["shortName"],
                quickCode: values["quickCode"],
                sampleType: values["sampleType"],
                category: values["category"],
                type: +values["reportType"],
                displayOrder: values["displayOrder"] || 0,
                tags: values["tags"],
                remark: values["remark"],
                inUse: values["inUse"],
                customName: customName
            }
            await onUpdateTest({
                test: updateTest,
                callback: isEdit ? onCancel : afterUpdate,
            })
        }
    }

    const onRuleSubmit = async (e, values) => {
        const type = RuleManagementType.normal
        values.expression = '1==1'
        onUpdateRuleManagement({ rules: { rules: [values] }, type, callback: afterRuleUpdate })
    }

    const afterUpdate = () => {
        onFetchDetail()
        toggleParamModal()
    }

    const afterRuleUpdate = () => {
        history.push(`view?tab=${currentTab}`)
        onGetNormalRuleByTestCode([test.testCode]);
    }

    const toggleParamModal = () => {
        setModal(prev => !prev)
    }

    const onCancel = () => {
        history.push(`view?tab=${currentTab}`)
    }

    const onFetchDetail = () => {
        if (params && params.id) {
            onGetTestDetail(params.id)
        }
    }

    useEffect(() => {
        onFetchDetail()
    }, [params.id])

    useEffect(() => {
        setModal(false)
    }, [params.id])

    useEffect(() => {
        setRowEdit(test)
        onGetNormalRuleByTestCode([test.testCode]);
    }, [test])

    useEffect(() => {
        setRuleEdit({ ...ruleTest })
    }, [ruleTest])
    const onDelete = () => {
        setConfirmModal(true)
    }

    const onDeleteToggleHandler = (e, param) => {
        setConfirmModal(false)
    }

    const onDeleteTestsHandler = () => {
        onDeleteTests({ tests: [test], callback: callback() })
    }

    const callback = () => {
        let url = `/Test`
        if (currentPage)
            url += `?page=${currentPage}`
        setConfirmModal(false)
        history.replace(url)
    }
    const ListTabName = [
        {
            id: 1,
            name: 'common:Detail'
        },
        {
            id: 2,
            name: t('testConfig:Rule')
        },
        {
            id: 7,
            name: t("testResultPage:History")
        }
    ]
    if (isEmpty(test)) return null;

    return (
        <React.Fragment>
            <div className="sticky-header-detail">
                <Row>
                    <Col lg="12">
                        <div className={sticky ? '' : 'sticky-header-detail-expand'}>
                            <TitleAndInfo
                                onEdit={onEditHandler}
                                onClone={onCloneHandler}
                                data={test}
                                title={t("Test")}
                                subtitle={test.testName}
                                resource={RESOURCE}
                                onDelete={onDelete}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
            <TestModal
                formEl={formEl}
                modal={modal}
                isEdit={!isClone}
                onValidSubmit={onValidSubmit}
                toggle={toggleModal}
                data={test}
            />
            <ConfirmModal
                modal={confirmModal}
                title={`${t("common:Delete")} ${t("Test")}`}
                message={`${t("message:DeleteRecordConfirm", {
                    entity: `${t("Test")}`,
                })}`}
                onToggle={onDeleteToggleHandler}
                onDelete={onDeleteTestsHandler}
            />
            <Row>
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <Row>
                                <CustomNav
                                    onToggle={e => {
                                        if (e != currentTab)
                                            insertUrlParam({ tab: e })
                                        setCurrentTab(e)

                                    }}
                                    defaultTab={currentTab}
                                    tabs
                                    className="nav-tabs-custom"
                                    tabContents={customActiveTab => (
                                        <TabContent
                                            activeTab={customActiveTab}
                                            className="p-3 text-muted"
                                        >
                                            <TabPane tabId="1">
                                                <TabDetails
                                                    onValidSubmit={onValidSubmit}
                                                    data={rowEdit}
                                                    isEdit={isEdit}
                                                    onCancel={onCancel}
                                                />
                                            </TabPane>
                                            <TabPane tabId="2">
                                                <TabRule
                                                    onValidSubmit={onRuleSubmit}
                                                    data={ruleEdit}
                                                    isEdit={isEdit}
                                                    onCancel={onCancel}
                                                />
                                            </TabPane>
                                            <TabPane tabId="7">
                                                <CustomTestAuditLog
                                                    resource={ModuleIds.Test}
                                                    identifier={AuditLogIdentifier.LA_Test + params.id}
                                                    // ref={refResult}
                                                    title={""}
                                                />
                                            </TabPane>
                                        </TabContent>
                                    )}
                                >
                                    {/* <CustomNavLink tabId="2">
                                        <span className="">{t("Code Details")}</span>
                                    </CustomNavLink> */}
                                    {ListTabName.map(row => <CustomNavLink key={row.id} tabId={row.id.toString()}>
                                        <span className="">{t(`${row.name}`)}</span>
                                    </CustomNavLink>)}
                                </CustomNav>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}


CardDetail.propTypes = {
    test: PropTypes.object,
    match: PropTypes.object,
    onGetTestDetail: PropTypes.func,
    onUpdateTest: PropTypes.func,
    onAddNewTest: PropTypes.func,
    t: PropTypes.any,
    onDeleteTests: PropTypes.func,
}

const mapStateToProps = ({ test, RuleManagement }) => {
    return ({
        test: test.test,
        ruleTest: RuleManagement.ruleTest
    })
}


const mapDispatchToProps = dispatch => ({
    onGetTestDetail: id => dispatch(getTestDetail(id)),
    onAddNewTest: (test, history) => dispatch(addNewTest(test, history)),
    onUpdateTest: test => dispatch(updateTest(test)),
    onDeleteTests: tests => dispatch(deleteTests(tests)),
    onUpdateRuleManagement: (payload, callback) => dispatch(updateRuleManagement(payload, callback)),
    onGetNormalRuleByTestCode: (payload, callback) => dispatch(getNormalRuleByTestCode(payload, callback))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["testPage", "common", "testResultPage", "testConfig"])(CardDetail)))