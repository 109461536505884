import { CustomBootstrapInlineTable } from "components/Common"
import { convertDateFormat } from "helpers/utilities"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"

const CustomResultAuditLogTable = ({
    onSelect,
    onSelectAll,
    data,
    onEdit,
    onDelete,
    t,
    loading,
    updatedTime,
    onRowClick,
    module,
    ...rest
}) => {
    const [testResultHistory, setTestResultHistory] = useState([])
    const [isJson, setIsJson] = useState(false);

    const formatMessage = (message) => {
        return { __html: message };
    };
    useEffect(() => {
        if (data) {
          const processedData = data.map(log => {
            const payload = log.payload.replace(/ISODate\("([^"]+)"\)/g, '"$1"')
            const parsedPayload = JSON.parse(payload)
            return {
              ...log,
              content: parsedPayload.Message,
            }
          })
          setTestResultHistory(processedData)
        }
      }, [data])

    const handleParseJSON = (msg) => {
        let tmp = '';
        try {
            tmp = msg ? JSON.parse(msg) : ''
            setIsJson(true)
        } catch (e) {
            tmp = msg || ''
            setIsJson(false)
        }
        // setDataDisplay(tmp)
        return tmp
    }

    useEffect(() => {
        handleParseJSON()
    }, [data])

    const columns = [
        {
            dataField: "id",
            text: "id",
            hidden: true,
            searchable: false,
        },
        {
            dataField: "idx",
            text: "#",
            searchable: false,
            headerStyle: { width: "50px" },
            formatter: (cellContent, user, index) => {
                return index + 1
            },
        },
        {
            dataField: "identifier",
            text: t("Id"),
            style: { width: "100px" },
            formatter: (cellContent) => {
                return <span>{cellContent.split(':')[2]}</span>
            },
        },
        {
            dataField: "timeStamp",
            text: t("testRequestPage:Timestamp"),
            formatter: (cellContent) => (
                <span>{convertDateFormat(cellContent, "YYYY-MM-DD HH:mm:ss")}</span>
            ),
        },
        {
            dataField: "userName",
            text: t("common:User"),
            formatter: (cellContent, user) => {
                return <Link to={`/User/${user.userId}/view?tab=1`} target="_blank">{cellContent ?? "System User"}</Link>
            },
        },
        {
            dataField: "",
            text: t("common:Module"),
            style: { width: "150px" },
            formatter: (cellContent, user) => {
                return <span className="d-flex badge-profile">{t(module || 'Request')}</span>
            },
        },
        {
            dataField: "content",
            text: t("common:Message"),
            style: {maxWidth:'250px'},
            formatter: (cellContent, user) => {
                const messageContent = cellContent ?? user.message;
            return (
              <div dangerouslySetInnerHTML={formatMessage(messageContent)} />
            );
    },
        },
        {
            dataField: "payload",
            text: t("Payload"),
            style: { textAlign: "center" },
            headerAlign: "center",
            formatter: (cellContent, item) => (
              <i
              onClick={e => {
                onRowClick(e, item)
              }}
              className="mdi mdi-eye"
              style={{
                fontSize: "18px",
                cursor: "pointer",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                transition: "color 0.3s ease-in-out",
              }}
              onMouseEnter={e => (e.currentTarget.style.color = "#0056b3")}
              onMouseLeave={e => (e.currentTarget.style.color = "#007bff")}
            >
            </i>
            ),
          },
        {
            dataField: "action",
            text: t("common:Action"),
            // headerStyle: { paddingLeft: "25px", width:'100px', minWidth:'100px', maxWidth:'100px', },
            // style: { paddingLeft: "25px", width:'100px', minWidth:'100px', maxWidth:'100px', },
            formatter: (cellContent) => {
                return <span className="d-flex badge-test">{t(`common:${cellContent}`)}</span>
            },
        },
    ];
    return (
        <div className="table-result-auditlog">
            <CustomBootstrapInlineTable
                columns={columns}
                data={testResultHistory}
                isScrollable
                updatedTime={updatedTime}
                isEnableLoadInfo={true}
                searchText={""}
                loading={loading}
                keyField={"id"}
                {...rest}
            />
        </div>
    )
}

export default withTranslation(["testRequestPage", "common"])(CustomResultAuditLogTable)
