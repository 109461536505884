import { ConfirmModal, ConfirmModal2, TitleAndTable, WarningModal, showToast } from "components/Common"
import { ModuleIds, parameterCode } from "constant"
import {
  onDelete,
  onDeleteToggle,
  selectCheckboxHandler
} from "helpers/utilities"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
//i18n
import { showErrToast } from "components/Common"
import { isEmpty } from "lodash"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import {
  addProfileMapping,
  addTestMapping,
  deleteProfileMapping,
  deleteTestMapping,
  getProfileMappingListWithPage,
  setConnectorScrollHisCode,
  updateProfileMapping,
  updateTestMapping
} from "store/connector/HISConnector/actions"
import HisCodeMappingModal from "./Modal/HisCodeMappingModal"
import HisCodeMappingTable from "./HisCodeMappingTable"
import HisCodeTableButtons from "./HisCodeTableButtons"
import { addNewCode, updateCode } from "store/actions"

const RESOURCE = ModuleIds.Connector
let pageGlobal = 0;
let countLoad = 0;
const MappingTab = ({
  onAddProfileMapping,
  onUpdateProfileMapping,
  onDeleteProfileMapping,
  onRefresh,
  hisConnector,
  updateProfileMappingTime,
  loadingProfileMapping,
  profileMappings,
  profileMappingPaging,
  paramId,
  onUpdateTestMapping,
  onAddTestMapping,
  onDeleteTestMapping,
  onLoad,
  onSetConnectorScrollHisCode,
  t,
  hisCodeTestLanguages,
  hisCodeProfileLanguages,
  languages,
  onAddNewCode,
  onUpdateCode,
  isDMSCode
}) => {
  const [confirmModal, setConfirmModal] = useState(false)
  const [confirmModal2, setConfirmModal2] = useState(false)
  const [row, setRow] = useState({})
  const [searchText, setSearchText] = useState('')
  const [rowDelete, setRowDelete] = useState({})
  const [rows, setRows] = useState([])
  const [selectMappingModal, setSelectMappingModal] = useState(false);
  const [defaultProfileMapping, setDefaultProfileMapping] = useState([])
  const [mappedList, setMappedList] = useState([])
  const [isSorted, setIsSorted] = useState(false)
  const [warningModal, setWarningModal] = useState(false)
  const [selectedEditItem, setSelectedEditItem] = useState({});
  const [curPage, setCurPage] = useState({
    page: 1, size: 100
  })
  useEffect(() => {
    countLoad++;
    if (countLoad <= 3) {
      onLoad(!!loadingProfileMapping)
    }
  }, [loadingProfileMapping])

  useEffect(() => {
    return () => {
      countLoad = 0
      onSetConnectorScrollHisCode({
        left: 0,
        top: 0
      })
    }
  }, [])

  const size = 15;
  const [page, setPage] = useState(0);

  const io = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (!entry.isIntersecting) {
        return;
      }
      pageGlobal++;
      setPage(pageGlobal)
    });
  });

  let elEnd = document.getElementById(`watch_end_of_document`)
  useEffect(() => {
    if (elEnd) {
      io.observe(elEnd);
    }
  }, [elEnd])

  const [model, setModel] = useState({
    searchHis: "",
    searchLis: "",
  })
  useEffect(() => {
    if (paramId)
      onRefresh({ id: paramId, page: curPage.page, size: curPage.size, ...model, insuranceCode: hisConnector.insuranceMappingKey, isDMSCode })
  }, [curPage, paramId])

  const onResetHandler = () => {
    const initModel = {
      searchHis: "",
      searchLis: "",
    }

    setModel(initModel)
  }

  const onAddClick = () => {
    setSelectMappingModal(true)
  }

  const createTestSuccess = () => {
    showToast(
      `${t("message:CreatedMessage", {
        field: `${t("hisConnectorPage:HisConnector")} <span class='text-decoration-underline fw-bold'></span>`,
      })}`
    )
    onRefresh({ id: paramId, page: curPage.page, size: curPage.size, ...model, insuranceCode: hisConnector.insuranceMappingKey, isDMSCode })
    toggleModal()
  }
  const createProfileSuccess = () => {
    showToast(
      `${t("message:CreatedMessage", {
        field: `${t(
          "hisConnectorPage:HisConnector"
        )} <span class='text-decoration-underline fw-bold'></span>`,
      })}`
    )
    onRefresh({ id: paramId, page: curPage.page, size: curPage.size, ...model, insuranceCode: hisConnector.insuranceMappingKey, isDMSCode })
    toggleModal()
  }
  const updateTestSuccess = () => {
    showToast(
      `${t("message:UpdatedMessage", {
        field: `${t(
          "hisConnectorPage:HisConnector"
        )} <span class='text-decoration-underline fw-bold'></span>`,
      })}`
    )
  }
  const updateProfileSuccess = () => {
    showToast(
      `${t("message:UpdatedMessage", {
        field: `${t(
          "hisConnectorPage:HisConnector"
        )} <span class='text-decoration-underline fw-bold'></span>`,
      })}`
    )
  }
  const submitCreateUpdate = (datas) => {
    let codeModify = []
    if (datas && datas.length > 0) {
      const codes = datas.map(x => x.hisCode) || []
      codeModify = (profileMappings || []).filter(x => codes.includes(x.hisCode))
      let testEmpty = codeModify.filter(x => `${x.lisCode}`.trim() == "" || x.lisCode == null)
      if (testEmpty.length > 0) {
        showErrToast(
          `${t("message:InvalidError", {
            field: `${t(testEmpty.map(x => x.lisCode).join())}`,
          })}`
        )
        return;
      }
    }
    let codesUpdate = [];
    let codesCreate = [];
    let seq = 1;
    codeModify.forEach(element => {
      languages.forEach(language => {
        element.message = element.value
        if (element.mappingId == null) {
          const e = {
            parameterId: element.isTest == true ? hisConnector.testMappingId : hisConnector.profileMappingId,
            code: element.hisCode,
            sequence: element.sequence || 1,
            languageCode: language.code,
            message: element.lisCode,
            group: element.group,
            inUse: element.inUse || true,
            isDefault: element.isDefault,
          }
          codesCreate.push(e)
        }
        else {
          let old = '';
          if (element.isTest == true) {
            old = hisCodeTestLanguages.find(x => x.languageCode == language.code && x.code == element.hisCode)
          }
          else {
            old = hisCodeProfileLanguages.find(x => x.languageCode == language.code && x.code == element.hisCode)
          }
          if (old) {
            const e = {
              id: old.id,
              parameterId: element.isTest == true ? hisConnector.testMappingId : hisConnector.profileMappingId,
              code: element.hisCode,
              sequence: element.sequence || 1,
              languageCode: language.code,
              message: element.lisCode,
              group: old.group,
              inUse: old.inUse || true,
              isDefault: old.isDefault,
            }
            codesUpdate.push(e)
          }
        }
      });
      seq++;
    });
    const sumSucess = codesCreate.length + codesUpdate.length;
    let countSuccess = 0;

    codesUpdate.forEach(element => {
      onUpdateCode({
        code: element, callback: () => {
          countSuccess++;
          if (sumSucess === countSuccess) {
            showToast(
              `${t("message:UpdatedMessage", { field: `${t("His Code")}` })}`
            )
            onRefreshHandler()
            setConfirmModal2(false)
          }
        }
      })
    });
    //create
    codesCreate.forEach(element => {
      onAddNewCode({
        code: element, callback: () => {
          countSuccess++;
          if (sumSucess === countSuccess) {
            showToast(
              `${t("message:UpdatedMessage", { field: `${t("His Code")}` })}`
            )
            onRefreshHandler()
            setConfirmModal2(false)
          }
        }
      })
    })
  }
  const handleValidSubmit = async (values) => {
    let parameterId = '';
    if (values.isProfile) {
      parameterId = hisConnector.profileMappingId;
    }
    else {
      parameterId = hisConnector.testMappingId;
    }
    if ((values.isEdit == true && values.lisCode) || (values.isMapped == true && values.isInsurance != true) || (values.insuranceCode && values.isInsurance == true)) {
      if (values.isProfile) {
        onUpdateProfileMapping({
          code: { ...values, parameterId: parameterId },
          selectedEditItem, callback: () => {
            if (values.insuranceCode && values.insuranceCode.length > 0) {
              values.languageCode = parameterCode.INSURANCE_LANGUAGE_MAPPING
              values.lisTestCode = values.insuranceCode
              values.isInsurance = true;
              onUpdateProfileMapping({
                code: { ...values, parameterId: parameterId },
                selectedEditItem,
                callback: () => {
                  updateProfileSuccess()
                  afterCreate()
                }
              })
            }
            else {
              updateProfileSuccess()
              afterCreate()
            }

          }
        })
      } else {
        onUpdateTestMapping({
          code: { ...values, parameterId: parameterId },
          selectedEditItem, callback: () => {
            if (values.insuranceCode && values.insuranceCode.length > 0) {
              values.languageCode = parameterCode.INSURANCE_LANGUAGE_MAPPING
              values.lisTestCode = values.insuranceCode
              values.isInsurance = true;
              onUpdateTestMapping({
                code: { ...values, parameterId: parameterId },
                selectedEditItem,
                callback: () => {
                  updateTestSuccess()
                  afterCreate()
                }
              })
            }
            else {
              updateTestSuccess()
              afterCreate()
            }

          }
        })
      }
    } else {
      if (selectMappingModal) {
        if (values.isTest) {
          onAddTestMapping({
            code: { ...values, parameterId: parameterId },
            callback: () => {
              if (values.insuranceCode && values.insuranceCode.length > 0) {
                values.languageCode = parameterCode.INSURANCE_LANGUAGE_MAPPING
                values.lisTestCode = values.insuranceCode
                values.isInsurance = true;
                onAddTestMapping({
                  code: { ...values, parameterId: parameterId },
                  callback: () => {
                    createTestSuccess()
                  }
                })
              }
              else {
                createTestSuccess()
              }
            }
          })
        } else {
          onAddProfileMapping({
            code: { ...values, parameterId: parameterId },
            callback: () => {
              if (values.insuranceCode && values.insuranceCode.length > 0) {
                values.languageCode = parameterCode.INSURANCE_LANGUAGE_MAPPING
                values.lisProfileCode = values.insuranceCode
                values.isInsurance = true;
                onAddProfileMapping({
                  code: { ...values, parameterId: parameterId },
                  callback: () => {
                    createProfileSuccess()
                  }
                })
              }
              else {
                createProfileSuccess()
              }
            }
          })
        }
      }
    }
  }

  const handleValidSubmitEdit = async (values) => {
    let parameterId = '';
    if (values.isProfile) {
      parameterId = hisConnector.profileMappingId;
    }
    else {
      parameterId = hisConnector.testMappingId;
    }

    if ((values.isMapped == true && values.isInsurance != true) || (values.insuranceCode && values.isInsurance == true)) {
      if (values.isProfile) {
        onUpdateProfileMapping({
          code: { ...values, parameterId: parameterId },
          selectedEditItem, callback: () => {
            updateProfileSuccess()
            afterCreate()
          }
        })
      } else {
        onUpdateTestMapping({
          code: { ...values, parameterId: parameterId },
          selectedEditItem, callback: () => {
            updateTestSuccess()
            afterCreate()
          }
        })
      }
    } else {
      if (values.isTest) {
        onAddTestMapping({
          code: { ...values, parameterId: parameterId }, callback: () => {
            createTestSuccess()
            afterCreate()
          }
        })
      } else {
        onAddProfileMapping({
          code: { ...values, parameterId: parameterId }, callback: () => {
            createProfileSuccess()
            afterCreate()
          }
        })
      }
    }
  }

  const afterCreate = () => {
    setSelectMappingModal(false)
    setSelectedEditItem(null)
    onRefresh({ id: paramId, page: curPage.page, size: curPage.size, ...model, insuranceCode: hisConnector.insuranceMappingKey, isDMSCode })
  }

  const resetState = () => {
    setRows([])
    setRow({})
  }

  const onDeleteToggleHandler = (e, field) => {
    onDeleteToggle({
      rows,
      row: field || rowDelete,
      setConfirmModal,
      setWarningModal,
      setRowDelete,
    })
  }

  const onDeleteMultipleRows = rowsState => {
    let deletedMapping = rowsState.map(item => {
      return { ...item, parameterId: hisConnector.profileMappingId, id: item.mappingId }
    })

    let mappedArr = deletedMapping.filter(x => !(!x.id))

    if (mappedArr.length == 0) {
      showErrToast(
        `${t("message:UnmappedHisCode", {
          field: `<span class='text-decoration-underline fw-bold'></span>`,
        })}`
      )
      return;
    }
    if (rowsState[0]?.isProfile) {
      onDeleteProfileMapping({
        codes: mappedArr,
        parameterId: hisConnector.profileMappingId,
        callback: () => {
          resetState()
          onRefresh({ id: paramId, page: curPage.page, size: curPage.size, ...model, insuranceCode: hisConnector.insuranceMappingKey, isDMSCode })
        }
      })
    }
    if (rowsState[0]?.isTest) {
      onDeleteTestMapping({
        codes: mappedArr,
        parameterId: hisConnector.testMappingId,
        callback: () => {
          resetState();
          onRefresh({ id: paramId, page: curPage.page, size: curPage.size, ...model, insuranceCode: hisConnector.insuranceMappingKey, isDMSCode })
        }
      })
    }
  }

  const onDeleteSingleRow = rowsState => {

    var deletedMapping = rowsState.map(item => {
      return { ...item, id: item.mappingId, }
    })

    if (!deletedMapping[0].id) {
      showErrToast(
        `${t("message:UnmappedHisCode", {
          field: `<span class='text-decoration-underline fw-bold'></span>`,
        })}`
      )
      return;
    }
    if (rowsState[0]?.isProfile) {
      onDeleteProfileMapping({
        codes: deletedMapping,
        parameterId: hisConnector.profileMappingId,
        callback: () => {
          setRows(prev => prev.filter(x => x.mappingId !== rowDelete.mappingId));
          onRefresh({ id: paramId, page: curPage.page, size: curPage.size, ...model, insuranceCode: hisConnector.insuranceMappingKey, isDMSCode })
        },
      })
    }
    if (deletedMapping[0]?.isTest) {
      onDeleteTestMapping({
        codes: deletedMapping,
        parameterId: hisConnector.testMappingId,
        callback: () => {
          setRows(prev => prev.filter(x => x.mappingId !== rowDelete.mappingId));
          onRefresh({ id: paramId, page: curPage.page, size: curPage.size, ...model, insuranceCode: hisConnector.insuranceMappingKey, isDMSCode })
        },
      })
    }
    setRowDelete({})
    setRow({})
  }

  const onDeleteParameterHandler = () => {
    onDelete({
      rowDelete,
      rows,
      onDeleteSingleRow,
      onDeleteMultipleRows,
    })
    // toggle modal
    setConfirmModal(false)
  }

  /**Get selected row and set to state
   *
   */
  const onSelectCheckbox = (row, isSelected) => {
    const { rowsState, currentRow } = selectCheckboxHandler(
      rows,
      row,
      isSelected
    )
    setRows(rowsState)
    setRow(currentRow)
  }

  const onSelectAllCheckbox = rows => {
    setRows(rows)
    if (rows.length < 1) setRow({})
    else setRow(rows[rows.length - 1])
  }

  /** Table methods */

  const onRefreshHandler = () => {
    let top = document.getElementById("resizeMeAsync")
    if (top) {
      // top.scrollIntoView(true)
      setTimeout(() => {
        setPage(0)
        pageGlobal = 0
      }, 100);
    }
    setIsSorted(false)
    onRefresh({ id: paramId, page: curPage.page, size: curPage.size, ...model, insuranceCode: hisConnector.insuranceMappingKey, isDMSCode })
  }

  const onSubmitFilter = (values) => {
    let val = {
      searchLIS: values?.searchLis,
      searchHIS: values?.searchHis,
    }

    val.mapped = values.mappingStatus

    setModel(val)
    onRefresh({ id: paramId, page: curPage.page, size: curPage.size, ...val, insuranceCode: hisConnector.insuranceMappingKey, isDMSCode })
  }

  const onDragEnd = data => {
    const newData = JSON.parse(JSON.stringify(data))
    setDefaultProfileMapping([...newData])
  }

  const onSortHandler = (a, b, order, dataTestProfile, newData) => {
    // if (a === "a") {
    //     return null
    // }
    // onDragEnd(newData)
    // return compareSortArray(a, b, order)
    const sortString = `${a}:${b}`
    onRefresh({ id: paramId, page: curPage.page, size: curPage.size, sort: sortString, ...model, insuranceCode: hisConnector.insuranceMappingKey, isDMSCode })
  }

  useEffect(() => {

    setDefaultProfileMapping(profileMappings)
    let tempMappedList = profileMappings.filter(item => !isEmpty(item?.lisProfileCode));
    setMappedList(tempMappedList);
  }, [profileMappings])

  const onEditHandler = (e, item) => {

    if (isEmpty(row) && !item) {
      setWarningModal(true)
      return;
    }

    if (!item) {
      item = row
    }

    setSelectedEditItem(item);
    setSelectMappingModal(true)

  }

  const toggleModal = () => {
    if (selectMappingModal) {
      setSelectedEditItem(null)
    }
    setSelectMappingModal(!selectMappingModal)
  }

  const onSearch = () => {
    let val = {
      searchHIS: searchText
    }
    setModel({ ...model, ...val })
    onRefresh({ id: paramId, page: curPage.page, size: curPage.size, ...val, insuranceCode: hisConnector.insuranceMappingKey, isDMSCode })
  }
  const onSearchTextChange = (e) => {
    setSearchText(e.target.value)
  }

  const onClearSearchTextChange = () => {
    setSearchText('')
    onRefresh({ page: curPage.page, size: curPage.size })
    onRefresh({ id: paramId, page: curPage.page, size: curPage.size, insuranceCode: hisConnector.insuranceMappingKey, isDMSCode })
  }
  return (
    <React.Fragment>
      {/* Table */}
      <TitleAndTable
        resource={RESOURCE}
        table={() => (
          <HisCodeMappingTable
            mappingKey={hisConnector.profileMappingKey}
            insuranceMappingKey={hisConnector.insuranceMappingKey}
            page={page}
            size={size}
            data={defaultProfileMapping}
            onSelect={onSelectCheckbox}
            onSelectAll={onSelectAllCheckbox}
            onDelete={onDeleteToggleHandler}
            onEdit={onEditHandler}
            loading={loadingProfileMapping}
            model={model}
            updatedTime={updateProfileMappingTime}
            onDragEnd={onDragEnd}
            isSorted={isSorted}
            onSort={onSortHandler}
            onPageChange={(e) => { setCurPage({ ...curPage, page: e }) }}
            paging={profileMappingPaging}
            onSizePerPageChange={(e) => { setCurPage({ page: 1, size: e }) }}
            onSubmit={handleValidSubmitEdit}
            isEnableRefresh={false}
            filterForm={null}
            onRefresh={onRefreshHandler}
            onResetFilter={onResetHandler}
            onSubmitFilter={onSubmitFilter}
            selectedRows={rows.map(r => r.id)}
            customButtonTable={() =>
              <HisCodeTableButtons
                onSetRows={(data) => {
                  setRows(data)
                }}
                onSubmit={() => {
                  if (rows.length == 0) {
                    setWarningModal(true)
                  }
                  else {
                    setConfirmModal2(true)
                  }
                }}
                data={defaultProfileMapping}
                onRefresh={() => onRefreshHandler()}
                onSearch={(e) => {
                  onSearch()
                }}
                t={t}
                loading={loadingProfileMapping}
                searchText={searchText}
                onChangeSearchText={onSearchTextChange}
                onClearSearchText={onClearSearchTextChange}
              />
            }
          />
        )}
        external
        subtitle={''}
        icon={false}
      />

      <WarningModal
        modal={warningModal}
        onToggle={() => setWarningModal(prev => !prev)}
        message={t("SelectRowWarning")}
      />

      <ConfirmModal
        modal={confirmModal}
        title={`${t("common:Delete")} ${t("common:Profile")}`}
        message={t("DeleteConfirm")}
        onToggle={onDeleteToggleHandler}
        onDelete={onDeleteParameterHandler}
      />
      <ConfirmModal2
        modal={confirmModal2}
        title={t("Department mapping")}
        message={t("Save Department mapping?")}
        onToggle={() => { setConfirmModal2(prev => !prev) }}
        onConfirm={() => {
          submitCreateUpdate(rows)
        }}
        btnConfirmText={t("common:Save")}
        isEdit
      />
      <HisCodeMappingModal
        item={selectedEditItem}
        mappedList={mappedList}
        mappingKey={hisConnector.profileMappingKey}
        hisParamaterKey={hisConnector.profileParamaterKey}
        testParamaterKey={hisConnector.testParamaterKey}
        insuranceMappingKey={hisConnector.insuranceMappingKey}
        modal={selectMappingModal}
        lang="vi"
        toggle={() => toggleModal()}
        onSubmit={handleValidSubmit}
      />

    </React.Fragment>
  )
}

MappingTab.propTypes = {
  hisConnectors: PropTypes.array,
  hisConnector: PropTypes.object,
  updateProfileMappingTime: PropTypes.any,
  loadingProfileMapping: PropTypes.bool,
  profileMappings: PropTypes.array,
  onRefresh: PropTypes.func,
  t: PropTypes.any,

}

MappingTab.defaultProps = {}

const mapStateToProps = ({ hisConnector, common }) => ({
  hisConnectors: hisConnector.hisConnectors,
  hisConnector: hisConnector.hisConnector,
  updateProfileMappingTime: hisConnector.updateProfileMappingTime,
  loadingProfileMapping: hisConnector.loadingProfileMapping,
  profileMappings: hisConnector.profileMappings,
  profileMappingPaging: hisConnector.profileMappingPaging,
  updateTestMappingTime: hisConnector.updateTestMappingTime,
  loadingTestMapping: hisConnector.loadingTestMapping,
  testMappings: hisConnector.testMappings,
  testMappingPaging: hisConnector.testMappingPaging,
  paramId: hisConnector.hisConnector?.id,
  hisCodeTestLanguages: hisConnector.hisCodeTestLanguages || [],
  hisCodeProfileLanguages: hisConnector.hisCodeProfileLanguages || [],
  languages: common.languages
})

const mapDispatchToProps = dispatch => ({
  onAddProfileMapping: mapping => dispatch(addProfileMapping(mapping)),
  onUpdateProfileMapping: mapping => dispatch(updateProfileMapping(mapping)),
  onDeleteProfileMapping: mappings => dispatch(deleteProfileMapping(mappings)),
  onRefresh: payload => dispatch(getProfileMappingListWithPage(payload)),
  onAddTestMapping: (mapping) => dispatch(addTestMapping(mapping)),
  onUpdateTestMapping: mapping => dispatch(updateTestMapping(mapping)),
  onDeleteTestMapping: mappings => dispatch(deleteTestMapping(mappings)),
  onSetConnectorScrollHisCode: mappings => dispatch(setConnectorScrollHisCode(mappings)),
  onAddNewCode: code => dispatch(addNewCode(code)),
  onUpdateCode: code => dispatch(updateCode(code)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["message", "common", "hisConnectorPage"])(MappingTab))