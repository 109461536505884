import * as signalR from '@microsoft/signalr';
import { showToastNotify } from 'components/Common/ToastrNotify';
import { useEffect } from 'react';
import { withTranslation } from 'react-i18next';

const SignalRNotify = ({ t, onRefresh }) => {
    let userInfo = localStorage.getItem("userInfo")
    let userInfoObject = JSON.parse(userInfo || "{}");
    useEffect(() => {
        try {
            //let connection = new signalR.HubConnectionBuilder()
            //    .withUrl(process.env.REACT_APP_MASTER_SETUP_ENDPOINT.replaceAll("/v1", "") + "/notification", {
            //        transport: signalR.HttpTransportType.LongPolling
            //    })
            //    .withAutomaticReconnect()
            //    .build();
            //connection.on("notification", data => {
            //    if (data && data.userReceivers && `,${data.userReceivers},`.includes(`,${userInfoObject.sub},`)) {
            //        onRefresh && onRefresh()
            //    }
            //});
            //connection.start();
        } catch (error) {
            console.log(error);
        }
    }, [])
    return (
        <></>
    );
}
export default withTranslation(["testRequestPage"])(SignalRNotify)