import { AvField, AvForm } from "availity-reactstrap-validation"

import { Button, Col, Label, ModalBody, ModalFooter, Row } from "reactstrap"

import {
  Accordion,
  AccordionWrapper,
  CustomAvField,
  CustomButton,
  CustomModal,
  CustomSelect,
  CustomSelectAsync,
  CustomSelectButton
} from "components/Common"
import { Instrument_Translator, Instrument_Translator_Type, parameterCode } from "constant/utility"

//i18n
import FormSwitchForm from "components/Common/FormSwitchForm"
import { getInvalidMessageI18n } from "helpers/utilities"
import { isEmpty } from "lodash"
import { withTranslation } from "react-i18next"
import { useEffect, useState } from "react"

const MachineModal = ({
  modal,
  toggle,
  isEdit,
  onValidSubmit,
  data,
  formEl,
  t,
}) => {
  const [type, setType] = useState(Instrument_Translator.Replace)
  const [testCode, setTestCode] = useState(undefined)
  const [instrumentId, setInstrumentId] = useState('')
  const [isAdd, setIsAdd] = useState(false)
  const [valueFrom1, setValueFrom1] = useState(Instrument_Translator_Type.PosNeg)
  const [valueFrom2, setValueFrom2] = useState(Instrument_Translator_Type.PosNeg)
  const [valueTo1, setValueTo1] = useState(Instrument_Translator_Type.Result)
  const [valueTo2, setValueTo2] = useState(Instrument_Translator_Type.Result)

  useEffect(() => {
    if (data.type != type) {
      setType(data.type || Instrument_Translator.Replace)
    }
    if (data.instrumentId != instrumentId) {
      setInstrumentId(data.instrumentId)
    }
    if (data.valueTo != valueTo1) {
      setValueTo1(data.valueTo || Instrument_Translator_Type.Result)
    }
    if (data.valueFrom != valueFrom1) {
      setValueFrom1(data.valueFrom || Instrument_Translator_Type.PosNeg)
    }
  }, [data])
  useEffect(() => {
    if (testCode == undefined) {
      setType(Instrument_Translator.Replace)
    }
  }, [testCode])

  useEffect(() => {
    setIsAdd(false);
  }, [modal])

  const title =
    (!!isEdit ? t("Edit Instrument Config") : t("Add Instrument Config"))
  if (isEdit && isEmpty(data)) return null

  return (
    <CustomModal modal={modal} title={title} onToggle={toggle}>
      <ModalBody>
        <AvForm
          ref={formEl}
          id="machineForm"
          onValidSubmit={onValidSubmit}
          model={data}
        >
          <CustomAvField name="id" type="hidden" value={data.id || ""} />
          <Row className="px-3">
            <Col xs="6">
              <div className="mb-3">
                <CustomSelectAsync
                  required
                  errorMessage={getInvalidMessageI18n(t, "Instrument")}
                  validate={{
                    required: { value: true },
                  }}
                  name="instrumentId"
                  value={data.instrumentId || ""}
                  code={parameterCode.INSTRUMENT_TRANSLATOR}
                  label={t("Instrument")}
                  detected={isEdit}
                  onChange={(a, b, c) => {
                    setInstrumentId(b[0] || '')
                  }}
                />
              </div>
            </Col>
            <Col xs="6">
              <div className="mb-3">
                <CustomSelectAsync
                  // required
                  // errorMessage={getInvalidMessageI18n(t, "Test Code")}
                  // validate={{
                  //   required: { value: true },
                  // }}
                  name="testCode"
                  value={data.insCode || ""}
                  valueNameNotSearch={(data.insCode && data.testCode && data.testName) ? `${data.insCode} - ${data.testCode} - ${data.testName}` : ''}
                  code={parameterCode.INSTRUMENT_TRANSLATOR_TESTCODE}
                  group={instrumentId || ''}
                  isDependent={true}
                  label={t("Test Code")}
                  detected={isEdit}
                  onChange={(e, vals) => {
                    setTestCode(vals[0])
                  }}
                />
              </div>
            </Col>
            <Col xs="6">
              <div className="mb-3">
                <CustomSelectButton
                  readOnly={testCode == undefined || testCode == null || testCode == ''}
                  type="button"
                  name="type"
                  value={type || Instrument_Translator.Replace}
                  valueName={""}
                  code={parameterCode.INSTRUMENT_TRANSLATOR}
                  label={t("Type")}
                  onChange={(e, values, name) => {
                    setType(values[0])
                    // onChangeHis(values[0], name)
                  }}
                />
              </div>
            </Col>
            <Col xs="6">
              <div className="d-flex">
                <div className="mb-3" style={{ width: 'calc(100% - 80px)', marginRight: '10px' }}>
                  <CustomAvField
                    disabled={!(type == Instrument_Translator.Numeric)}
                    name="formula"
                    type="text"
                    value={`${data.formula || ''}`}
                    label={t("Formula")}
                    detected={isEdit}
                    maxLength={50}
                  />
                </div>
                <div className="mb-3" style={{ width: '70px' }}>
                  <CustomAvField
                    disabled={!(type == Instrument_Translator.Numeric)}
                    name="numericRound"
                    type="number"
                    value={`${data.numericRound || 0}`}
                    label={t("Numeric Round")}
                    detected={isEdit}
                    maxLength={2}
                  />
                </div>
              </div>
            </Col>
            {type == Instrument_Translator.Replace &&
              <>
                <Col xs="6">
                  <div className="mb-3 instrument-translator-input">
                    <Label for="insResult">{t("Raw Result")}</Label>
                    <div className="input-group">
                      <div className="d-none">
                        <AvField type="hidden" name="valueFrom" value={valueFrom1} />
                      </div>
                      <Button
                        name="valueFrom"
                        value={valueFrom1}
                        style={{ width: '75px' }}
                        type="button"
                        color="primary"
                        onClick={() => {
                          setValueFrom1(valueFrom1 == Instrument_Translator_Type.PosNeg ? Instrument_Translator_Type.Result : Instrument_Translator_Type.PosNeg)
                        }}
                      >
                        {valueFrom1}
                      </Button>
                      <CustomAvField
                        disabled={!(type == Instrument_Translator.Replace)}
                        name="insResult"
                        type="text"
                        value={data.insResult || ""}
                        valueName={""}
                        label={t("")}
                        detected={isEdit}
                        maxLength={20}
                      />
                    </div>
                  </div>
                </Col>
                <Col xs="6" className="position-relative instrument-translator">
                  {isAdd == false && isEdit != true &&
                    <div className="position-absolute icon-content" onClick={() => {
                      setIsAdd(!isAdd)
                    }}>
                      <div className="icon">
                        <i className="fa fa-plus"></i>
                      </div>
                    </div>
                  }
                  <div className="mb-3 instrument-translator-input">
                    <Label for="result">{t("Result")}</Label>
                    <div className="input-group">
                      <div className="d-none">
                        <AvField type="hidden" name="valueTo" value={valueTo1} />
                      </div>
                      <Button
                        name="valueTo"
                        value={valueTo1}
                        style={{ width: '75px' }}
                        type="button"
                        color="primary"
                        onClick={() => {
                          setValueTo1(valueTo1 == Instrument_Translator_Type.PosNeg ? Instrument_Translator_Type.Result : Instrument_Translator_Type.PosNeg)
                        }}
                      >
                        {valueTo1}
                      </Button>
                      <CustomAvField
                        disabled={(type == Instrument_Translator.Numeric)}
                        name="result"
                        type="text"
                        value={data.result || ""}
                        valueName={""}
                        label={t("")}
                        detected={isEdit}
                        maxLength={20}
                      />
                    </div>
                  </div>
                </Col>
              </>
            }
            {type == Instrument_Translator.Replace && isAdd == true &&
              <>
                <Col xs="6">
                  <div className="mb-3 instrument-translator-input">
                    <Label for="insResult2">{t("Raw Result")}</Label>
                    <div className="input-group">
                      <div className="d-none">
                        <AvField type="hidden" name="valueFrom2" value={valueFrom2} />
                      </div>
                      <Button
                        name="valueFrom2"
                        value={valueFrom2}
                        style={{ width: '75px' }}
                        type="button"
                        color="primary"
                        onClick={() => {
                          setValueFrom2(valueFrom2 == Instrument_Translator_Type.PosNeg ? Instrument_Translator_Type.Result : Instrument_Translator_Type.PosNeg)
                        }}
                      >
                        {valueFrom2}
                      </Button>
                      <CustomAvField
                        disabled={!(type == Instrument_Translator.Replace)}
                        name="insResult2"
                        type="text"
                        value={data.insResult2 || ""}
                        valueName={""}
                        label={t("")}
                        detected={isEdit}
                        maxLength={20}
                      />
                    </div>
                  </div>
                </Col>
                <Col xs="6" className="position-relative instrument-translator">
                  {isAdd == true &&
                    <div className="position-absolute icon-content" onClick={() => {
                      setIsAdd(!isAdd)
                    }}>
                      <div className="icon">
                        <i className="fa fa-minus"></i>
                      </div>
                    </div>
                  }
                  <div className="mb-3 instrument-translator-input">
                    <Label for="result2">{t("Result")}</Label>
                    <div className="input-group">
                      <div className="d-none">
                        <AvField type="hidden" name="valueTo2" value={valueTo2} />
                      </div>
                      <Button
                        name="valueTo2"
                        value={valueTo2}
                        style={{ width: '75px' }}
                        type="button"
                        color="primary"
                        onClick={() => {
                          setValueTo2(valueTo2 == Instrument_Translator_Type.PosNeg ? Instrument_Translator_Type.Result : Instrument_Translator_Type.PosNeg)
                        }}
                      >
                        {valueTo2}
                      </Button>
                      <CustomAvField
                        disabled={(type == Instrument_Translator.Numeric)}
                        name="result2"
                        type="text"
                        value={data.result2 || ""}
                        valueName={""}
                        label={t("")}
                        detected={isEdit}
                        maxLength={20}
                      />
                    </div>
                  </div>
                </Col>
              </>
            }
          </Row>
          <button type="submit" className="d-none" />
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <CustomButton
          text={t("common:Cancel")}
          type="button"
          onClick={toggle}
          data-dismiss="modal"
          className="button-width"
        />

        <CustomButton
          text={t("common:Save")}
          type="submit"
          color="primary"
          onClick={() => formEl?.current?.submit()}
          className="save-user button-width"
          isEdit
        />
      </ModalFooter>
    </CustomModal>
  )
}

export default withTranslation(["machinePage", "common"])(MachineModal)
