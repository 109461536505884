import i18n from "i18next";
import { all, call, delay, put, select, takeEvery } from "redux-saga/effects";

//setting redux states
import {
    ADD_HISCCONECTOR,
    ADD_PROFILE_MAPPING,
    ADD_TEST_MAPPING,
    DELETE_HISCCONECTOR,
    DELETE_PROFILE_MAPPING,
    DELETE_TEST_MAPPING,
    GET_CONNECTOR_DEPARTMENT_MAPPING,
    GET_CONNECTOR_PHYSICIAN_MAPPING,
    GET_CONNECTOR_INSTRUMENT_MAPPING,
    GET_CONNECTOR_INSURANCE_MAPPING,
    GET_HISCCONECTOR, GET_HISCCONECTOR_DETAIL,
    /* ==== PROFILE MAPPING START === */
    GET_PROFILE_MAPPING_LIST,
    GET_PROFILE_MAPPING_LIST_WITH_PAGE,
    /* ==== PROFILE MAPPING END === */
    /* ==== TEST MAPPING START === */
    GET_TEST_MAPPING_LIST,
    GET_TEST_MAPPING_LIST_WITH_PAGE,
    UPDATE_HISCCONECTOR,
    UPDATE_PROFILE_MAPPING,
    UPDATE_TEST_MAPPING,
    GET_DMS_TESTCODE_MAPPING,
} from "./actionTypes";

import {
    addNewHisConnectorFail, addNewHisConnectorSuccess,
    addProfileMappingFail,
    addProfileMappingSuccess,
    addTestMappingFail,
    addTestMappingSuccess,
    deleteHisConnectorsFail, deleteHisConnectorsSuccess,
    deleteProfileMappingFail,
    deleteProfileMappingSuccess,
    deleteTestMappingFail,
    deleteTestMappingSuccess,
    getConnectorDepartmentMappingFail,
    getConnectorDepartmentMappingLanguageSuccess,
    getConnectorDepartmentMappingSuccess,
    getConnectorPhysicianMappingLanguageSuccess,
    getConnectorPhysicianMappingSuccess,
    getConnectorHisCodeProfileLanguageSuccess,
    getConnectorHisCodeTestLanguageSuccess,
    getConnectorInstrumentMappingFail,
    getConnectorInstrumentMappingLanguageSuccess,
    getConnectorInstrumentMappingSuccess,
    getConnectorInsuranceLanguageSuccess,
    getConnectorInsuranceMappingFail,
    getConnectorInsuranceMappingSuccess,
    getHisConnectorDetailFail, getHisConnectorDetailSuccess,
    getHisConnectorsFail, getHisConnectorsSuccess,
    getProfileMappingListFail,
    /* ==== PROFILE MAPPING START === */
    getProfileMappingListSuccess,
    getProfileMappingListWithPageFail,
    getProfileMappingListWithPageSuccess,
    getTestMappingListFail,
    /* ==== PROFILE MAPPING END === */
    /* ==== TEST MAPPING START === */
    getTestMappingListSuccess,
    getTestMappingListWithPageFail,
    getTestMappingListWithPageSuccess,
    setHisConnectorSearchQuery,
    updateHisConnectorFail, updateHisConnectorSuccess,
    updateProfileMappingFail,
    updateProfileMappingSuccess,
    updateTestMappingFail,
    updateTestMappingSuccess,
    getConnectorPhysicianMappingFail,
    getDMSTestMappingMappingFail,
    getDMSTestMappingMappingSuccess,
    getDMSTestMappingLanguageSuccess,
} from "./actions";


import {
    createHisConnector,
    deleteHisConnectorById,
    getAllHisConnector,
    getConnectorInsuranceMapping,
    getHisConnectorById,
    getProfileMappingList,
    getProfileMappingListWithPage,
    getTestMappingList,
    getTestMappingListWithPage,
    updateHisConnectorById,
} from "helpers/app-backend/hisConnectors_backend_helper";

import {
    createCodesByParameterId,
    createMappingCode,
    deleteCodesByParameterIdAndCodeId,
    getCodesByParameterIdWithPage,
    updateCodesByParameterId,
    updateMappingCodeByParameterId
} from "helpers/app-backend/parameters_backend_helper";


import { showToast } from "components/Common";
import { parameterCode } from "constant";
import { getAllCompanies, getAllDepartments, getAllParameters, getAllPhysicians, getFullTest } from "helpers/app-backend";
import { setParameterCodeSearchQuery } from "store/actions";
import { getAllMachine } from "helpers/app-backend/machines_backend_helper";
import { individualsHandler, physiciansHandler } from "helpers/common_services_helper";
const t = (msg, param) => i18n.t(msg, param)

function* fetchHisConnectors({ payload }) {
    try {
        const searchQuery = yield select(state => {
            return state.hisConnector.searchQuery
        })
        payload = { ...searchQuery, ...payload }
        const payloadCompany = { size: 100 }
        const { response, companies } = yield all({
            response: call(getAllHisConnector, payload),
            companies: call(getAllCompanies, payloadCompany)
        })
        let result = response;
        result.data = response.data.map(row => ({
            ...row,
            companyName: companies.data.find(x => `${x.id}` === `${row.companyId}`)?.name
        }))

        yield put(getHisConnectorsSuccess(result))
        yield put(setHisConnectorSearchQuery(payload))
    } catch (error) {
        console.log(error);
        yield put(getHisConnectorsFail(error))
    }
}

function* fetchHisConnectorDetail({ id, callback }) {
    try {
        const payloadCompany = { size: 100 }
        const { response, companies } = yield all({
            response: call(getHisConnectorById, id),
            companies: call(getAllCompanies, payloadCompany),
            // parameters: call(getAllParameters, payloadParameter),
        })
        let parameters = { data: [] }
        if (response) {
            let parameterKeys = [response.departmentMappingKey,
            response.physicianMappingKey,
            response.instrumentMappingKey,
            response.insuranceMappingKey,
            response.insuranceParameterKey,
            response.profileMappingKey,
            response.profileParamaterKey,
            response.testMappingKey,
            response.testParamaterKey,
            response.testCodeDMSMappingKey,
            response.testCodeDMSParamaterKey]
            parameterKeys = parameterKeys.filter(x => x != null && x != undefined && x != '')
            if (parameterKeys.length > 0) {
                const payloadParameter = {
                    "size": 0,
                    "parameterKey": parameterKeys,
                }
                parameters = yield call(getAllParameters, payloadParameter)
            }
        }

        let result = response;
        result.companyName = companies.data.find(x => `${x.id}` === `${result.companyId}`)?.name || "";
        result.profileMappingKeyName = parameters.data.find(x => `${x.parameterKey}` === `${result.profileMappingKey}`)?.name || "";
        result.profileParamaterKeyName = parameters.data.find(x => `${x.parameterKey}` === `${result.profileParamaterKey}`)?.name || "";
        result.testMappingKeyName = parameters.data.find(x => `${x.parameterKey}` === `${result.testMappingKey}`)?.name || "";
        result.testParamaterKeyName = parameters.data.find(x => `${x.parameterKey}` === `${result.testParamaterKey}`)?.name || "";
        result.insuranceMappingKeyName = parameters.data.find(x => `${x.parameterKey}` === `${result.insuranceMappingKey}`)?.name || "";
        result.insuranceParameterKeyName = parameters.data.find(x => `${x.parameterKey}` === `${result.insuranceParameterKey}`)?.name || "";
        result.departmentMappingKeyName = parameters.data.find(x => `${x.parameterKey}` === `${result.departmentMappingKey}`)?.name || "";
        result.physicianMappingKeyName = parameters.data.find(x => `${x.parameterKey}` === `${result.physicianMappingKey}`)?.name || "";
        result.instrumentMappingKeyName = parameters.data.find(x => `${x.parameterKey}` === `${result.instrumentMappingKey}`)?.name || "";
        result.testCodeDMSMappingKeyName = parameters.data.find(x => `${x.parameterKey}` === `${result.testCodeDMSMappingKey}`)?.name || "";
        result.testCodeDMSParamaterKeyName = parameters.data.find(x => `${x.parameterKey}` === `${result.testCodeDMSParamaterKey}`)?.name || "";


        result.profileMappingId = parameters.data.find(x => `${x.parameterKey}` === `${result.profileMappingKey}`)?.id || "";
        result.profileParamaterId = parameters.data.find(x => `${x.parameterKey}` === `${result.profileParamaterKey}`)?.id || "";
        result.testMappingId = parameters.data.find(x => `${x.parameterKey}` === `${result.testMappingKey}`)?.id || "";
        result.testParamaterId = parameters.data.find(x => `${x.parameterKey}` === `${result.testParamaterKey}`)?.id || "";
        result.insuranceMappingId = parameters.data.find(x => `${x.parameterKey}` === `${result.insuranceMappingKey}`)?.id || "";
        result.insuranceParameterId = parameters.data.find(x => `${x.parameterKey}` === `${result.insuranceParameterKey}`)?.id || "";
        result.departmentMappingId = parameters.data.find(x => `${x.parameterKey}` === `${result.departmentMappingKey}`)?.id || "";
        result.physicianMappingId = parameters.data.find(x => `${x.parameterKey}` === `${result.physicianMappingKey}`)?.id || "";
        result.instrumentMappingId = parameters.data.find(x => `${x.parameterKey}` === `${result.instrumentMappingKey}`)?.id || "";
        result.testCodeDMSMappingId = parameters.data.find(x => `${x.parameterKey}` === `${result.testCodeDMSMappingKey}`)?.id || "";
        result.testCodeDMSParamaterId = parameters.data.find(x => `${x.parameterKey}` === `${result.testCodeDMSParamaterKey}`)?.id || "";
        yield put(getHisConnectorDetailSuccess(result))
        callback && callback(result)
    } catch (error) {
        yield put(getHisConnectorDetailFail(error))
    }
}


function* onAddNewHisConnector({ payload: { hisConnector, history, callback } }) {
    try {
        const response = yield call(createHisConnector, hisConnector)
        const id = response?.id
        yield put(addNewHisConnectorSuccess(id))
        const url = `/Connector/${id}/view`
        showToast(
            `${t("message:CreatedMessage", {
                field: `${t(
                    "hisConnectorPage:Connector"
                )} <span class='text-decoration-underline fw-bold'>${hisConnector["name"]
                    }</span>`,
            })}`
        )
        if (history)
            history.push(url)
        if (callback)
            callback()
    } catch (error) {
        console.log(error)
        yield put(addNewHisConnectorFail(error))
    }
}

function* onUpdateHisConnector({ payload: { hisConnector, callback } }) {
    try {
        const response = yield call(updateHisConnectorById, hisConnector)
        yield put(updateHisConnectorSuccess(hisConnector))

        showToast(
            `${t("message:UpdatedMessage", {
                field: `${t(
                    "hisConnectorPage:Connector"
                )} <span class='text-decoration-underline fw-bold'>${hisConnector["name"]
                    }</span>`,
            })}`
        )
        if (callback) callback()
    } catch (error) {
        console.log(error);
        yield put(updateHisConnectorFail(error))
    }
}

function* onDeleteHisConnector({ payload: { hisConnectors, callback } }) {
    try {
        const response = yield call(deleteHisConnectorById, hisConnectors)
        yield put(deleteHisConnectorsSuccess(hisConnectors))
        showToast(
            `${t("message:DeletedMessage", {
                field: `${t("hisConnectorPage:Connector")}`,
            })}`
        )
        if (callback) callback()
    } catch (error) {
        console.log(error);
        yield put(deleteHisConnectorsFail(error))
    }
}

/* ==== PROFILE MAPPING START === */

function* fetchProfileMappingList({ payload }) {
    try {
        //yield delay(500)
        const response = yield call(getProfileMappingList, payload)
        // const { id } = payload;
        // const response = yield call(getProfileMappingList, id)
        const temp = response.map(item => {
            return {
                ...item, id: item.hisProfileCode
                // new Date().getTime()
            }
        })

        yield put(getProfileMappingListSuccess(temp))
    } catch (error) {
        console.log(error);
        yield put(getProfileMappingListFail(error))
    }
}

function* fetchProfileMappingTestList({ payload }) {
    try {
        let searchQuery = yield select(state => state.hisConnector.searchQueryTest)
        let hisConnector = yield select(state => state.hisConnector.hisConnector)
        yield delay(400)
        payload.size = 0
        const sort = payload?.sort ? payload?.sort.split(':') : ['hisCode']
        if (!payload.page) {
            payload.page = 1
        }
        payload = { ...payload, ...searchQuery }
        if (payload.sort) {
            payload.sort = ''
        }
        // let profiles = yield call(getProfileMappingListWithPage, payload)
        // let tests = yield call(getTestMappingListWithPage, payload)
        const { profiles, tests } = yield all({
            profiles: call(getProfileMappingListWithPage, payload),
            tests: call(getTestMappingListWithPage, payload),
            // mappingTests: call(getCodesByParameterIdWithPage, hisConnector.testMappingId, { size: 0, lang: '*', search: payload.search || '' }),
            // mappingProfiles: call(getCodesByParameterIdWithPage, hisConnector.profileMappingId, { size: 0, lang: '*', search: payload.search || '' })
        })
        let mappingTests = { data: [] }
        let mappingProfiles = { data: [] }

        if (hisConnector.testMappingId) {
            mappingTests = yield call(getCodesByParameterIdWithPage, hisConnector.testMappingId, { size: 0, lang: '*', search: payload.search || '' })
        }
        if (hisConnector.profileMappingId) {
            mappingProfiles = yield call(getCodesByParameterIdWithPage, hisConnector.profileMappingId, { size: 0, lang: '*', search: payload.search || '' })
        }

        profiles.data = profiles.data.map(item => {
            return {
                ...item,
                id: item.hisProfileCode,
                hisCode: item.hisProfileCode,
                hisName: item.hisProfileName,
                lisCode: item.lisProfileCode,
                lisName: item.lisProfileName,
                isProfile: true
            }
        })

        tests.data = tests.data.map(item => {
            return {
                ...item,
                id: item.hisTestCode,
                hisCode: item.hisTestCode,
                hisName: item.hisTestName,
                lisCode: item.lisTestCode,
                lisName: item.lisTestName,
                isTest: true
            }
        })
        const response = { data: [...profiles.data, ...tests.data] }
        const dataSort = _.orderBy(response.data, sort[0], sort[1]);
        response.data = [...dataSort]
        yield put(getProfileMappingListWithPageSuccess(response))
        yield put(getConnectorHisCodeTestLanguageSuccess(mappingTests?.data || []))
        yield put(getConnectorHisCodeProfileLanguageSuccess(mappingProfiles?.data || []))
    } catch (error) {
        console.log(error);
        yield put(getProfileMappingListWithPageFail(error))
    }
}

function* onAddProfileMapping({ payload: { code, callback } }) {
    try {
        const request = {
            parameterId: code.parameterId,
            message: code.hisProfileCode,
            code: code.lisProfileCode,
            languageCode: code.languageCode || 'vi',
            inUse: true,
            isMapping: true
        }

        let response = yield call(createCodesByParameterId, request)
        if (code.languageCode != parameterCode.INSURANCE_LANGUAGE_MAPPING) {
            request.languageCode = "en"
            response = yield call(createCodesByParameterId, request)
        }
        yield delay(500)
        yield put(addProfileMappingSuccess(response))
        // showToast(
        //     `${t("message:CreatedMessage", {
        //         field: `${t(
        //             "hisConnectorPage:HisConnector"
        //         )} <span class='text-decoration-underline fw-bold'>${request["code"]
        //             }</span>`,
        //     })}`
        // )
        callback()
    } catch (error) {
        console.log(error);
        yield put(addProfileMappingFail(error))
    }
}

function* onUpdateProfileMapping({ payload: { code, callback } }) {
    try {
        const request = {
            id: code.mappingId,
            parameterId: code.parameterId,
            message: code.hisProfileCode,
            code: code.lisProfileCode,
            languageCode: code.languageCode || 'vi',
            inUse: true,
            isInsurance: code.isInsurance == true,
        }

        const response = yield call(updateCodesByParameterId, request)
        yield delay(500)
        yield put(updateProfileMappingSuccess(code))
        // showToast(
        //     `${t("message:UpdatedMessage", {
        //         field: `${t(
        //             "hisConnectorPage:HisConnector"
        //         )} <span class='text-decoration-underline fw-bold'>${request["code"]
        //             }</span>`,
        //     })}`
        // )

        callback()
    } catch (error) {
        yield put(updateProfileMappingFail(error))
    }
}

function* onDeleteProfileMapping({ payload: { codes, parameterId, callback } }) {
    try {
        const response = yield call(
            deleteCodesByParameterIdAndCodeId,
            parameterId,
            codes
        )
        yield delay(500)
        yield put(deleteProfileMappingSuccess(codes))

        showToast(
            `${t("message:DeletedMessage", {
                field: `${t("hisConnectorPage:HisConnector")}`,
            })}`
        )
        // delay(200)
        callback()
    } catch (error) {
        console.log(error)
        yield put(deleteProfileMappingFail(error))
    }
}

/* ==== PROFILE MAPPING END === */

/* ==== TEST MAPPING START === */

function* fetchTestMappingList({ payload }) {
    try {
        yield delay(400) //delay(500)
        const response = yield call(getTestMappingList, payload)
        const temp = response.map(item => {
            return {
                ...item, id: item.hisTestCode
                // new Date().getTime()
            }
        })
        yield put(getTestMappingListSuccess(temp))

    } catch (error) {
        console.log(error);
        yield put(getTestMappingListFail(error))
    }
}

function* fetchTestMappingListWithPage({ payload }) {
    try {
        let searchQuery = yield select(state => state.hisConnector.searchQueryTest)
        yield delay(400)
        // if (!payload.size) {
        //     payload.size = 50
        // }
        payload.size = 0
        if (!payload.page) {
            payload.page = 1
        }
        payload = { ...payload, ...searchQuery }
        let response = yield call(getTestMappingListWithPage, payload)
        response.data = response.data.map(item => {
            return {
                ...item, id: item.hisTestCode
            }
        })
        yield put(getTestMappingListWithPageSuccess(response))
        yield put(setParameterCodeSearchQuery(searchQuery))
    } catch (error) {
        console.log(error);
        yield put(getTestMappingListWithPageFail(error))
    }
}

function* onAddTestMapping({ payload: { code, callback } }) {
    try {
        let request = {
            parameterId: code.parameterId,
            codeHis: code.hisTestCode,
            codeLis: code.lisTestCode,
            languageCode: code.languageCode || 'vi',
            inUse: true
        }
        let response = yield call(createMappingCode, request)
        if (code.languageCode != parameterCode.INSURANCE_LANGUAGE_MAPPING) {
            request.languageCode = "en"
            response = yield call(createMappingCode, request)
        }
        yield put(addTestMappingSuccess(response))
        // showToast(
        //     `${t("message:CreatedMessage", {
        //         field: `${t(
        //             "hisConnectorPage:HisConnector"
        //         )} <span class='text-decoration-underline fw-bold'>${request["code"]
        //             }</span>`,
        //     })}`
        // )
        callback()
    } catch (error) {
        yield put(addTestMappingFail(error))
    }
}

function* onUpdateTestMapping({ payload: { code, callback } }) {
    try {
        const request = {
            id: code.mappingId,
            parameterId: code.parameterId,
            codeHis: code.hisTestCode,
            codeLis: code.lisTestCode,
            languageCode: code.languageCode || 'vi',
            inUse: true,
            isInsurance: code.isInsurance == true,
        }
        const response = yield call(updateMappingCodeByParameterId, request)
        yield put(updateTestMappingSuccess(code))
        // showToast(
        //     `${t("message:UpdatedMessage", {
        //         field: `${t(
        //             "hisConnectorPage:HisConnector"
        //         )} <span class='text-decoration-underline fw-bold'>${request["code"]
        //             }</span>`,
        //     })}`
        // )

        callback()
    } catch (error) {
        yield put(updateTestMappingFail(error))
    }
}

function* onDeleteTestMapping({ payload: { codes, parameterId, callback } }) {
    try {
        const response = yield call(
            deleteCodesByParameterIdAndCodeId,
            parameterId,
            codes
        )
        yield put(deleteTestMappingSuccess(codes))

        showToast(
            `${t("message:DeletedMessage", {
                field: `${t("hisConnectorPage:HisConnector")}`,
            })}`
        )
        yield delay(200)
        callback()
    } catch (error) {
        console.log(error)
        yield put(deleteTestMappingFail(error))
    }
}

/* ==== TEST MAPPING END === */
function* fetchInsuranceMappingList({ payload }) {
    try {
        let searchQuery = yield select(state => state.hisConnector.searchQueryInsurance)
        let hisConnector = yield select(state => state.hisConnector.hisConnector)
        yield delay(400)
        payload.size = 0
        const sort = payload?.sort ? payload?.sort.split(':') : ['hisCode']
        if (!payload.page) {
            payload.page = 1
        }
        payload = { ...payload, ...searchQuery }
        if (payload.sort) {
            payload.sort = ''
        }
        //
        // let tests = yield call(getConnectorInsuranceMapping, payload)
        const { tests, mappings } = yield all({
            tests: call(getConnectorInsuranceMapping, payload),
            mappings: call(getCodesByParameterIdWithPage, hisConnector.insuranceMappingId, { size: 0, lang: '*', search: payload.search || '' })
        })
        tests.data = tests.data.map(item => {
            const child = mappings.data.find(x => x.code == item.lisTestCode)
            return {
                ...item,
                id: item.lisTestCode,
                isTest: item.isTest,
                mappingId: child?.id || null,
                languageCode: child?.languageCode || 'vi',
                parameterId: mappings?.data[0]?.parameterId,
                sequence: child?.sequence || 1,
                group: child?.group || null,
                isDefault: child?.isDefault || false,
                inUse: child?.inUse || true
            }
        })
        const response = { data: [...tests.data] }
        const dataSort = _.orderBy(response.data, sort[0], sort[1]);
        response.data = [...dataSort]
        yield put(getConnectorInsuranceMappingSuccess(response))
        yield put(getConnectorInsuranceLanguageSuccess(mappings))
    } catch (error) {
        console.log(error);
        yield put(getConnectorInsuranceMappingFail(error))
    }
}

function* fetchDepartmentMappingList({ payload }) {
    try {
        let searchQuery = yield select(state => state.hisConnector.searchQueryDepartment)
        let hisConnector = yield select(state => state.hisConnector.hisConnector)
        yield delay(400)
        payload.size = 0
        // const sort = payload?.sort ? payload?.sort.split(':') : ['hisCode']
        if (!payload.page) {
            payload.page = 1
        }
        payload = { ...payload, ...searchQuery }
        if (payload.sort) {
            payload.sort = ''
        }
        const { depts, mappings } = yield all({
            depts: call(getAllDepartments, payload),
            mappings: call(getCodesByParameterIdWithPage, hisConnector.departmentMappingId, { size: 0, lang: '*', search: payload.search || '' })
        })

        depts.data = depts.data.map(item => {
            const child = mappings.data.find(x => x.code == item.managedCode)
            return {
                ...item,
                departmentHis: child?.message || '',
                mappingId: child?.id || null,
                languageCode: child?.languageCode || 'vi',
                parameterId: mappings?.data[0]?.parameterId,
                sequence: child?.sequence || 1,
                group: child?.group || null,
                isDefault: child?.isDefault || false,
                inUse: child?.inUse || true
            }
        })

        const response = { data: [...depts.data] }
        yield put(getConnectorDepartmentMappingLanguageSuccess(mappings))
        yield put(getConnectorDepartmentMappingSuccess(response))
    } catch (error) {
        console.log(error);
        yield put(getConnectorDepartmentMappingFail(error))
    }
}

function* fetchPhysicianMappingList({ payload }) {
    try {
        let searchQuery = yield select(state => state.hisConnector.searchQueryPhysician)
        let hisConnector = yield select(state => state.hisConnector.hisConnector)
        yield delay(400)
        payload.size = 0
        // const sort = payload?.sort ? payload?.sort.split(':') : ['hisCode']
        if (!payload.page) {
            payload.page = 1
        }
        payload = { ...payload, ...searchQuery }
        if (payload.sort) {
            payload.sort = ''
        }
        const { phys, mappings } = yield all({
            phys: call(getAllPhysicians, payload),
            mappings: call(getCodesByParameterIdWithPage, hisConnector.physicianMappingId, { size: 0, lang: '*', search: payload.search || '' })
        })

        phys.data = phys.data.map(item => {
            const child = mappings.data.find(x => x.code == item.managedCode)
            return {
                ...item,
                physicianHis: child?.message || '',
                mappingId: child?.id || null,
                languageCode: child?.languageCode || 'vi',
                parameterId: mappings?.data[0]?.parameterId,
                sequence: child?.sequence || 1,
                group: child?.group || null,
                isDefault: child?.isDefault || false,
                inUse: child?.inUse || true
            }
        })

        // if (phys?.data?.length > 0) {
        //     yield call(individualsHandler, phys.data)
        //     yield call(physiciansHandler, phys.data)
        // }

        const response = { data: [...phys.data] }
        yield put(getConnectorPhysicianMappingLanguageSuccess(mappings))
        yield put(getConnectorPhysicianMappingSuccess(response))
    } catch (error) {
        console.log(error);
        yield put(getConnectorPhysicianMappingFail(error))
    }
}

function* fetchInstrumentMappingList({ payload }) {
    try {
        let searchQuery = yield select(state => state.hisConnector.searchQueryInstrument)
        let hisConnector = yield select(state => state.hisConnector.hisConnector)
        yield delay(400)
        payload.size = 0
        if (!payload.page) {
            payload.page = 1
        }
        payload = { ...payload, ...searchQuery }
        if (payload.sort) {
            payload.sort = ''
        }

        const { ins, mappings } = yield all({
            ins: call(getAllMachine, payload),
            mappings: call(getCodesByParameterIdWithPage, hisConnector.instrumentMappingId, { size: 0, lang: '*', search: payload.search || '' })
        })
        ins.data = ins.data.map(item => {
            const child = mappings.data.find(x => x.code == item.manageCode)
            return {
                ...item,
                instrumentHis: child?.message || '',
                mappingId: child?.id || null,
                languageCode: child?.languageCode || 'vi',
                parameterId: mappings?.data[0]?.parameterId,
                sequence: child?.sequence || 1,
                group: child?.group || null,
                isDefault: child?.isDefault || false,
                inUse: child?.inUse || true
            }
        })
        const response = { data: [...ins.data] }
        yield put(getConnectorInstrumentMappingLanguageSuccess(mappings))
        yield put(getConnectorInstrumentMappingSuccess(response))
    } catch (error) {
        console.log(error);
        yield put(getConnectorInstrumentMappingFail(error))
    }
}

function* fetchDMSTestCodeMappingList({ payload }) {
    try {
        let searchQuery = yield select(state => state.hisConnector.searchQueryInstrument)
        let hisConnector = yield select(state => state.hisConnector.hisConnector)
        yield delay(400)
        payload.size = 0
        if (!payload.page) {
            payload.page = 1
        }
        payload = { ...payload, ...searchQuery }
        if (payload.sort) {
            payload.sort = ''
        }

        const { tests, mappings } = yield all({
            // ins: call(getAllMachine, payload),
            tests: call(getFullTest, {}),
            mappings: call(getCodesByParameterIdWithPage, hisConnector.testCodeDMSMappingId, { size: 0, lang: '*', search: payload.search || '' })
        })
        const dmsTestCode = tests.map(item => {
            const child = mappings.data.find(x => x.code == item.testCode)
            return {
                ...item,
                testCodeDMS: child?.message || '',
                mappingId: child?.id || null,
                languageCode: child?.languageCode || 'vi',
                parameterId: mappings?.data[0]?.parameterId,
                sequence: child?.sequence || 1,
                group: child?.group || null,
                isDefault: child?.isDefault || false,
                inUse: child?.inUse || true
            }
        })

        const response = { data: dmsTestCode }
        yield put(getDMSTestMappingLanguageSuccess(mappings))
        yield put(getDMSTestMappingMappingSuccess(response))
    } catch (error) {
        console.log(error)
        yield put(getDMSTestMappingMappingFail(error))
    }
}

function* hisConnectorSaga() {
    yield takeEvery(GET_HISCCONECTOR, fetchHisConnectors)
    yield takeEvery(GET_HISCCONECTOR_DETAIL, fetchHisConnectorDetail)
    yield takeEvery(ADD_HISCCONECTOR, onAddNewHisConnector)
    yield takeEvery(UPDATE_HISCCONECTOR, onUpdateHisConnector)
    yield takeEvery(DELETE_HISCCONECTOR, onDeleteHisConnector)

    yield takeEvery(GET_PROFILE_MAPPING_LIST, fetchProfileMappingList)
    yield takeEvery(GET_PROFILE_MAPPING_LIST_WITH_PAGE, fetchProfileMappingTestList)
    yield takeEvery(ADD_PROFILE_MAPPING, onAddProfileMapping)
    yield takeEvery(UPDATE_PROFILE_MAPPING, onUpdateProfileMapping)
    yield takeEvery(DELETE_PROFILE_MAPPING, onDeleteProfileMapping)

    yield takeEvery(GET_TEST_MAPPING_LIST, fetchTestMappingList)
    yield takeEvery(GET_TEST_MAPPING_LIST_WITH_PAGE, fetchTestMappingListWithPage)
    yield takeEvery(ADD_TEST_MAPPING, onAddTestMapping)
    yield takeEvery(UPDATE_TEST_MAPPING, onUpdateTestMapping)
    yield takeEvery(DELETE_TEST_MAPPING, onDeleteTestMapping)
    yield takeEvery(GET_CONNECTOR_INSURANCE_MAPPING, fetchInsuranceMappingList)
    yield takeEvery(GET_CONNECTOR_DEPARTMENT_MAPPING, fetchDepartmentMappingList)
    yield takeEvery(GET_CONNECTOR_PHYSICIAN_MAPPING, fetchPhysicianMappingList)
    yield takeEvery(GET_CONNECTOR_INSTRUMENT_MAPPING, fetchInstrumentMappingList)
    yield takeEvery(GET_DMS_TESTCODE_MAPPING, fetchDMSTestCodeMappingList)

}
export default hisConnectorSaga