import { call, put, takeEvery, select } from "redux-saga/effects"
import i18n from "i18next"
// Settings Redux States
import {
  GET_ORGANIZATIONS,
  GET_ORGANIZATION_DETAIL,
  ADD_NEW_ORGANIZATION,
  DELETE_ORGANIZATIONS,
  UPDATE_ORGANIZATION,
} from "./actionTypes"

import {
  getOrganizationsFail,
  getOrganizationsSuccess,
  getOrganizationDetailFail,
  getOrganizationDetailSuccess,
  addOrganizationSuccess,
  addOrganizationFail,
  updateOrganizationSuccess,
  updateOrganizationFail,
  deleteOrganizationsSuccess,
  deleteOrganizationsFail,
  setOrganizationSearchQuery,
} from "./actions"

import {
  getAllOrganizations,
  getOrganizationById,
  updateOrganizationById,
  deleteOrganizationById,
  createOrganization,
  getProfileById,
} from "helpers/app-backend"

import { showToast } from "components/Common"

import {
  individualsHandler,
  setSystemNameArray,
} from "helpers/common_services_helper"
import { isEmptyValues } from "helpers/utilities"
import { textFieldTypes } from "constant"

const t = (msg, param) => i18n.t(msg, param)

function* fetchOrganizationDetail({ payload: { id, callback } }) {
  try {
    const response = yield call(getOrganizationById, id)
    // get proifleName
    if (!isEmptyValues(response?.ProfileId) && response.ProfileId > 0) {
      const profile = yield call(getProfileById, response.ProfileId)
      response.ProfileName = profile.name

      // add additional fields
      const arrayName = ["ManagementCompanyId"]
      yield call(setSystemNameArray, {
        type: textFieldTypes.COMPANY,
        data: response,
        arrayName,
      })
    }

    yield put(getOrganizationDetailSuccess(response))
    if (callback) callback()
  } catch (error) {
    yield put(getOrganizationDetailFail(error))
  }
}

function* fetchOrganizations({ payload }) {
  try {
    const searchQuery = yield select(state => state.organization.searchQuery)
    payload = { ...searchQuery, ...payload }

    const response = yield call(getAllOrganizations, payload)
    if (response?.data?.length > 0) {
      yield call(individualsHandler, response.data)
    }
    yield put(getOrganizationsSuccess(response))
    yield put(setOrganizationSearchQuery(payload))
  } catch (error) {
    console.log(error)
    yield put(getOrganizationsFail(error))
  }
}

function* onUpdateOrganization({ payload: { organization, callback } }) {
  try {
    const response = yield call(updateOrganizationById, organization)
    yield put(updateOrganizationSuccess(organization))

    showToast(
      `${t("message:UpdatedMessage", {
        field: `${t(
          "organizationPage:Organization"
        )} <span class='text-decoration-underline fw-bold'>${
          organization["Name"]
        }</span>`,
      })}`
    )
    if (callback) callback()
  } catch (error) {
    console.log(error)
    yield put(updateOrganizationFail(error))
  }
}

function* onDeleteOrganization({ payload: { organizations, callback } }) {
  try {
    const response = yield call(deleteOrganizationById, organizations)
    yield put(deleteOrganizationsSuccess(organizations))

    showToast(
      `${t("message:DeletedMessage", {
        field: `${t("organizationPage:Organization")}`,
      })}`
    )
    if (callback) callback()
  } catch (error) {
    yield put(deleteOrganizationsFail(error))
  }
}

function* onAddNewOrganization({ payload: { organization, history } }) {
  try {
    const response = yield call(createOrganization, organization)
    const id = response?.id
    yield put(addOrganizationSuccess(id))

    const url = `/Organization/${id}/view`
    showToast(
      `${t("message:CreatedMessage", {
        field: `${t(
          "organizationPage:Organization"
        )} <span class='text-decoration-underline fw-bold'>${
          organization["Name"]
        }</span>`,
      })}`
    )
    history.push(url)
  } catch (error) {
    console.log(error)
    yield put(addOrganizationFail(error))
  }
}

function* organizationSaga() {
  yield takeEvery(GET_ORGANIZATION_DETAIL, fetchOrganizationDetail)
  yield takeEvery(GET_ORGANIZATIONS, fetchOrganizations)
  yield takeEvery(ADD_NEW_ORGANIZATION, onAddNewOrganization)
  yield takeEvery(UPDATE_ORGANIZATION, onUpdateOrganization)
  yield takeEvery(DELETE_ORGANIZATIONS, onDeleteOrganization)
}

export default organizationSaga
