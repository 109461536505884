import { AvForm } from "availity-reactstrap-validation"
import {
    Check,
    ConfirmModal2,
    CustomButton,
    CustomModal
} from "components/Common"
import { ModalBody, ModalFooter, Nav, NavItem, NavLink } from "reactstrap"

//i18n
import {
    GroupTestRequestOption,
    ModuleIds,
    TestRequest_Test_Type,
    permissionType
} from "constant"
import { useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { createGroupRequest } from "store/actions"
import ConfirmDetail from "./RquestModal/ConfirmDetail"
import GeneralInfo from "./RquestModal/GeneralInfo"
import GroupEmployee from "./RquestModal/GroupEmployee"
import RequestServices from "./RquestModal/RequestServices"

const RESOURCE = ModuleIds.PatientGroup

const GroupRequestModal = ({
    modal,
    toggle,
    onCreateGroupRequest,
    t,
    onChange,
}) => {
    const ref = useRef();
    const refServices = useRef()
    const [tabActive, setTabActive] = useState('1')
    const CheckActive = (tab) => {
        return tabActive == tab
    }
    const [selectOption, setSelectOption] = useState(GroupTestRequestOption.UploadFile);
    const [patients, setPatients] = useState([])
    const [patientServices, setPatientServices] = useState([])
    const [his, setHis] = useState('')
    const [saveConfirmModal, setSaveConfirmModal] = useState(false)
    const [isSpin, setIsSpin] = useState('');
    const [success, setSuccess] = useState([]);
    const [lstFail, setLstFail] = useState([])
    const [companySelected, setCompanySelected] = useState('');
    const [close , setClosing] = useState(false);
    const emptyServices = patientServices.find((item) =>  item.services === undefined || (item.services && item.services.length === 0)) 
    const Submit = async (values) => {
        setIsSpin(true)
        setSuccess([])
        setLstFail([])
        patientServices.forEach((item, index) => {
            let data = {
                "individualValues": {
                    "companyId": values.companyId,
                    "PatientId": `${item.PatientId}`,
                    "FullName": item.FullName,
                    "DOB": item.DOB,
                    "Gender": item.Gender,
                    "Nationality": item.Nationality,
                    "PIN": item.PIN,
                    "Contact": {
                        "PhoneNumber": item.PhoneNumber,
                        "EmailAddress": item.EmailAddress
                    },
                    "Address": {
                        "Address": item.Address
                    },
                    "ProfileId": item.ProfileId,
                    "Title": item.Title
                },
                "id": "",
                "companyId": values.companyId,
                "alternateId": item.visitCode || '',
                "medicalId": "",
                "serviceType": 3,
                "requestDate": values.requestDate,
                "resultTime": null,
                "diagnosis": "",
                "remark": "",
                "patientId": item.PatientId,
                "isCollected": false,
                "isReceived": false,
                'isGroupRequest': true,
            }
            data.tests = getTest(item.services || [])
            data.profiles = getProfile(item.services || [])
            data.samples = getSample(item.services || [])
            try {
                onCreateGroupRequest({
                    request: data, callback: (res) => {
                        if (res.id) {
                            let tmp = success
                            tmp.push(`${item.PatientId}`)
                            setSuccess([...tmp])
                            if (index == patientServices.length - 1)
                                setIsSpin(false)
                        }
                        else {
                            let tmp = lstFail
                            tmp.push(`${item.PatientId}`)
                            setLstFail([...tmp])
                            if (index == patientServices.length - 1)
                                setIsSpin(false)
                        }
                    }
                })
            } catch (error) {
                let tmp = lstFail
                tmp.push(`${item.PatientId}`)
                setLstFail([...tmp])
                if (index == patientServices.length - 1)
                    setIsSpin(false)
            }
        });
    }

    const getTest = (data) => {
        let result = [];
        data.forEach(parent => {
            if (TestRequest_Test_Type.TEST == parent.type) {
                result.push({
                    "testCode": parent.code,
                    "testCategory": parent.category,
                    "sampleType": parent.sampleType,
                    "profileCode": parent.parentCode || '',
                    "sID": 0
                })
            }
            if (parent.children && parent.children.length > 0) {
                parent.children.forEach(child => {
                    if (child.type == TestRequest_Test_Type.TEST) {
                        result.push({
                            "testCode": child.code,
                            "testCategory": child.category,
                            "sampleType": child.sampleType,
                            "profileCode": child.parentCode || '',
                            "sID": 0
                        })
                    }
                    if (child.children && child.children.length > 0) {
                        child.children.forEach(element => {
                            if (element.type == TestRequest_Test_Type.TEST) {
                                result.push({
                                    "testCode": element.code,
                                    "testCategory": element.category,
                                    "sampleType": element.sampleType,
                                    "profileCode": element.parentCode || '',
                                    "sID": 0
                                })
                            }
                        });
                    }
                });

            }

        });
        return result;
    }
    const getProfile = (data) => {
        let result = [];
        data.forEach(parent => {
            if (TestRequest_Test_Type.TEST != parent.type) {
                result.push({
                    "profileCategory": parent.category || '',
                    "profileCode": parent.code
                })
            }
            if (parent.children && parent.children.length > 0) {
                parent.children.forEach(child => {
                    if (child.type != TestRequest_Test_Type.TEST) {
                        result.push({
                            "profileCategory": child.category || '',
                            "profileCode": child.code
                        })
                    }
                    if (child.children && child.children.length > 0) {
                        child.children.forEach(element => {
                            if (element.type != TestRequest_Test_Type.TEST) {
                                result.push({
                                    "profileCategory": element.category || '',
                                    "profileCode": element.code
                                })
                            }
                        });
                    }
                });

            }

        });
        return result;
    }

    const getSample = (data) => {
        let sampleTypes = [];
        //let sampleTypes = response;
        data.forEach(item => {
            if (item.type == TestRequest_Test_Type.TEST) {
                sampleTypes = pushSample(sampleTypes, {}, item)
            } else if (item.type == TestRequest_Test_Type.PROFILE) {
                item?.children.forEach(test => {
                    sampleTypes = pushSample(sampleTypes, item, test)
                })
            } else if (item.type == TestRequest_Test_Type.PROFILE_GROUP) {
                item?.children.forEach(child => {
                    if (child.type == TestRequest_Test_Type.TEST) {
                        sampleTypes = pushSample(sampleTypes, {}, child)
                    }
                    if (child.type == TestRequest_Test_Type.PROFILE) {
                        child.children?.forEach(test => {
                            sampleTypes = pushSample(sampleTypes, child, test)
                        })
                    }
                })
            }

        });
        return sampleTypes
    }

    const pushSample = (sampleTypes, parent, child) => {
        let sampleType = child.sampleType;
        if (!child.sampleType) {
            sampleType = parent.sampleType;
        }
        if (sampleType) {
            let isExist = sampleTypes.find(x => x.sampleType === sampleType);
            if (!isExist) {
                sampleTypes.push(
                    {
                        sampleType: sampleType,
                        sampleColor: '',
                        numberOfLabels: '1',
                        "quality": "",
                        collectorUserId: null,
                        receiverUserId: null,
                        "sID": 0,
                        "subID": '',
                    })
            }
        }

        return sampleTypes;
    }

    return (
        <CustomModal
            modal={close ? false : modal}
            title={t("Group Test Request")}
            onToggle={() => {
                setTabActive("1")
                toggle()
            }}
            customClass={"zIndex9999"}
            size="xl"
            centered={false}
        >
            <ModalBody className="position-relative pt-0 mt-3">
                <AvForm
                    ref={ref}
                    id="grouprequestmodal"
                    onValidSubmit={(e, values) => {
                        Submit(values)
                    }}
                    onChange={onChange}
                >
                    <div className="wizard clearfix">
                        <div className="steps clearfix">
                            <Nav>
                                <NavItem className={CheckActive("1") ? "current" : ""}>
                                    <NavLink className={CheckActive("1") ? "current" : ""}
                                        onClick={() => {
                                            setTabActive("1")
                                        }}
                                    >
                                        <span className="number">1.</span>{t("General Info")}
                                    </NavLink>
                                </NavItem>
                                <NavItem className={CheckActive("2") ? "current" : ""}>
                                    <NavLink className={CheckActive("2") ? "current" : ""}
                                        onClick={() => {
                                            setTabActive("2")
                                        }}
                                    >
                                        <span className="number">2.</span>{t("Group/Employees")}
                                    </NavLink>
                                </NavItem>
                                <NavItem className={(CheckActive("3") ? "current" : "") + (patients.length == 0 ? " pointer-events-none" : "")}>
                                    <NavLink className={CheckActive("3") ? "current" : ""}
                                        onClick={() => {
                                            setTabActive("3")
                                        }}
                                    >
                                        <span className="number">3.</span>{t("Request Services")}
                                    </NavLink>
                                </NavItem>
                                <NavItem className={(CheckActive("4") ? "current" : "") + (patients.length == 0 ? " pointer-events-none" : "")}>
                                    <NavLink className={CheckActive("4") ? "current" : ""}
                                        onClick={() => {
                                            setTabActive("4")
                                        }}
                                    >
                                        <span className="number">4.</span>{t("Confirm Detail")}
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </div>
                    </div>
                    <div className="position-relative mt-3">
                        <div className={(tabActive == "1" ? "current-" : "") + "request-modal-general"}>
                            <GeneralInfo
                                changeSelectOpntion={(e) => {
                                    setSelectOption(e)
                                }}
                                onChangeHis={(val) => {
                                    setHis(val)
                                }}
                                onChangeCompany={(val) => {
                                    setCompanySelected(val)
                                }}
                            />
                        </div>
                        <div className={(tabActive == "2" ? "current-" : "") + "request-modal-general"}>
                            <GroupEmployee
                                type={selectOption}
                                onChange={(vals) => {
                                    setPatients(vals)
                                }}
                                companySelected={companySelected}
                                resetData={() => {
                                    setPatients([])
                                    refServices.current.onReset && refServices.current.onReset()
                                    setPatientServices([])
                                }}
                            />
                        </div>
                        <div className={(tabActive == "3" ? "current-" : "") + "request-modal-general"}>
                            <RequestServices
                                ref={refServices}
                                his={his}
                                patients={patients}
                                onChange={(vals) => {
                                setPatientServices(vals)
                                }}
                            />
                        </div>
                        <div className={(tabActive == "4" ? "current-" : "") + "request-modal-general"}>
                            <ConfirmDetail
                                patients={patientServices}
                                spin={isSpin}
                                success={success}
                                error={lstFail}
                            />
                        </div>
                    </div>
                </AvForm>
            </ModalBody>
            <ModalFooter>
                <CustomButton
                    type="button"
                    onClick={() => {
                        setTabActive("1")
                        toggle()
                    }}
                    color="secondary"
                    data-dismiss="modal"
                    className="button-width"
                    text={"common:Cancel"}
                />
                <Check
                    resource={RESOURCE}
                    permission={permissionType.C}
                >
                    <CustomButton
                        disabled={tabActive == "1"}
                        type="button"
                        onClick={() => {
                            if (tabActive > 1)
                                setTabActive(`${Number(tabActive) - 1}`)
                        }}
                        color="primary"
                        className="button-width"
                        text={"common:Previous"}
                    />
                    {tabActive != "4" ?
                        <CustomButton
                            type="submit"
                            color="primary"
                            onClick={() => {
                                if (tabActive < 4)
                                    setTabActive(`${Number(tabActive) + 1}`)
                            }}
                            className="save-user button-width"
                            text={"common:Next"}
                            disabled={
                                tabActive == "4" ||
                                (tabActive == "2" && patients.length == 0) ||
                                (tabActive == "3" && emptyServices)
                            }
                        />
                        :
                        success.length > 0 ? 
                            <CustomButton
                            isEdit={isSpin == true}
                            loading={isSpin == true}
                            type="submit"
                            color="primary"
                            onClick={() => {
                                setClosing(true);
                            }}
                            className="save-user button-width"
                            text={"common:Finish"}
                        />
                        : 
                        <CustomButton
                            isEdit={isSpin == true}
                            loading={isSpin == true}
                            type="submit"
                            color="primary"
                            onClick={() => {
                                setSaveConfirmModal(true)
                            }}
                            className="save-user button-width"
                            text={"common:Save"}
                        />
                    }
                </Check>
            </ModalFooter>
            <ConfirmModal2
                modal={saveConfirmModal}
                title={`${t("common:Save")} ${t("Group Test Request")}`}
                message={t("SaveGroupTestRequest")}
                onToggle={() => { setSaveConfirmModal(false) }}
                onConfirm={() => {
                    setSaveConfirmModal(false)
                    ref.current?.submit()
                }}
                btnConfirmText={t("common:Save")}
            />
        </CustomModal>
    )
}

// export default withTranslation(["deliveryPage", "common"])(GroupRequestModal)
const mapStateToProps = ({ }) => {
    return {

    }
}

const mapDispatchToProps = dispatch => ({
    onCreateGroupRequest: (payload, callback) => dispatch(createGroupRequest(payload, callback)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["deliveryPage", "common"])(GroupRequestModal)))