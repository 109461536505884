import axios from "axios"
import * as moment from "moment"
//pass new generated access token here
import authHeader from "./jwt-token-access/auth-token-header"
import { getI18nextLng } from "./utilities"
import { saveAs } from "file-saver";
import store from "../store"
import {
  setForbiddenError,
  logoutUser,
  setCommonUpdating,
  setCommonUpdatingSuccess,
  setCommonUpdatingFail,
  displayErrors,
} from "../store/actions"

//apply base url for axios
const API_URL = "/"

const axiosApi = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
})
axiosApi.interceptors.request.use(config => {
  const size = localStorage.getItem("size")
  if (!config.headers.Authorization) config.headers.Authorization = authHeader()
  if (!config.headers["Accept-Language"])
    config.headers["Accept-Language"] = getI18nextLng()
  if (!config.headers["BusinessType"]) {
    if (size && size != '0')
      config.headers["BusinessType"] = size
  }
  return config
})

// axiosApi.defaults.headers.common["Authorization"] = token

axiosApi.interceptors.response.use(
  response => response,
  error => {
    store.dispatch(setCommonUpdatingFail())
    if (error?.response?.status === 401) {
      store.dispatch(logoutUser())
      window.location.reload()
      return
    } else if (error?.response?.status === 403) {
      store.dispatch(setForbiddenError({ isForbidden: true, response: error?.response }))
    } else if (error?.response?.status === 504 || error?.response?.status === 499 || error?.response?.status === 404) {
      // không xử lý 504 GatewayTimeout
    } else {
      store.dispatch(displayErrors(error))
    }
    return Promise.reject(error)
  }
)

const axiosApiWithFormData = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "multipart/form-data",
  },
})
axiosApiWithFormData.interceptors.request.use(config => {
  const size = localStorage.getItem("size")
  if (!config.headers.Authorization) config.headers.Authorization = authHeader()
  if (!config.headers["Accept-Language"])
    config.headers["Accept-Language"] = getI18nextLng()
  if (!config.headers["BusinessType"]) {
    if (size && size != '0')
      config.headers["BusinessType"] = size
  }
  return config
})


export async function downloadFile(url) {
  let filename = "download.pdf";
  await axiosApi.get(url, { responseType: 'arraybuffer' }).then(response => {
    const header = response.headers['content-disposition'];
    if (header) {
      const parts = header.split(';');
      filename = parts[1].split('=')[1];
    }
    const blob = new Blob([response?.data], {
      type: 'application/octet-stream'
    })
    saveAs(blob, filename)
  })
}

export async function downloadFileWithName(url) {
  await axiosApi.get(url).then(response => {
    const b64 = response.data.data.fileContents;
    var data = Uint8Array.from(atob(b64), c => c.charCodeAt(0));
    var blob = new Blob([data], { type: "octet/stream" });
    saveAs(blob, response.data.fileName);
  })
}

export async function viewFileWithName(url) {
  await axiosApi.get(url).then(response => {
    const b64 = response.data.data.fileContents;
    var data = Uint8Array.from(atob(b64), c => c.charCodeAt(0));
    var blob = new Blob([data], { type: "application/pdf" });
    var fileURL = window.URL.createObjectURL(blob);
    let tab = window.open();
    tab.location.href = fileURL;
  })
}

axiosApiWithFormData.interceptors.response.use(
  response => response,
  error => {
    store.dispatch(setCommonUpdatingFail())
    if (error?.response?.status === 401) {
      store.dispatch(logoutUser())
      window.location.reload()
      return
    } else if (error?.response?.status === 403) {
      store.dispatch(setForbiddenError({ isForbidden: true, response: error?.response }))
    } else if (error?.response?.status === 504 || error?.response?.status === 499) {
      // không xử lý 504 GatewayTimeout
    } else {
      store.dispatch(displayErrors(error))
    }
    return Promise.reject(error)
  }
)

export async function getWithImage(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => {
    return response?.data
  })
}

export async function getWithReport(url, config = {}) {
  store.dispatch(setCommonUpdating())
  return await axiosApi.get(url, { ...config }).then(response => {
    store.dispatch(setCommonUpdatingSuccess())
    return response?.data
  })
}

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response?.data)
}

export async function post(url, data, config = {}) {
  store.dispatch(setCommonUpdating())
  data = AxiosRequestTransformer(data)
  return axiosApi.post(url, { ...data }, { ...config }).then(response => {
    store.dispatch(setCommonUpdatingSuccess())
    return response.data
  })
}

const AxiosRequestTransformer = data => {
  if (data instanceof Date) {
    return moment(data).format("YYYY-MM-DDTHH:mm") + ":00.000Z"
  }
  if (Array.isArray(data)) {
    return data.map(val => AxiosRequestTransformer(val))
  }
  if (typeof data === "object" && data !== null) {
    return Object.fromEntries(Object.entries(data).map(([key, val]) =>
      [key, AxiosRequestTransformer(val)]))
  }
  return data
}

export async function put(url, data, config = {}) {
  store.dispatch(setCommonUpdating())
  return axiosApi.put(url, AxiosRequestTransformer(data), { ...config }).then(response => {
    store.dispatch(setCommonUpdatingSuccess())
    return response.data
  })
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
export async function postWithFormData(url, data, config = {}) {
  store.dispatch(setCommonUpdating())
  return axiosApiWithFormData.post(url, data, { ...config }).then(response => {
    store.dispatch(setCommonUpdatingSuccess())
    return response.data
  })
}

export async function putWithFormData(url, data, config = {}) {
  store.dispatch(setCommonUpdating())
  return axiosApiWithFormData.put(url, data, { ...config }).then(response => {
    store.dispatch(setCommonUpdatingSuccess())
    return response.data
  })
}

export async function delWithUpdateCommon(url, config = {}) {
  store.dispatch(setCommonUpdating())
  return await axiosApi
    .delete(url, { ...config })
    .then(response => {
      store.dispatch(setCommonUpdatingSuccess())
      return response.data
    })
}