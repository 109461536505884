import { CustomButton } from "components/Common"
import CustomSearchBar from "components/Common/CustomTable/SearchBar"
import { columnsCodeDMSMapping, columnsInstrumentMapping, columnsInstrumentMappingCode, columnsTestCodeDMSMapping } from "constant"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect, useDispatch } from "react-redux"
import { getConnectorInstrumentMappingUploadSuccess } from "store/actions"
import { read, utils } from "xlsx"
import ButtonExport from "./ButtonExport"

const DMSTestCodeTableButtons = ({
  onRefresh,
  onSearch,
  loadingInstrumentMapping,
  data,
  t,
  searchText,
  onChangeSearchText,
  onClearSearchText,
  onSubmit,
  onSetRows,
  testCodeDmsMappings,
}) => {
  const [dataSource, setDataSource] = useState([])
  const [dataExport, setDataExport] = useState([])
  const [fileName, setFileName] = useState(t("common:Upload"))
  const [isUpload, setIsUpload] = useState(false)

  useEffect(() => {
    setDataSource(data)
  }, [data])
  const dispatch = useDispatch();

  const getDataExport = () => {
    let dt = dataExport.map(row => {
      let res = {}
      columnsCodeDMSMapping.forEach(element => {
        res[element] = row[columnsTestCodeDMSMapping[element]]
      });
      return res
    })
    return dt
  }

  useEffect(() => {
    setDataExport(testCodeDmsMappings || [])
  }, [testCodeDmsMappings])

  const getColumnExport = () => {
    return columnsCodeDMSMapping
  }

  const getInstrumentHisString = (value) => {
    if (value) {
      return `${value}`
    }
    return null
  }

  const handleImport = e => {
    const files = e.target.files
    if (files.length) {
      let rowChoose = []
      const file = files[0]
      const reader = new FileReader()
      reader.onload = event => {
        const wb = read(event.target.result)
        const sheets = wb.SheetNames
        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]])
          let tmp = dataSource
          let rowChecked = [];
          rows.forEach(item => {
            if (item.testCodeDMS && item.testCodeDMS != '') {
              const ind = tmp.findIndex(x => x.testCode == item.testCode)
              if (ind >= 0) {
                tmp[ind].testCodeDMS = getInstrumentHisString(item['testCodeDMS']);
                tmp[ind].message = getInstrumentHisString(item['testCodeDMS']);
                tmp[ind].selected = 1;
                rowChoose.push(tmp[ind])
                rowChecked.push(item)
              }
            }
          })
          dispatch(getConnectorInstrumentMappingUploadSuccess(tmp))
          onSetRows(rowChoose)
        }
      }
      reader.readAsArrayBuffer(file)
    }
  }

  return (
    <React.Fragment>
      <div className="input-table-header-tat-setting"
      >
        <CustomButton
          color="secondary"
          outline
          onClick={() => {
            onRefresh()
          }}
        >
          <i className={`fas fa-sync-alt ${loadingInstrumentMapping == true ? " loading-spin" : ""}`}></i>
        </CustomButton>
        <input
          id="importExcel"
          name="reportTemplateName"
          type="file"
          className=" d-none"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          onChange={e => {
            setFileName(e.target.files[0]?.name || t("common:Upload"))
            handleImport(e)
          }}
        />
        <CustomButton
          isEdit
          color="primary"
          outline
          onClick={() => {
            $(`#importExcel`).click()
            setIsUpload(true)
          }}
        >
          {fileName}
        </CustomButton>
        <ButtonExport
          rows={dataSource}
          data={getDataExport()}
          columns={getColumnExport()}
        />
        <CustomButton
          color="primary"
          onClick={() => { onSubmit() }}
        >
          {t('button:Save')}
        </CustomButton>
        <CustomSearchBar
          onSearch={onChangeSearchText}
          searchText={searchText}
          placeholder={t("common:Search")}
          onClear={onClearSearchText}
          onSubmit={(e) => {
            e.preventDefault()
            onSearch()
          }}
        />
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = ({ hisConnector }) => ({
  loadingInstrumentMapping: hisConnector.loadingInstrumentMapping,
  testCodeDmsMappings: hisConnector.testCodeDmsMappings || []
})

const mapDispatchToProps = dispatch => ({

})

DMSTestCodeTableButtons.displayName = 'DMSTestCodeTableButtons';
export default withTranslation(["testConfig", "common", "button"], { withRef: true })(connect(
  mapStateToProps,
  mapDispatchToProps, null, { forwardRef: true }
)(DMSTestCodeTableButtons))
