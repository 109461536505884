import {
    CustomButton, CustomModal
} from "components/Common";
import Editable from "react-bootstrap-editable";
import { Input, ModalBody, ModalFooter } from "reactstrap";
import CustomBootstrapInlineTable from "components/Common/CustomInlineTable/CustomBootstrapInlineTable";
import { getI18nextLng } from "helpers/utilities";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

let lang = getI18nextLng()

const ConfigColumnModal = ({
    modal,
    toggle,
    data,
    formEl,
    onValidSubmit,
    t,
    ...rest
}) => {
    const title = t("Config Column");
    const [dataSource, setDataSource] = useState([data]);
    useEffect(() => {
        setDataSource(data)
    }, [data])

    const confirmElement = (
        <button type="submit"
            className="btn btn-outline-primary editable-submit btn-sm ms-2 me-1"
        ><i className="mdi mdi-check"></i></button>
    );

    /** Cancel button */
    const cancelElement = (
        <button type="button"
            className="btn btn-outline-primary editable-cancel btn-sm me-3">
            <i className="mdi mdi-close"></i></button>
    );
    const onEditableChange = (value, item, field) => {
        let newData = [...dataSource];
        let itemResult = newData.find(x => x.fieldName === item.fieldName);
        itemResult[field] = value;
        setDataSource([...newData])
    }
    const columns = [
        {
            dataField: "id",
            text: "id",
            hidden: true,
        },
        {
            dataField: "message",
            text: t("Column Name"),
            searchable: false,
        },
        {
            dataField: "isDisplay",
            text: t("Display"),
            headerAlign: 'center',
            headerStyle: { width: "100px" },
            formatter: (cellContent, item, index) => {
                return <div className="text-center" onClick={e => e.stopPropagation()} >
                    <Input type="checkbox" checked={item.isDisplay == true} onChange={(e) => {
                        onEditableChange(e.target.checked, item, 'isDisplay')
                    }}
                    />
                </div>
            },
        },
        {
            dataField: "displayLength",
            text: t("Column Width"),
            formatter: (cellContent, item, index) => (
                <div style={{ width: 200 }}
                >
                    <Editable
                        alwaysEditing={false}
                        disabled={false}
                        editText={`${(item.displayLength == null || item.displayLength == '' || item.displayLength == '0') ? 'Auto' : item.displayLength}`}
                        initialValue={`${(item.displayLength == null || item.displayLength == '') ? '0' : item.displayLength}`}
                        validate={(value) => {
                            if (Number(value) || value == '' || value == '0') {
                                onEditableChange(value, item, 'displayLength')
                            }
                            else {
                                return t("Text not allowed")
                            }
                        }}
                        id={item.id}
                        isValueClickable={false}
                        mode="inline"
                        placement="top"
                        showText={false}
                        type="textfield"
                        renderConfirmElement={confirmElement}
                        renderCancelElement={cancelElement}
                    />
                </div>
            ),
        },
        {
            dataField: "displayOrder",
            text: t("Position"),
            formatter: (cellContent, item, index) => (
                <div style={{ width: 200 }} onClick={(e) => {
                    e.stopPropagation();
                }}>
                    <Editable
                        alwaysEditing={false}
                        disabled={false}
                        editText={`${(item.displayOrder == null || item.displayOrder == '') ? '0' : item.displayOrder}`}
                        initialValue={`${(item.displayOrder == null || item.displayOrder == '') ? '0' : item.displayOrder}`}
                        id={item.id}
                        isValueClickable={false}
                        mode="inline"
                        placement="top"
                        showText={false}
                        type="textfield"
                        renderConfirmElement={confirmElement}
                        renderCancelElement={cancelElement}
                        validate={(value) => {
                            if (Number(value) || value == '' || value == '0') {
                                onEditableChange(value, item, 'displayOrder')
                            }
                            else {
                                return t("Text not allowed")
                            }
                        }}
                    />
                </div>
            ),
        },
    ]
    return (
        <CustomModal modal={modal} title={title} onToggle={toggle} size="lg" customClass={"model-height"} >
            <ModalBody className="configColumnModal">
                <>
                    <CustomBootstrapInlineTable
                        columns={columns}
                        data={dataSource || []}
                        // onSelect={onSelect}
                        // onSelectAll={onSelectAll}
                        isScrollable
                        // updatedTime={updatedTime}
                        isEnableLoadInfo={true}
                        searchText={""}
                        draggable
                        keyField={"fieldName"}
                        {...rest}
                    />
                </>
            </ModalBody>

            <ModalFooter>
                <CustomButton
                    text={t("common:Cancel")}
                    type="button"
                    onClick={toggle}
                    data-dismiss="modal"
                    className="button-width"
                />

                <CustomButton
                    text={t("common:Save")}
                    type="submit"
                    color="primary"
                    onClick={() => {
                        onValidSubmit(dataSource)
                    }}
                    className="save-user button-width"
                    isEdit
                />
            </ModalFooter>
        </CustomModal>
    )
}

ConfigColumnModal.propTypes = {
}

export default withTranslation(["testResultPage", "common"])(ConfigColumnModal)