import React, { Fragment, useEffect } from "react"
import { Card, CardBody, CardFooter, Col, Row } from "reactstrap"
import { connect } from "react-redux"
import {
  getPatientRequestCount,
  getSampleDoneCount,
  getSampleInProcess,
  getSampleReceived,
  getSampleTracking,
  getSampleWaitingCount,
} from "store/actions"

const Header = ({
  patientRequest,
  onGetPatientRequestCount,
  sampleTracking,
  onGetSampleTrackingCount,
  sampleDone,
  onGetSampleDoneCount,
  sampleReceived,
  onGetSampleReceivedCount,
  sampleInProcess,
  onGetSampleInProcessCount,
  onGetSampleWaitingValidCount,
  sampleWaitingValid,
}) => {
  const onFetchHeader = () => {
    onGetPatientRequestCount()
    onGetSampleTrackingCount()
    onGetSampleDoneCount()
    onGetSampleReceivedCount()
    onGetSampleInProcessCount()
    onGetSampleWaitingValidCount()
  }
  useEffect(() => {
    onFetchHeader()
  }, [])
  return (
    <Fragment>
      <Row>
        <Col md={4} xl={2}>
          <Card className="mini-stats-wid mb-2">
            <CardBody>
              <div className="d-flex">
                <div className="flex-grow-1">
                  <p
                    className="text-muted fw-medium"
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      fontSize: 16,
                    }}
                  >
                    BN đã tiếp nhận
                  </p>
                  <h4 className="mb-0" style={{ fontSize: 22 }}>
                    {patientRequest.countPatients}
                  </h4>
                </div>
                <div className="flex-shrink-0 align-self-center">
                  <span
                    className="avatar-title rounded-circle bg-primary bg-soft font-size-24"
                    style={{ width: 45, height: 45 }}
                  >
                    <i
                      className="mdi mdi-account-group text-primary"
                      style={{ fontSize: 30 }}
                    />
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col md={4} xl={2}>
          <Card className="mini-stats-wid mb-2">
            <CardBody>
              <div className="d-flex">
                <div className="flex-grow-1">
                  <p
                    className="text-muted fw-medium"
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      fontSize: 16,
                    }}
                  >
                    Đã lấy mẫu
                  </p>
                  <h4 className="mb-0" style={{ fontSize: 22 }}>
                    {sampleTracking.countSamples}
                  </h4>
                </div>

                <div className="flex-shrink-0 align-self-center">
                  <span
                    className="avatar-title rounded-circle bg-primary bg-soft font-size-24"
                    style={{ width: 45, height: 45 }}
                  >
                    <i
                      style={{ fontSize: 34 }}
                      className="mdi mdi-test-tube text-primary"
                    ></i>
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col md={4} xl={2}>
          <Card className="mini-stats-wid mb-2">
            <CardBody className="position-relative">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <p
                    className="text-muted fw-medium"
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      fontSize: 16,
                    }}
                  >
                    Đã giao nhận
                  </p>
                  <h4 className="mb-0" style={{ fontSize: 22 }}>
                    {sampleReceived.countSamples}
                  </h4>
                </div>
                <div className="tracking-icon">
                  <div className="tooltip-custom">
                    <i className="fas fa-exclamation-circle text-primary"></i>
                    <span className="tooltiptext-custom">
                      Số lượng mẫu đã được giao nhận
                    </span>
                  </div>
                </div>
                <div className="flex-shrink-0 align-self-center">
                  <span
                    className="avatar-title rounded-circle bg-primary bg-soft font-size-24"
                    style={{ width: 45, height: 45 }}
                  >
                    <i
                      style={{ fontSize: 25 }}
                      className="fas fa-vials text-primary"
                    ></i>
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col md={4} xl={2}>
          <Card className="mini-stats-wid mb-2">
            <CardBody className="position-relative">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <p
                    className="text-muted fw-medium"
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      fontSize: 16,
                    }}
                  >
                    Đang phân tích
                  </p>
                  <h4 className="mb-0" style={{ fontSize: 22 }}>
                    {sampleInProcess.countSamples}
                  </h4>
                </div>
                <div className="tracking-icon">
                  <div className="tooltip-custom">
                    <i className="fas fa-exclamation-circle text-primary"></i>
                    <span className="tooltiptext-custom">
                      Số lượng mẫu đang phân tích
                    </span>
                  </div>
                </div>
                <div className="flex-shrink-0 align-self-center">
                  <span
                    className="avatar-title rounded-circle bg-primary bg-soft font-size-24"
                    style={{ width: 45, height: 45 }}
                  >
                    <i
                      style={{ fontSize: 25 }}
                      className="fas fa-vials text-primary"
                    ></i>
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col md={4} xl={2}>
          <Card className="mini-stats-wid mb-2">
            <CardBody className="position-relative">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <p
                    className="text-muted fw-medium"
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      fontSize: 16,
                    }}
                  >
                    Đã có kết quả
                  </p>
                  <h4 className="mb-0" style={{ fontSize: 22 }}>
                    {sampleDone.countSamples}
                  </h4>
                </div>
                <div className="tracking-icon">
                  <div className="tooltip-custom">
                    <i className="fas fa-exclamation-circle text-primary"></i>
                    <span className="tooltiptext-custom">
                      Số lượng mẫu đã có kết quả một phần hoặc toàn bộ
                    </span>
                  </div>
                </div>
                <div className="flex-shrink-0 align-self-center">
                  <span
                    className="avatar-title rounded-circle bg-primary bg-soft font-size-24"
                    style={{ width: 45, height: 45 }}
                  >
                    <i
                      style={{ fontSize: 25 }}
                      className="fas fa-vials text-primary"
                    ></i>
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col md={4} xl={2}>
          <Card className="mini-stats-wid mb-2">
            <CardBody className="position-relative">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <p
                    className="text-muted fw-medium"
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      fontSize: 16,
                    }}
                  >
                    Đã duyệt
                  </p>
                  <h4 className="mb-0" style={{ fontSize: 22 }}>
                    {sampleWaitingValid.countSamples}
                  </h4>
                </div>
                <div className="tracking-icon">
                  <div className="tooltip-custom">
                    <i className="fas fa-exclamation-circle text-primary"></i>
                    <span className="tooltiptext-custom">
                      Số lượng mẫu đã duyệt một phần hoặc toàn bộ
                    </span>
                  </div>
                </div>
                <div className="flex-shrink-0 align-self-center">
                  <span
                    className="avatar-title rounded-circle bg-primary bg-soft font-size-24"
                    style={{ width: 45, height: 45 }}
                  >
                    <i
                      style={{ fontSize: 25 }}
                      className="fas fa-vials text-primary"
                    ></i>
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  )
}

const mapStateToProps = ({ sampleTracking }) => ({
  patientRequest: sampleTracking.patientRequest,
  sampleTracking: sampleTracking.sampleTracking,
  sampleDone: sampleTracking.sampleDone,
  sampleReceived: sampleTracking.sampleReceived,
  sampleInProcess: sampleTracking.sampleInProcess,
  sampleWaitingValid: sampleTracking.sampleWaitingValid,
})

const mapDispatchToProps = dispatch => ({
  onGetPatientRequestCount: payload =>
    dispatch(getPatientRequestCount(payload)),
  onGetSampleDoneCount: payload => dispatch(getSampleDoneCount(payload)),
  onGetSampleWaitingValidCount: payload => dispatch(getSampleWaitingCount(payload)),
  onGetSampleInProcessCount: payload => dispatch(getSampleInProcess(payload)),
  onGetSampleReceivedCount: payload => dispatch(getSampleReceived(payload)),
  onGetSampleTrackingCount: payload => dispatch(getSampleTracking(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
