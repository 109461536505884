import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import {
  DepartmentChangeTreeViewItem,
  addNewDepartment,
  deleteDepartments,
  getDepartmentDetail,
  getDepartments,
  resetDepartmentSearchQuery,
  updateDepartment,
} from "store/users/department/actions"

import {
  getCommonCompanies,
  getCommonDepartments,
} from "store/setting/common/actions"

import { Col, Row } from "reactstrap"

// import common components
import {
  ConfirmModal,
  TitleAndTable,
  TreeViewBar,
  WarningModal,
} from "components/Common"

import DepartmentTable from "./DepartmentTable"
// import DepartmentModal from "./Modal/DepartmentModal"

import {
  onDelete,
  onDeleteToggle,
  selectCheckboxHandler,
} from "helpers/utilities"
import DepartmentModal from "./Modal/DepartmentModal"

//i18n
import { ModuleIds } from "constant"
import { getUrlParamByKey, getUrlParams, insertUrlParam } from "helpers/utilities"
import { withTranslation } from "react-i18next"

const RESOURCE = ModuleIds.Department

const Departments = ({
  history,
  departments,
  paging,
  onGetDepartments,
  onAddNewDepartment,
  onUpdateDepartment,
  onDeleteDepartment,
  onResetDepartmentSearchQuery,
  onGetDepartmentDetail,
  department,
  loadingDepartments,
  updatedTime,
  t,
  onGetCommonCompanies,
  onGetCommonDepartments,
  onChangeTreeViewItem,
}) => {
  const [confirmModal, setConfirmModal] = useState(false)
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(true)
  const [isClone, setIsClone] = useState(false)
  const [row, setRow] = useState({})
  const [rowDelete, setRowDelete] = useState({})
  const [rows, setRows] = useState([])

  const [warningModal, setWarningModal] = useState(false)

  const formEl = useRef(null)

  const toggle = () => {
    setModal(prev => !prev)
  }

  const afterUpdate = () => {
    toggle()
    fetchCommonCompanies()
    fetchCommonDepartments()
  }

  const addDepartmentClicks = () => {
    setIsEdit(false)
    setIsClone(false)
    toggle()
  }

  /**
   * Handling submit Company on Company form
   */

  const onValidSubmit = (e, values) => {
    if (isEdit) {
      values.status = values.isActive
      onUpdateDepartment({ department: values, callback: afterUpdate })
    } else {
      const newDepartment = {
        ...values,
        status: !!values["isActive"],
      }

      // save new Department
      onAddNewDepartment({ department: newDepartment, history })
    }
  }

  const onCloneHandler = () => {
    const id = row?.id
    if (id) {
      onGetDepartmentDetail(id)
      setIsEdit(false)
      setIsClone(true)
      toggle()
    } else setWarningModal(true)
  }

  const onEditHandler = (e, departmentId) => {
    const id = departmentId || row?.id
    if (id) {
      onGetDepartmentDetail(id)
      setIsEdit(true)
      toggle()
    } else setWarningModal(true)
  }

  const resetState = () => {
    setRows([])
    setRow({})
  }

  const onDeleteToggleHandler = (e, department) => {
    onDeleteToggle({
      rows,
      row: department || rowDelete,
      setConfirmModal,
      setWarningModal,
      setRowDelete,
    })
    if (confirmModal) setRowDelete({})
  }

  const onDeleteMultipleRows = rowsState => {
    onDeleteDepartment({
      departments: rowsState,
      callback: () => {
        resetState()
        fetchCommonCompanies()
        fetchCommonDepartments()
      },
    })
  }

  const onDeleteSingleRow = rowsState => {
    onDeleteDepartment({
      departments: rowsState,
      callback: () => {
        setRows(prev => prev.filter(x => x.id !== rowDelete.id))
        fetchCommonCompanies()
        fetchCommonDepartments()
      },
    })
    setRowDelete({})
    setRow({})
  }

  const onDeleteDepartmentHandler = () => {
    onDelete({
      rowDelete,
      rows,
      onDeleteSingleRow,
      onDeleteMultipleRows,
    })
    // toggle modal
    setConfirmModal(false)
  }

  /**Get selected row and set to state
   *
   */
  const onSelectCheckbox = (row, isSelected) => {
    const { rowsState, currentRow } = selectCheckboxHandler(
      rows,
      row,
      isSelected
    )
    setRows(rowsState)
    currentRow.isActive = !!row.status
    setRow(currentRow)
  }

  const onSelectAllCheckbox = rows => {
    setRows(rows)
    if (rows.length < 1) setRow({})
    else setRow(rows[rows.length - 1])
  }
  const onGetDeparmentList = (payload) => {
    insertUrlParam(payload)
    onGetDepartments(payload)
  }
  const fetchDepartments = () => {
    onGetDeparmentList({ page: 1 })
  }

  /** Table methods */
  const onRefreshHandler = () => {
    resetState()
    fetchDepartments()
  }

  const onSearch = searchText => {
    onGetDeparmentList({ page: 1, search: searchText })
  }

  const onSizePerPageChange = size => {
    onGetDeparmentList({ page: 1, size })
  }

  const onPageChange = page => {
    onGetDeparmentList({ page })
  }

  const onSubmitFilter = values => {
    onGetDeparmentList({ page: 1, ...values })
  }

  const onSortHandler = (field, order) => {
    const sortString = `${field}:${order}`
    onGetDeparmentList({ page: 1, sort: sortString })
  }

  // Common
  const fetchCommonCompanies = () => {
    onGetCommonCompanies({ size: 100 })
  }

  const fetchCommonDepartments = () => {
    onGetCommonDepartments({ size: 100 })
  }

  /**-----CYCLE------ */

  useEffect(() => {
    onResetDepartmentSearchQuery()
  }, [])

  useEffect(() => {
    let params = getUrlParams();
    let paramPage = getUrlParamByKey("page");
    if (paramPage) {
      onGetDeparmentList(params)
    } else {
      fetchDepartments()
    }
  }, [])

  useEffect(() => {
    fetchCommonCompanies()
  }, [])

  useEffect(() => {
    fetchCommonDepartments()
  }, [])

  return (
    <React.Fragment>
      <Row>
        <Col lg="3">
          <TreeViewBar
            buttonText={t("Add Department")}
            onChangeTreeViewItem={onChangeTreeViewItem}
            onAddNew={addDepartmentClicks}
            resource={RESOURCE}
          />
        </Col>
        <Col lg="9">
          <TitleAndTable
            table={() => (
              <DepartmentTable
                departments={departments}
                onSelect={onSelectCheckbox}
                onSelectAll={onSelectAllCheckbox}
                onSearch={onSearch}
                onSort={onSortHandler}
                onRefresh={onRefreshHandler}
                onPageChange={onPageChange}
                paging={paging}
                onSizePerPageChange={onSizePerPageChange}
                onSubmitFilter={onSubmitFilter}
                onEdit={onEditHandler}
                onDelete={onDeleteToggleHandler}
                loading={loadingDepartments}
                updatedTime={updatedTime}
              />
            )}
            resource={RESOURCE}
            buttons
            onEdit={onEditHandler}
            onDelete={onDeleteToggleHandler}
            onClone={onCloneHandler}
            title={t("Departments")}
            subtitle={t("Department List")}
          />
        </Col>
      </Row>

      <WarningModal
        modal={warningModal}
        onToggle={() => setWarningModal(prev => !prev)}
        message={t("message:SelectRowWarning")}
      />
      <ConfirmModal
        modal={confirmModal}
        title={`${t("common:Delete")} ${t("common:Department")}`}
        message={t("message:DeleteConfirm")}
        onToggle={onDeleteToggleHandler}
        onDelete={onDeleteDepartmentHandler}
      />
      <DepartmentModal
        formEl={formEl}
        modal={modal}
        isEdit={isEdit}
        onValidSubmit={onValidSubmit}
        toggle={toggle}
        data={!isEdit ? (isClone ? department : {}) : department}
      />
    </React.Fragment>
  )
}
Departments.propTypes = {
  departments: PropTypes.array,
  onGetDepartments: PropTypes.func,
  onAddNewDepartment: PropTypes.func,
  onUpdateDepartment: PropTypes.func,
  onDeleteDepartment: PropTypes.func,
  onGetDepartmentDetail: PropTypes.func,
  paging: PropTypes.object,
  loadingDepartments: PropTypes.bool,
  updatedTime: PropTypes.any,
  department: PropTypes.object,
  t: PropTypes.any,
  onGetCommonCompanies: PropTypes.func,
  onGetCommonDepartments: PropTypes.func,
  onChangeTreeViewItem: PropTypes.func,
  onResetDepartmentSearchQuery: PropTypes.func,
}

const mapStateToProps = ({ department }) => ({
  departments: department.departments,
  department: department.department,
  paging: department.paging,
  loadingDepartments: department.loadingDepartments,
  updatedTime: department.updatedTime,
})

const mapDispatchToProps = dispatch => ({
  onGetDepartments: payload => dispatch(getDepartments(payload)),
  onAddNewDepartment: (department, history) =>
    dispatch(addNewDepartment(department, history)),
  onUpdateDepartment: department => dispatch(updateDepartment(department)),
  onDeleteDepartment: department => dispatch(deleteDepartments(department)),
  onGetDepartmentDetail: departmentId =>
    dispatch(getDepartmentDetail(departmentId)),
  onGetCommonCompanies: payload => dispatch(getCommonCompanies(payload)),
  onGetCommonDepartments: payload => dispatch(getCommonDepartments(payload)),
  onChangeTreeViewItem: item => dispatch(DepartmentChangeTreeViewItem(item)),
  onResetDepartmentSearchQuery: () => dispatch(resetDepartmentSearchQuery()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["departmentPage", "message", "common"])(Departments)))
