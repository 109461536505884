import {
    ButtonDropdownGroup,
    Check,
    CustomButton,
} from "components/Common"
import { permissionType } from "constant"
import PropTypes from "prop-types"

import {
    DropdownToggle,
} from "reactstrap"
import { withTranslation } from "react-i18next"
const HeaderButtons = ({
    resource,
    onSaveClick,
    onRefreshClick,
    isSaveSample,
    t,
    isLoading = false,
}) => {
    return (
        <>
            <Check permission={permissionType.R} resource={resource}>
                <CustomButton color="secondary" outline onClick={onRefreshClick}>
                    <i className={isLoading == true ? "fas fa-sync fa-spin" : "fas fa-sync-alt"}></i>
                </CustomButton>
            </Check>
            <ButtonDropdownGroup
                onDeleteClick={() => { }}
                onCloneClick={() => { }}
            >
                <Check permission={permissionType.U} resource={resource}>
                    <CustomButton color="primary" isEdit={true} onClick={onSaveClick} outline disabled={isSaveSample == true}>
                        {t("common:Save")}
                    </CustomButton>
                </Check>
                <Check
                    permissions={[permissionType.U]}
                    resource={resource}
                >
                    <Check permission={permissionType.D} resource={resource}>
                        <DropdownToggle caret color="primary" outline disabled>
                            <i className="mdi mdi-chevron-down"></i>
                        </DropdownToggle>
                    </Check>
                </Check>
            </ButtonDropdownGroup>
        </>
    )
}

HeaderButtons.propTypes = {
    resource: PropTypes.string.isRequired,
    onRefreshClick: PropTypes.func.isRequired,
    onSaveClick: PropTypes.func.isRequired,
}

export default withTranslation(["common", "hisConnectorPage"])(HeaderButtons)
