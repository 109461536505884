import BadgeLabelAndValue from "components/Common/BadgeLabelAndValue"
import {
    RESULT_STATE,
    RESULT_STATE_Text
} from "constant"
import { getPatientRequestById } from "helpers/app-backend"

import { convertDateFormat, convertDateFormat_VN, getAgeFromDOB } from "helpers/utilities"
import { isEmpty } from "lodash"

import {
    Accordion,
    AccordionBody,
    AccordionWrapper,
    FormSwitch
} from "components/Common"
import React, { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import {
    emptyTestRequestDetail,
    getIndividualDetail,
    getTestrequestGeneralDetail,
    getTestrequestSamplesDetail,
    getTestrequestTestsDetail,
} from "store/actions"
import { getGeneralSetting } from "store/setting/generalSetting/actions"

let timeOut = null
const GeneralInfo = ({
    t,
    formEl,
    dataRequest,
    onGetIndividualDetail,
    testRequestTests,
    testRequestSamples,
    onChangeData,
    onValidSubmit,
    fetchGetTestrequestGeneralDetail,
    fetchGetTestrequestTestsDetail,
    fetchGetTestrequestSamplesDetail,
    patientVisit,
    oneEmptyTestRequestDetail,
    onGetGeneralSetting,
    generalSetting
}) => {
    const formRef = formEl ? formEl : useRef()
    const [data, setData] = useState(dataRequest)
    const [isRequiredPatient, setIsRequiredPatient] = useState(
        data.patientId && data.patientId != ""
    )
    const [popoverOpenRequest, setPopoverOpenRequest] = useState(false)
    const [requestState, setRequestState] = useState(0)
    const [isChangeData, setIsChangeData] = useState(false)
    const [companyId, setCompanyId] = useState(null)
    const [requestLabel, setRequestLabel] = useState("")
    const [oldRequestId, setOldRequestId] = useState("")
    const [config, setConfig] = useState({})
    const [open, setOpen] = useState(false)
    const userInfo = JSON.parse(localStorage.getItem("userInfo"))
    let patientVisitConfig = localStorage.getItem("patientVisitConfig")

    useEffect(() => {
        setConfig(patientVisitConfig ? JSON.parse(patientVisitConfig) : {})
    }, [localStorage.getItem("patientVisitConfig")])

    useEffect(() => {
        if (isEmpty(generalSetting)) {
            onGetGeneralSetting(userInfo?.company);
        }
        return () => {
            oneEmptyTestRequestDetail()
        }
    }, [])

    useEffect(() => {
        setData(dataRequest)
        setIsRequiredPatient(
            dataRequest.patientId != undefined && dataRequest.patientId != ""
        )
        setRequestLabel(dataRequest.requestLabel || "")
        setOldRequestId(dataRequest.requestId || "")
    }, [dataRequest])

    useEffect(() => {
        setCompanyId(patientVisit.companyId)
        if (patientVisit.id == "" || patientVisit.id == undefined) {
            setPopoverOpenRequest(false)
        }
        if (patientVisit.patientId && patientVisit.patientId != "") {
            getPatientInfo()
        } else {
            const newData = {
                ...data,
                requestDate: patientVisit.requestDate,
                requestid: patientVisit.id,
                alternateId: patientVisit.alternateId,
                patientId: patientVisit.patientId,
                patient: "",
                medicalId: patientVisit.medicalId,
                fullName: ``,
                insuranceNumber: patientVisit.insuranceNumber,
                inPatient: patientVisit.inPatient,
                emergency: patientVisit.emergency,
                serviceType: patientVisit.serviceType,
                dob: "",
                physicianId: patientVisit.physicianId,
                gender: "",
                departmentId: patientVisit.departmentId,
                phone: "",
                diagnosis: patientVisit.diagnosis,
                address: "",
                resultTime: patientVisit.resultTime,
                email: "",
                remark: patientVisit.remark,
                companyId: patientVisit.companyId,
                pin: ``,
            }
            patientVisit.requestDate && setData(newData)
            patientVisit.requestDate && onChangeData && onChangeData(newData, true)
        }
    }, [patientVisit])

    useEffect(() => {
        setIsChangeData(true)
        if (testRequestTests.length > 0) {
            setRequestState(RESULT_STATE_Text.Submitted)
            if (testRequestSamples.length > 0) {
                if (
                    testRequestSamples[0].isReceived == true
                    // && samples[0].isCollected == true
                ) {
                    setRequestState(RESULT_STATE_Text.Received)
                } else if (testRequestSamples[0].isCollected == true) {
                    setRequestState(RESULT_STATE_Text.Collected)
                } else {
                    setRequestState(RESULT_STATE_Text.Submitted)
                }
            }
        } else {
            setRequestState(RESULT_STATE_Text.Draft)
        }
    }, [testRequestTests, testRequestSamples])

    const getPatientInfo = async () => {
        const res = await getPatientRequestById(
            patientVisit.id,
            patientVisit.patientId
        )
        const newData = {
            ...data,
            requestDate: patientVisit.requestDate,
            requestid: patientVisit.id,
            alternateId: patientVisit.alternateId,
            patientId: patientVisit.patientId,
            patient: res.id,
            medicalId: patientVisit.medicalId,
            insuranceNumber: patientVisit.insuranceNumber,
            inPatient: patientVisit.inPatient,
            emergency: patientVisit.emergency,
            fullName: `${res.familyName}${res.givenName ? " " + res.givenName : ""}`,
            serviceType: patientVisit.serviceType,
            dob: res.dob,
            physicianId: patientVisit.physicianId,
            gender: res.gender,
            departmentId: patientVisit.departmentId,
            phone: res["Contact.PhoneNumber"],
            diagnosis: patientVisit.diagnosis,
            address: res["Address.Address"],
            resultTime: patientVisit.resultTime,
            email: res["Contact.EmailAddress"],
            remark: patientVisit.remark,
            companyId: patientVisit.companyId,
            pin: res.PIN,
        }
        setData(newData)
        onChangeData && onChangeData(newData)
    }

    const onInputDebounce = val => {
        if (timeOut) {
            clearInterval(timeOut)
        }
        timeOut = setInterval(() => {
            setIsChangeData(false)
            clearInterval(timeOut)
            auToSave()
        }, 2000)
    }

    const auToSave = () => {
        config.autoSave == true && formRef && formRef.current?.submit()
    }
    const loadOldRequest = values => {
        setData({
            ...data,
            requestid: values[0].value,
            requestLabel: values[0].label,
        })
        fetchGetTestrequestTestsDetail(values[0].value)
        fetchGetTestrequestSamplesDetail(values[0].value)
        fetchGetTestrequestGeneralDetail(values[0].value)
    }

    const displayInPatient = generalSetting.find(item => item.fieldName === 'InPatient')?.fieldValue === 'True'

    return (
        <div className="header-result-detail sumamry-info-result-detail">
            <AccordionWrapper>
                <Accordion tabId={"1"} iconPosition='right'
                    onChange={(e, val) => {
                        setOpen(val)
                    }}
                    customTitle={() => <React.Fragment>
                        {open == true ?
                            <React.Fragment>
                                <div>{t("testRequestPage:Patient Infomation")}</div>
                                <div className="flex-1" style={{ textAlign: 'right' }}>
                                    {dataRequest.emergency == true &&
                                        <label style={{ marginLeft: 5 }}>
                                            <div className="">
                                                <BadgeLabelAndValue
                                                    label=''
                                                    type={'danger'}
                                                    value={t(`testResultPage:CC`)}
                                                    className={"fw-500"}
                                                />
                                            </div>
                                        </label>
                                    }
                                    {dataRequest.state && <label style={{ marginLeft: 5 }}>
                                        <div className="state-result-header-detail">
                                            <BadgeLabelAndValue
                                                label=''
                                                type={RESULT_STATE[dataRequest.state]}
                                                value={t(`testResultPage:${RESULT_STATE[dataRequest.state]}`)}
                                                className={"fw-500"}
                                            />
                                        </div>
                                    </label>}
                                </div>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <div className="header-result-detail-content d-flex header-result-detail-content-summary">
                                    {/* <div>
                                        <label className="header-result-detail-label">{t("SID")}: </label>
                                        <label className="header-result-detail-value">
                                            <CustomSIDHover data={dataRequest.sampleIds} indexParent={'header-result'} showTooltip={false} />
                                        </label>
                                    </div> */}
                                    <div>
                                        <label style={{ color: '#4775C5' }} className="header-result-detail-value">
                                            <Link to={`/Patient/${dataRequest.patientId}/view?tab=1`} target="_blank">{dataRequest.patientName}</Link>
                                        </label>
                                    </div>
                                    <div>
                                        <label className="header-result-detail-label">{t("Birthday")}: </label>
                                        <label className="header-result-detail-value">{convertDateFormat_VN(dataRequest.dob)}</label>
                                    </div>
                                    <div>
                                        <label className="header-result-detail-label">{t("Gender")}: </label>
                                        <label className="header-result-detail-value">{dataRequest.genderName}</label>
                                    </div>
                                    <div>
                                        <label className="header-result-detail-label">{t("testRequestPage:Age")}: </label>
                                        <label className="header-result-detail-value">{dataRequest.dob ? getAgeFromDOB(convertDateFormat(dataRequest.dob, "YYYY-MM-DD")) : ''}</label>
                                    </div>
                                    <div>
                                        <label className="header-result-detail-label">{t("common:ServiceType")}: </label>
                                        <label className="header-result-detail-value">{dataRequest.serviceTypeName}</label>
                                    </div>
                                    <div>
                                        <label className="header-result-detail-label">{t("Time Infomation")}: </label>
                                        <label className="header-result-detail-value">{dataRequest.createdDate ? convertDateFormat(dataRequest.createdDate, "YYYY-MM-DD hh:mm") : ''}</label>
                                    </div>
                                </div>
                                <div className="flex-1" style={{ textAlign: 'right' }}>
                                    {dataRequest.emergency == true &&
                                        <label style={{ marginLeft: 5 }}>
                                            <div className="">
                                                <BadgeLabelAndValue
                                                    label=''
                                                    type={'danger'}
                                                    value={t(`testResultPage:CC`)}
                                                    className={"fw-500"}
                                                />
                                            </div>
                                        </label>
                                    }
                                    {dataRequest.state && <label style={{ marginLeft: 5 }}>
                                        <div className="state-result-header-detail">
                                            <BadgeLabelAndValue
                                                label=''
                                                type={RESULT_STATE[dataRequest.state]}
                                                value={t(`testResultPage:${RESULT_STATE[dataRequest.state]}`)}
                                                className={"fw-500"}
                                            />
                                        </div>
                                    </label>}
                                </div>
                            </React.Fragment>
                        }
                    </React.Fragment>}
                >
                    <AccordionBody>
                        <div>
                            <div className="row">
                                <div className="col-lg-3 header-result-detail-content">
                                    <div>
                                        <label className="header-result-detail-label">{t("Full Name")}: </label>
                                        <label style={{ color: '#4775C5' }} className="header-result-detail-value">
                                            <Link to={`/Patient/${dataRequest.patientId}/view?tab=1`} target="_blank">{dataRequest.patientName}</Link>
                                        </label>
                                    </div>
                                    <div>
                                        <label className="header-result-detail-label">{t("Birthday")}: </label>
                                        <label className="header-result-detail-value">{convertDateFormat_VN(dataRequest.dob)}</label>
                                    </div>
                                    <div>
                                        <label className="header-result-detail-label">{t("Gender")}: </label>
                                        <label className="header-result-detail-value">{dataRequest.genderName}</label>
                                        <label style={{ marginLeft: '10px' }} className="header-result-detail-label">{t("testRequestPage:Age")}: </label>
                                        <label className="header-result-detail-value">{dataRequest.dob ? getAgeFromDOB(convertDateFormat(dataRequest.dob, "YYYY-MM-DD")) : ''}</label>
                                    </div>
                                    <div>
                                        <label className="header-result-detail-label">{t("common:Address")}:
                                            <span className="header-result-detail-value">{dataRequest.addressAddress}</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-lg-3 header-result-detail-content">
                                    <div>
                                        <label className="header-result-detail-label">{t("Alternate ID")}: </label>
                                        <label className="header-result-detail-value">{dataRequest.alternateId}</label>
                                    </div>
                                    <div>
                                        <label className="header-result-detail-label">{t("Medical ID")}: </label>
                                        <label className="header-result-detail-value">{dataRequest.medicalId}</label>
                                    </div>
                                    <div>
                                        <label className="header-result-detail-label">{t("common:ServiceType")}: </label>
                                        <label className="header-result-detail-value">{dataRequest.serviceTypeName}</label>
                                    </div>
                                    <div>
                                        <label className="header-result-detail-label">{t("Insurance Number")}: </label>
                                        <label className="header-result-detail-value">{dataRequest.insuranceNumber}</label>
                                    </div>
                                </div>
                                <div className="col-lg-3 header-result-detail-content">
                                    <div>
                                        <label className="header-result-detail-label">{t("PID")}: </label>
                                        <label className="header-result-detail-value">{dataRequest.patientId}</label>
                                    </div>
                                    <div>
                                        <label className="header-result-detail-label">{t("Time Infomation")}: </label>
                                        <label className="header-result-detail-value">{dataRequest.createdDate ? convertDateFormat(dataRequest.createdDate, "YYYY-MM-DD hh:mm") : ''}</label>
                                    </div>
                                    {displayInPatient && <div>
                                        <label className="header-result-detail-label">{t("common:InPatient")}: </label>
                                        <label className="header-result-detail-value">
                                            <FormSwitch
                                                label=""
                                                disabled
                                                value={data.inPatient}
                                            />

                                        </label>
                                    </div>}
                                    <div>
                                        <label className="header-result-detail-label">{t("common:Department")}: </label>
                                        <label className="header-result-detail-value">{dataRequest.departmentName}</label>
                                    </div>
                                </div>
                                <div className="col-lg-3 header-result-detail-content">

                                    <div>
                                        <label className="header-result-detail-label">{t("common:Phycisian")}: <span className="header-result-detail-value">{dataRequest.physicianName}</span></label>
                                    </div>
                                    {/* <div>
                                        <label className="header-result-detail-label">{t("common:Diagnosis")}: <span className="header-result-detail-value">{dataRequest.diagnosisName}</span></label>
                                    </div>
                                    <div>
                                        <label className="header-result-detail-label">{t("Remark")}: </label>
                                        <label className="header-result-detail-value">{dataRequest.remark}</label>
                                    </div> */}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 mb-2 header-result-detail-content">
                                    <div>
                                        <label className="header-result-detail-label">{t("common:Diagnosis")}: <span className="header-result-detail-value">{dataRequest.diagnosisName}</span></label>
                                    </div>
                                </div>
                                <div className="col-lg-6 mb-2 header-result-detail-content">
                                    <div>
                                        <label className="header-result-detail-label">{t("Remark")}: </label>
                                        <label className="header-result-detail-value">{dataRequest.remark}</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </AccordionBody>
                </Accordion>
            </AccordionWrapper>
        </div>
    )
}

const mapStateToProps = ({ testRequest, generalSetting }) => {
    return {
        billingTypes: testRequest.billingTypes,
        testRequestTests: testRequest.testRequest.tests || [],
        testRequestSamples: testRequest.testRequest.samples || [],
        patientVisit: testRequest.patientVisit,
        generalSetting: generalSetting.generalSetting
    }
}

const mapDispatchToProps = dispatch => ({
    onGetIndividualDetail: individualId =>
        dispatch(getIndividualDetail(individualId)),
    fetchGetTestrequestGeneralDetail: (requestId, callback) =>
        dispatch(getTestrequestGeneralDetail(requestId, callback)),
    fetchGetTestrequestTestsDetail: requestId =>
        dispatch(getTestrequestTestsDetail(requestId)),
    fetchGetTestrequestSamplesDetail: requestId =>
        dispatch(getTestrequestSamplesDetail(requestId)),
    oneEmptyTestRequestDetail: requestId =>
        dispatch(emptyTestRequestDetail(requestId)),
    onGetGeneralSetting: payload => dispatch(getGeneralSetting(payload)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["testRequestPage", "message", "common", "patientVisitPage"])(GeneralInfo)))
