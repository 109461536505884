import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import OrganizationTable from "./OrganizationTable"
import {
  Check,
  ConfirmModal,
  CustomButton,
  TitleAndTable,
  WarningModal,
} from "components/Common"

import {
  getInputChangedValue,
  getNumber,
  isEmptyValues,
  onDelete,
  onDeleteToggle,
  selectCheckboxHandler,
} from "helpers/utilities"

import {
  getOrganizationDetail,
  getOrganizations,
  resetOrganizationSearchQuery,
  updateOrganization,
  addNewOrganization,
  deleteOrganizations,
} from "store/party/organization/actions"

//i18n
import { withTranslation } from "react-i18next"
import { ModuleIds, PartyTypeText, permissionType } from "constant"
import { FieldFormModal, SelectProfileModal } from "../Individuals/Modal"
import { insertUrlParam, appendUrl, getUrlParams, getUrlParamByKey, appendParams } from "helpers/utilities"

const RESOURCE = ModuleIds.Organization

const Organizations = ({
  history,
  organizations,
  paging,
  onGetOrganizations,
  onAddNewOrganization,
  onUpdateOrganization,
  onDeleteOrganization,
  onResetOrganizationSearchQuery,
  onGetOrganizationDetail,
  organization,
  loadingOrganizations,
  updatedOrganizationTime,
  t,
}) => {
  const [confirmModal, setConfirmModal] = useState(false)
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(true)
  const [isClone, setIsClone] = useState(false)
  const [row, setRow] = useState({})
  const [rowDelete, setRowDelete] = useState({})
  const [rows, setRows] = useState([])

  const [rowAddNew, setRowAddNew] = useState({})
  const [rowEdit, setRowEdit] = useState(organization)

  const [warningModal, setWarningModal] = useState(false)

  const [selectModal, setSelectModal] = useState(false)

  const toggle = () => {
    setModal(prev => !prev)
  }

  const addOrganizationClicks = () => {
    setIsEdit(false)
    setIsClone(false)
    setRowAddNew({})
    // toggle()
    setSelectModal(prev => !prev)
  }

  /**
   * Handling submit Organization on Organization form
   */
  const handleValidSubmit = (e, values) => {
    values.FirstVisitCompanyId = getNumber(values["FirstVisitCompanyId"])
    values.ManagementCompanyId = getNumber(values["ManagementCompanyId"])
    values.OrganizationCode = `${values.OrganizationCode}`.trim()
    delete values.ParentId
    if (isEdit) {
      onUpdateOrganization({ organization: values, callback: toggle })
    } else {
      const newOrganization = {
        ...values,
      }
      delete newOrganization.Id
      // save new Organization
      onAddNewOrganization({ organization: newOrganization, history })
    }
  }

  const onCloneHandler = (param) => {
    const id = param || row?.id
    if (id) {
      setIsEdit(false)
      setIsClone(true)
      onGetOrganizationDetail({
        id,
        callback: () => {
          toggle()
        },
      })
    } else setWarningModal(true)
  }

  const onEditHandler = (e, paramId) => {
    const id = paramId || row?.id
    if (id) {
      onGetOrganizationDetail({
        id,
        callback: () => {
          setIsEdit(true)
          toggle()
        },
      })
    } else setWarningModal(true)
  }

  const resetState = () => {
    setRows([])
    setRow({})
  }

  const onDeleteToggleHandler = (e, param) => {
    onDeleteToggle({
      rows,
      row: param || rowDelete,
      setConfirmModal,
      setWarningModal,
      setRowDelete,
    })
    if (confirmModal) setRowDelete({})
  }

  const onDeleteMultipleRows = rowsState => {
    onDeleteOrganization({ organizations: rowsState, callback: resetState })
  }

  const onDeleteSingleRow = rowsState => {
    onDeleteOrganization({
      organizations: rowsState,
      callback: () => {
        setRows(prev => prev.filter(x => x.id !== rowDelete.id))
      },
    })
    setRowDelete({})
    setRow({})
  }

  const onDeleteOrganizationHandler = () => {
    onDelete({
      rowDelete,
      rows,
      onDeleteSingleRow,
      onDeleteMultipleRows,
    })
    // toggle modal
    setConfirmModal(false)
  }

  /**Get selected row and set to state
   *
   */
  const onSelectCheckbox = (row, isSelected) => {
    const { rowsState, currentRow } = selectCheckboxHandler(
      rows,
      row,
      isSelected
    )
    setRows(rowsState)
    setRow(currentRow)
  }

  const onSelectAllCheckbox = rows => {
    setRows(rows)
    if (rows.length < 1) setRow({})
    else setRow(rows[rows.length - 1])
  }

  const onGetOrganizationList = (payload) => {
    insertUrlParam(payload)
    onGetOrganizations(payload)
  }

  const fetchOrganizations = () => {
    onGetOrganizationList({ page: 1, sort: "createdDate:desc" })
  }

  /** Table methods */
  const onRefreshHandler = () => {
    resetState()
    fetchOrganizations()
  }

  const onSearch = searchText => {
    onGetOrganizationList({ page: 1, search: searchText })
  }

  const onSizePerPageChange = size => {
    onGetOrganizationList({ page: 1, size })
  }

  const onPageChange = page => {
    onGetOrganizationList({ page })
  }

  const onSubmitFilter = values => {
    onGetOrganizationList({ page: 1, ...values })
  }

  const onSortHandler = (field, order) => {
    const sortString = `${field}:${order}`
    onGetOrganizationList({ page: 1, sort: sortString })
  }

  /**-----CYCLE------ */

  useEffect(() => {
    onResetOrganizationSearchQuery()
  }, [])

  useEffect(() => {
    let params = getUrlParams();
    let paramPage = getUrlParamByKey("page");
    if (paramPage) {
      onGetOrganizationList(params)
    } else {
      fetchOrganizations()
    }
  }, [])

  useEffect(() => {
    setRowEdit(organization)
  }, [organization])

  return (
    <React.Fragment>
      {/* Body */}
      <TitleAndTable
        table={() => (
          <OrganizationTable
            organizations={organizations}
            onSelect={onSelectCheckbox}
            onSelectAll={onSelectAllCheckbox}
            onSearch={onSearch}
            onSort={onSortHandler}
            onRefresh={onRefreshHandler}
            onPageChange={onPageChange}
            paging={paging}
            onSizePerPageChange={onSizePerPageChange}
            onSubmitFilter={onSubmitFilter}
            onEdit={onEditHandler}
            onDelete={onDeleteToggleHandler}
            onClone={onCloneHandler}
            loading={loadingOrganizations}
            updatedTime={updatedOrganizationTime}
          />
        )}
        resource={RESOURCE}
        buttons={() => (
          <Check permission={permissionType.C} resource={RESOURCE}>
            <CustomButton
              color="primary"
              onClick={addOrganizationClicks}
              outline
            >
              {t("common:Add")}
            </CustomButton>
            <CustomButton color="primary" onClick={(e) => { onDeleteToggleHandler(e, rows.id) }} outline>
              {t("common:Delete")}
            </CustomButton>
          </Check>
        )}
        onEdit={onEditHandler}
        onDelete={onDeleteToggleHandler}
        onClone={onCloneHandler}
        isDisableClone
        isHideEdit
        isDropDown
        title={t("Organizations")}
        subtitle={t("Organization List")}
      />
      <WarningModal
        modal={warningModal}
        onToggle={() => setWarningModal(prev => !prev)}
        message={t("message:SelectRowWarning")}
      />
      <ConfirmModal
        modal={confirmModal}
        title={`${t("common:Delete")} ${t("Organization")}`}
        message={t("message:DeleteConfirm")}
        onToggle={onDeleteToggleHandler}
        onDelete={onDeleteOrganizationHandler}
      />
      {(isEdit || isClone) && !isEmptyValues(rowEdit) && (
        <FieldFormModal
          modal={modal}
          toggle={toggle}
          profileId={rowEdit.ProfileId}
          profileName={rowEdit.ProfileName}
          onSubmit={handleValidSubmit}
          isEdit={isEdit}
          isClone={isClone}
          onSelectChanged={(data) => {
          }}
          data={rowEdit}
        />
      )}

      {/* {selectModal && ( */}
      <SelectProfileModal
        modal={selectModal}
        isEdit={isEdit || isClone}
        toggle={() => setSelectModal(prev => !prev)}
        onSubmit={handleValidSubmit}
        type={PartyTypeText.Organization}
        data={!isEdit && !isClone ? rowAddNew : rowEdit}
        resource={RESOURCE}
      />
      {/* )} */}
    </React.Fragment>
  )
}

Organizations.propTypes = {
  organizations: PropTypes.array,
  onGetOrganizationDetail: PropTypes.func,
  onGetOrganizations: PropTypes.func,
  onAddNewOrganization: PropTypes.func,
  onUpdateOrganization: PropTypes.func,
  onDeleteOrganization: PropTypes.func,
  onResetOrganizationSearchQuery: PropTypes.func,
  paging: PropTypes.object,
  loadingOrganizations: PropTypes.bool,
  updatedOrganizationTime: PropTypes.any,
  t: PropTypes.any,
}

const mapStateToProps = ({ organization }) => ({
  organizations: organization.organizations,
  organization: organization.organization,
  paging: organization.paging,
  loadingOrganizations: organization.loadingOrganizations,
  updatedOrganizationTime: organization.updatedOrganizationTime,
})

const mapDispatchToProps = dispatch => ({
  onGetOrganizations: payload => dispatch(getOrganizations(payload)),
  onAddNewOrganization: (organization, history) =>
    dispatch(addNewOrganization(organization, history)),
  onUpdateOrganization: organization =>
    dispatch(updateOrganization(organization)),
  onDeleteOrganization: organization =>
    dispatch(deleteOrganizations(organization)),
  onGetOrganizationDetail: organizationId =>
    dispatch(getOrganizationDetail(organizationId)),
  onResetOrganizationSearchQuery: () =>
    dispatch(resetOrganizationSearchQuery()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["partyPage", "message", "common"])(Organizations)))
