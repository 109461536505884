import { useEffect, useState } from "react"

import { AvForm } from "availity-reactstrap-validation"
import { CustomAutoComplete } from "components/Common"
import {
  parameterCode
} from "constant"
import { withTranslation } from "react-i18next"

const ReasonCancel = ({
  onChangeReason,
  sample,
  data
}) => {
  const [reason, setReason] = useState('')
  const [isSelect, setIsSelect] = useState(false)

  useEffect(() => {
    if (data) {
      setReason({ code: data.code, label: data.reasonName })
    }
  }, [data])

  const onChange = (value) => {
    setReason(value)
    onChangeReason(value, sample)
  }

  return (
    <div>
      <AvForm>
        <div
          onClick={e => {
            setIsSelect(true)
          }}
        >
          {!isSelect && <a href="#">
            {reason.label || '---'}
          </a>}
          {isSelect && <div
            id={`length`}
            onBlur={e => {
              setIsSelect(false)
            }}>
            <AvForm>
              <CustomAutoComplete
                isClearable={false}
                value={reason?.code}
                autoFocus={true}
                name="reasonCorrection"
                code={parameterCode.REASON_KEY}
                onChange={(e, values, item) => {
                  onChange(item[0])
                }}
              />
            </AvForm>
          </div>}
        </div>
      </AvForm>
    </div>

  )
}

export default withTranslation(["deliveryPage", "common"])(ReasonCancel)
