import {
    ButtonDropdownGroup,
    Check,
    CustomButton
} from "components/Common"
import { permissionType } from "constant"
import PropTypes from "prop-types"
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "reactstrap"

import { withTranslation } from "react-i18next"
const HeaderButtons = ({
    resource,
    onRefreshClick,
    onSaveClick,
    onOpenModal,
    isSaveSample,
    t,
    displayTimeUpdate = true,
    isLoading = false,
}) => {
    return (
        <>
            <Check permission={permissionType.R} resource={resource}>
                <CustomButton color="secondary" outline onClick={onRefreshClick}>
                    <i className={isLoading == true ? "fas fa-sync fa-spin" : "fas fa-sync-alt"}></i>
                </CustomButton>
            </Check>

            <ButtonDropdownGroup
                onDeleteClick={() => { }}
                onCloneClick={() => { }}
            >
                <Check permission={permissionType.U} resource={resource}>
                    <CustomButton color="primary" isEdit={true} onClick={onSaveClick} outline disabled={isSaveSample == true}>
                        {t("common:Save")}
                    </CustomButton>
                </Check>
                {/* {displayTimeUpdate && <Check permission={permissionType.U} resource={resource}>
                    <CustomButton color="primary" onClick={onOpenModal} outline>
                        {t("common:Time Update")}
                    </CustomButton>
                </Check>} */}
                <Check
                    permissions={[permissionType.U]}
                    resource={resource}
                >
                    <Check permission={permissionType.D} resource={resource}>
                        <DropdownToggle caret color="primary" outline disabled>
                            <i className="mdi mdi-chevron-down"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem disabled>{t("Action")}</DropdownItem>

                        </DropdownMenu>
                    </Check>
                </Check>
            </ButtonDropdownGroup>
        </>
    )
}

HeaderButtons.propTypes = {
    resource: PropTypes.string.isRequired,
    onRefreshClick: PropTypes.func.isRequired,
    onSaveClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    displayTimeUpdate: PropTypes.bool,
}

export default withTranslation(["common", "testRequestPage"])(HeaderButtons)
