import React, { Fragment, useEffect, useState } from "react"
import { Label } from "reactstrap"
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap"
import { Link } from "react-router-dom"
import Sidebar from "components/VerticalLayout/Sidebar"
import { connect } from "react-redux"
import { getRequestSampleTrackingDetail } from "store/actions"
import {
  convertDateFormat,
  convertDateTimeFormat_VN,
  getI18nextLng,
  getMinuteBetween,
} from "helpers/utilities"
import moment from "moment"
import { getAllDepartments } from "helpers/app-backend"
import { CustomSID } from "components/Common"

const TabAllTest = ({ data, onGetRequestSampleTrackingDetail }) => {
  const [row, setRow] = useState(null)
  const [department, setDepartment] = useState([])

  let lang = getI18nextLng()
  const onClickRow = item => {
    item != row ? setRow(item) : setRow({})
    onGetRequestSampleTrackingDetail({
      requestId: item.requestId,
      sid: item.sid,
    })
  }
  useEffect(() => {
    getDepartments()
  }, [])

  const getDepartments = async () => {
    const res = await getAllDepartments({ size: 100 })
    let data = []
    data = res.data.map(_item => {
      _item.value = _item.id
      _item.label = lang === "vi" ? _item.name : _item.englishName || _item.name
      return _item
    })
    setDepartment([...data])
  }


  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Label style={{ fontWeight: 600 }}>Mới nhất</Label>
        <Label style={{ fontWeight: 600 }}>
          {convertDateTimeFormat_VN(new Date())}
        </Label>
      </div>
      <div>
        <ul
          className="list-group list-group-flush"
          style={{ overflowX: "hidden", height: "38vh" }}
        >
          {data.map(item => {
            let color = ""
            if (item.countInprogress === 0 && item.countDone > 0) {
              color = "green"
            } else if (
              getMinuteBetween(new Date(), new Date(item.collectionTime)) < item.tat &&
              item.countInprogress > 0 &&
              item.countDone > 0
            ) {
              color = "#f1b44c"
            } else if (item.countDone === 0 && item.countInprogress > 0) {
              color = "gray"
            } else if (getMinuteBetween(new Date(), new Date(item.collectionTime)) > item.tat && item.countInprogress > 0 && item.countDone > 0) {
              color = "#dc3545"
            }
            return (
              <li
                key={item}
                onClick={() => {
                  onClickRow(item)
                }}
                className="list-group-item"
                style={{
                  background: item == row ? "#fff" : "transparent",
                  paddingBottom: 0,
                  paddingTop: 0,
                  border: item == row && "1px solid #ccc",
                }}
              >
                <div className="py-2">
                  <Row>
                    <Col lg="1" style={{ alignSelf: "center" }}>
                      <div
                        style={{
                          width: 17,
                          height: 17,
                          background: color,
                          borderRadius: "100%",
                        }}
                      ></div>
                    </Col>
                    <Col lg="1" style={{ paddingLeft: 0, top: 10 }}>
                      <div className="avatar-sm mx-auto mb-4">
                        <span
                          className="avatar-title rounded-circle bg-primary bg-soft font-size-24"
                          style={{ width: 45, height: 45 }}
                        >
                          <i
                            style={{ fontSize: 34 }}
                            className="mdi mdi-test-tube text-primary"
                          ></i>
                        </span>
                      </div>
                    </Col>
                    <Col
                      lg="7"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignSelf: "center",
                      }}
                    >
                      <div>
                        <p
                          style={{
                            fontSize: 12,
                            fontWeight: 600,
                            marginBottom: 0,
                          }}
                        >
                          SID: <CustomSID
                            textOnly={true}
                            sid={item.sid}
                          />{` - ${item.sampleTypeName}`}
                        </p>
                        <p style={{ fontSize: 12, marginBottom: 0 }}>
                          PID:{" "}
                          <Link
                            to={`/Patient/${item.patientId}/view?tab=1`}
                            target="_blank"
                          >
                            {item.patientId
                              ? `${item.patientId
                              } - ${item.patientName?.trim()} - ${item.genderName
                              }`
                              : ""}
                          </Link>
                        </p>
                        <p style={{ fontSize: 12, marginBottom: 0 }}>
                          RID:{" "}
                          <Link
                            to={`/TestRequest/${item.requestId}/view?tab=1`}
                            target="_blank"
                          >
                            {`${moment(new Date(item.requestDate)).format(
                              "YYYYMMDD"
                            )}-R${item.requestId}`}
                          </Link>{" "}
                        </p>
                      </div>
                      <div>
                        <Label
                          style={{
                            fontSize: 12,
                            marginBottom: 0,
                            fontWeight: 600,
                            fontStyle: "italic",
                            float: "right",
                          }}
                        >
                          {item.departmentName}
                        </Label>
                      </div>
                    </Col>
                    <Col lg="3" style={{ textAlign: "right", paddingTop: 8, paddingRight: 0 }}>
                      <Row>
                        <span
                          style={{
                            fontSize: 12,
                            fontWeight: 600,
                            fontStyle: 'oblique'
                          }}
                        >
                          {
                            department.find(row => row.id === item.departmentId)
                              ?.name
                          }
                        </span>
                      </Row>
                      {item.emergency && (
                        <Row
                          style={{
                            justifyContent: "right",
                            paddingRight: "12px",
                          }}
                        >
                          <span
                            style={{
                              background: "red",
                              width: "inherit",
                              borderRadius: "20%",
                              justifyContent: "right",
                              color: 'white'
                            }}
                          >
                            CC
                          </span>
                        </Row>
                      )}
                    </Col>
                  </Row>
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </>
  )
}

const mapStateToProps = ({ sampleTracking }) => ({})

const mapDispatchToProps = dispatch => ({
  onGetRequestSampleTrackingDetail: payload =>
    dispatch(getRequestSampleTrackingDetail(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TabAllTest)
