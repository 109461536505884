import { CustomBootstrapTable } from "components/Common"
import { ModuleIds } from "constant"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import FilterForm from "./FilterForm"
import { useSelector } from "react-redux"
import { convertDateFormat } from "helpers/utilities"

const RESOURCE = ModuleIds.Logs

const AuditLogTable = ({
    onSelect,
    onSelectAll,
    auditLog,
    onSearch,
    onSort,
    onRefresh,
    onPageChange,
    paging,
    onSizePerPageChange,
    onSubmitFilter,
    loading,
    onRowClick,
    t,
    updatedTime,
}) => {
    const [data, setData] = useState([])
    const [model, setModel] = useState({
        search: "",
        start: ""
    })

    const searchQuery = useSelector(state => state.auditLog.searchAuditQuery)

    useEffect(() => {
        if (auditLog) {
            setData(auditLog)
        }
    }, [auditLog])

    const onResetHandler = () => {
        const initModel = {
        }
        setModel(initModel)
    }

    useEffect(() => {
        setModel(searchQuery)
    }, [searchQuery])

    const columns = [
        {
            dataField: "id",
            text: "#",
            hidden: true,
        },
        {
            dataField: "Identifier",
            text: "Identifier",
            sort: true,
            formatter: (cellContent, code, index) => (
                <div >{code.identifier}</div>
            ),
        },
        {
            dataField: "TimeStamp",
            text: "Time Stamp",
            sort: true,
            formatter: (cellContent, code, index) => (
                <span>{convertDateFormat(code.timeStamp, "YYYY-MM-DD HH:mm:ss")}</span>
            ),
        },
        {
            dataField: "Payload",
            text: "Payload",
            sort: false,
            formatter: (cellContent, code, index) => (
                <div
                    onClick={(e) => { onRowClick(e, code) }}
                    style={{
                        width: '300px',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        color: 'blue'
                    }}
                >{code.payload}</div>
            ),
        },
        {
            dataField: "ShortType",
            text: "Short Type",
            sort: true,
            formatter: (cellContent, code, index) => (
                <div  >{code.shortType}</div>
            ),
        },

        {
            dataField: "Type",
            text: "Type",
            sort: true,
            formatter: (cellContent, code, index) => (
                <div
                    style={{
                        width: '300px',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap'
                    }}
                >{code.type}</div>
            ),
        },
        {
            dataField: "UserName",
            text: "User Name",
            sort: true,
            formatter: (cellContent, code, index) => (
                <div >{code.userName}</div>
            ),
        },

    ]

    return (
        <CustomBootstrapTable
            columns={columns}
            search
            data={data}
            paging={paging}
            onSelect={onSelect}
            onSelectAll={onSelectAll}
            onSearch={onSearch}
            onSort={onSort}
            onRefresh={onRefresh}
            filterForm={() => <FilterForm model={model} />}
            onReset={onResetHandler}
            onPageChange={onPageChange}
            onSizePerPageChange={onSizePerPageChange}
            onSubmitFilter={onSubmitFilter}
            searchText={model?.search}
            loading={loading}
            updatedTime={updatedTime}
            isEnableExport={false}
            resource={RESOURCE}
            keyField="id"
        />
    )
}

export default withTranslation(["auditlog", "common"])(AuditLogTable)
