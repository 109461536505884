import { useEffect } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Card, CardBody, CardHeader } from "reactstrap"
import { getCompanyDetail, getDepartmentDetail } from "store/actions"
const Information = ({
  onGetCompanyDetail,
  onGetDepartmentDetail,
  company,
  department,
  t
}) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"))
  useEffect(() => {
    fetchCompanyAndDepartment()
  }, [])
  const fetchCompanyAndDepartment = () => {
    onGetCompanyDetail(userInfo.company)
    onGetDepartmentDetail(userInfo.department)
  }
  return (
    <Card style={{ marginBottom: 2 }}>
      <CardHeader
        style={{
          border: "none",
          background: "#fff",
          fontWeight: 600,
          fontSize: 18,
        }}
      >
        GIÁM SÁT MẪU
      </CardHeader>
      <CardBody style={{ paddingTop: 0, paddingBottom: 0 }}>
        <p className="mb-0" style={{ fontWeight: 600 }}>
          Thông tin Lab
        </p>
        <p className="mb-0">
          {t("Site")}: {company.name} - {department.name}
        </p>
        <p>LabId: #{company.id}</p>
      </CardBody>
    </Card>
  )
}
const mapStateToProps = ({ company, department }) => ({
  company: company.company,
  department: department.department,
})
const mapDispatchToProps = dispatch => ({
  onGetCompanyDetail: companyId => dispatch(getCompanyDetail(companyId)),
  onGetDepartmentDetail: departmentId =>
    dispatch(getDepartmentDetail(departmentId)),
})
export default connect(mapStateToProps, mapDispatchToProps)
(withRouter(withTranslation(["testConfig"])(Information)))
