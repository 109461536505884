import {
    ConfirmModal,
    OkCancelModal,
    TitleAndTable,
    WarningModal
} from "components/Common"
import { ModuleIds } from "constant"
import HeaderButtons from "./HeaderButtons"

import {
    convertDateFormat,
    getUrlParamByKey,
    getUrlParams,
    insertUrlParam,
    onActionToggle,
    onDelete,
    onDeleteToggle,
    selectCheckboxHandler,
} from "helpers/utilities"

import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
//i18n
import { withTranslation } from "react-i18next"
import { connect, useSelector } from "react-redux"
import { withRouter } from "react-router-dom"
import {
    deleteValidResults,
    getValidResultsList,
    resetValidResultSearchQuery,
    updateInValidResultsREV,
    updateValidResultsSampleId,
    updateValidResultsVAL,
} from "store/analysis-process/validresults/actions"

import ChangeSIDModal from "../Modals/ChangeSIDModal"
import ValidationResultTable from "./ValidationResultTable"

const RESOURCE = ModuleIds.ValidResult

const UpdateResult = ({
    history,
    paging,
    onGetValidResults,
    onUpdateValidResultsSampleId,
    onDeleteResult,
    onResetValidResultSearchQuery,
    loadingResult,
    updatingResult,
    updateResultTime,
    onUpdateValidResults,
    onUpdateInValidResults,
    results,
    result,
    t,
}) => {
    const [confirmModal, setConfirmModal] = useState(false)

    const [confirmUpdateValidModal, setConfirmUpdateValidModal] = useState(false)
    const [confirmUpdateInValidModal, setConfirmUpdateInValidModal] = useState(false)

    const [modal, setModal] = useState(false)
    const [isEdit, setIsEdit] = useState(true)
    const [row, setRow] = useState({})
    const [rowDelete, setRowDelete] = useState({})

    //const [rowUpdate, setRowUpdate] = useState({})

    const [rows, setRows] = useState([])

    const [warningModal, setWarningModal] = useState(false)

    const formEl = useRef(null)

    useEffect(() => {
        window.addEventListener('popstate', (event) => popStateChange(event));
    }, []);

    const popStateChange = (event) => {
        let params = getUrlParams();
        let paramPage = getUrlParamByKey("page");
        if (paramPage) {
            onGetValidResultList(params)
        }
    }

    useEffect(() => {
        let params = getUrlParams();
        let paramPage = getUrlParamByKey("page");
        if (paramPage) {
            onGetValidResultList(params)
        } else {
            fetchResults()
        }
    }, [])

    const onGetValidResultList = (payload) => {
        insertUrlParam(payload)
        onGetValidResults(payload)
    }

    const searchQuery = useSelector(state => state.validResult.searchQuery)

    const toggle = () => {
        setModal(prev => !prev)
    }

    const handleValidSubmit = (values) => {
        onUpdateValidResultsSampleId({ samples: values, callback: afterUpdate })
    }
    const afterUpdate = () => {
        onRefreshHandler();
        toggle()
    }
    const resetState = () => {
        setRows([])
        setRow({})
    }

    const onDeleteToggleHandler = (e, param) => {
        onDeleteToggle({
            rows,
            row: param || rowDelete,
            setConfirmModal,
            setWarningModal,
            setRowDelete,
        })
        if (confirmModal) setRowDelete({})
    }

    const onDeleteMultipleRows = rowsState => {
        onDeleteResult({ results: rowsState, callback: resetState })
    }

    const onDeleteSingleRow = rowsState => {
        onDeleteResult({
            results: rowsState,
            callback: () => {
                setRows(prev => prev.filter(x => x.id !== rowDelete.id))
            },
        })
        setRowDelete({})
        setRow({})
    }

    const onDeleteResultHandler = () => {
        onDelete({
            rowDelete,
            rows,
            onDeleteSingleRow,
            onDeleteMultipleRows,
        })
        setConfirmModal(false)
    }

    /**Get selected row and set to state
     *
     */
    const onSelectCheckbox = (row, isSelected) => {
        const { rowsState, currentRow } = selectCheckboxHandler(
            rows,
            row,
            isSelected
        )
        setRows(rowsState)
        setRow(currentRow)
    }

    const onSelectAllCheckbox = rows => {
        setRows(rows)
        if (rows.length < 1) setRow({})
        else setRow(rows[rows.length - 1])
    }

    const fetchResults = () => {
        let requestDate = convertDateFormat(new Date(), "YYYY-MM-DD")
        if (searchQuery.requestDate) {
            requestDate = searchQuery.requestDate
        }
        onGetValidResultList({ page: 1, requestDate: requestDate, sort: "runTime:desc" })
    }

    /** Table methods */
    const onRefreshHandler = () => {
        resetState()
        fetchResults()
    }

    const onSearch = searchText => {
        onGetValidResultList({ page: 1, search: searchText })
    }

    const onSizePerPageChange = size => {
        onGetValidResultList({ page: 1, size })
    }

    const onPageChange = page => {
        onGetValidResultList({ page })
    }

    const onSubmitFilter = values => {
        if (!values.requestDate) {
            values.requestDate = convertDateFormat(new Date(), "YYYY-MM-DD")
        }
        onGetValidResultList({ page: 1, ...values })
    }

    const onSortHandler = (field, order) => {
        const sortString = `${field}:${order}`
        onGetValidResultList({ page: 1, sort: sortString })
    }

    const onUpdateValidHandler = () => {
        onUpdateValidResults({ results: rows, callback: afterUpdateValid })
    }

    const onUpdateInValidHandler = () => {
        onUpdateInValidResults({ results: rows, callback: afterUpdateValid })

    }

    const afterUpdateValid = () => {
        //resetState()
        setConfirmUpdateInValidModal(false)
        setConfirmUpdateValidModal(false)
        fetchResults()
    }

    /**-----CYCLE------ */

    useEffect(() => {
        onResetValidResultSearchQuery()
    }, [])

    //Update status confirm
    const onUpdateValidStatusToggleHandler = (e, param) => {
        onActionToggle({
            rows,
            row: param || rowDelete,
            setConfirmUpdateModal: setConfirmUpdateValidModal,
            setWarningModal,
            //setRowDelete,
        })
    }

    const onUpdateInValidStatusToggleHandler = (e, param) => {
        onActionToggle({
            rows,
            row: param || rowDelete,
            setConfirmUpdateModal: setConfirmUpdateInValidModal,
            setWarningModal,
            //setRowDelete,
        })
    }

    return (
        <React.Fragment>
            {/* Body */}
            <TitleAndTable
                table={() => (
                    <ValidationResultTable
                        results={results}
                        onSelect={onSelectCheckbox}
                        onSelectAll={onSelectAllCheckbox}
                        onSearch={onSearch}
                        onSort={onSortHandler}
                        onRefresh={onRefreshHandler}
                        onPageChange={onPageChange}
                        paging={paging}
                        onSizePerPageChange={onSizePerPageChange}
                        onSubmitFilter={onSubmitFilter}
                        onEdit={() => { }}
                        onDelete={onDeleteToggleHandler}
                        loading={loadingResult}
                        updatedTime={updateResultTime}
                    />
                )}
                resource={RESOURCE}
                buttons={() => (
                    <HeaderButtons
                        resource={RESOURCE}
                        onChangeSIDClick={() => toggle()}
                        //onUpdateValidClick={onUpdateValidHandler}
                        onUpdateValidClick={onUpdateValidStatusToggleHandler}
                        onUpdateInvalidClick={onUpdateInValidStatusToggleHandler}
                        onDeleteClick={onDeleteToggleHandler}
                        model={{}}
                    />
                )}
                external
                onEdit={() => { }}
                onDelete={onDeleteToggleHandler}
                onClone={() => { }}
                title={t("Valid Result")}
                subtitle={t("Valid Result List")}
            />


            <WarningModal
                modal={warningModal}
                onToggle={() => setWarningModal(prev => !prev)}
                message={t("message:SelectRowWarning")}
            />
            <ConfirmModal
                modal={confirmModal}
                title={`${t("common:Delete")} ${t("Result")}`}
                message={t("message:DeleteConfirm")}
                onToggle={onDeleteToggleHandler}
                onDelete={onDeleteResultHandler}
            />

            <OkCancelModal
                modal={confirmUpdateValidModal}
                title={`${t("Change Status")} ${t("Result")}`}
                message={t("Update Valid Status")}
                onToggle={onUpdateValidStatusToggleHandler}
                onAction={onUpdateValidHandler}
            />

            <OkCancelModal
                modal={confirmUpdateInValidModal}
                title={`${t("Change Status")} ${t("Result")}`}
                message={t("Update InValid Status")}
                onToggle={onUpdateInValidStatusToggleHandler}
                onAction={onUpdateInValidHandler}
            />

            <ChangeSIDModal
                formEl={formEl}
                modal={modal}
                isEdit={false}
                onValidSubmit={handleValidSubmit}
                toggle={toggle}
                data={result}
            />
        </React.Fragment>
    )
}

UpdateResult.propTypes = {
    results: PropTypes.array,
    result: PropTypes.object,
    paging: PropTypes.object,
    loadingResult: PropTypes.bool,
    updatingResult: PropTypes.bool,
    updateResultTime: PropTypes.any,
    t: PropTypes.any,

    onGetValidResults: PropTypes.func,
    onDeleteResult: PropTypes.func,
    onResetValidResultSearchQuery: PropTypes.func,

    onUpdateValidResults: PropTypes.func,
    onUpdateInValidResults: PropTypes.func,
}

const mapStateToProps = ({ validResult }) => {
    return ({
        results: validResult.results,
        result: validResult.result,
        paging: validResult.paging,
        loadingResult: validResult.loadingResult,
        updateResultTime: validResult.updateResultTime,
        updatingResult: validResult.updatingResult,
    })
}

const mapDispatchToProps = dispatch => ({
    onGetValidResults: payload => dispatch(getValidResultsList(payload)),
    onDeleteResult: results => dispatch(deleteValidResults(results)),
    onResetValidResultSearchQuery: () => dispatch(resetValidResultSearchQuery()),
    onUpdateValidResultsSampleId: (payload) => dispatch(updateValidResultsSampleId(payload)),

    onUpdateValidResults: (payload) => dispatch(updateValidResultsVAL(payload)),
    onUpdateInValidResults: (payload) => dispatch(updateInValidResultsREV(payload)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["resultPage", "message"])(UpdateResult)))