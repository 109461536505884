import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import {
    TitleAndTable,
    WarningModal
} from "components/Common"
import {
    selectCheckboxHandler
} from "helpers/utilities"

import {
    resetSearchQuery
} from "store/laboratory/tests/actions"

import { ModuleIds } from "constant"
import { getUrlParamByKey, getUrlParams, insertUrlParam } from "helpers/utilities"
import { withTranslation } from "react-i18next"
import NotificationTable from "./NotificationTable"
import { getNotification } from "store/users/notification/actions"

const RESOURCE = ModuleIds.TestNotification

const NotificationMessage = ({
    paging,
    onGetNotification,
    notificationList,
    onResetTestSearchQuery,
    t
}) => {
    let userInfo = localStorage.getItem("userInfo")
    let userInfoObject = JSON.parse(userInfo || "{}")
    const [row, setRow] = useState({})
    const [rows, setRows] = useState([])
    const [warningModal, setWarningModal] = useState(false)
    const [data, setData] = useState([])
    const resource = RESOURCE ? true : false
    let params = getUrlParams()

    useEffect(() => {
        setData(notificationList)
    }, [notificationList])

    const resetState = () => {
        setRow({})
        setRows([])
    }

    const onSelectCheckbox = (row, isSelected) => {
        const { rowsState, currentRow } = selectCheckboxHandler(
            rows,
            row,
            isSelected
        )
        setRows(rowsState)
        setRow(currentRow)
    }

    const onSelectAllCheckbox = rows => {
        setRows(rows)
        if (rows.length < 1) setRow({})
        else setRow(rows[rows.length - 1])
    }

    const onGetNotificationList = (payload) => {
        insertUrlParam(payload)
        onGetNotification({
            ...payload,
            ...params,
            id: userInfoObject?.sub,
            isPage: true
        })
    }
    const fetchNotifications = () => {
        onGetNotification({
            ...params,
            page: 1,
            size: 10,
            id: userInfoObject?.sub,
            isPage: true
        })
    }

    const onRefreshHandler = () => {
        resetState()
        fetchNotifications()
    }
    const onSearch = searchText => {
        onGetNotificationList({
            ...params,
            id: userInfoObject?.sub,
            page: 1, search: searchText
        })
    }

    const onSizePerPageChange = size => {
        onGetNotificationList({
            ...params,
            id: userInfoObject?.sub,
            page: 1, size
        })
    }

    const onPageChange = page => {
        onGetNotificationList({
            ...params,
            id: userInfoObject?.sub,
            page
        })
    }

    const onSubmitFilter = values => {
        onGetNotificationList({
            ...params,
            id: userInfoObject?.sub,
            page: 1, ...values
        })
    }

    const onSortHandler = (field, order) => {
        const sortString = `${field}:${order}`
        onGetNotificationList({
            ...params,
            id: userInfoObject?.sub,
            page: 1, sort: sortString
        })
    }
    useEffect(() => {
        onResetTestSearchQuery()
    }, [])

    useEffect(() => {
        let paramPage = getUrlParamByKey("page")
        if (paramPage) {
            onGetNotificationList(params)
        } else {
            fetchNotifications()
        }
    }, [])

    return (
        <React.Fragment>
            <TitleAndTable
                customIcon="bx bxs-bell-ring"
                table={() => (
                    <NotificationTable
                        notification={data}
                        onSelect={onSelectCheckbox}
                        onSelectAll={onSelectAllCheckbox}
                        onSearch={onSearch}
                        onSort={onSortHandler}
                        onRefresh={onRefreshHandler}
                        onPageChange={onPageChange}
                        paging={paging}
                        onSizePerPageChange={onSizePerPageChange}
                        onSubmitFilter={onSubmitFilter}
                    />
                )}
                resource={RESOURCE}
                title={t("Notifications")}
                subtitle={t("Notifications List")}
                isHideCancel={resource}
                isHideDelete={resource}
            />
            <WarningModal
                modal={warningModal}
                onToggle={() => setWarningModal(prev => !prev)}
                message={t("message:SelectRowWarning")}
            />
        </React.Fragment>
    )
}

NotificationMessage.propTypes = {
    paging: PropTypes.object,
    onGetNotifications: PropTypes.func,
}

const mapStateToProps = ({ notification }) => ({
    paging: notification.pagingPage,
    notificationList: notification.notificationListPage,
})

const mapDispatchToProps = dispatch => ({
    onResetTestSearchQuery: () => dispatch(resetSearchQuery()),
    onGetNotification: payload => dispatch(getNotification(payload)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["notificationPage", "message"])(NotificationMessage)))