import { ButtonDropdownGroup, Check, CustomButton, Header, TitleAndTable } from "components/Common"
import { ModuleIds, permissionType } from "constant"
import React, { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect, useSelector } from "react-redux"
import { withRouter } from "react-router-dom"
import { Row } from "reactstrap"
import { createNewRequestSuccess, getWorkLists, getWorkListsSuccess, inValidWorkLists, updateWorkLists, validWorkLists } from "store/actions"
import ManualRequestInfo from "./ManualRequestInfo"
import ConfigRequestModal from "./Modal/ConfigRequestModal"

const RESOURCE = ModuleIds.ManualRequest

const ManualRequest = ({
    t,
    onCreateNewRequestSuccess,
    testRequest,
}) => {
    const ref = useRef();
    const [modal, setModal] = useState(false)
    const [isDelete, setIsDelete] = useState(false)
    const updating = useSelector(state => state.common.updating)

    useEffect(() => {
        if (updating == false) {
            setIsDelete(false)
        }
    }, [updating])

    return (
        <React.Fragment className='p-3'>
            <Row className="mb-1">
                <div className="d-flex p-2 bg-white border-bottom">
                    <div className="col-6">
                        <Header
                            resource={RESOURCE}
                            title={t("Test Request")}
                            subtitle={t("Manual Test Request")}
                        />
                    </div>
                    <div className="d-flex col-6 gap-1 justify-content-end">
                        <React.Fragment>
                            <Check permission={permissionType.C} resource={RESOURCE}>
                                <CustomButton color="success" onClick={() => {
                                    ref.current.ResetData()
                                    onCreateNewRequestSuccess([])
                                    document.getElementById("alternateId").focus()
                                }}>
                                    {t("New Request")}
                                </CustomButton>
                            </Check>
                            <ButtonDropdownGroup onDeleteClick={() => { }} onCloneClick={() => { }}>
                                <Check permission={permissionType.C} resource={RESOURCE}>
                                    <CustomButton
                                        type="button"
                                        color="primary"
                                        onClick={() => {
                                            ref.current.submit()
                                        }}
                                        outline
                                        isEdit={isDelete == false}
                                        disabled={testRequest.id != undefined && testRequest.id != ''}
                                    >
                                        {t("common:Save")}
                                    </CustomButton>
                                </Check>
                                <Check permission={permissionType.U} resource={RESOURCE}>
                                    <CustomButton
                                        type="button"
                                        color="primary"
                                        onClick={() => {
                                            ref.current.submit()
                                        }}
                                        outline
                                        isEdit={isDelete == false}
                                        disabled={testRequest.id == undefined || testRequest.id == ''}
                                    >
                                        {t("common:Update")}
                                    </CustomButton>
                                </Check>
                                <Check permission={permissionType.D} resource={RESOURCE}>
                                    <CustomButton
                                        type="button"
                                        color="primary"
                                        onClick={() => {
                                            setIsDelete(true)
                                            ref.current.onDelete()
                                        }}
                                        outline
                                        isEdit={isDelete == true}
                                    >
                                        {t("common:Delete")}
                                    </CustomButton>
                                </Check>
                            </ButtonDropdownGroup>
                            {/* <CustomButton color="secondary" outline
                                onClick={() => {
                                    setModal(true)
                                }}
                            >
                                <i className="fa fa-cog"></i>
                            </CustomButton> */}
                        </React.Fragment>
                    </div>
                </div>
            </Row >
            <TitleAndTable
                table={() => (
                    <div style={{ overflowX: 'hidden' }}>
                        <ManualRequestInfo ref={ref} />
                    </div>
                )}
                resource={RESOURCE}
                isHideEdit={true}
                external={true}
                title={t("")}
                subtitle={t("")}
            />
            <ConfigRequestModal
                modal={modal}
                toggle={(val) => {
                    setModal(val)
                }}
                resource={RESOURCE}
            />
        </React.Fragment>
    )
}

const mapStateToProps = ({ workList, testRequest }) => ({
    workLists: workList.workLists,
    paging: workList.paging,
    loadingData: workList.loadingData,
    updateDataTime: workList.updatedTime,
    testRequest: testRequest.testRequest
});

const mapDispatchToProps = dispatch => ({
    onGetWorkLists: payload => dispatch(getWorkLists(payload)),
    onEmptyWorkLists: payload => dispatch(getWorkListsSuccess(payload)),
    onUpdateWorkLists: payload => dispatch(updateWorkLists(payload)),
    onValidWorkLists: payload => dispatch(validWorkLists(payload)),
    onInValidWorkLists: payload => dispatch(inValidWorkLists(payload)),
    onCreateNewRequestSuccess: payload => dispatch(createNewRequestSuccess(payload))

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["testRequestPage", "message", "common"])(ManualRequest)));