import { CustomBootstrapTable, CustomCheckbox, CustomSelect } from "components/Common"
import { ModuleIds, parameterCode } from "constant"
import { convertDateFormat } from "helpers/utilities"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import FilterForm from "./FilterForm"
import { AvForm, AvInput } from "availity-reactstrap-validation"
import classNames from "classnames"
import { getAllUsers } from "helpers/app-backend"

const RESOURCE = ModuleIds.Logs

const ErrorLogTable = ({
    onSelect,
    onSelectAll,
    errorLog,
    onSearch,
    onSort,
    onRefresh,
    onPageChange,
    paging,
    onSizePerPageChange,
    onSubmitFilter,
    loading,
    onSolveChange,
    t,
    updatedTime,
    onRowClick
}) => {
    const [data, setData] = useState([])
    const [user, setUser] = useState([])
    const [model, setModel] = useState({
        search: "",
        userId: ""
    })

    const searchQuery = useSelector(state => state.auditLog.searchErrorLogQuery)

    useEffect(() => {
        getUser()
    }, [])

    const getUser = async (errorLogData = []) => {
        const query = { size: 0 }
        const res = await getAllUsers(query)
        const reuslt = res.data.map(_item => {
            _item.value = _item.id
            _item.label = _item.familyName + " " + _item.givenName
            return _item
        })
        setUser(reuslt)
        if (errorLogData.length > 0) {
            errorLogData.forEach(item =>
                item.userName = reuslt.find(x => item.userId == x.id)?.label
            )
            setData(errorLog)
        }

        return reuslt
    }

    useEffect(() => {
        if (errorLog) {
            if (user.length === 0) {
                getUser(errorLog)
            } else {
                errorLog.forEach(item =>
                    item.userName = user.find(x => item.userId == x.id)?.label
                )
                setData(errorLog)
            }
        }
    }, [errorLog])

    const onResetHandler = () => {
        const initModel = {
            search: "",
        }
        setModel(initModel)
    }

    useEffect(() => {
        setModel(searchQuery)
    }, [searchQuery])

    const columns = [
        {
            dataField: "id",
            text: "#",
            hidden: true,
            searchable: false,
        },
        {
            dataField: "Title",
            text: "Title",
            searchable: false,
            sort: true,
            formatter: (cellContent, code, index) => (
                <span>{code.title}</span>
            ),
        },
        {
            dataField: "TimeStamp",
            text: "Time Stamp",
            searchable: false,
            sort: true,
            formatter: (cellContent, code, index) => (
                <span>{convertDateFormat(code.timeStamp, "YYYY-MM-DD HH:mm:ss")}</span>
            ),
        },
        {
            dataField: "Message",
            text: "Message",
            searchable: false,
            sort: true,
            style: { width: '800px' },
            formatter: (cellContent, code, index) => (
                <div
                    onClick={(e) => { onRowClick(e, code) }}
                    style={{
                        color: 'blue'
                    }}
                >{code.message}</div>
            ),
        },
        {
            dataField: "Level",
            text: "Level",
            searchable: false,
            sort: true,
            formatter: (cellContent, code, index) => {
                let levelName = ''
                switch (code.level) {
                    case 4:
                        levelName = 'Error'
                        break
                    case 5:
                        levelName = 'Critical'
                        break
                    case 6:
                        levelName = 'None'
                        break
                }
                return (
                    <span>{levelName}</span>
                )
            },
        },
        {
            dataField: "UserId",
            text: "User",
            searchable: false,
            sort: true,
            formatter: (cellContent, code, index) => {
                return (
                    <span>{code?.userName}</span>
                )
            },
        },
        {
            dataField: "IsSolved",
            text: "Is Solved",
            sort: true,
            formatter: (cellContent, log, index) => {
                return (
                    <AvForm>
                        <div>
                            <AvInput
                                type="checkbox"
                                name="isSolved"
                                value={log.isSolved}
                                onChange={(e) => {
                                    log.isSolved = e.target.checked
                                    onSolveChange(log)
                                }}
                            />
                        </div>
                    </AvForm>
                )
            },
        },
    ]

    return (
        <CustomBootstrapTable
            columns={columns}
            search
            data={data}
            paging={paging}
            onSelect={onSelect}
            onSelectAll={onSelectAll}
            onSearch={onSearch}
            onSort={onSort}
            onRefresh={onRefresh}
            filterForm={() => <FilterForm model={model} />}
            onReset={onResetHandler}
            onPageChange={onPageChange}
            onSizePerPageChange={onSizePerPageChange}
            onSubmitFilter={onSubmitFilter}
            searchText={model?.search}
            loading={loading}
            updatedTime={updatedTime}
            isEnableExport={false}
            resource={RESOURCE}
            showSelectRow={false}
            keyField="id"
        />
    )
}

export default withTranslation(["hisConnectorPage", "common"])(ErrorLogTable)
