import { useEffect, useState } from "react"

import CustomPopover from "components/Common/Input/CustomPopover"
import {
  parameterCode
} from "constant"
import { withTranslation } from "react-i18next"

const ReasonReturn = ({
  onChangeReason,
  sample
}) => {
  const [reason, setReason] = useState({})

  useEffect(() => {
    if (sample?.reason) {
      setReason({ value: sample?.reason, label: sample?.reasonName })
    }
  }, [sample])

  const onChange = (value) => {
    setReason(value)
    onChangeReason(value, sample)
  }

  return (
    <div>
      <CustomPopover
        style={{ width: '205px' }}
        confirmButton={false}
        namepopover="namepopover-serviceType"
        isSearch={true}
        code={parameterCode.REASON_KEY}
        title={reason?.label}
        valueName={reason?.label}
        value={reason?.value}
        onChange={onChange}
      />
    </div>

  )
}

export default withTranslation(["deliveryPage", "common"])(ReasonReturn)
