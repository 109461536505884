import { CustomBootstrapTable } from "components/Common"
import { CustomTooltipButton } from "components/Common/Button"
import IconStatus from "components/Common/IconStatus"
import { Instrument_Translator, ModuleIds, statusTypes } from "constant"
import { indexCalculator } from "helpers/utilities"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import FilterForm from "./FilterForm"
const RESOURCE = ModuleIds.Instrument
const InstrumentConfigTable = ({
  onSelect,
  onSelectAll,
  instrumentTranslators,
  onSearch,
  onSort,
  onRefresh,
  onPageChange,
  paging,
  onSizePerPageChange,
  onSubmitFilter,
  onEdit,
  onDelete,
  onClone,
  loading,
  t,
  updatedTime,
}) => {
  const [data, setData] = useState([])
  const [model, setModel] = useState({
    search: "",
  })
  const searchQuery = useSelector(state => state.machine.searchQueryInstrumentTranslator)
  const onResetHandler = () => {
    const initModel = {
      search: "",
    }
    setModel(initModel)
  }
  useEffect(() => {
    setModel(searchQuery)
  }, [searchQuery])
  useEffect(() => {
    setData(instrumentTranslators)
  }, [instrumentTranslators])

  const renderTestName = (dataString) => {
    if (dataString && dataString.includes('[')) {
      let dataArr = JSON.parse(dataString)
      let res = dataArr.map((row, index) => {
        return <div key={index}>{row.TestCode} - {row.TestName}</div>
      })
      return res
    }
    else {
      return '';
    }
  }
  const columns = [
    {
      dataField: "id",
      text: "#",
      style: { width: 50 },
      formatter: (cellContent, machine, index) => {
        return indexCalculator(paging.page, paging.size, index + 1)
      },
    },
    {
      dataField: "name",
      text: t("common:Name"),
      formatter: (cellContent, machine, index) => {
        return (
          <span>{machine.name}</span>
          // <Link to={`/Instrument/${machine.id}/view`}>{machine.name}</Link>
        )
      },
    },
    {
      dataField: "manageCode",
      text: t("Manage Code"),
    },
    {
      dataField: "testCode",
      text: t("Test Code"),
      formatter: (cellContent, machine, index) => {
        return (
          <>
            {machine.testName &&
              <div>
                <label
                  key={index}
                  data-tip
                  data-for={`${machine.insCode}-${index}`}
                  style={{
                    color: "#5F5F5F",
                    backgroundColor: "#fff",
                    padding: "1px 5px",
                    marginRight: "5px",
                    borderRadius: "5px",
                    border: "1px solid #808080",
                  }}
                >
                  {machine.insCode}
                </label>
                <div style={{ marginLeft: 5 }}>
                  {renderTestName(machine.testName)}
                </div>
              </div>
            }
          </>
        )
      },
    },
    {
      dataField: "type",
      text: t("Type"),
    },
    {
      dataField: "insResult",
      text: t("Raw Result"),
      formatter: (cellContent, machine, index) => {
        return (
          <>
            {!(machine.type == Instrument_Translator.Numeric) && <>
              <div>
                <label
                  key={index}
                  data-tip
                  data-for={`${machine.insCode}valueFrom-${index}`}
                  style={{
                    color: "#5F5F5F",
                    backgroundColor: "#fff",
                    padding: "1px 5px",
                    marginRight: "5px",
                    borderRadius: "5px",
                    border: "1px solid #808080",
                    width: '55px',
                    textAlign: 'center'
                  }}
                >
                  {machine.valueFrom}
                </label>
                <span style={{ marginLeft: 5 }}>{cellContent}</span>
              </div>
            </>}
          </>
        )
      },
    },
    {
      dataField: "result",
      text: t("Result"),
      formatter: (cellContent, machine, index) => {
        return (
          <>
            {!(machine.type == Instrument_Translator.Numeric) && <>
              <div>
                <label
                  key={index}
                  data-tip
                  data-for={`${machine.insCode}-valueTo-${index}`}
                  style={{
                    color: "#5F5F5F",
                    backgroundColor: "#fff",
                    padding: "1px 5px",
                    marginRight: "5px",
                    borderRadius: "5px",
                    border: "1px solid #808080",
                    width: '55px',
                    textAlign: 'center'
                  }}
                >
                  {machine.valueTo}
                </label>
                <span style={{ marginLeft: 5 }}>{cellContent}</span>
              </div>
            </>}
          </>
        )
      },
    },
    {
      dataField: "numericRound",
      text: t("Numeric Round"),
      formatter: (cellContent, machine, index) => {
        return (
          <>
            {machine.type == Instrument_Translator.Numeric && <span>{cellContent}</span>}
          </>
        )
      },
    },
    {
      dataField: "formula",
      text: t("Formula"),
      formatter: (cellContent, machine, index) => {
        return (
          <>
            {machine.type == Instrument_Translator.Numeric && <span>{cellContent}</span>}
          </>
        )
      },
    },
    {
      dataField: "action",
      text: "",
      isDummyField: true,
      style: { width: 50 },
      formatter: (cellContent, item, index) => {
        return (
          <CustomTooltipButton
            index={index}
            component={item}
            onClone={onClone}
            onEdit={onEdit}
            onDelete={onDelete}
            isId
            t={t}
          />
        )
      },
    },
  ]
  return (
    <CustomBootstrapTable
      columns={columns}
      search
      data={data}
      paging={paging}
      onSelect={onSelect}
      onSelectAll={onSelectAll}
      onSearch={onSearch}
      onSort={onSort}
      onRefresh={onRefresh}
      filterForm={() =>
        <FilterForm model={model} />
      }
      onReset={onResetHandler}
      onPageChange={onPageChange}
      onSizePerPageChange={onSizePerPageChange}
      onSubmitFilter={onSubmitFilter}
      searchText={model.search}
      loading={loading}
      updatedTime={updatedTime}
      resource={RESOURCE}
    />
  )
}

const mapStateToProps = ({ }) => ({

})

const mapDispatchToProps = dispatch => ({

})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["machinePage", "common"])(InstrumentConfigTable))