import { call, put, takeEvery, select } from "redux-saga/effects"
import i18n from "i18next"

// Settings Redux States
import {
  GET_WORKODERS,
  GET_WORKODERS_SUCCESS,
  GET_WORKODERS_FAIL,
  SET_WORKODERS_SEARCH_QUERY,
  RESET_WORKODERS_SEARCH_QUERY,
  UPDATE_WORKORDER_MULTIPLE,

  // GET_TEST_DETAIL,
  // GET_TEST_DETAIL_SUCCESS,
  // GET_TEST_DETAIL_FAIL,

  // ADD_NEW_TEST,
  // ADD_TEST_SUCCESS,
  // ADD_TEST_FAIL,

  // UPDATE_TEST,
  // UPDATE_TEST_SUCCESS,
  // UPDATE_TEST_FAIL,

  // DELETE_TESTS,
  // DELETE_TESTS_SUCCESS,
  // DELETE_TESTS_FAIL
} from "./actionTypes"

import {
  resetSearchQuery,
  setWorkOderSearchQuery,
  getWorkOders,
  getWorkOdersSuccess,
  getWorkOdersFail,
  updateWorkOrderMultipleSuccess,
  updateWorkOrderMultipleFail,
} from "./actions"
import {
  getAllRequests,
  updateWorkOrderMultiple,
} from "helpers/app-backend/workOrders_backend_helper"
import { showToast } from "components/Common"

// import {
//     getAllTests,
//     getTestById,
//     createTest,
//     updateTestById,
//     deleteTestByIds
// } from "helpers/app-backend/tests_backend_helper"

// import { showToast } from "components/Common";

const t = (msg, param) => i18n.t(msg, param)

// function* fetchTestDetail({ id }) {
//     try {
//         const response = yield call(getTestById, id)
//         yield put(getTestDetailSuccess(response))
//     } catch (error) {
//         yield put(getTestDetailFail(error))
//     }
// }

function* fetchWorkOrders({ payload }) {
  try {
    const searchQuery = yield select(state => {
      return state.workOrder.searchQuery
    })
    payload = { ...searchQuery, ...payload }

    const response = yield call(getAllRequests, payload)

    yield put(getWorkOdersSuccess(response))

    yield put(setWorkOderSearchQuery(payload))
  } catch (error) {
    console.log(error)
    yield put(getWorkOdersFail(error))
  }
}

// function* onUpdateTest({ payload: { test, callback } }) {
//     try {
//         const response = yield call(updateTestById, test)
//         yield put(updateTestSuccess(test))

//         showToast(
//             `${t("message:UpdatedMessage", {
//                 field: `${t(
//                     "testPage:Test"
//                 )} <span class='text-decoration-underline fw-bold'>${test["testName"]
//                     }</span>`,
//             })}`
//         )
//         callback()
//     } catch (error) {
//         console.log(error)
//         yield put(updateTestFail(error))
//     }
// }

// function* onDeleteTest({ payload: { tests, callback } }) {
//     try {
//         const response = yield call(deleteTestByIds, tests)
//         yield put(deleteTestsSuccess(tests))

//         showToast(
//             `${t("message:DeletedMessage", {
//                 field: `${t("testPage:Test")}`,
//             })}`
//         )
//         callback()
//     } catch (error) {
//         yield put(deleteTestsFail(error))
//     }
// }

// function* onAddNewTest({ payload: { test, history } }) {
//     try {
//         const response = yield call(createTest, test)
//         const id = response?.id
//         yield put(addTestSuccess(id))

//         const url = `/Test/${id}/view`
//         showToast(
//             `${t("message:CreatedMessage", {
//                 field: `${t(
//                     "testPage:Test"
//                 )} <span class='text-decoration-underline fw-bold'>${test["testName"]
//                     }</span>`,
//             })}`
//         )
//         history.push(url)
//     } catch (error) {
//         console.log(error)
//         yield put(addTestFail(error))
//     }
// }

function* onUpdateWorkOrders({ payload }) {
  try {
    let { ids, action, callback } = payload
    let values = {
      id: [...ids],
      action,
    }
    const response = yield call(updateWorkOrderMultiple, values)
    yield put(updateWorkOrderMultipleSuccess(values))

    showToast(
      `${t("message:UpdatedMessage", {
        field: `${t(
          "workOrderPage:Work Order"
        )} <span class='text-decoration-underline fw-bold'>
                  </span>`,
      })}`
    )
    if (callback) callback()
  } catch (error) {
    console.log(error)
    yield put(updateWorkOrderMultipleFail(error))
  }
}

function* workOrdersSaga() {
  /* TEST */
  // yield takeEvery(GET_TEST_DETAIL, fetchTestDetail)
  yield takeEvery(GET_WORKODERS, fetchWorkOrders)
  yield takeEvery(UPDATE_WORKORDER_MULTIPLE, onUpdateWorkOrders)

  // yield takeEvery(ADD_NEW_TEST, onAddNewTest)
  // yield takeEvery(UPDATE_TEST, onUpdateTest)
  // yield takeEvery(DELETE_TESTS, onDeleteTest)
}

export default workOrdersSaga
