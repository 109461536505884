import {
    ADD_HISCCONECTOR, ADD_HISCCONECTOR_FAIL, ADD_HISCCONECTOR_SUCCESS,
    ADD_PROFILE_MAPPING,
    ADD_PROFILE_MAPPING_FAIL,
    ADD_PROFILE_MAPPING_SUCCESS,
    ADD_TEST_MAPPING,
    ADD_TEST_MAPPING_FAIL,
    ADD_TEST_MAPPING_SUCCESS,
    DELETE_HISCCONECTOR, DELETE_HISCCONECTOR_FAIL, DELETE_HISCCONECTOR_SUCCESS,
    DELETE_PROFILE_MAPPING,
    DELETE_PROFILE_MAPPING_FAIL,
    DELETE_PROFILE_MAPPING_SUCCESS,
    DELETE_TEST_MAPPING,
    DELETE_TEST_MAPPING_FAIL,
    DELETE_TEST_MAPPING_SUCCESS,
    GET_CONNECTOR_DEPARTMENT_MAPPING,
    GET_CONNECTOR_DEPARTMENT_MAPPING_FAIL,
    GET_CONNECTOR_DEPARTMENT_MAPPING_LANGUAGE_SUCCESS,
    GET_CONNECTOR_DEPARTMENT_MAPPING_SUCCESS,
    GET_CONNECTOR_DEPARTMENT_MAPPING_UPLOAD_SUCCESS,
    GET_CONNECTOR_HISCODE_PROFILE_LANGUAGE_SUCCESS,
    GET_CONNECTOR_HISCODE_TEST_LANGUAGE_SUCCESS,
    GET_CONNECTOR_INSTRUMENT_MAPPING,
    GET_CONNECTOR_INSTRUMENT_MAPPING_FAIL,
    GET_CONNECTOR_INSTRUMENT_MAPPING_LANGUAGE_SUCCESS,
    GET_CONNECTOR_INSTRUMENT_MAPPING_SUCCESS,
    GET_CONNECTOR_INSTRUMENT_MAPPING_UPLOAD_SUCCESS,
    GET_CONNECTOR_INSURANCE_LANGUAGE_SUCCESS,
    GET_CONNECTOR_INSURANCE_MAPPING,
    GET_CONNECTOR_INSURANCE_MAPPING_FAIL,
    GET_CONNECTOR_INSURANCE_MAPPING_SUCCESS,
    GET_CONNECTOR_INSURANCE_MAPPING_UPLOAD_SUCCESS,
    GET_CONNECTOR_PHYSICIAN_MAPPING,
    GET_CONNECTOR_PHYSICIAN_MAPPING_FAIL,
    GET_CONNECTOR_PHYSICIAN_MAPPING_LANGUAGE_SUCCESS,
    GET_CONNECTOR_PHYSICIAN_MAPPING_SUCCESS,
    GET_CONNECTOR_PHYSICIAN_MAPPING_UPLOAD_SUCCESS,
    GET_DMS_TESTCODE_MAPPING,
    GET_DMS_TESTCODE_MAPPING_FAIL,
    GET_DMS_TESTCODE_MAPPING_LANGUAGE_SUCCESS,
    GET_DMS_TESTCODE_MAPPING_SUCCESS,
    GET_HISCCONECTOR,
    GET_HISCCONECTOR_DETAIL, GET_HISCCONECTOR_DETAIL_FAIL, GET_HISCCONECTOR_DETAIL_SUCCESS,
    GET_HISCCONECTOR_FAIL, GET_HISCCONECTOR_SUCCESS,
    /* ==== PROFILE MAPPING START === */
    GET_PROFILE_MAPPING_LIST,
    GET_PROFILE_MAPPING_LIST_FAIL,
    GET_PROFILE_MAPPING_LIST_SUCCESS,
    GET_PROFILE_MAPPING_LIST_WITH_PAGE,
    GET_PROFILE_MAPPING_LIST_WITH_PAGE_FAIL,
    GET_PROFILE_MAPPING_LIST_WITH_PAGE_SUCCESS,
    GET_PROFILE_MAPPING_LIST_WITH_PAGE_UPLOAD_SUCCESS,
    /* ==== PROFILE MAPPING END === */
    /* ==== TEST MAPPING START === */
    GET_TEST_MAPPING_LIST,
    GET_TEST_MAPPING_LIST_FAIL,
    GET_TEST_MAPPING_LIST_SUCCESS,
    GET_TEST_MAPPING_LIST_WITH_PAGE,
    GET_TEST_MAPPING_LIST_WITH_PAGE_FAIL,
    GET_TEST_MAPPING_LIST_WITH_PAGE_SUCCESS,
    RESET_HISCCONECTOR_SEARCH_DEPARTMENT_QUERY,
    RESET_HISCCONECTOR_SEARCH_INSTRUMENT_QUERY,
    RESET_HISCCONECTOR_SEARCH_PHYSICIAN_QUERY,
    RESET_HISCCONECTOR_SEARCH_PROFILE_QUERY,
    RESET_HISCCONECTOR_SEARCH_QUERY,
    RESET_HISCCONECTOR_SEARCH_TEST_QUERY,
    SET_CONNECTOR_SCROLL_HISCODE,
    SET_CONNECTOR_SCROLL_INSURANCE,
    SET_HISCCONECTOR_SEARCH_DEPARTMENT_QUERY,
    SET_HISCCONECTOR_SEARCH_INSTRUMENT_QUERY,
    SET_HISCCONECTOR_SEARCH_PHYSICIAN_QUERY,
    SET_HISCCONECTOR_SEARCH_PROFILE_QUERY,
    SET_HISCCONECTOR_SEARCH_QUERY,
    SET_HISCCONECTOR_SEARCH_TEST_QUERY,
    SET_ITEM_ACTIVE_CONNECTOR,
    UPDATE_HISCCONECTOR, UPDATE_HISCCONECTOR_FAIL, UPDATE_HISCCONECTOR_SUCCESS,
    UPDATE_PROFILE_MAPPING,
    UPDATE_PROFILE_MAPPING_FAIL,
    UPDATE_PROFILE_MAPPING_SUCCESS,
    UPDATE_TEST_MAPPING,
    UPDATE_TEST_MAPPING_FAIL,
    UPDATE_TEST_MAPPING_SUCCESS
} from "./actionTypes";

//reset search query
export const resetHisConnectorSearchQuery = () => ({
    type: RESET_HISCCONECTOR_SEARCH_QUERY
})

export const resetHisConnectorSearchTestQuery = () => ({
    type: RESET_HISCCONECTOR_SEARCH_TEST_QUERY
})

export const resetHisConnectorSearchProfileQuery = () => ({
    type: RESET_HISCCONECTOR_SEARCH_PROFILE_QUERY
})

//set search query
export const setHisConnectorSearchQuery = searchQuery => ({
    type: SET_HISCCONECTOR_SEARCH_QUERY,
    payload: searchQuery
})

export const setHisConnectorSearchTestQuery = searchQuery => ({
    type: SET_HISCCONECTOR_SEARCH_TEST_QUERY,
    payload: searchQuery
})

export const setHisConnectorSearchProfileQuery = searchQuery => ({
    type: SET_HISCCONECTOR_SEARCH_PROFILE_QUERY,
    payload: searchQuery
})

//get HisConnector
export const getHisConnectors = payload => ({
    type: GET_HISCCONECTOR,
    payload: payload || {}
})

export const getHisConnectorsSuccess = hisConnectors => ({
    type: GET_HISCCONECTOR_SUCCESS,
    payload: hisConnectors
})

export const getHisConnectorsFail = error => ({
    type: GET_HISCCONECTOR_FAIL,
    payload: error
})

// get HisConnector detail
export const getHisConnectorDetail = (id, callback) => ({
    type: GET_HISCCONECTOR_DETAIL,
    id, callback
})

export const getHisConnectorDetailSuccess = hisConnectors => ({
    type: GET_HISCCONECTOR_DETAIL_SUCCESS,
    payload: hisConnectors,
})

export const getHisConnectorDetailFail = error => ({
    type: GET_HISCCONECTOR_DETAIL_FAIL,
    payload: error,
})

// Add HisConnector
export const addNewHisConnector = hisConnector => ({
    type: ADD_HISCCONECTOR,
    payload: hisConnector,
})

export const addNewHisConnectorSuccess = hisConnector => ({
    type: ADD_HISCCONECTOR_SUCCESS,
    payload: hisConnector,
})

export const addNewHisConnectorFail = error => ({
    type: ADD_HISCCONECTOR_FAIL,
    payload: error,
})

// update HisConnector
export const updateHisConnector = hisConnector => ({
    type: UPDATE_HISCCONECTOR,
    payload: hisConnector,
})

export const updateHisConnectorSuccess = hisConnector => ({
    type: UPDATE_HISCCONECTOR_SUCCESS,
    payload: hisConnector,
})

export const updateHisConnectorFail = error => ({
    type: UPDATE_HISCCONECTOR_FAIL,
    payload: error,
})

// Delete HisConnector
export const deleteHisConnectors = hisConnectors => ({
    type: DELETE_HISCCONECTOR,
    payload: hisConnectors,
})

export const deleteHisConnectorsSuccess = hisConnectors => ({
    type: DELETE_HISCCONECTOR_SUCCESS,
    payload: hisConnectors,
})

export const deleteHisConnectorsFail = error => ({
    type: DELETE_HISCCONECTOR_FAIL,
    payload: error,
})

/* ==== PROFILE MAPPING START === */
export const getProfileMappingList = error => ({
    type: GET_PROFILE_MAPPING_LIST,
    payload: error,
})

export const getProfileMappingListSuccess = profileMappings => ({
    type: GET_PROFILE_MAPPING_LIST_SUCCESS,
    payload: profileMappings,
})

export const getProfileMappingListFail = error => ({
    type: GET_PROFILE_MAPPING_LIST_FAIL,
    payload: error,
})

export const getProfileMappingListWithPage = error => ({
    type: GET_PROFILE_MAPPING_LIST_WITH_PAGE,
    payload: error,
})

export const getProfileMappingListWithPageSuccess = profileMappings => ({
    type: GET_PROFILE_MAPPING_LIST_WITH_PAGE_SUCCESS,
    payload: profileMappings,
})

export const getProfileMappingListWithPageFail = error => ({
    type: GET_PROFILE_MAPPING_LIST_WITH_PAGE_FAIL,
    payload: error,
})

export const addProfileMapping = profileMapping => ({
    type: ADD_PROFILE_MAPPING,
    payload: profileMapping,
})

export const addProfileMappingSuccess = profileMapping => ({
    type: ADD_PROFILE_MAPPING_SUCCESS,
    payload: profileMapping,
})

export const addProfileMappingFail = error => ({
    type: ADD_PROFILE_MAPPING_FAIL,
    payload: error,
})

export const updateProfileMapping = profileMapping => ({
    type: UPDATE_PROFILE_MAPPING,
    payload: profileMapping,
})

export const updateProfileMappingSuccess = profileMapping => ({
    type: UPDATE_PROFILE_MAPPING_SUCCESS,
    payload: profileMapping,
})

export const updateProfileMappingFail = error => ({
    type: UPDATE_PROFILE_MAPPING_FAIL,
    payload: error,
})
export const deleteProfileMapping = profileMappings => ({
    type: DELETE_PROFILE_MAPPING,
    payload: profileMappings,
})

export const deleteProfileMappingSuccess = profileMappings => ({
    type: DELETE_PROFILE_MAPPING_SUCCESS,
    payload: profileMappings,
})

export const deleteProfileMappingFail = error => ({
    type: DELETE_PROFILE_MAPPING_FAIL,
    payload: error,
})

/* ==== PROFILE MAPPING END === */


/* ==== TEST MAPPING START === */
export const getTestMappingList = error => ({
    type: GET_TEST_MAPPING_LIST,
    payload: error,
})

export const getTestMappingListSuccess = testMappings => ({
    type: GET_TEST_MAPPING_LIST_SUCCESS,
    payload: testMappings,
})

export const getTestMappingListFail = error => ({
    type: GET_TEST_MAPPING_LIST_FAIL,
    payload: error,
})

export const getTestMappingListWithPage = error => ({
    type: GET_TEST_MAPPING_LIST_WITH_PAGE,
    payload: error,
})

export const getTestMappingListWithPageSuccess = testMappings => ({
    type: GET_TEST_MAPPING_LIST_WITH_PAGE_SUCCESS,
    payload: testMappings,
})

export const getTestMappingListWithPageFail = error => ({
    type: GET_TEST_MAPPING_LIST_WITH_PAGE_FAIL,
    payload: error,
})

export const addTestMapping = testMapping => ({
    type: ADD_TEST_MAPPING,
    payload: testMapping,
})

export const addTestMappingSuccess = testMapping => ({
    type: ADD_TEST_MAPPING_SUCCESS,
    payload: testMapping,
})

export const addTestMappingFail = error => ({
    type: ADD_TEST_MAPPING_FAIL,
    payload: error,
})

export const updateTestMapping = testMapping => ({
    type: UPDATE_TEST_MAPPING,
    payload: testMapping,
})

export const updateTestMappingSuccess = testMapping => ({
    type: UPDATE_TEST_MAPPING_SUCCESS,
    payload: testMapping,
})

export const updateTestMappingFail = error => ({
    type: UPDATE_TEST_MAPPING_FAIL,
    payload: error,
})
export const deleteTestMapping = testMappings => ({
    type: DELETE_TEST_MAPPING,
    payload: testMappings,
})

export const deleteTestMappingSuccess = testMappings => ({
    type: DELETE_TEST_MAPPING_SUCCESS,
    payload: testMappings,
})

export const deleteTestMappingFail = error => ({
    type: DELETE_TEST_MAPPING_FAIL,
    payload: error,
})

export const getConnectorInsuranceMapping = error => ({
    type: GET_CONNECTOR_INSURANCE_MAPPING,
    payload: error,
})

export const getConnectorInsuranceMappingSuccess = profileMappings => ({
    type: GET_CONNECTOR_INSURANCE_MAPPING_SUCCESS,
    payload: profileMappings,
})

export const getConnectorInsuranceMappingFail = error => ({
    type: GET_CONNECTOR_INSURANCE_MAPPING_FAIL,
    payload: error,
})

export const setItemActiveConnector = item => ({
    type: SET_ITEM_ACTIVE_CONNECTOR,
    payload: item
})

export const getConnectorDepartmentMapping = error => ({
    type: GET_CONNECTOR_DEPARTMENT_MAPPING,
    payload: error,
})

export const getConnectorDepartmentMappingSuccess = profileMappings => ({
    type: GET_CONNECTOR_DEPARTMENT_MAPPING_SUCCESS,
    payload: profileMappings,
})

export const getConnectorDepartmentMappingFail = error => ({
    type: GET_CONNECTOR_DEPARTMENT_MAPPING_FAIL,
    payload: error,
})

export const getConnectorDepartmentMappingUploadSuccess = payload => ({
    payload: payload,
    type: GET_CONNECTOR_DEPARTMENT_MAPPING_UPLOAD_SUCCESS
})

export const getConnectorPhysicianMapping = error => ({
    type: GET_CONNECTOR_PHYSICIAN_MAPPING,
    payload: error,
})

export const getConnectorPhysicianMappingSuccess = profileMappings => ({
    type: GET_CONNECTOR_PHYSICIAN_MAPPING_SUCCESS,
    payload: profileMappings,
})

export const getConnectorPhysicianMappingFail = error => ({
    type: GET_CONNECTOR_PHYSICIAN_MAPPING_FAIL,
    payload: error,
})

export const getConnectorPhysicianMappingUploadSuccess = payload => ({
    payload: payload,
    type: GET_CONNECTOR_PHYSICIAN_MAPPING_UPLOAD_SUCCESS
})

//
export const setItemActiveDepartment = item => ({
    type: SET_HISCCONECTOR_SEARCH_DEPARTMENT_QUERY,
    payload: item
})
export const reSetItemActiveDepartment = item => ({
    type: RESET_HISCCONECTOR_SEARCH_DEPARTMENT_QUERY,
    payload: item
})

export const setItemActivePhysician = item => ({
    type: SET_HISCCONECTOR_SEARCH_PHYSICIAN_QUERY,
    payload: item
})
export const reSetItemActivePhysician = item => ({
    type: RESET_HISCCONECTOR_SEARCH_PHYSICIAN_QUERY,
    payload: item
})

export const getConnectorDepartmentMappingLanguageSuccess = profileMappings => ({
    type: GET_CONNECTOR_DEPARTMENT_MAPPING_LANGUAGE_SUCCESS,
    payload: profileMappings,
})

export const getConnectorPhysicianMappingLanguageSuccess = profileMappings => ({
    type: GET_CONNECTOR_PHYSICIAN_MAPPING_LANGUAGE_SUCCESS,
    payload: profileMappings,
})

//INSTRUMENT
export const getConnectorInstrumentMapping = error => ({
    type: GET_CONNECTOR_INSTRUMENT_MAPPING,
    payload: error,
})

export const getConnectorInstrumentMappingSuccess = profileMappings => ({
    type: GET_CONNECTOR_INSTRUMENT_MAPPING_SUCCESS,
    payload: profileMappings,
})

export const getConnectorInstrumentMappingFail = error => ({
    type: GET_CONNECTOR_INSTRUMENT_MAPPING_FAIL,
    payload: error,
})

export const getConnectorInstrumentMappingUploadSuccess = payload => ({
    payload: payload,
    type: GET_CONNECTOR_INSTRUMENT_MAPPING_UPLOAD_SUCCESS
})

export const getConnectorInsuranceMappingUploadSuccess = payload => ({
    payload: payload,
    type: GET_CONNECTOR_INSURANCE_MAPPING_UPLOAD_SUCCESS
})

//
export const setItemActiveInstrument = item => ({
    type: SET_HISCCONECTOR_SEARCH_INSTRUMENT_QUERY,
    payload: item
})
export const reSetItemActiveInstrument = item => ({
    type: RESET_HISCCONECTOR_SEARCH_INSTRUMENT_QUERY,
    payload: item
})

export const getConnectorInstrumentMappingLanguageSuccess = profileMappings => ({
    type: GET_CONNECTOR_INSTRUMENT_MAPPING_LANGUAGE_SUCCESS,
    payload: profileMappings,
})

export const setConnectorScrollHisCode = profileMapping => ({
    type: SET_CONNECTOR_SCROLL_HISCODE,
    payload: profileMapping,
})

export const setConnectorScrollInsurance = profileMapping => ({
    type: SET_CONNECTOR_SCROLL_INSURANCE,
    payload: profileMapping,
})

export const getProfileMappingListWithPageUploadSuccess = profileMappings => ({
    type: GET_PROFILE_MAPPING_LIST_WITH_PAGE_UPLOAD_SUCCESS,
    payload: profileMappings,
})

export const getConnectorHisCodeTestLanguageSuccess = profileMappings => ({
    type: GET_CONNECTOR_HISCODE_TEST_LANGUAGE_SUCCESS,
    payload: profileMappings,
})

export const getConnectorHisCodeProfileLanguageSuccess = profileMappings => ({
    type: GET_CONNECTOR_HISCODE_PROFILE_LANGUAGE_SUCCESS,
    payload: profileMappings,
})

export const getConnectorInsuranceLanguageSuccess = profileMappings => ({
    type: GET_CONNECTOR_INSURANCE_LANGUAGE_SUCCESS,
    payload: profileMappings,
})

export const getDMSTestMapping = error => ({
    type: GET_DMS_TESTCODE_MAPPING,
    payload: error,
})

export const getDMSTestMappingMappingSuccess = payload => ({
    type: GET_DMS_TESTCODE_MAPPING_SUCCESS,
    payload: payload,
})

export const getDMSTestMappingMappingFail = error => ({
    type: GET_DMS_TESTCODE_MAPPING_FAIL,
    payload: error,
})

export const getDMSTestMappingLanguageSuccess = payload => ({
    type: GET_DMS_TESTCODE_MAPPING_LANGUAGE_SUCCESS,
    payload: payload,
})