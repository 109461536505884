import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Check } from "components/Common";
import { indexCalculator } from "helpers/utilities";

import { TooltipButton } from "components/Common/Button/CustomTooltipButton";
import CustomBootstrapTableAsync from "components/Common/CustomTable/CustomBootstrapTableAsync";
import { ModuleIds, permissionType } from "constant";
import { withTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";

const RESOURCE = ModuleIds.Test;

const TestTemporaryTable = ({
    onSelect,
    onSelectAll,
    tests,
    testProfiles,
    onSearch,
    onSort,
    onRefresh,
    onPageChange,
    paging,
    onSizePerPageChange,
    onEdit,
    onDelete,
    loading,
    t,
    updatedTime,
    onAddTestList
}) => {
    const [data, setData] = useState([]);
    const [model, setModel] = useState({
        search: "",
        type: "",
        category: "",
        sampleType: "",
    });
    const searchQuery = useSelector(state => state.test.searchQuery);

    const onResetHandler = () => {
        const initModel = {
            search: "",
            type: "",
            category: "",
            sampleType: "",
        }
        let x = document.getElementsByName("type")
        for (let i = 0; i < x.length; i++) {
            x[i].checked = false
        }
        setModel(initModel)
    }

    useEffect(() => {
        setModel(searchQuery)
    }, [searchQuery])


    useEffect(() => {
        let tmp = testProfiles || []
        tmp = tmp.map(x => ({ ...x, profileCode: x.code }))
        setData([...tests, ...tmp])
    }, [tests, testProfiles])

    const columns = [
        {
            dataField: "id",
            text: "#",
            hidden: true,
            formatter: (cellContent, test, index) => {
                return cellContent
            },
        },
        {
            dataField: "id",
            text: "#",
            style: { width: 20 },
            formatter: (cellContent, test, index) => {
                return indexCalculator(paging.page, paging.size, index + 1)
            },
        },
        {
            dataField: "code",
            text: t(""),
            sort: false,
            style: { width: 60 },
            formatter: (cellContent, test, index) => {
                return <div style={{ display: "flex", gap: 1 }}>
                    {test.profileId?.split(',').map((item, position) =>
                        item != "" ?
                            <div key={position}>
                                <i
                                    data-tip
                                    data-for={`${item}-${position}`}
                                    className="fas fa-dot-circle"
                                    style={{ color: 'green' }}
                                    onClick={() => {
                                        const link = `TestProfile/${item || ''}/view?tab=1`
                                        window.open(link)
                                    }}
                                />

                                <ReactTooltip id={`${item}-${position}`} place="right" effect="solid">
                                    {test.profileName.split(',')[position]}
                                </ReactTooltip>
                            </div>
                            : <React.Fragment key={index}></React.Fragment>
                    )}
                </div>

            },
        },
        {
            dataField: "testCode",
            text: t("Code"),
            style: { width: 160 },
            sort: true,
            formatter: (cellContent, data) => {
                return <span style={{ fontWeight: 'bold' }}>{data?.testCode || data?.profileCode}</span>
            }
        },
        {
            dataField: "testName",
            text: t("Name"),
            sort: true,
            style: { width: 550 },
            formatter: (cellContent, data) => {
                return data?.profileName ? <Link to={`/TestProfile/${data.id}/view?tab=1`}>{data.profileName}</Link> :
                    <Link to={`/Test/${data.id}/view?tab=1`}>{data.testName}</Link>
            }
        },
        {
            dataField: "type",
            text: t("Type"),
            sort: true,
            formatter: (cellContent, data, index) => {
                return (
                    <>
                        {data?.profileCode && (
                            <div className="px-2 d-flex badge-profile">
                                <span>Profile</span>
                            </div>
                        )}
                        {data?.testCode && (
                            <div className="px-2 d-flex badge-test">
                                <span>Test</span>
                            </div>
                        )}
                    </>
                )
            },
        },
        {
            dataField: "action",
            text: "",
            isDummyField: true,
            formatter: (cellContent, data, index) => {
                return (
                    <div className="d-flex toolbar button-items justify-content-end">
                        {data?.profileCode && <Check permission={permissionType.U} resource={RESOURCE} >
                            <TooltipButton
                                id={`add-${index}`}
                                color="btn btn-outline-success btn-soft-success"
                                iconClass="bx bx-add-to-queue"
                                onClick={e => {
                                    onAddTestList(data)
                                }}
                                tooltipText={t("testProfilePage:QuickAdd")}
                            />
                        </Check>}
                        <Check
                            permission={permissionType.U}
                            resource={RESOURCE}
                        >
                            <TooltipButton
                                id={`edit-${index}`}
                                color="btn btn-outline-info btn-soft-info"
                                iconClass="mdi mdi-pencil-outline"
                                onClick={e => {
                                    onEdit(e, data.id, data)
                                }}
                                tooltipText={t("common:Edit")}
                            />
                        </Check>
                        <Check
                            permission={permissionType.D}
                            resource={RESOURCE}
                        >
                            <TooltipButton
                                id={`delete-${index}`}
                                color="btn btn-outline-danger btn-soft-danger"
                                iconClass="mdi mdi-delete-outline"
                                onClick={e => onDelete(e, data)}
                                tooltipText={t("common:Delete")}
                            />
                        </Check>
                    </div>
                )
            },
        },
    ]

    return (
        <div>
            <CustomBootstrapTableAsync
                columns={columns}
                search
                data={data}
                paging={paging}
                onSelect={onSelect}
                onSelectAll={onSelectAll}
                onSearch={onSearch}
                onSort={onSort}
                isScrollable
                onRefresh={onRefresh}
                onReset={onResetHandler}
                onPageChange={onPageChange}
                onSizePerPageChange={onSizePerPageChange}
                searchText={model.search}
                loading={loading}
                updatedTime={updatedTime}
                resource={RESOURCE}
                keyField="id"
            // isImportExportButton={true}
            // importExportForm={() => <ImportExportButton />}
            />

        </div>
    )
}

export default withTranslation(["testPage"])(TestTemporaryTable)