import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"

import { CustomBootstrapTable } from "components/Common"
import { convertDateFormat, indexCalculator } from "helpers/utilities"

import FilterForm from "./FilterForm"

//i18n
import { CustomTooltipButton } from "components/Common/Button"
import { ModuleIds } from "constant"
import { withTranslation } from "react-i18next"
import { getAllParties } from "helpers/app-backend"

const RESOURCE = ModuleIds.Patient

const PatientTable = ({
  onSelect,
  onSelectAll,
  individuals,
  onSearch,
  onSort,
  onRefresh,
  onPageChange,
  paging,
  onSizePerPageChange,
  onSubmitFilter,
  onEdit,
  onDelete,
  onClone,
  loading,
  t,
  updatedTime,
}) => {
  const [data, setData] = useState([])
  const [code, setCode] = useState([])
  const [model, setModel] = useState({
    search: "",
    managementCompanyId: "",
    profileId: "",
    parentId: "",
  })

  useEffect(() => {
    getCode()
  }, [data])

  useEffect(() => {
    getCode()
  }, [])

  const getCode = async () => {
    const res = await getAllParties({ page: 1 })
    setCode(res.data)
  }

  const searchQuery = useSelector(state => state.individual.searchQuery)

  const onResetHandler = () => {
    const initModel = {
      search: "",
      managementCompanyId: "",
      profileId: "",
      parentId: "",
    }
    setModel(initModel)
  }

  useEffect(() => {
    setModel(searchQuery)
  }, [searchQuery])

  useEffect(() => {
    setData(individuals)
  }, [individuals])

  const columns = [
    {
      dataField: "id",
      text: "#",
      hidden: true,
      formatter: (cellContent, test, index) => {
        return cellContent
      },
    },
    {
      dataField: "id",
      text: "#",
      style: { width: 50 },
      formatter: (cellContent, test, index) => {
        return indexCalculator(paging.page, paging.size, index + 1)
      },
    },
    {
      dataField: "name",
      text: t("common:Name"),
      sort: true,
      formatter: (cellContent, user, index) => {
        return <Link to={`/Patient/${user.id}/View?tab=1`}>{user.name}</Link>
      },
    },
    {
      dataField: "patientId",
      text: t("Patient Id"),
      sort: true,
    },
    {
      dataField: "gender",
      text: t("common:Gender"),
      sort: true,
      formatter: (cellContent, user, index) => {
        return (
          <div className="px-2 d-flex badge-gender" style={{ backgroundColor: user.genderType === 'F' ? "#cccecf" : "#d4dbf9", color: user.genderType === 'F' ? "#4d5057" : "#556ee6" }}>
            {user.gender}
          </div>
        )
      }
    },
    {
      dataField: "dob",
      text: t("common:Date Of Birth"),
      sort: true,
      formatter: (cellContent, user, index) =>
        convertDateFormat(cellContent, "YYYY-MM-DD"),
    },
    {
      dataField: "phoneNumber",
      text: t("common:PhoneNumber"),
      sort: true,
    },
    {
      dataField: "address",
      text: t("common:Address"),
      sort: true,
    },
    {
      dataField: "createdDate",
      text: t("Visit Date"),
      sort: true,
      formatter: (cellContent, user, index) =>
        convertDateFormat(cellContent, "YYYY-MM-DD hh:mm:ss"),
    },
    {
      dataField: "parentId",
      text: t("Organization"),
      formatter: (cellContent, user, index) => {
        return <span>{code.find(item => item.id == cellContent)?.name}</span>
      },
    },
    // {
    //   dataField: "managementCompanyId",
    //   text: t("Hospital/Clinic Mngt."),
    //   sort: true,
    //   formatter: (cellContent, user, index) => {
    //     return (
    //       <div style={{ textTransform: "uppercase" }}>
    //         {user.managementCompanyName}
    //       </div>
    //     )
    //   },
    // },
    // {
    //   dataField: "remark",
    //   text: t("common:Remark"),
    //   sort: true,
    // },
    {
      dataField: "action",
      text: "",
      isDummyField: true,
      style: { width: 50 },
      formatter: (cellContent, patients, index) => {
        return (
          <CustomTooltipButton
            index={index}
            component={patients}
            onClone={onClone}
            onEdit={onEdit}
            onDelete={onDelete}
            isId
            t={t}
          />
        )
      },
    },
  ]

  return (
    <CustomBootstrapTable
      columns={columns}
      search
      data={data}
      paging={paging}
      onSelect={onSelect}
      onSelectAll={onSelectAll}
      onSearch={onSearch}
      onSort={onSort}
      onRefresh={onRefresh}
      filterForm={() => <FilterForm model={model} />}
      onReset={onResetHandler}
      onPageChange={onPageChange}
      onSizePerPageChange={onSizePerPageChange}
      onSubmitFilter={onSubmitFilter}
      searchText={model.search}
      loading={loading}
      updatedTime={updatedTime}
      resource={RESOURCE}
    />
  )
}

export default withTranslation(["partyPage", "common"])(PatientTable)
