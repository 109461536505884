import {
    ADD_HISCCONECTOR, ADD_HISCCONECTOR_FAIL,
    ADD_PROFILE_MAPPING,
    ADD_PROFILE_MAPPING_FAIL,
    ADD_PROFILE_MAPPING_SUCCESS,
    ADD_TEST_MAPPING,
    ADD_TEST_MAPPING_FAIL,
    ADD_TEST_MAPPING_SUCCESS,
    DELETE_HISCCONECTOR_FAIL, DELETE_HISCCONECTOR_SUCCESS,
    DELETE_TEST_MAPPING_FAIL,

    GET_CONNECTOR_DEPARTMENT_MAPPING,
    GET_CONNECTOR_DEPARTMENT_MAPPING_FAIL,
    GET_CONNECTOR_DEPARTMENT_MAPPING_LANGUAGE_SUCCESS,
    GET_CONNECTOR_DEPARTMENT_MAPPING_SUCCESS,
    GET_CONNECTOR_DEPARTMENT_MAPPING_UPLOAD_SUCCESS,
    GET_CONNECTOR_HISCODE_PROFILE_LANGUAGE_SUCCESS,
    GET_CONNECTOR_HISCODE_TEST_LANGUAGE_SUCCESS,
    GET_CONNECTOR_INSTRUMENT_MAPPING,
    GET_CONNECTOR_INSTRUMENT_MAPPING_FAIL,
    GET_CONNECTOR_INSTRUMENT_MAPPING_LANGUAGE_SUCCESS,
    GET_CONNECTOR_INSTRUMENT_MAPPING_SUCCESS,
    GET_CONNECTOR_INSTRUMENT_MAPPING_UPLOAD_SUCCESS,
    GET_CONNECTOR_INSURANCE_LANGUAGE_SUCCESS,
    GET_CONNECTOR_INSURANCE_MAPPING,
    GET_CONNECTOR_INSURANCE_MAPPING_FAIL,
    GET_CONNECTOR_INSURANCE_MAPPING_SUCCESS,
    GET_CONNECTOR_INSURANCE_MAPPING_UPLOAD_SUCCESS,
    GET_CONNECTOR_PHYSICIAN_MAPPING,
    GET_CONNECTOR_PHYSICIAN_MAPPING_FAIL,
    GET_CONNECTOR_PHYSICIAN_MAPPING_LANGUAGE_SUCCESS,
    GET_CONNECTOR_PHYSICIAN_MAPPING_SUCCESS,
    GET_CONNECTOR_PHYSICIAN_MAPPING_UPLOAD_SUCCESS,
    GET_DMS_TESTCODE_MAPPING_FAIL,
    GET_DMS_TESTCODE_MAPPING_LANGUAGE_SUCCESS,
    GET_DMS_TESTCODE_MAPPING_SUCCESS,
    GET_HISCCONECTOR,
    GET_HISCCONECTOR_DETAIL, GET_HISCCONECTOR_DETAIL_FAIL, GET_HISCCONECTOR_DETAIL_SUCCESS,
    GET_HISCCONECTOR_FAIL, GET_HISCCONECTOR_SUCCESS,
    /* ==== PROFILE MAPPING START === */
    GET_PROFILE_MAPPING_LIST,
    GET_PROFILE_MAPPING_LIST_FAIL,
    GET_PROFILE_MAPPING_LIST_SUCCESS,
    GET_PROFILE_MAPPING_LIST_WITH_PAGE,
    GET_PROFILE_MAPPING_LIST_WITH_PAGE_FAIL,
    GET_PROFILE_MAPPING_LIST_WITH_PAGE_SUCCESS,
    GET_PROFILE_MAPPING_LIST_WITH_PAGE_UPLOAD_SUCCESS,
    /* ==== PROFILE MAPPING END === */
    /* ==== TEST MAPPING START === */
    GET_TEST_MAPPING_LIST,
    GET_TEST_MAPPING_LIST_FAIL,
    GET_TEST_MAPPING_LIST_SUCCESS,
    GET_TEST_MAPPING_LIST_WITH_PAGE,
    GET_TEST_MAPPING_LIST_WITH_PAGE_FAIL,
    GET_TEST_MAPPING_LIST_WITH_PAGE_SUCCESS,
    RESET_HISCCONECTOR_SEARCH_DEPARTMENT_QUERY,
    RESET_HISCCONECTOR_SEARCH_INSTRUMENT_QUERY,
    RESET_HISCCONECTOR_SEARCH_PHYSICIAN_QUERY,
    RESET_HISCCONECTOR_SEARCH_PROFILE_QUERY,
    RESET_HISCCONECTOR_SEARCH_QUERY,
    RESET_HISCCONECTOR_SEARCH_TEST_QUERY,
    SET_CONNECTOR_SCROLL_HISCODE,
    SET_CONNECTOR_SCROLL_INSURANCE,
    SET_HISCCONECTOR_SEARCH_DEPARTMENT_QUERY,
    SET_HISCCONECTOR_SEARCH_INSTRUMENT_QUERY,
    SET_HISCCONECTOR_SEARCH_PHYSICIAN_QUERY,
    SET_HISCCONECTOR_SEARCH_PROFILE_QUERY,
    SET_HISCCONECTOR_SEARCH_QUERY,
    SET_HISCCONECTOR_SEARCH_TEST_QUERY,
    SET_ITEM_ACTIVE_CONNECTOR,
    UPDATE_HISCCONECTOR, UPDATE_HISCCONECTOR_FAIL, UPDATE_HISCCONECTOR_SUCCESS,
    UPDATE_PROFILE_MAPPING,
    UPDATE_PROFILE_MAPPING_FAIL,
    UPDATE_PROFILE_MAPPING_SUCCESS,
    UPDATE_TEST_MAPPING,
    UPDATE_TEST_MAPPING_FAIL,
    UPDATE_TEST_MAPPING_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
    hisConnectors: [],
    paging: {},
    loadingHisConnector: false,
    updatingHisConnector: false,
    searchQuery: {},
    searchQueryProfile: {},
    searchQueryTest: {},
    updateHisConnectorTime: new Date(),
    error: {},
    hisConnector: {},
    itemActive: {},
    /* ==== PROFILE MAPPING START === */
    profileMappings: [],
    profileMappingPaging: {},
    loadingProfileMapping: false,
    updatingProfileMapping: false,
    updateProfileMappingTime: new Date(),
    profileMappingError: {},
    profileMapping: {},
    /* ==== PROFILE MAPPING END === */

    /* ==== TEST MAPPING START === */
    testMappings: [],
    testMappingPaging: {},
    loadingTestMapping: false,
    updatingTestMapping: false,
    updateTestMappingTime: new Date(),
    testMappingError: {},
    testMapping: {},
    /* ==== TEST MAPPING END === */
    searchQueryInsurance: {},
    loadingInsuranceMapping: false,
    updateInsuranceMappingTime: new Date(),
    insuranceMappings: [],
    insuranceMappingPaging: {},

    searchQueryDepartment: {},
    loadingDepartmentMapping: false,
    updateDepartmentMappingTime: new Date(),
    departmentMappings: [],
    departmentMappingLanguages: [],
    departmentMappingPaging: {},

    // PHYSICIAN
    searchQueryPhysician: {},
    loadingPhysicianMapping: false,
    updatePhysicianMappingTime: new Date(),
    physicianMappings: [],
    physicianMappingLanguages: [],
    physicianMappingPaging: {},

    //INSTRUMENT
    searchQueryInstrument: {},
    loadingInstrumentMapping: false,
    updateInstrumentMappingTime: new Date(),
    instrumentMappings: [],
    instrumentMappingLanguages: [],
    instrumentMappingPaging: {},

    scrollHisCode: { left: 0, top: 0 },
    scrollInsurance: { left: 0, top: 0 },
    hisCodeTestLanguages: [],
    hisCodeProfileLanguages: [],
    insuranceMappingLanguages: [],

    testCodeDMSMappings: [],
    testCodeDmsMappingLanguages:[],
    loadingTestCodeDmsMapping: false,
    updateTestCodeDmsMappingTime: new Date()
}

const HISConnector = (state = INIT_STATE, action) => {
    switch (action.type) {
        //get hisconnector list
        case GET_HISCCONECTOR:
            return {
                ...state,
                //hisConnectors: [],
                error: {},
                loadingHisConnector: true,
            }

        case GET_HISCCONECTOR_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingHisConnector: false,
            }

        case GET_HISCCONECTOR_SUCCESS: {
            const { data, totalElements, ...rest } = action.payload
            return {
                ...state,
                hisConnectors: data,
                paging: { ...rest, dataSize: totalElements },
                loadingHisConnector: false,
                updateHisConnectorTime: new Date()
            }
        }
        //set search query
        case SET_HISCCONECTOR_SEARCH_QUERY:
            return {
                ...state,
                searchQuery: { ...action.payload },
            }
        //reset search query
        case RESET_HISCCONECTOR_SEARCH_QUERY:
            return {
                ...state,
                searchQuery: {},
            }
        //get hisconnector detail
        case GET_HISCCONECTOR_DETAIL:
            return {
                ...state,
                hisConnector: {},
                error: {},
                errorField: {},
                insuranceMappings: [],
                insuranceMappingPaging: {},
                profileMappings: [],
                profileMappingPaging: {},
                departmentMappings: [],
                departmentMappingPaging: {},
                physicianMappings: [],
                physicianMappingPaging: {},
                instrumentMappings: [],
                instrumentMappingPaging: {},
                hisCodeTestLanguages: [],
                hisCodeProfileLanguages: []
            }
        case GET_HISCCONECTOR_DETAIL_SUCCESS:
            const hisConnector = action.payload
            return {
                ...state,
                hisConnector,
            }

        case GET_HISCCONECTOR_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        //add hiscconector
        case ADD_HISCCONECTOR:
            return {
                ...state,
            }

        case ADD_HISCCONECTOR_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        //update hisconnector
        case UPDATE_HISCCONECTOR:
            return {
                ...state,
                updatingHisConnector: true,
                error: {},
            }

        case UPDATE_HISCCONECTOR_SUCCESS:
            return {
                ...state,
                hisConnectors: state.hisConnectors.map(hisConnector =>
                    hisConnector.id?.toString() === action.payload.id.toString()
                        ? { ...hisConnector, ...action.payload }
                        : hisConnector
                ),
                updatingHisConnector: false,
            }

        case UPDATE_HISCCONECTOR_FAIL:
            return {
                ...state,
                error: action.payload,
                updatingHisConnector: false,
            }

        case DELETE_HISCCONECTOR_SUCCESS: {
            const payload = action.payload
            const hisConnectorsUpdate = state.hisConnectors.filter(
                hisConnector => payload.findIndex(_payload => hisConnector.id === _payload.id) < 0
            )

            const statePaging = { ...state.paging }
            statePaging.dataSize = statePaging.dataSize - payload.length
            if (statePaging.dataSize < 0) statePaging = 0

            return {
                ...state,
                hisConnectors: hisConnectorsUpdate,
                paging: statePaging,
            }
        }
        case DELETE_HISCCONECTOR_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        /* ==== PROFILE MAPPING START === */
        case GET_PROFILE_MAPPING_LIST:
            return {
                ...state,
                profileMappings: [],
                profileMappingError: {},
                loadingProfileMapping: true,
            }
        case GET_PROFILE_MAPPING_LIST_SUCCESS: {
            const { data, totalElements, ...rest } = action.payload
            return {
                ...state,
                profileMappings: action.payload,
                profileMappingPaging: { ...rest, dataSize: totalElements },
                loadingProfileMapping: false,
                updateProfileMappingTime: new Date()
            }
        }
        case GET_PROFILE_MAPPING_LIST_FAIL:
            return {
                ...state,
                profileMappingError: action.payload,
                loadingProfileMapping: false,
            }
        case ADD_PROFILE_MAPPING:
            return {
                ...state,
            }
        case ADD_PROFILE_MAPPING_SUCCESS:
            return {
                ...state,
                profileMappings: [...state.profileMappings, action.payload],
            }
        case ADD_PROFILE_MAPPING_FAIL:
            return {
                ...state,
                profileMappingError: action.payload,
            }
        case UPDATE_PROFILE_MAPPING:
            return {
                ...state,
                updatingProfileMapping: true,
                profileMappingError: {},
            }
        case UPDATE_PROFILE_MAPPING_SUCCESS:
            return {
                ...state,
                profileMappings: state.profileMappings.map(item =>
                    item.id?.toString() === action.payload.id.toString()
                        ? { ...item, ...action.payload }
                        : item
                ),
                updateProfileMappingTime: false,
            }
        case UPDATE_PROFILE_MAPPING_FAIL:
            return {
                ...state,
                profileMappingError: action.payload,
                updatingProfileMapping: false,
            }


        /* ==== PROFILE MAPPING END === */

        /* ==== TEST MAPPING START === */
        case GET_TEST_MAPPING_LIST:
            return {
                ...state,
                // testMappings: [],
                testMappingError: {},
                loadingTestMapping: true,
            }
        case GET_TEST_MAPPING_LIST_SUCCESS:
            const { data, totalElements, ...rest } = action.payload
            return {
                ...state,
                testMappings: action.payload,
                testMappingPaging: { ...rest, dataSize: totalElements },
                loadingTestMapping: false,
                updateTestMappingTime: new Date()
            }
        case GET_TEST_MAPPING_LIST_FAIL:
            return {
                ...state,
                testMappingError: action.payload,
                loadingTestMapping: false,
            }
        case ADD_TEST_MAPPING:
            return {
                ...state,
            }
        case ADD_TEST_MAPPING_SUCCESS:
            return {
                ...state,
                testMappings: [...state.testMappings, action.payload],
            }
        case ADD_TEST_MAPPING_FAIL:
            return {
                ...state,
                testMappingError: action.payload,
            }
        case UPDATE_TEST_MAPPING:
            return {
                ...state,
                updatingTestMapping: true,
                testMappingError: {},
            }
        case UPDATE_TEST_MAPPING_SUCCESS:
            return {
                ...state,
                testMappings: state.testMappings.map(item =>
                    item.id?.toString() === action.payload.id.toString()
                        ? { ...item, ...action.payload }
                        : item
                ),
                updatingTestMapping: false,
            }
        case UPDATE_TEST_MAPPING_FAIL:
            return {
                ...state,
                profileMappingError: action.payload,
                updatingTestMapping: false,
            }

        case DELETE_TEST_MAPPING_FAIL:
            return {
                ...state,
                testMappingError: action.payload,
            }
        /* ==== TEST MAPPING END === */

        case GET_PROFILE_MAPPING_LIST_WITH_PAGE:
            return {
                ...state,
                error: {},
                loadingProfileMapping: true,
            }

        case GET_PROFILE_MAPPING_LIST_WITH_PAGE_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingProfileMapping: false,
            }

        case GET_PROFILE_MAPPING_LIST_WITH_PAGE_SUCCESS: {
            const { data, totalElements, ...rest } = action.payload
            return {
                ...state,
                profileMappings: data,
                profileMappingPaging: { ...rest, dataSize: data.length },
                loadingProfileMapping: false,
                updateProfileMappingTime: new Date()
            }
        }

        case GET_TEST_MAPPING_LIST_WITH_PAGE:
            return {
                ...state,
                error: {},
                loadingTestMapping: true,
            }

        case GET_TEST_MAPPING_LIST_WITH_PAGE_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingTestMapping: false,
            }

        case GET_TEST_MAPPING_LIST_WITH_PAGE_SUCCESS: {
            const { data, totalElements, ...rest } = action.payload
            return {
                ...state,
                testMappings: data,
                testMappingPaging: { ...rest, dataSize: totalElements },
                loadingTestMapping: false,
                updateTestMappingTime: new Date()
            }
        }

        //set search query
        case SET_HISCCONECTOR_SEARCH_TEST_QUERY:
            return {
                ...state,
                searchQueryTest: { ...action.payload },
            }
        //reset search query
        case RESET_HISCCONECTOR_SEARCH_TEST_QUERY:
            return {
                ...state,
                searchQueryTest: {},
            }

        //set search query
        case SET_HISCCONECTOR_SEARCH_PROFILE_QUERY:
            return {
                ...state,
                searchQueryProfile: { ...action.payload },
            }
        //reset search query
        case RESET_HISCCONECTOR_SEARCH_PROFILE_QUERY:
            return {
                ...state,
                searchQueryInsurance: {},
            }
        case GET_CONNECTOR_INSURANCE_MAPPING:
            return {
                ...state,
                error: {},
                loadingInsuranceMapping: true,
            }

        case GET_CONNECTOR_INSURANCE_MAPPING_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingInsuranceMapping: false,
            }

        case GET_CONNECTOR_INSURANCE_MAPPING_SUCCESS: {
            const { data, totalElements, ...rest } = action.payload
            return {
                ...state,
                insuranceMappings: data,
                insuranceMappingPaging: { ...rest, dataSize: data.length },
                loadingInsuranceMapping: false,
                updateInsuranceMappingTime: new Date()
            }
        }
        case SET_ITEM_ACTIVE_CONNECTOR:
            return {
                ...state,
                itemActive: action.payload,
            }

        //department
        case SET_HISCCONECTOR_SEARCH_DEPARTMENT_QUERY:
            return {
                ...state,
                searchQueryDepartment: { ...action.payload },
            }
        case RESET_HISCCONECTOR_SEARCH_DEPARTMENT_QUERY:
            return {
                ...state,
                searchQueryDepartment: {},
            }

        case GET_CONNECTOR_DEPARTMENT_MAPPING:
            return {
                ...state,
                error: {},
                loadingDepartmentMapping: true,
            }

        case GET_CONNECTOR_DEPARTMENT_MAPPING_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingDepartmentMapping: false,
            }

        case GET_CONNECTOR_DEPARTMENT_MAPPING_SUCCESS: {
            const { data, totalElements, ...rest } = action.payload
            return {
                ...state,
                departmentMappings: data,
                departmentMappingPaging: { ...rest, dataSize: data.length },
                loadingDepartmentMapping: false,
                updateDepartmentMappingTime: new Date()
            }
        }

        case GET_CONNECTOR_DEPARTMENT_MAPPING_LANGUAGE_SUCCESS: {
            return {
                ...state,
                departmentMappingLanguages: action.payload,
            }
        }

        case GET_CONNECTOR_DEPARTMENT_MAPPING_UPLOAD_SUCCESS: {
            return {
                ...state,
                departmentMappings: action.payload,
                updateDepartmentMappingTime: new Date()
            }
        }

        //physician
        case SET_HISCCONECTOR_SEARCH_PHYSICIAN_QUERY:
            return {
                ...state,
                searchQueryPhysician: { ...action.payload },
            }
        case RESET_HISCCONECTOR_SEARCH_PHYSICIAN_QUERY:
            return {
                ...state,
                searchQueryPhysician: {},
            }
        case GET_CONNECTOR_PHYSICIAN_MAPPING:
            return {
                ...state,
                error: {},
                loadingPhysicianMapping: true,
            }

        case GET_CONNECTOR_PHYSICIAN_MAPPING_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingPhysicianMapping: false,
            }

        case GET_CONNECTOR_PHYSICIAN_MAPPING_SUCCESS: {
            const { data, totalElements, ...rest } = action.payload
            return {
                ...state,
                physicianMappings: data,
                physicianMappingPaging: { ...rest, dataSize: data.length },
                loadingPhysicianMapping: false,
                updatePhysicianMappingTime: new Date()
            }
        }


        case GET_CONNECTOR_PHYSICIAN_MAPPING_LANGUAGE_SUCCESS: {
            return {
                ...state,
                physicianMappingLanguages: action.payload,
            }
        }

        case GET_CONNECTOR_PHYSICIAN_MAPPING_UPLOAD_SUCCESS: {
            return {
                ...state,
                physicianMappings: action.payload,
                updatePhysicianMappingTime: new Date()
            }
        }

        //instrument
        case SET_HISCCONECTOR_SEARCH_INSTRUMENT_QUERY:
            return {
                ...state,
                searchQueryInstrument: { ...action.payload },
            }
        case RESET_HISCCONECTOR_SEARCH_INSTRUMENT_QUERY:
            return {
                ...state,
                searchQueryInstrument: {},
            }
        case GET_CONNECTOR_INSTRUMENT_MAPPING:
            return {
                ...state,
                error: {},
                loadingInstrumentMapping: true,
            }

        case GET_CONNECTOR_INSTRUMENT_MAPPING_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingInstrumentMapping: false,
            }

        case GET_CONNECTOR_INSTRUMENT_MAPPING_SUCCESS: {
            const { data, totalElements, ...rest } = action.payload
            return {
                ...state,
                instrumentMappings: data,
                instrumentMappingPaging: { ...rest, dataSize: data.length },
                loadingInstrumentMapping: false,
                updateInstrumentMappingTime: new Date()
            }
        }

        case GET_CONNECTOR_INSTRUMENT_MAPPING_LANGUAGE_SUCCESS: {
            return {
                ...state,
                instrumentMappingLanguages: action.payload,
            }
        }

        case GET_CONNECTOR_INSTRUMENT_MAPPING_UPLOAD_SUCCESS: {
            return {
                ...state,
                instrumentMappings: action.payload,
                updateInstrumentMappingTime: new Date()
            }
        }

        case SET_CONNECTOR_SCROLL_HISCODE: {
            return {
                ...state,
                scrollHisCode: action.payload
            }
        }

        case SET_CONNECTOR_SCROLL_INSURANCE: {
            return {
                ...state,
                scrollInsurance: action.payload
            }
        }

        case GET_PROFILE_MAPPING_LIST_WITH_PAGE_UPLOAD_SUCCESS: {
            return {
                ...state,
                profileMappings: action.payload,
                loadingProfileMapping: false,
                updateProfileMappingTime: new Date()
            }
        }

        case GET_CONNECTOR_HISCODE_TEST_LANGUAGE_SUCCESS: {
            return {
                ...state,
                hisCodeTestLanguages: action.payload,
            }
        }

        case GET_CONNECTOR_HISCODE_PROFILE_LANGUAGE_SUCCESS: {
            return {
                ...state,
                hisCodeProfileLanguages: action.payload,
            }
        }

        case GET_CONNECTOR_INSURANCE_MAPPING_UPLOAD_SUCCESS: {
            return {
                ...state,
                insuranceMappings: action.payload,
                updateInsuranceMappingTime: new Date()
            }
        }

        case GET_CONNECTOR_INSURANCE_LANGUAGE_SUCCESS: {
            return {
                ...state,
                insuranceMappingLanguages: action.payload,
            }
        }

        case GET_DMS_TESTCODE_MAPPING_LANGUAGE_SUCCESS: {
            return {
                ...state,
                testCodeDmsMappingLanguages: action.payload,
            }
        }

        case GET_DMS_TESTCODE_MAPPING_SUCCESS: {
            const { data, totalElements, ...rest } = action.payload
            return {
                ...state,
                testCodeDmsMappings: [...data],
                testCodeDmsMappingPaging: { ...rest, dataSize: data.length },
                loadingTestCodeDmsMapping: false,
                updateTestCodeDmsMappingTime: new Date()
            }
        }
        case GET_DMS_TESTCODE_MAPPING_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingInstrumentMapping: false,
            }

        default:
            return state
    }
}

export default HISConnector;