import { CustomBootstrapTable } from "components/Common"
import BadgeLabel from "components/Common/BadgeLabel"
import { ModuleIds } from "constant"
import { convertDateFormat, indexCalculator, mapStatusToDescription } from "helpers/utilities"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import ReactTooltip from "react-tooltip"
import FilterForm from "./FilterForm"

const RESOURCE = ModuleIds.ValidResult

const ValidationResultTable = ({
  onSelect,
  onSelectAll,
  results,
  onSearch,
  onSort,
  onRefresh,
  onPageChange,
  paging,
  onSizePerPageChange,
  onSubmitFilter,
  onEdit,
  onDelete,
  loading,
  t,
  updatedTime,
}) => {
  const [data, setData] = useState([])
  const [model, setModel] = useState({
    search: "",
    instrumentId: "",
    requestDate: convertDateFormat(new Date(), "YYYY-MM-DD"),
    status: "",
    testCode: "",
  })
  const searchQuery = useSelector(state => state.validResult.searchQuery)
  const onResetHandler = () => {
    const initModel = {
      search: "",
      instrumentId: "",
      requestDate: convertDateFormat(new Date(), "YYYY-MM-DD"),
      status: "",
      testCode: "",
    }
    setModel(initModel)
  }
  useEffect(() => {
    setModel(searchQuery)
  }, [searchQuery])

  useEffect(() => {
    setData(results)
  }, [results])


  const columns = [
    {
      dataField: "id",
      text: "#",
      style: { width: 50 },
      formatter: (cellContent, item, index) => {
        return indexCalculator(paging.page, paging.size, index + 1)
      },
    },
    {
      dataField: "requestDate",
      text: t("Request Date"),
      sort: true,
      style: { color: "#556ee6" },
      formatter: (cellContent, test, index) => {
        return (
          <Link
            to={`/TestRequest/${test.requestId}/view?tab=1`}
            target="_blank"
          >
            {convertDateFormat(test.requestDate, "YYYY-MM-DD")}
          </Link>
        )
      },
    },
    {
      dataField: "sid",
      text: t("SID"),
      sort: true,
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "patientId",
      text: t("Patient Id"),
      sort: true,
    },
    {
      dataField: "testCode",
      text: t("Test Code"),
      formatter: (cellContent, test, index) => {
        return (
          <Link to={`/Test/${cellContent}/view?tab=1`} target="_blank">
            {cellContent}
          </Link>
        )
      },
    },
    {
      dataField: "instrumentTestCode",
      text:  t("Ins Code"),
      sort: true,
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "testName",
      text: t("Test Name"),
      formatter: (cellContent, test, index) => {
        return (
          <Link to={`/Test/${test.testCode}/view?tab=1`} target="_blank">
            {cellContent}
          </Link>
        )
      },
    },
    {
      dataField: "result",
      text: t("Result"),
      align: "center",
      headerAlign: "center",
      formatter: (cellContent, item, index) => {      
        return <b>{item.resultText ? item.resultText : cellContent}</b>
      },
    },
    {
      dataField: "unit",
      text: t("Unit"),
    },
    {
      dataField: "status",
      text: t("common:Status"),
      align: "center",
      headerAlign: "center",
      formatter: (cellContent, testResult, index) => {
        return <>
          <BadgeLabel
            value={cellContent}
            type={cellContent}
            className="badge-width"
            dataFor={cellContent}
          />
        <ReactTooltip id={cellContent} place="top" effect="solid">
          <span>{mapStatusToDescription(cellContent)}</span>
        </ReactTooltip>      
      </>
      },
    },
    {
      dataField: "instrumentId",
      text: t("Ins Id"),
      align: "center",
      headerAlign: "center",
      formatter: (cellContent, test, index) => {
        return <>
        {test.instrumentCode ? (
        <span data-tip data-for={test.instrumentCode}>
          {cellContent}
        </span>
        ) : "---"}
        {test.instrumentCode && (
        <ReactTooltip id={test.instrumentCode} place="top" effect="solid">
          <span>{test.instrumentCode}</span>
        </ReactTooltip>  
        )}      
      </>
      }
    },
    {
      dataField: "category",
      text: t("Category"),
    },
    {
      dataField: "port",
      text: t("Port"),
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "runTime",
      text: t("Process Time"),
      formatter: (cellContent, item, index) => convertDateFormat(cellContent),
    },
    // {
    //   dataField: "action",
    //   text: "",
    //   isDummyField: true,
    //   style: { width: 50 },
    //   formatter: (cellContent, param, index) => (
    //     <InlineAction
    //       resource={RESOURCE}
    //       onEdit={e => onEdit(e, param.id)}
    //       onDelete={e => onDelete(e, param)}
    //     />
    //   ),
    // },
  ]
  return (
    <CustomBootstrapTable
      columns={columns}
      search
      data={data}
      paging={paging}
      onSelect={onSelect}
      onSelectAll={onSelectAll}
      onSearch={onSearch}
      onSort={onSort}
      onRefresh={onRefresh}
      filterForm={() => <FilterForm model={model} />}
      onReset={onResetHandler}
      onPageChange={onPageChange}
      onSizePerPageChange={onSizePerPageChange}
      onSubmitFilter={onSubmitFilter}
      searchText={model.search}
      loading={loading}
      updatedTime={updatedTime}
      resource={RESOURCE}presultPage
    />
  )
}

const mapStateToProps = ({}) => ({})

const mapDispatchToProps = dispatch => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["resultPage", "common"])(ValidationResultTable))
