import {
  CustomButton,
  CustomDatePicker,
  CustomModal,
  CustomSelectAsync
} from "components/Common"
import { Col, ModalBody, ModalFooter, Row } from "reactstrap"
import { AvForm } from "availity-reactstrap-validation"
import CustomBootstrapTableAsync from "components/Common/CustomTable/CustomBootstrapTableAsync"
import { parameterCode } from "constant"
import { convertDateFormat } from "helpers/utilities"
import { useEffect, useMemo, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { getGeneralHistoryResult } from "store/actions"

const GeneralHistoryModal = ({
  modal,
  toggle,
  data,
  t,
  onGetGeneralHistoryResult,
  testHistory,
  testResults,
  patientId,
  disableSID,
  individual,
  ...rest
}) => {
  const formEl = useRef(null)
  const title = t("General Result History")
  const [datatTable, setDataTable] = useState([])
  const [model, setModel] = useState({
    // fromDate: convertDateFormat(
    //   `${new Date().getFullYear()}-${new Date().getMonth() + 1}-01`,
    //   "YYYY-MM-DD"
    // ),
    fromDate: convertDateFormat(new Date().setDate(new Date().getDate() - 31), "YYYY-MM-DD"),
    toDate: convertDateFormat(new Date().setDate(new Date().getDate() - 1), "YYYY-MM-DD"),
    patientId: patientId,
  })
  const [column, setColumn] = useState([{}])
  const [patient, setPatient] = useState(null)
  // const [keySearch, setKeySearch] = useState("")

  useEffect(() => {
    if (individual.patientId || modal) {
      setPatient(individual.patientId)
    }
  }, [individual, modal])

  const callback = () => {
    let arrCol = [
      // {
      //   dataField: "testcode",
      //   text: t("common:Code"),
      //   headerStyle: { minWidth: 150, maxWidth: 150, width: 150, zIndex: 2 },
      //   style: { minWidth: 150, maxWidth: 150, width: 150, zIndex: 2 },
      // },
      {
        dataField: "testname",
        text: t("Test Name"),
        headerStyle: { minWidth: 300, maxWidth: 300, width: 300, zIndex: 2, position: 'sticky' },
        style: { minWidth: 300, maxWidth: 300, width: 300, zIndex: 2 },
        formatter: (cellContent, item, index) => {
          return <span>{item.testcode} - {item.testname}</span>
        },
      },
    ]
    let arrData = []
    let arrSID = []
    const data = testHistory.sort((a, b) => {
      return new Date(a.requestDate) - new Date(b.requestDate)
    })
    data.forEach(item => {
      if (!arrSID.includes(item.fullSID)) {
        arrSID.unshift(item.fullSID)
      }
    })
    arrSID.forEach((item, index) => {
      arrCol.push({
        dataField: `result${index}`,
        text: `${item}`,
        headerStyle: {
          minWidth: 160,
          maxWidth: 160,
          width: 160,
          zIndex: 2,
          textAlign: "center",
          position: 'sticky'
        },
        style: {
          minWidth: 100,
          maxWidth: 100,
          width: 100,
          zIndex: 2,
          textAlign: "center",
          height: "100%",
        },
        formatter: (cellContent, item, index) => {
          return cellContent != "" && cellContent ? (
            <div
              style={{
                padding: 8,
                background: "#cefbce",
              }}
            >
              {cellContent}
            </div>
          ) : (
            <></>
          )
        },
      })
    })
    let arrTestCode = []
    let testCode = ""
    let row = -1
    let colResult = ""
    testHistory.forEach(item => {
      let countTestCode = arrTestCode.filter(x => x == item.testCode);
      if (countTestCode.length <= 0) {
        if (item.testCode != testCode) {
          testCode = item.testCode
          row += 1
          //thêm record vào data
          arrData.push({
            testcode: item.testCode,
            testname: item.testName,
          })
          arrTestCode.push(item.testCode)

          //thêm kết quả xét nghiệm rỗng vào data
          for (let i = 0; i < arrSID.length; i++) {
            arrData[row][`result${i}`] = null
          }
        }
        if (item.testCode == testCode) {
          for (let i = 0; i < arrSID.length; i++) {
            colResult = "result" + i
            if (item.fullSID == arrSID[i]) {
              arrData[row][colResult] = item.result || item.resultText
            }
          }
        }
      }
      else {
        let indexArrData = arrData.findIndex(x => x.testcode == item.testCode)
        if (indexArrData >= 0) {
          for (let i = 0; i < arrSID.length; i++) {
            colResult = "result" + i
            if (item.fullSID == arrSID[i]) {
              arrData[indexArrData][colResult] = item.result || item.resultText
            }
          }
        }
      }
    })
    setDataTable(arrData)
    setColumn(arrCol)
  }
  const fetchData = async filter => {
    filter.patientId &&
      filter.fromDate &&
      filter.toDate &&
      (await onGetGeneralHistoryResult(filter))
  }

  const requestDateOnChange = date => {
    let newModel = { ...model }
    if (
      newModel.fromDate != convertDateFormat(date.time[0], "YYYY-MM-DD") ||
      newModel.toDate != convertDateFormat(date.time[1], "YYYY-MM-DD")
    ) {
      newModel.fromDate = convertDateFormat(date.time[0], "YYYY-MM-DD")
      newModel.toDate = convertDateFormat(date.time[1], "YYYY-MM-DD")
      setModel(newModel)
      fetchData(newModel)
    }
  }
  const pidOnChange = patient => {
    let pid = patient[0]?.patientId
    let newModel = { ...model }
    newModel.patientId = pid
    setModel(newModel)
    fetchData(newModel)
  }

  useEffect(() => {
    let newModel = { ...model }
    newModel.patientId = patient
    setModel(newModel)
    fetchData(newModel)
  }, [patient])

  useMemo(() => {
    callback()
  }, [testHistory])

  return (
    <CustomModal
      modal={modal}
      title={title}
      onToggle={toggle}
      size="lg"
      customClass={"model-height modal-w80"}
    >
      <ModalBody className="configColumnModal" style={{ overflow: 'hidden' }}>
        <div className="h-100" style={{ display: 'flex', flexDirection: 'column', }}>
          <AvForm ref={formEl} id="generalForm">
            <Row>
              <Col xs="6">
                <div className="mb-3">
                  {individual && (
                    <CustomSelectAsync
                      readOnly={disableSID == true}
                      name="patientId"
                      value={patient || individual.patientId || ""}
                      customSearchQuery={patient}
                      code={parameterCode.PATIENTS}
                      label={t("PID")}
                      onChange={(a, b, c, values) => {
                        setPatient(values[0]?.patientId)
                        pidOnChange(values)
                      }}
                    />
                  )}
                </div>
              </Col>

              <Col xs="6">
                <div className="mb-3">
                  <CustomDatePicker
                    name="requestDate"
                    label={t("Request Date")}
                    placeholder={"DD/MM/YYYY"}
                    format={"d-m-Y"}
                    value={[model.fromDate, model.toDate]}
                    maxDate={model.toDate}
                    onChangeHandler={requestDateOnChange}
                    closeOnSelect={true}
                    mode="range"
                  />
                </div>
              </Col>
            </Row>
          </AvForm>
          <div style={{ overflow: 'hidden' }} className="table-general-result h-100">
            <CustomBootstrapTableAsync
              columns={column}
              data={datatTable || []}
              isScrollable
              keyField={"fieldName"}
              showSelectRow={false}
              {...rest}
            />
            {/* columns={columns}
                search
                data={data}
                paging={paging}
                onSelect={onSelect}
                onSelectAll={onSelectAll}
                onSearch={onSearch}
                onSort={onSort}
                onRefresh={onRefresh}
                onReset={onResetHandler}
                onPageChange={onPageChange}
                onSizePerPageChange={onSizePerPageChange}
                onSubmitFilter={onSubmitFilter}
                searchText={model.search}
                loading={loading}
                updatedTime={updatedTime}
                resource={RESOURCE}
                keyField="id" */}
          </div>
          <div className="result-detail-bottom-column">&nbsp;</div>
        </div>
      </ModalBody >

      <ModalFooter>
        <CustomButton
          text={t("testResultPage:Cancel")}
          type="button"
          onClick={toggle}
          data-dismiss="modal"
          className="button-width"
        />
      </ModalFooter>
    </CustomModal >
  )
}

GeneralHistoryModal.propTypes = {}

const mapStateToProps = ({ testResult }) => ({
  testHistory: testResult.testHistory,
  testResults: testResult.testResults,
})

const mapDispatchToProps = dispatch => ({
  onGetGeneralHistoryResult: payload =>
    dispatch(getGeneralHistoryResult(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["testResultPage", "common"])(GeneralHistoryModal))
