import { CustomBreadcrumb, PageContent } from "components/Common";
import { ModuleIds } from "constant";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import Notifications from "./Notifications";

const RESOURCE = ModuleIds.TestRequest;

const NotificationsContainer = ({ t }) => {
    const titleTag = `Notifications List`
    return (
        <PageContent title={t(titleTag)}>
            <CustomBreadcrumb
                breadcrumbItem={t("Notifications List")}
                resource={RESOURCE}
            />
            <Notifications />
        </PageContent >
    )
}

NotificationsContainer.propTypes = {
    t: PropTypes.any,
}

export default withTranslation(["notificationPage"])(NotificationsContainer)