import { AvForm } from "availity-reactstrap-validation"
import { CustomButton, CustomButtonGroup } from "components/Common"
import { ModuleIds } from "constant"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
//i18n
import DynamicFormForPersonalSetting from "components/Common/Input/DynamicFormForPersonalSetting"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Col, Row } from "reactstrap"
import { getPersonalSetting, getSettingField, updatePersonalSetting } from "store/setting/personalSetting/actions"
import LanguageDropdown from "components/CommonForBoth/TopbarDropdown/LanguageDropdown"
import { Link } from "react-router-dom/cjs/react-router-dom"

const RESOURCE = ModuleIds.PersonalSetting

const PersonalSetting = ({
    t,
    onUpdatePersonalSetting,
    onGetPersonalSettingField,
    onGetPersonalSetting,
    personalSetting,
    settingField,
    companies,
    screen,
    open
}) => {

    const [dataGeneral, setDataGeneral] = useState({})
    const [field, setField] = useState([])
    const userInfo = JSON.parse(localStorage.getItem("userInfo"))
    const [isView, setIsView] = useState(false)
    const [company, setCompany] = useState(userInfo?.company)
    const [companyInfo, setCompanyInfo] = useState({})

    useEffect(() => {
        setCompany(userInfo?.company)
    }, [userInfo])

    useEffect(() => {
        onGetPersonalSetting({ companyId: company, userId: userInfo?.sub })
        onGetPersonalSettingField(company)
    }, [open])

    useEffect(() => {
        if (companies?.length > 0) {
            const data = companies.find(item => item.id === parseInt(userInfo?.company))
            setCompanyInfo(data)
        }
    }, [companies])

    useEffect(() => {
        onGetPersonalSetting({ companyId: company, userId: userInfo?.sub })
        onGetPersonalSettingField(company)
    }, [company])

    useEffect(() => {
        if (settingField) {
            settingField.forEach((item) => {
                item.fieldName = item.fieldCode
                item.typeName = item.type
            })
            setField([...settingField])
        }
    }, [settingField])

    useEffect(() => {
        if (personalSetting && field.length > 0) {
            let result = {}
            field.forEach((item) => {
                personalSetting?.forEach((el) => {
                    if (el.fieldName === item.fieldName) {
                        item.value = el.fieldValue
                    }
                })
            })
            setField([...field])
            personalSetting?.forEach((item) => {
                result[item.fieldName] = item.fieldValue
            })
            setDataGeneral(JSON.parse(JSON.stringify(result)));
            setIsView(false)
        }

    }, [personalSetting, settingField])

    const onValidSubmit = (e, values) => {
        let area = []
        if (dataGeneral?.Area) {
            area = JSON.parse(dataGeneral.Area)?.filter(item => item.area !== values.area)
        }
        area.forEach(item => item.isSelect = false)
        const result = {
            companyId: dataGeneral?.companyId || company,
            userId: dataGeneral?.sub || userInfo?.sub,
            settingFieldValues: {
                ...values,
                Area: [...area, {
                    collectorId: values.collectorId,
                    area: values.area,
                    isSelect: true,
                    defaultValue: Object.keys(values)
                        .filter(item => values.collectorId.includes(parseInt(item.split('@')[0])))
                        .map(item => {
                            const colectorData = item.split('@')
                            const isDefault = values[item]
                            return ({ collectorId: colectorData[0], collectorName: colectorData[1], isDefault: isDefault })
                        })
                }]
            }
        }

        onUpdatePersonalSetting(result, () => {
            setIsView(true)
            onGetPersonalSetting({ companyId: company, userId: userInfo?.sub })
        })
    }

    const onCancel = () => {
        setIsView(true)
        onGetPersonalSetting({ companyId: company, userId: userInfo?.sub })
        onGetPersonalSettingField(company)
    }

    return (
        <div style={{ marginTop: '60px' }}>
            <Row className="d-flex" style={{ height: '55px' }}>
                <Col sm={6} className="px-4" style={{ alignContent: 'center' }}>
                    <a
                        target="_blank" rel="noopener noreferrer"
                        href="https://drive.google.com/file/d/1nMhPUhrFF0d-yWoyySToZ4pQn-SrRvH5/view?usp=drive_link"
                        className="text-primary"
                    >
                        <span >{t("Download Printer")}</span>
                    </a>
                </Col>
                <Col sm={6} style={{
                    textAlign: 'end'
                }}>
                    <LanguageDropdown />
                </Col>
            </Row>
            {!isView &&
                <AvForm
                    onValidSubmit={onValidSubmit}
                >
                    <DynamicFormForPersonalSetting
                        items={field}
                        isEdit={true}
                        resource={screen}
                        needContact={true}
                        company={company}
                    />
                    <Row className="mb-2"
                        style={{
                            placeContent: 'center',
                            paddingLeft: 28,
                            paddingRight: 28,
                            position: 'sticky',
                            bottom: '5px',
                            zIndex: 1
                        }}>
                        <CustomButton
                            text={t("common:Save")}
                            type="submit"
                            color="primary"
                            className="save-user button-width"
                            isEdit
                        />
                    </Row>
                </AvForm>}
        </div>
    )
}

PersonalSetting.propTypes = {
    t: PropTypes.any,
    onUpdatePersonalSetting: PropTypes.any,
    onGetPersonalSettingField: PropTypes.any,
    onGetPersonalSetting: PropTypes.any,
    personalSetting: PropTypes.object
}

const mapStateToProps = ({ personalSetting, common }) => {
    return ({
        companies: common.companies,
        personalSetting: personalSetting.personalSetting,
        settingField: personalSetting.settingField,
        screen: personalSetting.screen,
    })
}

const mapDispatchToProps = dispatch => ({
    onGetPersonalSetting: payload => dispatch(getPersonalSetting(payload)),
    onGetPersonalSettingField: payload => dispatch(getSettingField(payload)),
    onUpdatePersonalSetting: (payload, callBack) => dispatch(updatePersonalSetting(payload, callBack)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["personalSettingPage", "message"])(PersonalSetting)))
