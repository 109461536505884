import { AuditLogIdentifier, ModuleIds } from "constant"
import React, { useEffect, useRef } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import CustomTestResultTableAuditLog from "components/Common/CustomTestAuditLog"

const RESOURCE = ModuleIds.TestResult
const HistoryTab = ({
    t,
    id,
    currentTab,
}) => {
    const refResult = useRef();
    useEffect(() => {
        if (currentTab == 2)
        {
            refResult.current && refResult.current.onRefresh && refResult.current.onRefresh()
        }
    }, [currentTab])

    return (
        <React.Fragment>
            <CustomTestResultTableAuditLog
                resource={RESOURCE}
                identifier={AuditLogIdentifier.LA_ResultTest + id}
                ref={refResult}
                module={"Result"}
                title={t("testResultPage:History")}
            />
        </React.Fragment>
    )
}


HistoryTab.defaultProps = {}
const mapStateToProps = ({ testResult }) => ({})
const mapDispatchToProps = dispatch => ({})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["message", "common", "testResultPage"])(HistoryTab))
