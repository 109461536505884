import { CustomButton, CustomModal } from "components/Common"
import { convertDateFormat } from "helpers/utilities"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { Col, Input, ModalBody, ModalFooter } from "reactstrap"

const CustomModalLog = ({ modal, toggle, data, t }) => {
    const title = `${t('Title Modal')}`
    const [dataDisplay, setDataDisplay] = useState("")
    useEffect(() => {
        setDataDisplay(data.message)
    }, [data])

    // const handleShowPayload = () => {
    //     return (
    //         <div className="flex-1" dangerouslySetInnerHTML={{ __html: setDataDisplay }}></div>
    //     )
    // }
    // useEffect(() => {
    //     handleShowPayload()
    // }, [data])

    return (
        <CustomModal modal={modal} title={title} onToggle={toggle} size="lg">
            <ModalBody className="requestModal">
                <Col style={{ fontSize: '15px' }}>
                    <div style={{ marginBottom: "10px" }}>
                        {t("User")}: <span style={{ color: "blue" }}>{data.userName}</span>
                        {" "}- {t("Time")}:{" "}
                        <span style={{ color: "blue" }}>
                            {convertDateFormat(data.timeStamp)}
                        </span>
                    </div>

                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "10px",
                        }}
                    >
                        <div>{t("Action")}: </div>
                        <span className="px-2 badge-test" style={{ fontSize: "11px", marginLeft: '10px' }}>
                            {data.action}
                        </span>
                    </div>
                    <div className="flex-1" dangerouslySetInnerHTML={{ __html: dataDisplay }}></div>
                </Col>
            </ModalBody>
            <ModalFooter>
                <CustomButton
                    text={t("common:Ok")}
                    type="button"
                    color="primary"
                    onClick={toggle}
                    data-dismiss="modal"
                    className="button-width"
                />
            </ModalFooter>
        </CustomModal>
    )
}

CustomModalLog.propTypes = {}

export default withTranslation(["testRequestPage", "common"])(CustomModalLog)
