import { convertDateFormat, convertDateTimeFormat_VN } from "helpers/utilities"
import { isNaN } from "lodash"
import React from "react"
import { withTranslation } from "react-i18next"
import { Card, CardBody, CardHeader } from "reactstrap"

const TimeLine = ({
  data,
  timeCollectionAndDelivery,
  timeDeliveryAndReceived,
  timeCorrectionAndReceived,
  timeValidAndConfirm,
  timeConfirmAndReceive,
  timeSignAndConfirm,
  t,
}) => {
  const getMinuteBetween = (fromDate, totDate) => {
    return Math.floor(
      Math.abs(new Date(fromDate) - new Date(totDate)) / 1000 / 60
    )
  }

  const iconStyle = { marginRight: '3px', marginTop: '3px' }
  return (
    <CardBody style={{ paddingBottom: 0, paddingTop: 0 }}>
      <ul
        className="verti-timeline list-unstyled"
        style={{ borderLeft: "3px dashed #e7e7e7" }}
      >
        <li className="event-list" style={{ paddingBottom: 16 }}>
          <div className="event-timeline-dot">
            <i
              className={`bx bx-right-arrow-circle font-size-18 icon-timeline ${data.collectionTime && !data.deliveryTime && "bx-fade-right"
                }`}
            />{" "}
            <i
              className="fas fa-vials text-primary"
              style={{ fontSize: 25, paddingLeft: 30, paddingTop: 4 }}
            />
          </div>
          <div className="d-flex container-timeline">
            <div className="flex-shrink-0 me-3">
              <h5 className="font-size-16 d-flex">Lấy mẫu
                {data.correctionTime &&
                  <div className="tooltip-custom">
                    <i className="fas fa-exclamation-circle text-primary"></i>
                    <span className="tooltiptext-custom">
                      Lấy lại mẫu
                    </span>
                  </div>
                }
              </h5>
              <p className="font-size-13">
                Ngày giờ:{" "}
                {data.collectionTime && (
                  data.correctionTime ? convertDateTimeFormat_VN(new Date(data.correctionTime)) : convertDateTimeFormat_VN(new Date(data.collectionTime))
                )}
              </p>
            </div>
          </div>
        </li>

        <li className="event-list" style={{ paddingBottom: 16 }}>
          <div className="event-timeline-dot">
            <i
              className={`bx bx-right-arrow-circle font-size-18 icon-timeline ${data.deliveryTime && !data.receivedTime && "bx-fade-right"
                }`}
            />{" "}
            <i
              className="dripicons-alarm text-primary"
              style={{ fontSize: 25, paddingLeft: 30 }}
            />
          </div>

          <div className="flex-shrink-0 d-flex container-timeline">
            <div className="me-3">
              <h5 className="font-size-16">Giao mẫu</h5>
              <p className="font-size-13">
                Ngày giờ:{" "}
                {data.deliveryTime &&
                  convertDateTimeFormat_VN(new Date(data.deliveryTime))}
              </p>
            </div>
            {timeCollectionAndDelivery != undefined && timeCollectionAndDelivery >= 0 &&
              <div className="d-sm-flex">
                <i className="bx bx-calendar-event" style={iconStyle}></i>
                <div>{t("Time")} {timeCollectionAndDelivery} {t("min")}</div>
              </div>
            }
          </div>
        </li>

        <li className="event-list" style={{ paddingBottom: 16 }}>
          <div className="event-timeline-dot">
            <i
              className={`bx bx-right-arrow-circle font-size-18 icon-timeline ${Boolean(data.receivedTime && !data.correctionTime && !data.confirmTime && !data.validTime) && "bx-fade-right"
                }`}
            />{" "}
            <i
              className="dripicons-alarm text-primary "
              style={{ fontSize: 25, paddingLeft: 30 }}
            />
          </div>

          <div className="flex-shrink-0 d-flex container-timeline">
            <div className="me-3">
              <h5 className="font-size-16">Nhận mẫu</h5>
              <p className="font-size-13">
                Ngày giờ:{" "}
                {data.receivedTime &&
                  convertDateTimeFormat_VN(new Date(data.receivedTime))}
              </p>
            </div>

            {timeDeliveryAndReceived != undefined && timeDeliveryAndReceived >= 0 &&
              <div className="d-sm-flex">
                <i className="bx bx-calendar-event" style={iconStyle}></i>
                <div>{t("Time")} {timeDeliveryAndReceived} {t("min")}</div>
              </div>
            }
          </div>
        </li>

        <li className="event-list" style={{ paddingBottom: 16 }}>
          <div className="event-timeline-dot">
            <i
              className={`bx bx-right-arrow-circle font-size-18 icon-timeline ${Boolean(data.confirmTime && !data.signTime && !data.validTime) && "bx-fade-right"
                }`}

            />
            <i
              className="mdi mdi-file-table-outline text-success"
              style={{ fontSize: 25, paddingLeft: 30 }}
            />
          </div>

          <div className="flex-shrink-0 d-flex container-timeline">
            <div className="me-3">
              <h5 className="font-size-16">Có kết quả</h5>
              <p className="font-size-13">
                Ngày giờ:{" "}
                {data.confirmTime ? convertDateTimeFormat_VN(new Date(data.confirmTime)) :
                  (data.validTime ? convertDateTimeFormat_VN(new Date(data.validTime)) : '')}
              </p>
            </div>
            {timeConfirmAndReceive != undefined && timeConfirmAndReceive >= 0 &&
              <div className="d-sm-flex">
                <i className="bx bx-calendar-event" style={iconStyle}></i>
                <div>{t("Time")} {timeConfirmAndReceive} {t("min")}</div>
              </div>
            }
          </div>
        </li>

        <li className="event-list" style={{ paddingBottom: 16 }}>
          <div className="event-timeline-dot">
            <i
              className={`bx bx-right-arrow-circle font-size-18 icon-timeline ${data.validTime && "bx-fade-right"
                }`}
            />
            <i
              className="mdi mdi-file-clock-outline text-primary"
              style={{ fontSize: 25, paddingLeft: 30 }}
            />
          </div>

          <div className="flex-shrink-0 d-flex container-timeline">
            <div className="me-3">
              <h5 className="font-size-16">Đã duyệt</h5>
              <p className="font-size-13">
                Ngày giờ:{" "}
                {data.validTime &&
                  convertDateTimeFormat_VN(new Date(data.validTime))}
              </p>
            </div>
            {timeValidAndConfirm != undefined && timeValidAndConfirm >= 0 &&
              <div className="d-sm-flex">
                <i className="bx bx-calendar-event" style={iconStyle}></i>
                <div>{t("Time")} {timeValidAndConfirm} {t("min")}</div>
              </div>
            }
          </div>
        </li>

        <li className="event-list" style={{ paddingBottom: 16 }}>
          <div className="event-timeline-dot">
            <i className="bx bx-right-arrow-circle font-size-18 icon-timeline" />
            <i
              className="fas fa-file-contract text-success"
              style={{ fontSize: 25, paddingLeft: 34, paddingTop: 7 }}
            />
          </div>

          <div className="flex-shrink-0 d-flex container-timeline">
            <div className="me-3">
              <h5 className="font-size-16">Ký số </h5>
              <p className="font-size-13">
                Ngày giờ:{" "}
                {data.signTime &&
                  convertDateTimeFormat_VN(new Date(data.signTime))}
              </p>
            </div>
            {timeSignAndConfirm != undefined && timeSignAndConfirm >= 0 &&
              <div className="d-sm-flex">
                <i className="bx bx-calendar-event" style={iconStyle}></i>
                <div>{t("Time")} {timeSignAndConfirm} {t("min")}</div>
              </div>
            }
          </div>
        </li>
      </ul>
    </CardBody>
  )
}

export default withTranslation(["testConfig"])(TimeLine)
