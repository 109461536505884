import React, { useEffect, useState, useMemo } from "react"
import { AvForm } from "availity-reactstrap-validation"
import { Col, ModalBody, ModalFooter, Row, Label } from "reactstrap"
import { AvField, AvRadio, AvRadioGroup } from "availity-reactstrap-validation"
import {
  CustomModal,
  CustomButton,
  Check,
  CustomAvField,
  CustomSelect,
  DateTimePicker,
  CustomDatePicker,
  CustomSelectAsync,
  CustomButtonGroup,
} from "components/Common"
import { getMyInfo } from "helpers/common_services_helper"

//i18n
import { withTranslation } from "react-i18next"
import { isEmpty } from "lodash"
import {
  ModuleIds,
  parameterCode,
  permissionType,
  DeliveryType,
  SAMPLES_DELIVERY_TYPE,
} from "constant"
import { now } from "moment"
import { getCodesByParameterId } from "helpers/app-backend"
import { getI18nextLng } from "helpers/utilities"

const RESOURCE = ModuleIds.SampleDelivery

const DeliveryModal = ({
  myInfo,
  modal,
  toggle,
  isEdit,
  onValidSubmit,
  data,
  formEl,
  t,
  onChange,
}) => {
  const title = !!isEdit ? t("Edit Delivery") : t("deliveryPage:Add Delivery")

  if (isEdit && isEmpty(data)) return null
  let lang = getI18nextLng()
  const { id } = myInfo
  const [companyId, setCompanyId] = useState(myInfo.companyId)
  const [listDeliveryType, setListDeliveryType] = useState([])
  const [deliveryType, setDeliveryType] = useState([])
  const [dataRelate, setDataRelate] = useState([])
  const [deliveryTime, setDeliveryTime] = useState(
    data.deliveryTime || new Date()
  )
  const [type, setType] = useState(data.type || "A")
  const fectchDeliveryType = async () => {
    const query = { lang }
    let res = await getCodesByParameterId(
      parameterCode.DELIVERY_TYPE_CODE,
      query
    )
    res = res.filter(
      r =>
        r.inUse &&
        [
          SAMPLES_DELIVERY_TYPE.Delivery,
          SAMPLES_DELIVERY_TYPE.Receipt,
          SAMPLES_DELIVERY_TYPE.DeliveryAndReceipt,
        ].findIndex(x => x == r.code) >= 0
    )
    setListDeliveryType(res)
  }
  useMemo(() => {
    fectchDeliveryType()
    let dataArr = []
    if (data.relatedIds) {
      dataArr = data.relatedIds.split(",")
      setDataRelate(dataArr)
    }
    // if (isEdit) {
    //   setType(data.type)
    // }
  }, [])

  useEffect(() => {
    setDeliveryType(data.type)
  }, [data])

  const conditionEdit = ![9, 99].includes(data.state) || !isEdit;

  return (
    <CustomModal
      modal={modal}
      title={conditionEdit ? title : "Can not edit this delivery"}
      onToggle={toggle}
    >
      {conditionEdit ? (
        <React.Fragment>
          <ModalBody className="">
            <AvForm
              ref={formEl}
              id="deliveryForm"
              onValidSubmit={(e, values) => {
                onValidSubmit(e, values, type)
              }}
              model={data}
              onChange={onChange}
            >
              <CustomAvField name="id" type="hidden" value={data.id || ""} />
              <CustomAvField
                name="state"
                type="hidden"
                value={data.state || 0}
              />
              <Row form>
                <Col className="col-12">
                  <div className="mb-3">
                    <Label for="example">{t("common:Type")} </Label>
                    {/* <AvRadioGroup
                      className="radioDelivery"
                      name="type"
                      value={
                        listDeliveryType.length == 1
                          ? listDeliveryType[0].code
                          : data.type || SAMPLES_DELIVERY_TYPE.Delivery
                      }
                      onChange={(e) => setDeliveryType(e.target.value)}
                    >
                      {listDeliveryType.map((item, index) => (
                        <AvRadio
                          style={{ display: "flex" }}
                          label={t(`${item.message}`)}
                          value={item.code}
                          key={index}
                        />
                      ))}
                    </AvRadioGroup> */}

                    <CustomButtonGroup className="justify-content-start">
                      {listDeliveryType.map((item, index) => (
                        <CustomButton
                          text={t(`common:${item.message}`)}
                          name="type"
                          type="button"
                          onClick={e => {
                            setType(item.code)
                          }}
                          color={item.code == type ? "primary" : "light"}
                          className="button-width"
                          value={item.code}
                          key={index}
                          disabled={isEdit}
                        />
                      ))}
                    </CustomButtonGroup>
                  </div>
                  <div className="mb-3">
                    <CustomDatePicker
                      showRemove
                      name={"deliveryTime"}
                      label={t("Delivery Time")}
                      required={true}
                      value={deliveryTime}
                      detected={isEdit}
                      closeOnSelect={true}
                      enableTime={true}
                      dateFormat={"Y-m-d H:i"}
                      onChangeHandler={e => {
                        setDeliveryTime(e.time[0])
                      }}
                    />
                  </div>

                  <div className="d-none">
                    <CustomSelect
                      name="companyId"
                      value={companyId || undefined}
                      code={parameterCode.COMPANIES}
                      label={t("common:Company")}
                      required={true}
                      detected={isEdit}
                    />
                  </div>
                  {!(type == SAMPLES_DELIVERY_TYPE.Receipt) &&
                    <div className="mb-3">
                      <CustomSelect
                        name="handlerUserId"
                        value={data.handlerUserId || id || undefined}
                        code={parameterCode.USERS}
                        label={t("Sender Name")}
                        required={true}
                        detected={isEdit}
                        onChange={async (name, value) => {
                          let res = await getMyInfo(value)
                          setCompanyId(res.companyId)
                        }}
                      />
                    </div>
                  }
                  {!(type == SAMPLES_DELIVERY_TYPE.Delivery) &&
                    <div className="mb-3">
                      <CustomSelect
                        name="targetUserId"
                        value={data.targetUserId || id || undefined}
                        code={parameterCode.USERS}
                        label={t("Receiver Name")}
                        required={true}
                        detected={isEdit}
                      />
                    </div>
                  }
                  <div className="mb-3">
                    <CustomSelectAsync
                      name="relatedIds"
                      value={dataRelate || ""}
                      code={parameterCode.DELIVERY_MODAL}
                      isMulti
                      label={t("Related Delivery")}
                      // required={true}
                      detected={isEdit}
                      valueName={""}
                    />
                  </div>

                  <div className="mb-3">
                    <CustomAvField
                      name="remark"
                      value={data.remark || ""}
                      label={t("common:Remark")}
                      detected={isEdit}
                      type="textarea"
                      rows="3"
                    />
                  </div>
                </Col>
              </Row>
              <button type="submit" className="d-none" />
            </AvForm>
          </ModalBody>
          <ModalFooter>
            <Check
              resource={RESOURCE}
              permission={isEdit ? permissionType.U : permissionType.C}
            >
              <CustomButton
                type="button"
                onClick={toggle}
                color="secondary"
                data-dismiss="modal"
                className="button-width"
                text={"common:Cancel"}
              />
              <CustomButton
                type="submit"
                color="primary"
                onClick={() => formEl?.current?.submit()}
                className="save-user button-width"
                text={"common:Save"}
                isEdit
              />
            </Check>
          </ModalFooter>
        </React.Fragment>
      ) : (
        <></>
      )}
    </CustomModal>
  )
}

export default withTranslation(["deliveryPage", "common"])(DeliveryModal)
