import { AvForm } from "availity-reactstrap-validation"
import { ConfirmModal2, CustomButton, CustomNav, CustomNavLink, FormSwitch, WarningModal } from "components/Common"
import FormSwitchForm from "components/Common/FormSwitchForm"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row } from "reactstrap"
import { getResultPending, updateResultPending } from "store/actions"
import { getNotification, updateNotificationReaded } from "store/users/notification/actions"
import SignalRNotify from "./SignalRNotify"
import { CountTrueNotificationByUserId } from "helpers/app-backend"

let countGlobal = 0;
const TestSentNotification = ({
  t,
  onGetNotification,
  notifications,
  onGetResultPending,
  resultPending,
  onUpdateResultPending,
  onUpdateNotificationReaded
}) => {

  const [isOpen, setIsOpen] = useState(false)
  const [notificationList, setNotificationList] = useState([])
  const [currentTab, setCurrentTab] = useState(1)
  const [count, setCount] = useState(0)
  const [isAllResult, setIsAllResult] = useState(true)
  const [size, setSize] = useState(10)
  let userInfo = localStorage.getItem("userInfo")
  let userInfoObject = JSON.parse(userInfo || "{}")

  useEffect(() => {
    countGlobal = count
  }, [count])

  useEffect(() => {
    getCount()

    // countGlobal = setInterval(() => {
    //   getCount()
    // }, 900000); 
  }, [])

  const markAsRead = (e, data) => {
    e.stopPropagation()
    e.preventDefault()
    if (data.status !== 2) {
      onUpdateNotificationReaded({ id: data.id, isReadAll: false }, () => {
        onGetNotification({
          id: userInfoObject?.sub,
          page: 1, size: 7,
          type: 5
        })
        getCount()
      })
    }
  }

  const getCount = async () => {
    if (userInfoObject.sub) {
      var res = await CountTrueNotificationByUserId({ id: Number(userInfoObject.sub) })
      if (res) {
        setCount(res?.countNotify)
      }
    }
  }

  const updateReadAll = () => {
    onUpdateNotificationReaded({ id: 0, isReadAll: true, userId: userInfoObject?.sub, }, () => {

      onGetNotification({
        id: userInfoObject?.sub,
        page: 1, size: 7,
        type: 5
      })
      getCount()
    })
  }

  useEffect(() => {
    if (isOpen && currentTab === 1) {
      onGetResultPending({ page: 1, size: size, resultFlag: isAllResult ? 0 : 1 })
    }
  }, [isOpen, size])

  useEffect(() => {
    if (isOpen && currentTab === 1) {
      setNotificationList([])
      setSize(10)
      onGetResultPending({ page: 1, size: 10, resultFlag: isAllResult ? 0 : 1 })
    }
  }, [currentTab, isAllResult])

  useEffect(() => {
    if (isOpen && currentTab === 2) {
      setNotificationList([])
      onGetNotification({
        id: userInfoObject.sub,
        page: 1, size: 7,
        type: 5
      })
      getCount()
    }
  }, [isOpen, currentTab])

  useEffect(() => {
    if (resultPending) {
      setNotificationList(resultPending.data)
    }
  }, [resultPending])

  useEffect(() => {
    if (notifications) {
      setNotificationList(notifications)
    }
  }, [notifications])

  const onOpenResultNotification = () => {
    setIsOpen(!isOpen)
  }

  const countTime = (data) => {
    let result = ''
    const date1 = new Date(data)
    const date2 = new Date()
    const diffTime = Math.abs(date2 - date1)
    const diffSeconds = (diffTime / (1000)).toFixed(0)
    const diffMinutes = (diffTime / (1000 * 60)).toFixed(0)
    const diffHours = (diffTime / (1000 * 60 * 60)).toFixed(0)
    const diffDays = (diffTime / (1000 * 60 * 60 * 24)).toFixed(0)
    if (diffDays >= 1) {
      result = `${diffDays} ${t('common:Day')} `
    }
    else if (diffHours >= 1) {
      result = `${diffHours} ${t('common:Hours')} `
    }
    else if (diffMinutes >= 1) {
      result = `${diffMinutes} ${t('common:Minute')} `
    }
    else if (diffSeconds >= 1) {
      result = `${diffSeconds} ${t('common:Second')} `
    }

    return result
  }

  const getContent = (data) => {
    let result = data.template
    if (data.messageContent && data.messageContent !== '') {
      const content = JSON.parse(data.messageContent)
      const key = Object.keys(content)
      key.forEach(item => {
        result = result.replace(`@${item}`, content[item])
      })
    }
    return <div dangerouslySetInnerHTML={{ __html: result }}></div>
  }

  const updateResultPending = (data, type) => {
    onUpdateResultPending({ id: data.id, type, sid: data.sid, testCode: data.testCode }, () => {
      onGetResultPending({ page: 1, size: 10, resultFlag: isAllResult ? 0 : 1 })
      // setWarningCancel(null)
      // setWarningUpdate(null)
    })
  }

  const getColor = (item) => {
    let color = ''
    switch (item.resultFlag) {
      case 0:
        color = 'text-secondary'
        break
      case 1:
        color = 'text-success'
        break
      case 2:
        color = 'text-danger'
        break
    }
    return 'mdi mdi-circle ' + color
  }


  const changeResultNotification = () => {
    return (
      <React.Fragment>
        <SignalRNotify onRefresh={() => {
          setCount(countGlobal + 1)
        }} />
        <Row
          style={{
            overflowY: 'overlay',
            overflowX: 'hidden',
            maxHeight: '400px',
            minHeight: '200px',
            width: '412px'
          }}>
          {notificationList.map((data, index) => (
            <div key={index} style={{ paddingRight: 0, }}>
              {currentTab == 1 &&
                <div className="px-2 pt-2 d-flex" style={{ alignItems: 'center', paddingRight: '10px' }}>
                  <Col sm={11}>
                    <span className="mt-0 mb-1">
                      {`${t("Result Pending Message", {
                        testName: `${data.testName}`,
                        sid: `${data.sid}`,
                      })}`}
                    </span>
                  </Col>
                  <Col sm={1} style={{ textAlign: 'end' }}>
                    <i className={getColor(data)} />
                  </Col>
                </div>}
              {currentTab == 2 &&
                <div className="px-2 pt-2 d-flex" style={{ alignItems: 'center', paddingRight: '10px' }}>
                <Col sm={11}>
                  <span className="mt-0 mb-1">
                    {getContent(data)}
                  </span>
                </Col>
                <Col sm={1} style={{ textAlign: 'end' }} onClick={e => markAsRead(e, data)}>
                {data.status === 2 ? (
                          <i
                            className="bx bx-check"
                            style={{ color: "#4CAF50", fontSize: '20px' }}
                          />
                        ) : (
                          <i
                            className="bx bx-radio-circle-marked"
                            style={{ color: "#2196F3", fontSize: '20px' }}
                          />
                        )}
                </Col>
              </div>}
              <Row className="px-2" style={{ textAlign: 'right' }}>
                <p className="mb-0">
                  {`${countTime(data.createdDate)}`}
                </p>
              </Row>
              {currentTab == 1 && data.resultFlag == 0 && <Row>
                <div className="d-flex px-3" style={{ justifyContent: 'right' }}>
                  <CustomButton
                    isEdit
                    onClick={() => {
                      // setWarningUpdate(data)
                      updateResultPending(data, 2)
                    }}
                  >
                    {t("common:Cancel")}
                  </CustomButton>
                  <CustomButton
                    color="primary"
                    isEdit
                    onClick={() => {
                      updateResultPending(data, 1)
                      // setWarningCancel(data)
                    }}
                  >
                    {t("common:Update")}
                  </CustomButton>
                </div>
              </Row>}
              <Row className="m-2" style={{ borderBottom: "0.1px solid lightgrey" }}></Row>
            </div>
          ))}
        </Row>
      </React.Fragment>

    )
  }

  const viewMore = () => {
    if (currentTab == 1) {
      setSize(prev => prev + 5)
    } else {
      window.open('Notifications?type=5')
    }
  }

  return (
    <>
      
        <div className="btn noti-icon" 
        onClick={onOpenResultNotification}>
        <i className={`bx bx-bell ${count > 0 ? " bx-tada" : ""}`} />
        {count > 0 &&
          <span className="badge bg-danger rounded-pill" style={{ top: '0'}}>{count}</span>
        }
        </div>
        
      <Dropdown
        target={`notification-button`}
        isOpen={isOpen}
        toggle={() => { setIsOpen(false) }}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownMenu
          className="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end"
          style={{ minWidth: "400px" }}
        >
          <div className="pt-3 px-3">
            <Row sm={7} className="align-items-center">
              <Col>
                <h6 className="mb-0"> {t("Notification Result Title")}</h6>
              </Col>
              <Col sm={5}>
                {currentTab == 1 && <AvForm>
                  <FormSwitchForm
                    label={t("Not Resolve")}
                    name='allResult'
                    value={isAllResult}
                    onChange={(value) => {
                      setIsAllResult(value)
                    }}
                    style={{ marginBottom: '0px' }}
                  />
                </AvForm>}
              </Col>
              <Col sm={5}>
                {currentTab == 2 && <AvForm>
                  <a className="small" onClick={updateReadAll}>
                  {t("notificationPage:Readed All")}
                  </a>
                </AvForm>}
              </Col>
            </Row>
          </div>

          <div className="d-flex">
            <CustomNav
              onToggle={value => {
                setCurrentTab(value)
              }}
              defaultTab={currentTab}
              tabs
              className="nav-tabs-custom"
            >
              <CustomNavLink tabId={1}>
                <span className="font-size-12">{t("Result Change")}</span>
              </CustomNavLink>
              <CustomNavLink tabId={2}>
                <span className="font-size-12 ">{t("More Infomation")}</span>
              </CustomNavLink>
            </CustomNav>
          </div>
          {changeResultNotification()}
          {((currentTab == 1 && resultPending?.totalElements > resultPending?.size) || currentTab == 2) && <div className="p-2 dz-message">
            <CustomButton
              onClick={viewMore}
            >
              {t("View More")}
            </CustomButton>
          </div>}
        </DropdownMenu>
      </Dropdown>

      {/* <ConfirmModal2
        modal={!!warningUpdate}
        onToggle={() => {
          setWarningUpdate(prev => null)
        }}
        title={`${t("message:ConfirmRecord")}`}
        message={`${t("message:ConfirmRecord")}`}
        onConfirm={() => {
          updateResultPending(warningUpdate, 1)
        }}
      /> */}

      {/* <ConfirmModal2
        modal={!!warningCancel}
        onToggle={() => {
          setWarningCancel(prev => null)
        }}
        title={`${t("message:ConfirmRecord")}`}
        message={`${t("message:DeleteConfirm")}`}
        onConfirm={() => {
          updateResultPending(warningCancel, 2)
        }}
      /> */}
    </ >
  )
}
TestSentNotification.propTypes = {
  onClick: PropTypes.func,
  t: PropTypes.func,
  isPrint: PropTypes.bool,
  isPreview: PropTypes.bool,
  sid: PropTypes.string,
  subSID: PropTypes.string,
  requestDate: PropTypes.any,
}
TestSentNotification.defaultProps = {
  type: "button",
  onClick: () => { },
}


const mapStateToProps = ({ notification, testResult }) => ({
  notifications: notification.notificationList,
  resultPending: testResult.resultPending
})

const mapDispatchToProps = dispatch => ({
  onGetNotification: payload => dispatch(getNotification(payload)),
  onGetResultPending: payload => dispatch(getResultPending(payload)),
  onUpdateNotificationReaded: (payload, callback) => dispatch(updateNotificationReaded(payload, callback)),
  onUpdateResultPending: (payload, callback) => dispatch(updateResultPending(payload, callback))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)((withTranslation(["resultPage"]))(TestSentNotification))
