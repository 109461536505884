import { AvForm } from "availity-reactstrap-validation"
import {
    CustomAvField, CustomButton, CustomButtonGroup, CustomSelectAsync, DynamicForm, showToast
} from "components/Common"
import { ModuleIds, ProfileId, parameterCode } from "constant/utility"
import {
    getUrlParamByKey, isEmptyArray, isEmptyValues
} from "helpers/utilities"
import { useEffect, useState } from "react"
import { Col, Row } from "reactstrap"
//i18n
import { isArray } from "lodash"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { connect, useDispatch } from "react-redux"
import { withRouter } from "react-router-dom"

import { getAllOrganizations, getFieldsByProfileId, getOrganizationById } from "helpers/app-backend"

import {
    addNewIndividual, getIndividualDetail, getPatientRequestDetail
} from "store/party/individual/actions"

import { CreateRequestPatient, UpdateRequestPatient } from "helpers/app-backend/testRequest_backend_helper"
import {
    addNewPhysician
} from "store/party/physician/actions"
import { handleFields as onHandleFields } from "store/party/profile/actions"
import { getOrganizations } from "store/actions"

const RESOURCE = ModuleIds.TestRequest

const GeneralTab = ({
    history,
    data,
    formEl,
    t,
    onGetIndividualDetail,
    isClone,
    isActive,
    patientId,
    onGetPatientRequestDetail,
    id,
    testRequest,
    onRefreshDetail,
    isEditForm,
    onCancel
}) => {
    const [items, setItems] = useState([])
    const [fields, setFields] = useState([])
    const [patientDetail, setPatientDetail] = useState(data || {})
    const [showData, setShowData] = useState(true)
    const [isSearch, setIsSearch] = useState(true)
    const dispatch = useDispatch()
    const [isEdit, setIsEdit] = useState(false)
    const [isView, setIsView] = useState(true)
    const [currentTab, setCurrentTab] = useState(getUrlParamByKey('tab'))
    useEffect(() => {
        setIsView(!isEditForm)
        setShowData(true)
    }, [isEditForm])

    useEffect(() => {
        if (testRequest.patientId && testRequest.patientId != 'undefined') {
            setShowData(false)
            onGetPatientRequestDetail({
                id: id,
                patientId: testRequest.patientId,
                callback: (res) => {
                    if (res.PIN) {
                        res.PIN = `${res.PIN.toString().slice(0, 3)}-${res.PIN.toString().slice(3, 4)}-${res.PIN.toString().slice(4, 6)}-${res.PIN.toString().slice(6, 12)}`;
                    }
                    setPatientDetail(res)
                },
            })

            setIsEdit(true)
            setIsSearch(false)
        }
        else {
            setIsEdit(false)
            setIsSearch(true);
            fetchFields(3)
            setPatientDetail({})
            setShowData(true)
        }
    }, [testRequest.patientId])

    useEffect(() => {
        fetchFields(3)
    }, [])
    const fetchFields = async id => {
        const res = await getFieldsByProfileId(id)
        if (res.length > 0) setItems(JSON.parse(JSON.stringify(res)))
    }
    useEffect(() => {
        if (!isEmptyArray(items)) handleFields(items, data)
    }, [items])
    useEffect(() => {
        if (patientDetail.GroupCode) {
            getOrganization(patientDetail)
        }
        else if (!isEmptyArray(items)) handleFields(items, patientDetail)
    }, [patientDetail, isActive])

    const getOrganization = async (data) => {
        const res = await getAllOrganizations({ search: data.groupCode })
        patientDetail.ParentId = res.data[0]?.id
        if (!isEmptyArray(items))
            handleFields(items, patientDetail)
    }

    const handleFields = (items, data) => {
        let newItems = JSON.parse(JSON.stringify(items))
        const state = testRequest.stateName;
        const fieldMap = new Map(newItems.map((item, index) => [item.fieldCode.toUpperCase(), index]));
        const genderIndex = fieldMap.get('GENDER');
        const dobIndex = fieldMap.get('DOB');

        // if (RESULT_STATE_Text.Received <= RESULT_STATE_Text[state]) {
        //     if (typeof genderIndex !== -1) {
        //       newItems[genderIndex].readonly = true;
        //     }

        //     if (typeof dobIndex !== -1) {
        //       newItems[dobIndex].readonly = true;
        //     }
        //   }

        if (isArray(newItems))
            newItems = newItems.filter(x => x.status == true)
        if (!isEmptyValues(data) && (isEdit || isClone)) {
            dispatch(
                onHandleFields({
                    fields: newItems,
                    party: data,
                    callback: items => {
                        const res = AddFieldSpecial(items)
                        setFields(res)
                        setShowData(true)
                    },
                })
            )
        } else {
            Object.keys(data).forEach(_key => {
                const idx = _.findIndex(newItems, item => item.fieldCode === _key)
                if (idx >= 0) {
                    newItems[idx].value = data[_key]
                }
            })
            const res = AddFieldSpecial(newItems)
            setFields(res)
        }
    }

    const AddFieldSpecial = (res) => {
        const displayOrder = res.find(x => x.type == 'GROUP')?.displayOrder || 0
        const result = [
            {
                "id": -1,
                "profileId": ProfileId.PATIENT,
                "fieldCode": "Generalinfomation",
                "fieldName": t("General infomation"),
                "type": "GROUP",
                "typeName": "Group",
                "require": false,
                "parameterKey": null,
                "format": "",
                "remark": "Group",
                "tooltip": "Group",
                "readonly": true,
                "initialValue": "",
                "displayOrder": 0,
                "status": true,
                "lookSequence": 0
            },
            ...res
        ]
        const resData = result.map(x => ({ ...x, require: (isActive == false) ? false : x.require })).sort((a, b) => {
            return parseFloat(a.displayOrder) - parseFloat(b.displayOrder);
        })
        return resData
    }

    const Submit = async (values) => {
        values.ProfileId = ProfileId.PATIENT
        if (values.ParentId) {
            const data = await getOrganizationById(values.ParentId)
            values.GroupCode = data.OrganizationCode
        }

        if (isSearch) {
            const res = await CreateRequestPatient(id, values)
            showToast(
                `${t("message:UpdatedMessage", {
                    field: testRequest.sid,
                })}`
            )
            onRefreshDetail && onRefreshDetail()
            setIsView(true)
            setIsSearch(false)
            const newurl = window.location.href.replace("/edit?", "/view?");
            window.history.pushState({ path: newurl }, "", newurl);
        }
        else {
            if (values.PIN) {
                "".replace
                values.PIN = values.PIN.replaceAll("-", "")
            }
            const res = await UpdateRequestPatient(id, patientDetail.id, values)
            showToast(
                `${t("message:UpdatedMessage", {
                    field: testRequest.sid,
                })}`
            )
            setIsView(x => !x)
            onGetPatientRequestDetail({
                id: id,
                patientId: testRequest.patientId,
                callback: (res) => {
                    if (res.PIN) {
                        res.PIN = `${res.PIN.toString().slice(0, 3)}-${res.PIN.toString().slice(3, 4)}-${res.PIN.toString().slice(4, 6)}-${res.PIN.toString().slice(6, 12)}`;
                    }
                    setPatientDetail(res)
                    const newurl = window.location.href.replace("/edit?", "/view?");
                    window.history.pushState({ path: newurl }, "", newurl);
                },
            })
        }

    }
    return (
        <>
            <AvForm
                ref={formEl}
                id="generalForm"
                onValidSubmit={(e, values) => {
                    Submit(values)
                }}
            >
                <CustomAvField name="id" type="hidden" value={data.id || ""} />
                <CustomAvField name="ProfileId" type="hidden" value={3} />
                <Row className="px-2">
                    {isSearch == true &&
                        <Col xs="12" className="pb-2">
                            <CustomSelectAsync
                                name="patientId"
                                value={patientId || data.patientId || ''}
                                valueName={''}
                                code={parameterCode.PATIENTS}
                                label={t("Quick search")}
                                detected={isEdit}
                                onChange={(a, b, c, values) => {
                                    if (values && values.length > 0)
                                        onGetIndividualDetail({
                                            id: values[0].id,
                                            callback: (res) => {
                                                setPatientDetail(res)
                                            },
                                        })
                                    else {
                                        setPatientDetail({})
                                        handleFields(items, data)
                                    }
                                }}
                            />
                        </Col>
                    }
                    <Col xs="12" className="pt-2">
                        {showData == true &&
                            <DynamicForm items={fields} isEdit={isEditForm} needContact={true}
                                resource={RESOURCE}
                                isView={isView && !isSearch}
                                penClick={() => {
                                    const tabVal = getUrlParamByKey('tab');
                                    let tabUrl = ""
                                    if (tabVal != null && tabVal != '') {
                                        tabUrl = "?tab=" + tabVal
                                    }
                                    history.push("edit" + tabUrl)
                                    setIsView(x => !x)
                                }}
                            />
                        }
                    </Col>
                </Row>
                {(!isView || isSearch) &&
                    <Row className="mt-2">
                        <Col>
                            <CustomButtonGroup className="justify-content-center">
                                {!isSearch &&
                                    <CustomButton
                                        text={t("common:Cancel")}
                                        type="button"
                                        onClick={() => {
                                            const newurl = window.location.href.replace("/edit?", "/view?");
                                            window.history.pushState({ path: newurl }, "", newurl);
                                            setIsView(x => !x)
                                            onCancel()
                                        }}
                                        className="button-width"
                                    />
                                }
                                <CustomButton
                                    text={t("common:Save")}
                                    type="submit"
                                    color="primary"
                                    className="save-user button-width"
                                    isEdit
                                />
                            </CustomButtonGroup>
                        </Col>
                    </Row>
                }
                <button type="submit" className="d-none" />
            </AvForm>
        </>


    )
}


GeneralTab.propTypes = {
    onAddNewIndividual: PropTypes.func,
    billingTypes: PropTypes.array,
}

const mapStateToProps = ({ testRequest, organization }) => {
    return {
        billingTypes: testRequest.billingTypes,
        organizations: organization.organizations,
    }
}

const mapDispatchToProps = dispatch => ({
    onGetOrganizations: payload => dispatch(getOrganizations(payload)),
    onAddNewIndividual: (individual, callback) =>
        dispatch(addNewIndividual(individual, callback)),
    onAddNewPhysician: (physician, callback) =>
        dispatch(addNewPhysician(physician, callback)),
    onGetIndividualDetail: individualId => dispatch(getIndividualDetail(individualId)),
    onGetPatientRequestDetail: individualId => dispatch(getPatientRequestDetail(individualId)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["testRequestPage", "message"])(GeneralTab)))
