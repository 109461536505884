import classnames from "classnames"
import { Fragment, useEffect, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap"
import {
  getRequestSampleTrackingList,
  setSampleTrackingSearchQuery,
} from "store/actions"
import CustomSearchBar from "./SearchBar"
import TabAllTest from "./TabAllTest"
import TabResultTest from "./TabResultTest"
import TabSampledTest from "./TabSampledTest"

const TabTestInternal = ({
  requestSampleTrackings,
  onGetRequestSampleTrackings,
  onSetSampleTrackingSearchQuery,
  requestSampleTrackingsCollected,
  requestSampleTrackingsValidated,
  requestSampleTrackingsConfirmed,
  requestSampleTrackingsOnHold,
  requestSampleTrackingsCompleted,
  t
}) => {
  const [searchText, setSearchText] = useState("")
  const [activeTab1, setactiveTab1] = useState("1")

  const toggleTab = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }

  const onFetchRequestSampleTrackings = () => {
    onGetRequestSampleTrackings({ size: 0 })
  }

  const onFetchRequestSampleTrackingsCollected = () => {
    onGetRequestSampleTrackings({ state: 3, size: 0 })
  }

  const onFetchRequestSampleTrackingsOnHold = () => {
    onGetRequestSampleTrackings({ state: 8, size: 0 })
  }

  const onFetchRequestSampleTrackingsCompleted = () => {
    onGetRequestSampleTrackings({ state: 90, size: 0 })
  }

  useEffect(() => {
    onFetchRequestSampleTrackings(),
      onFetchRequestSampleTrackingsCollected(),
      onFetchRequestSampleTrackingsOnHold(),
      onFetchRequestSampleTrackingsCompleted()
  }, [])

  return (
    <Fragment>
      <div className="d-flex flex-wrap flex-row">
        <div className="" style={{ width: "100%" }}>
          <CustomSearchBar
            placeholder={t("Search(SID, PID, Patient Name)")}
            searchText={searchText}
            width={"100%"}
            onSearch={e => {
              setSearchText(e.target.value)
            }}
            onClear={() => {
              setSearchText("")
              onGetRequestSampleTrackings({ search: "", size: 0 })
              onGetRequestSampleTrackings({ state: 3, search: "", size: 0 })
              onGetRequestSampleTrackings({ state: 8, search: "", size: 0 })
              onGetRequestSampleTrackings({ state: 90, search: "", size: 0 })
            }}
            onSubmit={() => {
              onGetRequestSampleTrackings({ search: searchText, size: 0 })
              onGetRequestSampleTrackings({ state: 3, search: searchText, size: 0 })
              onGetRequestSampleTrackings({ state: 8, search: searchText, size: 0 })
              onGetRequestSampleTrackings({ state: 90, search: searchText, size: 0 })
            }}
          />
        </div>
        <Nav
          pills
          className="navtab-bg nav-justified"
          style={{
            border: "1px solid #ccc",
            borderRadius: 6,
            width: "100%",
            marginTop: 10,
          }}
        >
          <NavItem>
            <NavLink
              style={{
                cursor: "pointer",
                color: activeTab1 === "1" ? "#fff" : "#ccc",
              }}
              className={classnames({
                active: activeTab1 === "1",
              })}
              onClick={() => {
                toggleTab("1")
              }}
            >
              {t("ALL")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{
                cursor: "pointer",
                color: activeTab1 === "2" ? "#fff" : "#ccc",
              }}
              className={classnames({
                active: activeTab1 === "2",
              })}
              onClick={() => {
                toggleTab("2")
              }}
            >
              Đã lấy mẫu
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{
                cursor: "pointer",
                color: activeTab1 === "3" ? "#fff" : "#ccc",
              }}
              className={classnames({
                active: activeTab1 === "3",
              })}
              onClick={() => {
                toggleTab("3")
              }}
            >
              Có kết quả
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{
                cursor: "pointer",
                color: activeTab1 === "4" ? "#fff" : "#ccc",
              }}
              className={classnames({
                active: activeTab1 === "4",
              })}
              onClick={() => {
                toggleTab("4")
              }}
            >
              Đã duyệt
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent
          activeTab={activeTab1}
          className="p-1 text-muted"
          style={{ width: "100%" }}
        >
          <TabPane tabId="1">
            <TabAllTest data={requestSampleTrackings} />
          </TabPane>
          <TabPane tabId="2">
            <TabSampledTest data={requestSampleTrackingsCollected} />
          </TabPane>
          <TabPane tabId="3">
            <TabResultTest data={requestSampleTrackingsCompleted} />
          </TabPane>
          <TabPane tabId="4">
            <TabResultTest data={requestSampleTrackingsValidated} />
            {/* <TabPendingTest data={requestSampleTrackingsOnHold} /> */}
          </TabPane>
          {/* <TabPane tabId="4">
            <TabPendingTest data={requestSampleTrackingsValidated} />
          </TabPane> */}
        </TabContent>
      </div>
    </Fragment>
  )
}

const mapStateToProps = ({ sampleTracking }) => ({
  requestSampleTrackings: sampleTracking.requestSampleTrackings,
  requestSampleTrackingsCollected:
    sampleTracking.requestSampleTrackingsCollected,
  requestSampleTrackingsValidated: sampleTracking.requestSampleTrackingsValidated,
  requestSampleTrackingsConfirmed: sampleTracking.requestSampleTrackingsConfirmed,
  requestSampleTrackingsOnHold: sampleTracking.requestSampleTrackingsOnHold,
  requestSampleTrackingsCompleted: sampleTracking.requestSampleTrackingsCompleted,
})

const mapDispatchToProps = dispatch => ({
  onGetRequestSampleTrackings: payload =>
    dispatch(getRequestSampleTrackingList(payload)),
  onSetSampleTrackingSearchQuery: payload =>
    setSampleTrackingSearchQuery(payload),
})

export default connect(mapStateToProps, mapDispatchToProps)
(withRouter(withTranslation(["testConfig"])(TabTestInternal)))

