import { useEffect, useState } from "react"
import CustomAvField from "./CustomAvField"
import { getInvalidMessageI18n } from "helpers/utilities"
import { Label } from "reactstrap"
import { withTranslation } from "react-i18next"

const CustomTestAvField = ({ value, t, isEdit }) => {
    const [testValue, setTestValue] = useState('')

    useEffect(() => {
        if (value) {
            setTestValue(value)
        }
    }, [value])

    const first = testValue.indexOf('[')
    const last = testValue.lastIndexOf(']')
    let customName = ''
    if (first !== -1 && last !== -1) {
        customName = testValue.slice(first + 1, last)
    }

    const onTestNameChange = (value) => {
        setTestValue(value)
    }
    return (
        <div>
            {(testValue || !isEdit) && <CustomAvField
                name="testName"
                type="text"
                className="mb-0"
                style={{ margin: '0px !important' }}
                required
                errorMessage={getInvalidMessageI18n(t, "Test Name")}
                validate={{
                    required: { value: true },
                    maxLength: { value: 200 }
                }}
                value={testValue}
                label={t("Test Name")}
                detected={isEdit}
                placeholder='VD: SARS [Labnovation]'
                onChange={(value) => {
                    onTestNameChange(value)
                }}
                helpMessage={customName !== '' && <Label style={{ color: 'green', paddingLeft: '3px', paddingTop: '2px', margin: '0px', fontSize: 'inherit' }} >
                    {`${t("Custom Name")}: ${customName}`}
                </Label>}
            />}
        </div>
    )
}

export default (withTranslation(["testPage", "common"])(CustomTestAvField))