import { showToast } from "components/Common"
import { PartyProfile, ProfileId, TestRequest_Test_Type } from "constant"
import {
  CreateRequestPatient,
  UpdateRequestPatient
} from "helpers/app-backend/testRequest_backend_helper"
import { convertDateFormat } from "helpers/utilities"
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import {
  createNewRequest,
  deleteRequests,
  emptyTestRequestDetail,
  getIndividualDetail,
  getTestrequestGeneralDetail,
  getTestrequestSamplesDetail,
  getTestrequestTestsDetail,
  updateRequestGeneral,
  updateRequestSample,
  updateRequestTests,
} from "store/actions"
import GeneralInfo from "./GeneralInfo"

const init = {
  requestDate: new Date(),
  requestid: "",
  alternateId: "",
  patientId: "",
  medicalId: "",
  fullName: "",
  serviceType: 3,
  dob: new Date(),
  physicianId: 0,
  gender: "",
  departmentId: "",
  phone: "",
  diagnosis: "",
  address: "",
  resultTime: "",
  email: "",
  remark: "",
}

const PatientFrame = forwardRef((
  {
    t,
    formEl,
    onCreateNewRequest,
    testRequestTests,
    testRequestSamples,
    onUpdateRequestGeneral,
    patientVisit,
    onUpdateRequestSample,
    onUpdateRequestTests,
    fetchGetTestrequestGeneralDetail,
    fetchGetTestrequestTestsDetail,
    fetchGetTestrequestSamplesDetail,
    onDeleteRequests,
    onEmptyTestRequestDetail,
    patientHis,
    generalSetting
  },
  ref
) => {
  const formRef = formEl ? formEl : useRef()
  const [data, setData] = useState(init)
  const [showSpin, setShowSpin] = useState(false)
  const [changeSample, setChangeSample] = useState(false)
  const [changeTest, setChangeTest] = useState(false)

  useImperativeHandle(ref, () => ({
    ResetData: () => {
      setData({ requestDate: new Date(), dob: new Date() })
      setTab(1)
      onEmptyTestRequestDetail()
    },
    submit: () => {
      formRef.current?.submit()
    },
    onDelete: () => {
      handleSubmitDelete()
    },
  }))

  useEffect(() => {
    if (
      data.requestid &&
      data.requestid != "" &&
      patientVisit.sid != undefined
    ) {
      let sid = testRequestSamples || []
      let sidText =
        sid.length > 0 ? ` (${sid.map(x => x.sid).join(", ")})` : ""
      setData({
        ...data,
        requestLabel: patientVisit.sid + sidText,
      })
    }
  }, [testRequestTests])

  useEffect(() => {
    if (
      data.requestid &&
      data.requestid != "" &&
      patientVisit.sid != undefined
    ) {
      let sid = testRequestSamples || []
      let sidText =
        sid.length > 0 ? ` (${sid.map(x => x.sid).join(", ")})` : ""
      setData({
        // ...data,
        ...patientVisit,
        requestLabel: patientVisit.sid + sidText,
      })
    }
  }, [patientVisit])

  const handleValidRequestSubmit = async values => {
    setShowSpin(true)
    if (!values.ProfileId) {
      values.ProfileId = PartyProfile.DEFAULT_PATIENT
    }
    values.resultTime = isNaN(values.resultTime)
      ? null
      : values.resultTime == ""
        ? null
        : values.resultTime
    let tests = []
    let profiles = []
    testRequestTests.forEach(item => {
      //push test đơn
      if (item.type === TestRequest_Test_Type.TEST) {
        tests.push({
          testCode: item.code,
          testCategory: item.category,
          sampleType: item.sampleType,
          profileCode: item.parentCode,
          sID: item?.sid || 0,
          subSID: item?.subSID,
        })
      } else if (item.type === TestRequest_Test_Type.PROFILE_GROUP) {
        profiles.push({
          profileCode: item.code,
          profileCategory: item.category,
        })

        if (item.children) {
          item.children
            .filter(x => x.type === TestRequest_Test_Type.TEST)
            .map(test => {
              tests.push({
                testCode: test.code,
                testCategory: test.category,
                sampleType: test.sampleType,
                profileCode: test.parentCode,
                sID: test?.sid || 0,
                subSID: test?.subSID
              })
            })
          item.children
            .filter(x => x.type === TestRequest_Test_Type.PROFILE)
            .map(profile => {
              profiles.push({
                profileCode: profile.code,
                profileCategory: profile.category,
              })
              if (profile.children) {
                profile.children.map(test => {
                  tests.push({
                    testCode: test.code,
                    testCategory: test.category,
                    sampleType: test.sampleType,
                    profileCode: test.parentCode,
                    sID: test?.sid || 0,
                    subSID: test?.subSID
                  })
                })
              }
            })
        }
      } else if (item.type === TestRequest_Test_Type.PROFILE) {
        profiles.push({
          profileCode: item.code,
          profileCategory: item.category,
        })
        if (item.children) {
          item.children.map(test => {
            tests.push({
              testCode: test.code,
              testCategory: test.category,
              sampleType: test.sampleType,
              profileCode: test.parentCode,
              sID: test?.sid || 0,
              subSID: test?.subSID
            })
          })
        }
      }
    })

    values.tests = tests
    values.profiles = profiles
    if (!values.patientId) values.patientId = ""
    if (values.requestid && values.requestid != "") {
      values.id = values.requestid
      onUpdateRequestGeneral({
        request: values,
        reset: false,
        showMSG: false,
        callback: id => {
          // if (config.autoSave == true) {
          //   // setShowSpin(true)
          // } else {
          showToast(
            `${t("message:UpdatedMessage", {
              field: `${t(
                "testRequestPage:Request"
              )} <span class='text-decoration-underline fw-bold'>${convertDateFormat(
                values["requestDate"],
                "YYYY"
              )}${convertDateFormat(values["requestDate"], "MMDD")}-R${id
                .toString()
                .padStart(5, "0")}</span>`,
            })}`
          )
          // }
          if (!(patientVisit.patientId == "" && values.patientId == "")) {
            submitPatientInfo({ ...values, ...data })
          }
          if (changeSample == true) {
            setChangeSample(false)
            handleSaveRequestSampleSubmit(patientVisit.id)
          } else {
            if (changeTest == true) {
              setChangeTest(false)
              handleSaveRequestTestSubmit(patientVisit.id)
            }
          }
        },
      })
    } else {
      onCreateNewRequest({
        request: values,
        reset: false,
        callback: val => {
          values.id = val.id
          setData({
            ...data,
            requestid: val.id,
            requestLabel: val.requestLabel,
          })
          if (!(patientVisit.patientId == "" && values.patientId == "")) {
            if (values.patientId != "") {
              submitPatientInfo({ ...values, ...data })
            } else {
              fetchGetTestrequestGeneralDetail(values.id)
              fetchGetTestrequestTestsDetail(values.id)
              fetchGetTestrequestSamplesDetail(values.id)
            }
          } else {
            fetchGetTestrequestGeneralDetail(values.id)
            fetchGetTestrequestTestsDetail(values.id)
            fetchGetTestrequestSamplesDetail(values.id)
          }
        },
      })
    }
  }

  const submitPatientInfo = async values => {
    setShowSpin(true)
    values.ProfileId = ProfileId.PATIENT
    if (!values.familyName) {
      values.familyName = values.fullName
    }
    const req = {
      PatientId: values.patientId,
      companyId: values.companyId,
      FullName: values.fullName,
      FamilyName: values.familyName,
      GivenName: values.givenName,
      DOB: values.dob,
      Gender: values.gender,
      PIN: values.pin || "",
      Contact: {
        PhoneNumber: values.phone,
        EmailAddress: values.email,
      },
      Address: {
        Address: values.address,
      },
      ProfileId: ProfileId.PATIENT,
      InsuranceNumber: values.insuranceNumber,
      InPatient: values.inPatient,
      Emergency: values.emergency,
    }
    if (patientVisit.requestPatientId && patientVisit.requestPatientId != 0) {
      const res = await UpdateRequestPatient(
        values.requestid,
        patientVisit.requestPatientId,
        req
      )
    } else {
      if (values.patientId != "") await CreateRequestPatient(values.id, req)
      fetchGetTestrequestGeneralDetail(values.id)
      fetchGetTestrequestTestsDetail(values.id)
      fetchGetTestrequestSamplesDetail(values.id)
    }
  }

  const handleSaveRequestSampleSubmit = (id, samplesNew) => {
    setShowSpin(true)
    let values = {}
    values.id = id
    let samples = []
    let newData = samplesNew ? samplesNew : testRequestSamples
    newData.forEach(item => {
      samples.push({
        sampleType: item.sampleType,
        sampleColor: item.sampleColor || "",
        numberOfLabels: item.numberOfLabels ? `${item.numberOfLabels}` : 0,
        collectionTime: (item.isClear === false || item.isCollected === false) ? null : (item.collectionTime ? `${item.collectionTime}` : null),
        quality: item.quality ? `${item.quality}` : "",
        collectorUserId: (item.isClear === false || item.isCollected === false) ? null : (item.collectorUserId ? `${item.collectorUserId}` : null),
        receivedTime: (item.isClear === false || item.isReceived === false) ? null : (item.receivedTime ? `${item.receivedTime}` : null),
        receiverUserId: (item.isClear === false || item.isReceived === false) ? null : (item.receiverUserId ? `${item.receiverUserId}` : null),
        sID: item.sid ? `${item.sid}` : 0,
        subSID: item?.subSID
      })
    })
    if (testRequestSamples.length > 0) {
      values.isCollected = testRequestSamples[0].isCollected || false
      values.isReceived = testRequestSamples[0].isReceived || false
    }

    values.samples = samples
    onUpdateRequestSample({
      request: values,
      showMSG: false,
      callback: () => {
        if (config.autoSave != true) {
          showToast(
            `${t("message:UpdatedMessage", {
              field: `${t("testRequestPage:Sample Info")}`,
            })}`
          )
        } else {
          // setShowSpin(true)
        }
        if (changeTest == true) {
          setChangeTest(false)
          handleSaveRequestTestSubmit(patientVisit.id)
        }
      },
    })
  }

  const handleSaveRequestTestSubmit = (id, result = {}) => {
    setShowSpin(true)
    let values = {}
    values.id = id
    let tests = []
    let profiles = []
    let newData = result.tests ? result.tests : testRequestTests
    newData.forEach(item => {
      //push test đơn
      if (item.type === TestRequest_Test_Type.TEST) {
        tests.push({
          testCode: item.code,
          testCategory: item.category,
          sampleType: item.sampleType,
          profileCode: item.parentCode,
          sID: item?.sid || 0,
          subID: item?.subID || '',
          subSID: item?.subSID
        })
      } else if (item.type === TestRequest_Test_Type.PROFILE_GROUP) {
        profiles.push({
          profileCode: item.code,
          profileCategory: item.category,
        })

        if (item.children) {
          item.children
            .filter(x => x.type === TestRequest_Test_Type.TEST)
            .map(test => {
              tests.push({
                testCode: test.code,
                testCategory: test.category,
                sampleType: test.sampleType,
                profileCode: test.parentCode,
                sID: test?.sid || 0,
                subID: test?.subID || '',
                subSID: test?.subSID
              })
            })
          item.children
            .filter(x => x.type === TestRequest_Test_Type.PROFILE)
            .map(profile => {
              profiles.push({
                profileCode: profile.code,
                profileCategory: profile.category,
              })
              if (profile.children) {
                profile.children.map(test => {
                  tests.push({
                    testCode: test.code,
                    testCategory: test.category,
                    sampleType: test.sampleType,
                    profileCode: test.parentCode,
                    sID: test?.sid || 0,
                    subID: test?.subID || '',
                    subSID: test?.subSID
                  })
                })
              }
            })
        }
      } else if (item.type === TestRequest_Test_Type.PROFILE) {
        profiles.push({
          profileCode: item.code,
          profileCategory: item.category,
        })
        if (item.children) {
          item.children.map(test => {
            tests.push({
              testCode: test.code,
              testCategory: test.category,
              sampleType: test.sampleType,
              profileCode: test.parentCode,
              sID: test?.sid || 0,
              subID: test?.subID || '',
              subSID: test?.subSID
            })
          })
        }
      }
    })
    values.tests = tests
    values.profiles = profiles

    onUpdateRequestTests({
      request: values,
      showMSG: false,
      callback: () => {
        fetchGetTestrequestTestsDetail(values.id)
        fetchGetTestrequestSamplesDetail(values.id)
        if (config.autoSave != true) {
          showToast(
            `${t("message:UpdatedMessage", {
              field: `${t("testRequestPage:Profile/Test")}`,
            })}`
          )
        } else {
          // setShowSpin(true)
        }
      },
    })
  }

  const handleSubmitDelete = () => {
    if (patientVisit.id != undefined && patientVisit.id != "") {
      onDeleteRequests({
        requests: [patientVisit],
        callback: () => {
          setData({ requestDate: new Date(), dob: new Date() })
          onEmptyTestRequestDetail()
        },
      })
    }
  }

  let patientVisitConfig = localStorage.getItem("patientVisitConfig")
  const [config, setConfig] = useState({})
  useEffect(() => {
    setConfig(patientVisitConfig ? JSON.parse(patientVisitConfig) : {})
  }, [localStorage.getItem("patientVisitConfig")])

  useEffect(() => {
    if (showSpin == true) {
      setTimeout(() => {
        setShowSpin(false)
      }, 500)
    }
  }, [showSpin])


  return (
    <>
      <GeneralInfo
        formEl={formRef}
        dataRequest={patientHis}
        onChangeData={(val, isEmptyPatient) => {
          if (isEmptyPatient == true) {
            val.patientId = ""
            val.patient = ""
            val.dob = ""
            val.fullName = ""
            val.gender = ""
            val.phone = ""
            val.address = ""
            val.pin = ""
          }
          setData(val)
        }}
        onValidSubmit={vals => {
          if (vals.requestid && vals.requestid != "") {
            data.requestid = vals.requestid
          }
          handleValidRequestSubmit(data)
        }}
      />
    </>
  )
})

const mapStateToProps = ({ testRequest }) => {
  return {
    billingTypes: testRequest.billingTypes,
    testRequestTests: testRequest.testRequest.tests || [],
    testRequestSamples: testRequest.testRequest.samples || [],
    patientVisit: testRequest.patientVisit || {}
  }
}

const mapDispatchToProps = dispatch => ({
  onGetIndividualDetail: individualId =>
    dispatch(getIndividualDetail(individualId)),
  onCreateNewRequest: ({ request, history, callback, reset }) =>
    dispatch(createNewRequest({ request, history, callback, reset })),
  onUpdateRequestGeneral: ({ request, callback, showMSG }) =>
    dispatch(updateRequestGeneral({ request, callback, showMSG })),
  onUpdateRequestSample: ({ request, callback, showMSG }) =>
    dispatch(updateRequestSample({ request, callback, showMSG })),
  onUpdateRequestTests: ({ request, callback, showMSG }) =>
    dispatch(updateRequestTests({ request, callback, showMSG })),
  fetchGetTestrequestGeneralDetail: requestId =>
    dispatch(getTestrequestGeneralDetail(requestId)),
  fetchGetTestrequestTestsDetail: requestId =>
    dispatch(getTestrequestTestsDetail(requestId)),
  fetchGetTestrequestSamplesDetail: requestId =>
    dispatch(getTestrequestSamplesDetail(requestId)),
  onDeleteRequests: ({ requests, callback }) =>
    dispatch(deleteRequests({ requests, callback })),
  onEmptyTestRequestDetail: payload =>
    dispatch(emptyTestRequestDetail(payload))
})

PatientFrame.displayName = "PatientFrame"
export default withTranslation(["partyPage", "message", "common"], { withRef: true })(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PatientFrame))
