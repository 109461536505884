import { LabelAndValue } from "components/Common"
import BadgeLabelAndValue from "components/Common/BadgeLabelAndValue"
import { DeliveryState, ModuleIds } from "constant"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Col, Row } from "reactstrap"
import HeaderButtons from "./HeaderButtons"
import TitleAndInformation from "./TitleAndInformation"

const RESOURCE = ModuleIds.DeliveryManagement

const TitleAndInfo = ({
  onEdit,
  onClone,
  onDelete,
  onConfirm,
  onCreateReport,
  data,
  total,
  isDisableEdit,
  isDisableClone,
  onPreviewReport,
  resourceReport,
  onPreviewClick,
  onCreateClick,
  onExportClick,
  onPrintClick,
  onReportSettingClick,
  onDeleteClick,
  t,
  ...rest
}) => {
  const { deliverySamples, deliveryTests } = useSelector(state => ({
    deliverySamples: state.sampleDelivery.deliverySamples || [],
    deliveryTests: state.sampleDelivery.deliveryTests || [],
  }))
  return (
    <TitleAndInformation
      onEdit={onEdit}
      onClone={onClone}
      onDelete={onDelete}
      isDisableEdit={isDisableEdit}
      isDisableClone={isDisableClone}
      resource={RESOURCE}
      external
      buttons={() => (
        <>
          <HeaderButtons
            resource={RESOURCE}
            onConfirm={onConfirm}
            onPreviewReport={onPreviewReport}
            onCreateReport={onCreateReport}
            deliveryDate={data.deliveryDate}
            model={data}
            resourceReport={resourceReport}
            onCreateClick={onCreateClick}
            onPrintClick={onPrintClick}
            onReportSettingClick={onReportSettingClick}
            onPreviewClick={onPreviewClick}
            onExportClick={onExportClick}
            onDeleteClick={onDeleteClick}
          />
        </>
      )}
      {...rest}
    >
      <Row>
        <Col xs="12">
          <Row>
            <Col md="auto" className="pr-6">
              <LabelAndValue label={t("ID")} value={data.id} />
            </Col>
            <Col md="auto" className="pr-6">
              <LabelAndValue label={t("common:Type")} value={data.typeName || ""} />
            </Col>
            <Col md="auto" className="pr-6">
              <LabelAndValue
                label={t("Sample Count")}
                value={deliverySamples.length}
                className={"fw-bold"}
              />
            </Col>
            <Col md="auto" className="pr-6">
              <LabelAndValue
                label={t("Test Count")}
                value={data.testCancelCount + data?.profileCancelCount || 0}
                className={"fw-bold"}
              />
            </Col>
            <Col md="auto">
              <BadgeLabelAndValue
                label={t("State")}
                type={DeliveryState[data.state]}
                value={t(DeliveryState[data.state])}
                className={"fw-bold"}
              />
            </Col>
            <Col md="auto" className="d-flex justify-content-end ms-auto">
              <i className="bx bx-test-tube" style={{fontSize : '48px', color: '#adb5bd'}}></i>
            </Col>
          </Row>
        </Col>
      </Row >
    </TitleAndInformation >
  )
}

TitleAndInfo.propTypes = {
  onEdit: PropTypes.func,
  onClone: PropTypes.func,
  onDelete: PropTypes.func,
  onConfirm: PropTypes.func,
  onCreateReport: PropTypes.func,
  isDisableEdit: PropTypes.bool,
  isDisableClone: PropTypes.bool,
  data: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation(["deliveryPage", "common"])(TitleAndInfo)
