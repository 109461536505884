import PropTypes from "prop-types";
//i18n
import { CustomBreadcrumb, CustomNav, CustomNavLink, PageContent } from "components/Common";
import { ModuleIds } from "constant";
import { getUrlParamByKey, insertUrlParam } from "helpers/utilities";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Card, CardBody, Col, Row, TabContent, TabPane } from "reactstrap";
import SampleDelivery from "./SampleDelivery/SampleDelivery.js";
import CancelationRequest from "./CancelationRequest/CancelationRequest.js";
import CorrectionRequest from "./CorrectionRequest/CorrectionRequest.js";

const RESOURCE = ModuleIds.DeliveryManagement

const DeliveryManagementContainer = ({ t }) => {
    const titleTag = t(`Delivery Management`)
    const [currentTab, setCurrentTab] = useState(getUrlParamByKey('tab') || '1')
    useEffect(() => {
        setCurrentTab(`${getUrlParamByKey('tab')}`)
    }, [])
    useEffect(() => {
        if (getUrlParamByKey('tab') != currentTab) {
            insertUrlParam({ tab: currentTab })
        }
    }, [currentTab])
    useEffect(() => {
        setCurrentTab(getUrlParamByKey('tab') || '1')
    }, [window.location.search])
    return (
        <PageContent title={titleTag}>
            <CustomBreadcrumb
                breadcrumbItem={t("Delivery Management")}
                resource={RESOURCE}
            />
            <Row>
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <Row>
                                <CustomNav
                                    onToggle={e => {
                                        setCurrentTab(e);
                                    }}
                                    defaultTab={currentTab}
                                    tabs
                                    className="nav-tabs-custom"
                                    tabContents={customActiveTab => (
                                        <TabContent
                                            activeTab={customActiveTab}
                                            className="p-3 text-muted"
                                        >
                                            <TabPane tabId="1">
                                                {currentTab == '1' &&
                                                    <SampleDelivery />
                                                }
                                            </TabPane>
                                            <TabPane tabId="2">
                                                {currentTab == '2' &&
                                                    <CorrectionRequest />
                                                }
                                            </TabPane>
                                            <TabPane tabId="3">
                                                {currentTab == '3' &&
                                                    <CancelationRequest />
                                                }
                                            </TabPane>
                                        </TabContent>
                                    )}
                                >
                                    <CustomNavLink tabId="1">
                                        <span>{t("Sample Delivery Management")}</span>
                                    </CustomNavLink>
                                    <CustomNavLink tabId="2">
                                        <span>{t("Correction Request Management")}</span>
                                    </CustomNavLink>
                                    <CustomNavLink tabId="3">
                                        <span>{t("Cancelation Request Management")}</span>
                                    </CustomNavLink>

                                </CustomNav>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </PageContent>
    );
}

DeliveryManagementContainer.propTypes = {
    t: PropTypes.any,
}

export default withTranslation(["deliveryPage"])(DeliveryManagementContainer)