import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import { CustomBootstrapTable } from "components/Common"
import { convertDateFormat, indexCalculator } from "helpers/utilities"

import BadgeLabel from "components/Common/BadgeLabel"
import { ModuleIds } from "constant"
import { withTranslation } from "react-i18next"
import ReactTooltip from "react-tooltip"
import FilterForm from "./FilterForm"

const RESOURCE = ModuleIds.AnalyticalProcess

const QCResultTable = ({
    onSelect,
    onSelectAll,
    tests,
    onSearch,
    onSort,
    onRefresh,
    onPageChange,
    paging,
    onSizePerPageChange,
    onSubmitFilter,
    loading,
    isSelected,
    t,
    updatedTime,
}) => {
    const [data, setData] = useState([])
    const [model, setModel] = useState({
        search: "",
        testCode: "",
        instrument: "",
        requestDate: convertDateFormat(new Date(), "YYYY-MM-DD"),
    })
    const searchQuery = useSelector(state => state.qcResult.searchQuery)
    const defaultSortValue = { dataField: "requestDate", order: "desc" }
    const onResetHandler = () => {
        const initModel = {
            search: "",
            testCode: "",
            instrument: "",
            requestDate: convertDateFormat(new Date(), "YYYY-MM-DD"),
        }
        setModel(initModel)
    }

    useEffect(() => {
        setModel(searchQuery)
    }, [searchQuery])

    useEffect(() => {
        setData(tests.map(x => ({
            ...x, inRequestId: x.id, id: `${x.id}${x.instrumentTestCode}`
        })))
    }, [tests])

    const columns = [
        {
            dataField: "id",
            text: "",
            hidden: true,
            formatter: (cellContent, test, index) => {
                return cellContent
            },
        },
        {
            dataField: "SEQ",
            text: "#",
            headerStyle: { width: "50px" },
            formatter: (cellContent, test, index) => {
                return indexCalculator(paging.page, paging.size, index + 1)
            },
        },
        {
            dataField: "requestDate",
            text: t("Date"),
            sort: true,
            style: { color: "#556ee6" },
            formatter: (cellContent, test, index) => {
                return (
                    convertDateFormat(cellContent, "YYYY-MM-DD")
                )
            },
        },
        {
            dataField: "qcsid",
            text: t("SID"),
            sort: true,
            style: { color: "#556ee6", width: "250px" },
            formatter: (cellContent, test, index) => {
                return (
                    <span className="sid-custom">{cellContent}</span>
                )
            },
        },
        {
            dataField: "testCode",
            text: t("Test Code"),
        },
        {
            dataField: "instrumentTestCode",
            text: t("Ins Code"),
            sort: true,
            // style: { color: "#556ee6" },
            formatter: (cellContent, test, index) => {
                return (
                    <span>{cellContent}</span>
                )
            },
        },
        {
            dataField: "result",
            text: t("Result"),
            formatter: (cellContent, item, index) => {
                return <b>{item.resultText ? item.resultText : cellContent}</b>
            },
        },
        {
            dataField: "unit",
            text: t("Unit"),
            formatter: (cellContent, item, index) => {
                return <b>{item?.unit ? cellContent : "--"}</b>
            },
        },
        {
            dataField: "instrumentId",
            text: t("Ins Id"),
            sort: true,
            style: { fontWeight: 'bold' },
            formatter: (cellContent, test, index) => {
                return <>
                    {test.instrumentTestCode ? (
                        <span data-tip data-for={test.instrumentTestCode}>
                            {cellContent}
                        </span>
                    ) : "---"}
                    {test.instrumentTestCode && (
                        <ReactTooltip id={test.instrumentTestCode} place="top" effect="solid">
                            <span>{test.instrumentName}</span>
                        </ReactTooltip>
                    )}
                </>
            },
        },
        {
            dataField: "createdDate",
            text: t("Result Time"),
            formatter: (cellContent, item, index) => convertDateFormat(cellContent),
        },
        // {
        //     dataField: "status",
        //     text: t("Status"),
        //     style: { minWidth: "85px", display: "flex", justifyContent: "center" },
        //     align: "center",
        //     headerAlign: "center",
        //     formatter: (cellContent, test, index) => {
        //         return <>
        //             <BadgeLabel
        //                 value={cellContent}
        //                 type={cellContent}
        //                 className="badge-width"
        //                 dataFor={cellContent}
        //             />
        //             <ReactTooltip id={cellContent} place="top" effect="solid">
        //                 <span>{(cellContent)}</span>
        //             </ReactTooltip>
        //         </>
        //     },
        // },
    ]

    return (
        <div>
            <CustomBootstrapTable
                columns={columns}
                search
                data={data}
                paging={paging}
                onSelect={onSelect}
                onSelectAll={onSelectAll}
                onSearch={onSearch}
                onSort={onSort}
                isSelected={isSelected}
                onRefresh={onRefresh}
                filterForm={() => <FilterForm model={model} />}
                onReset={onResetHandler}
                onPageChange={onPageChange}
                onSizePerPageChange={onSizePerPageChange}
                onSubmitFilter={onSubmitFilter}
                searchText={model.search}
                loading={loading}
                updatedTime={updatedTime}
                resource={RESOURCE}
                keyField="id"
                defaultSortValue={defaultSortValue}
                showSelectRow={false}
            />
        </div>
    )
}

export default withTranslation(["qcResultPage"])(QCResultTable)
