import { CustomDatePicker, CustomSelect, CustomSelectAsync, CustomSelectButton } from "components/Common"
import { getMyInfo } from "helpers/common_services_helper"
import { Col, Row } from "reactstrap"

//i18n
import {
    GroupTestRequestOption, parameterCode
} from "constant"
import { useCallback, useEffect, useState } from "react"
import { withTranslation } from "react-i18next"

const GroupRequestModal = ({
    onChangeCompany,
    t,
    onChangeHis,
    changeSelectOpntion,
}) => {
    const [myInfo, setMyInfo] = useState({})
    const [companyId, setCompanyId] = useState(myInfo.companyId)
    const [companyName, setCompanyName] = useState(myInfo.companyName)
    const [selectOption, setSelectOption] = useState(GroupTestRequestOption.UploadFile)
    const [selectHis, setSelectHis] = useState('')
    const [requestDate, setRequestDate] = useState(new Date())

    const getMyUserInfo = useCallback(async () => {
        const userInfo = localStorage.getItem("userInfo")
        if (userInfo) {
            const { sub } = JSON.parse(userInfo)
            const res = await getMyInfo(sub)
            setMyInfo(res)
        }
    }, [])

    useEffect(() => {
        changeSelectOpntion && changeSelectOpntion(selectOption)
    }, [selectOption])

    useEffect(() => {
        getMyUserInfo()
    }, [])

    useEffect(() => {
        setCompanyId(myInfo.companyId)
        setCompanyName(myInfo.companyName)
    }, [myInfo])

    useEffect(() => {
        onChangeCompany && onChangeCompany(companyId)
    }, [companyId])


    return (
        <Row className="px-2">
            <Col className="col-6">
                <CustomDatePicker
                    name={"requestDate"}
                    label={t("Request Date")}
                    value={requestDate}
                    onChangeHandler={(e) => {
                        setRequestDate(e.time[0])
                    }}
                    closeOnSelect={true}
                />
            </Col>
            <Col className="col-6">
                <div className="mb-3">
                    <CustomSelectButton
                        type="button"
                        name="his"
                        value={selectHis}
                        valueName={""}
                        code={parameterCode.HISCONNECTOR_LIST}
                        label={t("His Select")}
                        onChange={(e, values) => {
                            setSelectHis(values[0])
                            onChangeHis(values[0])
                        }}
                    />
                </div>
            </Col>
            <Col className="col-6">
                <div className="mb-3">
                    <CustomSelectAsync
                        name="companyId"
                        value={`${companyId || ''}`}
                        valueName={companyName || ""}
                        code={parameterCode.COMPANIES}
                        label={t("companyPage:Company")}
                        group={`${companyId}`}
                        isDependent={true}
                        onChange={(e, values, name) => {
                            setCompanyId(values[0])
                            setCompanyName(name)
                        }}
                    />
                </div>
            </Col>
            <Col className="col-6">
                <div className="mb-3">
                    <CustomSelectButton
                        name="option"
                        type="button"
                        value={selectOption}
                        valueName={""}
                        code={""}
                        label={t("Option")}
                        required={true}
                        options={[
                            { value: GroupTestRequestOption.UploadFile, label: t("Upload File") },
                            { value: GroupTestRequestOption.Company, label: t("Select Company") }
                        ]}
                        onChange={(e, values) => {
                            setSelectOption(values[0])
                        }}
                    />
                </div>
            </Col>
        </Row>
    )
}

export default withTranslation(["deliveryPage", "common", "companyPage"])(GroupRequestModal)
