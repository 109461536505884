import { CustomBreadcrumb, PageContent } from "components/Common"
import { ModuleIds } from "constant"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import QCResult from "./QCResult"

const RESOURCE = ModuleIds.AnalyticalProcess

const QCResultContainer = ({ t }) => {
    const titleTag = `QC Results`
    return (
        <PageContent title={t(titleTag)}>
            {/* Page Title */}
            <CustomBreadcrumb breadcrumbItem={t("QC Results List")} resource={RESOURCE} />
            {/* Page Body */}
            <QCResult />
        </PageContent>
    )
}

QCResultContainer.propTypes = {
    t: PropTypes.any,
}

export default withTranslation(["qcResultPage"])(QCResultContainer)
