import { CustomButton } from "components/Common"
import { SAMPLES_DELIVERY_TYPE } from "constant"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"

const HeaderButtons = ({
  t,
  onDeleteClick,
  onSaveClick,
  disabled,
  deliveryType
}) => {

  return (
    <>
      {deliveryType !== SAMPLES_DELIVERY_TYPE.Cancelation && <CustomButton
        color="success"
        onClick={onSaveClick}
        isEdit
        disabled={disabled}
      >
        {t("common:Save")}
      </CustomButton>}
      <CustomButton
        color="primary"
        onClick={onDeleteClick}
        outline
      >
        {t("common:Delete")}
      </CustomButton>
    </>
  )
}

HeaderButtons.propTypes = {
  resource: PropTypes.string.isRequired,
  onCreateReport: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  model: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
}

export default withTranslation(["deliveryPage", "common"])(HeaderButtons)
