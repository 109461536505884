import { ButtonDropdownGroup, Check, CustomButton } from "components/Common"

import { permissionType } from "constant"
import { DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap"

//i18n
import jsPDF from "jspdf"
import "jspdf-autotable"
import { withTranslation } from "react-i18next"
import RobotoFont from "./Roboto-Regular-normal"

// import excel libs
import { getVisitsByPartyId } from "helpers/app-backend"
import ReactExport from "react-export-excel"
const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

const ButtonsVisitHistory = ({
  resource,
  onClickResult,
  t,
  data,
  partyId,
  paging,
}) => {
  const exportPDF = async () => {
    let fullData = []
    const query = {
      ...{ page: 1, size: paging.dataSize },
      patientId: partyId,
    }
    await getVisitsByPartyId(query).then(res => (fullData = res.data))
    if (fullData.length == 0) return
    const unit = "pt"
    const size = "A2" // Use A1, A2, A3 or A4
    const orientation = "portrait" // portrait or landscape

    const marginLeft = 40
    const doc = new jsPDF(orientation, unit, size)
    var font = RobotoFont

    // add the font to jsPDF
    doc.addFileToVFS("Roboto-Regular-normal.ttf", font)
    doc.addFont("Roboto-Regular-normal.ttf", "Roboto-Regular", "normal")
    doc.setFont("Roboto-Regular")

    doc.setFontSize(15)

    const title = ""
    let strSampleIds = []
    let dataExport = []
    fullData.forEach(item => {
      strSampleIds = []
      item.sampleIds &&
        JSON.parse(item.sampleIds).map(e => strSampleIds.push(e.SID))
      dataExport.push({
        RequestId: item.sid,
        Company: item.companyName,
        RequestDate: new Date(item.requestDate).toLocaleDateString("vi-VN", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }),
        Physician: item.physicianName,
        Department: item.departmentName,
        Sid: strSampleIds.join(","),
        Diagnosis: item.diagnosisName,
      })
    })
    const patientIdInfo = `PID: ${fullData[0].patientId}`
    const patientNameInfo = `Tên: ${fullData[0].patientName}`
    const patientGenderInfo = `GT: ${fullData[0].genderName}`

    const headers = [Object.keys(dataExport[0]).map(col => camelCase(col))]

    const body = dataExport.map(elt => Object.values(elt))
    let content = {
      startY: 100,
      head: headers,
      body: body,
      styles: {
        font: "Roboto-Regular",
        fontStyle: "normal",
      },
    }

    doc.text(patientIdInfo, marginLeft, 40)
    doc.text(patientNameInfo, marginLeft, 60)
    doc.text(patientGenderInfo, marginLeft, 80)
    doc.autoTable(content)
    doc.save("report.pdf")
  }

  const camelCase = str => {
    return str.substring(0, 1).toUpperCase() + str.substring(1)
  }

  const filterColumns = data => {
    // Get column names
    const columns = Object.keys(data[0])

    // Remove by key (firstname)
    const filterColsByKey = columns.filter(c => c !== "firstname")

    // OR use the below line instead of the above if you want to filter by index
    // columns.shift()

    return filterColsByKey // OR return columns
  }

  return (
    <>
      <Check permission={permissionType.R} resource={resource}>
        <CustomButton color="primary" outline onClick={onClickResult}>
          {t("partyPage:Result History")}
        </CustomButton>
      </Check>

      <ButtonDropdownGroup
        onEditClick={() => { }}
        onDeleteClick={() => { }}
        onCloneClick={() => { }}
        direction="down"
      >
        <Check
          permissions={[permissionType.U, permissionType.C]}
          resource={resource}
        >
          <Check permission={permissionType.D} resource={resource}>
            <DropdownToggle
              caret
              color="primary"
              outline
              style={{ border: "1px solid #6660E0" }}
            >
              <i className="mdi mdi-logout"></i>
              <span style={{ marginLeft: 5, marginRight: 5 }}>{t("partyPage:Export Data")}</span>
              <i className="mdi mdi-chevron-down"></i>
            </DropdownToggle>
            <DropdownMenu>
              <ExcelFile
                filename="visit_history"
                element={<DropdownItem>Excel</DropdownItem>}
              >
                <ExcelSheet data={data} name="Test">
                  {data?.length > 0 &&
                    filterColumns(data).map((col, _idx) => {
                      return (
                        <ExcelColumn
                          key={_idx}
                          label={camelCase(col)}
                          value={col}
                        />
                      )
                    })}
                </ExcelSheet>
              </ExcelFile>
              <DropdownItem onClick={exportPDF}>{t("PDF")}</DropdownItem>
            </DropdownMenu>
          </Check>
        </Check>
      </ButtonDropdownGroup>
    </>
  )
}

export default withTranslation(["common", "partyPage"])(ButtonsVisitHistory)
