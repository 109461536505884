import { SystemInformation, TextField } from "components/Common"
import { DeliveryStateText, ModuleIds, textFieldTypes } from "constant/utility"
import React, { useEffect, useMemo, useState } from "react"
//i18n
import { withTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"
import ConfirmInformation from "./ConfirmInformation"
import { getDeliveries } from "store/actions"
import { useSelector, useDispatch } from "react-redux"
import { element } from "prop-types"
import { convertDateFormat } from "helpers/utilities"
import { getAllDeliveries } from "helpers/app-backend"
const RESOURCE = ModuleIds.CorrectionRequest

const ViewMode = ({
  data: { deliveryTime, typeName, handlerUserName, targetUserName, remark, relatedIds },
  data,
  t,
}) => {
  const [dataRelated, setDataRelated] = useState("")
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getDeliveries())
  }, [dispatch])

  useEffect(() => {
    GetDeliveries(relatedIds);
  }, [relatedIds])

  const GetDeliveries = async (relatedIds) => {
    if (relatedIds) {
      const dataRelatedID = relatedIds.split(',');
      if (dataRelatedID && dataRelatedID.length > 0) {
        let query = { id: dataRelatedID }
        let res = await getAllDeliveries(query)
        if (res.data && res.data.length > 0) {
          setDataRelated(res.data.map(x => x.deliveryDate).join(','))
        }
      }
    }
  }

  const isOpen = false

  return (
    <Row>
      <Col sm="12">
        <Row>
          <Col sm="6">
            <TextField
              label={t("Delivery Time")}
              textField={convertDateFormat(deliveryTime)}
              resource={RESOURCE}
              isEdit={isOpen}
            />
          </Col>
          <Col sm="6">
            <TextField
              label={t("common:Type")}
              textField={typeName}
              resource={RESOURCE}
              isEdit={isOpen}
            />
          </Col>
          <Col sm="6">
            <TextField
              label={t("Performer Name")}
              textField={targetUserName}
              resource={RESOURCE}
              isEdit={isOpen}
            />
          </Col>
          <Col sm="6">
            <TextField
              label={t("common:Remark")}
              textField={remark}
              resource={RESOURCE}
              isEdit={isOpen}
            />
          </Col>
        </Row>
      </Col>
      {/* <Col sm="12" className="px-0">
        <ConfirmInformation t={t} data={data} />
      </Col> */}
      <Col sm="12" className="px-0">
        <SystemInformation t={t} data={data} />
      </Col>
    </Row>
  )
}

export default withTranslation(["deliveryPage", "common"])(ViewMode)
