import { AvForm } from "availity-reactstrap-validation"
import classnames from "classnames"
import {
  CustomAvField,
  CustomCheckbox,
  CustomSelect,
  CustomSelectAsync
} from "components/Common"
import { useEffect, useRef, useState } from "react"
import ReactDrawer from "react-drawer"
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import {
  Col, Label,
  Nav,
  NavItem,
  NavLink,
  Row
} from "reactstrap"
import SimpleBar from "simplebar-react"
import GroupRequestModal from "./GroupRequestModal"
import HisConnectModal from "./HisConnectModal"
import { parameterCode } from "constant"
let firstShow = true

const SettingModal = ({ t, modal, toggle }) => {
  const [activeTab, setactiveTab] = useState("1")
  const [addAllTest, setAddAllTest] = useState(false)
  const [autoSave, setAutoSave] = useState(false)
  const [autoCollected, setAutoCollected] = useState(false)
  const [autoSID, setAutoSID] = useState(false)
  const [autoPrintBarcode, setAutoPrintBarcode] = useState(false)
  const [config, setConfig] = useState({})
  const [configPrinter, setConfigPrinter] = useState({})
  const [columns, setColumns] = useState()
  const [printer, setPrinter] = useState(0)
  const [printerName, setPrinterName] = useState("")
  const [exportFormat, setExportFormat] = useState("pdf")
  const [show, setShow] = useState(modal)

  const formEl = useRef()

  const GetInfo = () => {
    return {
      addAllTest,
      autoSID,
      autoSave,
      autoCollected,
      autoPrintBarcode,
      columns,
      printer,
      printerName
    }
  }
  let patientVisitConfig = localStorage.getItem("patientVisitConfig");
  let printConfig = localStorage.getItem("printConfig")

  useEffect(() => {
    setShow(modal)
    const tmp = patientVisitConfig ? JSON.parse(patientVisitConfig) : {}
    const tmpPrinter = printConfig ? JSON.parse(printConfig) : {}
    setConfig(tmp)
    setConfigPrinter(tmpPrinter)
  }, [modal])
  const SaveInfo = data => {
    localStorage.setItem("patientVisitConfig", JSON.stringify(data))
  }
  const toggleTab = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }
  // useEffect(() => {
  //   if (!modal && !firstShow) {
  //     SaveInfo(GetInfo())
  //     firstShow = true
  //   }
  //   if (!!modal) {
  //     firstShow = false
  //   }
  // }, [modal])
  return (
    <>
      <ReactDrawer
        open={modal}
        position={"right"}
        onClose={() => {
          toggle()
        }}
        style={{ zIndex: 1 }}
      >
        <SimpleBar style={{ height: "100vh" }}>
          <div data-simplebar className="h-100">
            <div className="rightbar-title px-3 py-4">
              <Link
                to="#"
                onClick={e => {
                  toggle()
                }}
                className="right-bar-toggle float-end"
              >
                <i className="mdi mdi-close noti-icon" />
              </Link>
              <h5 className="m-0" style={{ textAlign: "left" }}>
                {t("common:Patient Visit Settings")}
              </h5>
            </div>
            <hr className="my-0" />
            <AvForm>
              <div
                className="pt-4 pb-4"
                style={{
                  textAlign: "left",
                  paddingLeft: "14px",
                  paddingRight: "14px",
                }}
              >
                {/* <div style={{ marginTop: 20 }}>
                  <Label style={{ fontWeight: "bold" }}>
                    {t("Type")}
                  </Label>
                  <Nav
                    pills
                    className="navtab-bg nav-justified"
                    style={{ border: "1px solid #ccc", borderRadius: 6 }}
                  >
                    <NavItem>
                      <NavLink
                        style={{
                          cursor: "pointer",
                          color: activeTab === "1" ? "#fff" : "#556ee6",
                        }}
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleTab("1")
                        }}
                      >
                        {t("HIS Connect")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{
                          cursor: "pointer",
                          color: activeTab === "2" ? "#fff" : "#556ee6",
                        }}
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggleTab("2")
                        }}
                      >
                        {t("Group Test")}
                      </NavLink>
                    </NavItem>
                  </Nav>
                  
                </div> */}
                <Row className="p-3 text-muted">
                  <Col sm="12" style={{ padding: 0 }}>
                    <HisConnectModal modal={modal}
                    />
                  </Col>
                </Row>
                <Label>{t("Request Options")}</Label>
                <div className="mb-3">
                  <CustomCheckbox
                    name="request3"
                    checked={config.autoSID == true}
                    direction={"left_full_size"}
                    label={t("Automatically generate sample ID")}
                    onChange={value => {
                      // setAutoSID(value)
                      localStorage.setItem("patientVisitConfig", JSON.stringify({ ...config, autoSID: value }))
                      window.dispatchEvent(new Event("storage"));
                    }}
                  />
                </div>
                <div className="mb-3">
                  <CustomCheckbox
                    name="autoSave"
                    checked={config.autoSave == true}
                    direction={"left_full_size"}
                    label={t("Auto Save")}
                    value={config.autoSave}
                    onChange={value => {
                      console.log('value', value)
                      localStorage.setItem("patientVisitConfig", JSON.stringify({ ...config, autoSave: value }))
                      window.dispatchEvent(new Event("storage"));
                    }}
                  />
                </div>
                <div className="mb-3">
                  <CustomCheckbox
                    name="autoCollected"
                    checked={config.autoCollected == true}
                    direction={"left_full_size"}
                    label={t("Auto Collected")}
                    value={config.autoCollected}
                    onChange={value => {
                      localStorage.setItem("patientVisitConfig", JSON.stringify({ ...config, autoCollected: value }))
                      window.dispatchEvent(new Event("storage"));
                    }}
                  />
                </div>
                {/* <div className="mb-3">
                  <CustomCheckbox
                    name="useVisited"
                    checked={config.useVisited == true}
                    direction={"left_full_size"}
                    label={t("Use Visited")}
                    value={config.useVisited}
                    onChange={value => {
                      localStorage.setItem("patientVisitConfig", JSON.stringify({ ...config, useVisited: value }))
                      window.dispatchEvent(new Event("storage"));
                    }}
                  />
                </div> */}
                <Label>{t("Print Options")}</Label>
                <div className="mb-3">
                  <CustomCheckbox
                    name="print1"
                    direction={"left_full_size"}
                    label={t("Automatically print labels")}
                    checked={false}
                    value={config.autoPrintBarcode}
                    onChange={value => {
                      localStorage.setItem("patientVisitConfig", JSON.stringify({ ...config, autoPrintBarcode: value }))
                      window.dispatchEvent(new Event("storage"));
                    }}
                  />
                </div>
                {/* <div className="mb-3">
                  <CustomCheckbox
                    name="print2"
                    direction={"left_full_size"}
                    label={t("Send data to OTBBarcode")}
                    checked={false}
                  />
                </div> */}
                <div className="mb-3">
                  <CustomCheckbox
                    name="print3"
                    direction={"left_full_size"}
                    label={t("Print appointment ticket")}
                    checked={false}
                  />
                </div>
                <div className="mb-3">
                  <CustomCheckbox
                    name="print4"
                    direction={"left_full_size"}
                    label={t("Print queue number ticket")}
                    checked={false}
                  />
                </div>
                {/* <div className="mb-3">
                  <CustomCheckbox
                    name="print5"
                    direction={"left_full_size"}
                    label={t("Print multiple/2-column labels")}
                    checked={false}
                  />
                </div> */}
                <div className="mb-3">
                  <CustomAvField
                    label={t("common:Columns")}
                    name="Columns"
                    type="text"
                    className="form-input"
                    value={configPrinter.columns}
                    onChange={(e, values, name, data) => {
                      localStorage.setItem("printConfig", JSON.stringify({ ...configPrinter, columns: e && parseInt(e) }))
                      window.dispatchEvent(new Event("storage"));
                      // setColumns(e && parseInt(e))
                    }}
                  />
                </div>
                <div className="mb-3">
                  <CustomSelect
                    name="exportFormat"
                    value={configPrinter.exportFormat || "pdf"}
                    valueName={""}
                    code={""}
                    options={[
                      {
                        value: "pdf",
                        label: "pdf",
                      },
                      {
                        value: "xlsx",
                        label: "xlsx",
                      },
                      {
                        value: "docx",
                        label: "docx",
                      },
                    ]}
                    label={t("common:Export Format")}
                    onChange={(e, values) => {
                      localStorage.setItem("printConfig", JSON.stringify({ ...configPrinter, exportFormat: values[0] }))
                      window.dispatchEvent(new Event("storage"));
                      // setExportFormat(values[0])
                    }}
                  />
                </div>
                {/* <hr className="my-0 my-3" /> */}
                <div className="mb-3">
                  <CustomSelectAsync
                    name="printerid"
                    value={configPrinter.printer}
                    valueName={configPrinter.printerName}
                    code={parameterCode.PRINTER}
                    label={t("common:Printer select")}
                    onChange={(e, values, name) => {
                      localStorage.setItem("printConfig", JSON.stringify({ ...configPrinter, printer: values[0], printerName: name }))
                      window.dispatchEvent(new Event("storage"));
                      // setPrinter(values[0])
                      // setPrinterName(name)
                    }}
                  />
                </div>
              </div>
            </AvForm>
          </div>
        </SimpleBar>
      </ReactDrawer>
      <GroupRequestModal
        modal={activeTab == "2"}
        toggle={() => {
          setactiveTab(activeTab == "2" ? "1" : "2")
        }}
      />
    </>
  )
}
SettingModal.propTypes = {}
export default withTranslation(["resultPage", "common", "message"])(
  SettingModal
)
