import React from "react"
import PropTypes from "prop-types"
import { Label, Row } from "reactstrap"
import { AvField } from "availity-reactstrap-validation"
//i18n
import { withTranslation } from "react-i18next"
import { CustomSelect } from "components/Common"
import { parameterCode, DeliveryState } from "constant/utility"

const FilterForm = ({ model, t }) => {
  // const options = DeliveryState
  const options = Object.keys(DeliveryState).map(key => {
    return { label: t(DeliveryState[key]), value: key }
  })
  return (
    <>
      <Row>
        <div className="mb-3">
          <Label for="example">{t("State")}</Label>
          <CustomSelect
            portal
            name="state"
            value={model.state || ""}
            // code={parameterCode.DELIVERY_TYPE_CODE}
            options={options}
            onChange={(name, value, label, item) => {
              model.state = value[0];
          }}
          />
        </div>
      </Row>
    </>
  )
}

FilterForm.propTypes = {
  model: PropTypes.object,
  t: PropTypes.any
}
export default withTranslation(["deliveryPage", "common"])(FilterForm)
