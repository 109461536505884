import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import BadgeLabel from "./BadgeLabel"

const BadgeLabelAndValue = ({ label, isSoft, className, isRound, value, type }) => {
  return (
    <div>
      <p className="text-muted text-truncate mb-2 font-size-12">{label}</p>
      <h6 className="mb-0">
        <BadgeLabel
          className={className}
          isRound={isRound}
          isSoft={isSoft}
          type={type}
          value={value}
        />
      </h6>
    </div>
  )
}

BadgeLabelAndValue.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  isRound: PropTypes.bool,
  isSoft: PropTypes.bool,
  type: PropTypes.string,
}

BadgeLabelAndValue.defaultProps = {
  label: "label",
  value: "",
  className: "",
  isRound: false,
  isSoft: false,
  type: ''
}

export default BadgeLabelAndValue
