import { call, put, takeEvery } from "redux-saga/effects";
import {
    GET_AUDIT_LOG, GET_CONNECTOR_LOG, GET_ERROR_LOG, GET_HISTORY_RESULTS, UPDATE_ERROR_LOG
} from "./actionTypes";

import { getAuditLog, getConnectorLog, getErrorLog, updateErrorLog } from "helpers/app-backend/testResult_backend_helper";
import { getAuditLogFail, getAuditLogSuccess, getConnectorLogFail, getConnectorLogSuccess, getErrorLogFail, getErrorLogSuccess, getHistoryResultsFail, getHistoryResultsSuccess, setAuditlogSearchQuery, setConnectorSearchQuery, setErrorlogSearchQuery, setHistoryResultsSearchQuery, updateErrorLogFail, updateErrorLogSuccess } from "./actions";
import { showToast } from "components/Common"
import i18n from "i18n";
import { getHistoryResults } from "helpers/app-backend/workOrders_backend_helper";
import moment from "moment";

const t = (msg, param) => i18n.t(msg, param)

function* fetchAudiLogs({ payload }) {
    try {
        const res = yield call(getAuditLog, payload)
        yield put(getAuditLogSuccess(res))
        yield put(setAuditlogSearchQuery(payload))
    } catch (error) {
        yield put(getAuditLogFail(error))
    }
}

function* fetchConnectorLogs({ payload }) {
    try {

        payload.startDate=moment(payload.start).format("YYYY-MM-DD HH:mm")
        payload.endDate=moment(payload.end).format("YYYY-MM-DD HH:mm")
        const res = yield call(getConnectorLog, payload)
        yield put(getConnectorLogSuccess(res))
        yield put(setConnectorSearchQuery(payload))
    } catch (error) {
        yield put(getConnectorLogFail(error))
    }
}

function* fetchErrorLogs({ payload }) {
    try {
        const res = yield call(getErrorLog, payload)
        yield put(getErrorLogSuccess(res))
        yield put(setErrorlogSearchQuery(payload))
    } catch (error) {
        yield put(getErrorLogFail(error))
    }
}

function* updateErrorLogs({ payload }) {
    try {
        const res = yield call(updateErrorLog, payload)
        yield put(updateErrorLogSuccess(res))
        showToast(
            `${t("message:UpdatedMessage")}`
          )
    } catch (error) {
        yield put(updateErrorLogFail(error))
    }
}
function* fetchHistoryResults({ payload }) {
    try {
      const response = yield call(getHistoryResults, payload)
      yield put(getHistoryResultsSuccess(response))
      yield put(setHistoryResultsSearchQuery(payload))
    } catch (error) {
      console.log(error)
      yield put(getHistoryResultsFail(error))
    }
  }

function* auditSaga() {
    yield takeEvery(GET_AUDIT_LOG, fetchAudiLogs)
    yield takeEvery(GET_CONNECTOR_LOG, fetchConnectorLogs)
    yield takeEvery(GET_ERROR_LOG, fetchErrorLogs)
    yield takeEvery(UPDATE_ERROR_LOG, updateErrorLogs)
    yield takeEvery(GET_HISTORY_RESULTS, fetchHistoryResults)
}

export default auditSaga