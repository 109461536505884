import { call, put, takeEvery, select } from "redux-saga/effects";
import i18n from "i18next"

//setting redux states
import {
    GET_TESTPROFILES, GET_TESTPROFILE_DETAIL, ADD_NEW_TESTPROFILE, UPDATE_TESTPROFILE, DELETE_TESTPROFILES,
    GET_TESTPROFILE_STATISTICS, GET_TESTPROFILE_GROUP,
    DELETE_TESTPROFILE_GROUPS,
    ADD_TESTPROFILE_GROUP,
    SAVE_TESTPROFILE_GROUP,
    GET_TESTPROFILE_TESTS, DELETE_TESTPROFILE_TESTS, ADD_TESTPROFILE_TESTS, SAVE_TESTPROFILE_TESTS,
    EDIT_TESTPROFILE_GROUP,
    EDIT_TESTPROFILE_TEST,
    SAVE_DISPLAY_ORDER,
    UPDATE_PROFILE_VENDOR,
    DELETE_PROFILE_VENDOR,
    UPDATE_INFO_PROFILE,
} from "./actionTypes"

import {
    getTestProfilesSuccess, getTestProfilesFail, setTestProfileSearchQuery,
    getTestProfileDetailFail, getTestProfileDetailSuccess,
    addTestProfileSuccess, addTestProfileFail,
    updateTestProfileFail, updateTestProfileSuccess,
    deleteTestProfilesFail, deleteTestProfilesSuccess,
    getTestProfileStatisticsSuccess,
    getTestProfileStatisticsFail,
    getTestProfileGroupsFail, getTestProfileGroupsSuccess,
    deleteSubProfilesFail, deleteSubProfilesSuccess,
    addNewSubProfileSuccess, addNewSubProfileFail,
    saveTestProfileGroupsFail, saveTestProfileGroupsSuccess,
    getTestProfileTestsFail, getTestProfileTestsSuccess,
    deleteProfileTestsFail, deleteProfileTestsSuccess,
    addNewProfileTestFail, addNewProfileTestSuccess,
    saveProfileTestsFail, saveProfileTestsSuccess,
    editSubProfileFail, editSubProfileSuccess,
    editProfileTestFail, editProfileTestSuccess, saveDisplayOrderSuccess, saveDisplayOrderFail, updateProfileVendorSuccess, updateProfileVendorFail, deleteProfileVendorSuccess, deleteProfileVendorFail,
    updateInfoProfileSuccess,
    updateInfoProfileFail,
} from "./actions"

import {
    getAllTestProfiles, getTestProfileById, createTestProfile, updateTestProfileById, deleteTestProfileById,
    getTestProfileStatisticsById, getAllTestProfileGroups,
    updateTestProfileGroupsById,
    getAllTestProfileTests,
    updateTestProfileTestsById,
    updateDisplayOrderProfile,
    updateProfileVendor,
    deleteProfileVendor,
    updateInfoProfile
} from "helpers/app-backend/testProfiles_backend_helper"

import { showToast, showErrToast } from "components/Common";

const t = (msg, param) => i18n.t(msg, param)

function* fetchTestProfiles({ payload }) {
    try {
        const searchQuery = yield select(state => {
            return state.testProfile.searchQuery
        })
        payload = { ...searchQuery, ...payload }
        const response = yield call(getAllTestProfiles, payload)
        yield put(getTestProfilesSuccess(response))
        yield put(setTestProfileSearchQuery(payload))
    } catch (error) {
        console.log(error);
        yield put(getTestProfilesFail(error))
    }
}

function* fetchTestProfileDetail({ id }) {
    try {
        const response = yield call(getTestProfileById, id)
        yield put(getTestProfileDetailSuccess(response))
    } catch (error) {
        yield put(getTestProfileDetailFail(error))
    }
}


function* onAddNewTestProfile({ payload: { testProfile, history } }) {
    try {
        // return;
        const response = yield call(createTestProfile, testProfile)
        const id = response?.id
        yield put(addTestProfileSuccess(id))
        const url = `/TestProfile/${id}/view`
        showToast(
            `${t("message:CreatedMessage", {
                field: `${t(
                    "profilePage:Profile"
                )} <span class='text-decoration-underline fw-bold'>${testProfile["profileName"]
                    }</span>`,
            })}`
        )
        history.push(url)
    } catch (error) {
        console.log(error)
        yield put(addTestProfileFail(error))
    }
}

function* onUpdateTestProfile({ payload: { testProfile, callback } }) {
    try {
        const response = yield call(updateTestProfileById, testProfile)
        yield put(updateTestProfileSuccess(testProfile))

        showToast(
            `${t("message:UpdatedMessage", {
                field: `${t(
                    "testProfilePage:Profile"
                )} <span class='text-decoration-underline fw-bold'>${testProfile["profileName"]
                    }</span>`,
            })}`
        )
        if (callback) callback()
    } catch (error) {
        yield put(updateTestProfileFail(error))
    }
}

function* onTestDeleteProfile({ payload: { testProfiles, callback } }) {
    try {
        const response = yield call(deleteTestProfileById, testProfiles)
        yield put(deleteTestProfilesSuccess(testProfiles))

        showToast(
            `${t("message:DeletedMessage", {
                field: `${t("testProfilePage:Profile")}`,
            })}`
        )
        if (callback) callback()
    } catch (error) {
        yield put(deleteTestProfilesFail(error))
    }
}

function* fetchTestProfileStatistics({ id }) {
    try {
        const response = yield call(getTestProfileStatisticsById, id)
        yield put(getTestProfileStatisticsSuccess(response))
    } catch (error) {
        yield put(getTestProfileStatisticsFail(error))
    }
}

function* fetchTestProfileGroups({ id }) {
    try {
        const response = yield call(getAllTestProfileGroups, id)
        yield put(getTestProfileGroupsSuccess(response))
    } catch (error) {
        console.log(error);
        yield put(getTestProfileGroupsFail(error))
    }
}

function* onDeleteSubProfiles({ payload: { testProfileGroups } }) {
    try {
        const newSubProfiles = yield select(state => state.testProfile.testProfileGroups)
        const results = newSubProfiles.filter(
            SubProfile => testProfileGroups.findIndex(_f => _f.id === SubProfile.id) < 0
        )
        yield put(deleteSubProfilesSuccess(results))
    } catch (error) {
        console.log(error)
        yield put(deleteSubProfilesFail(error))
    }
}

// add subprofile
function* onAddNewSubProfile({ payload: { testProfileGroup, callback } }) {
    try {
        const testProfileGroups = yield select(state => state.testProfile.testProfileGroups)
        if (testProfileGroups) {
            const index = testProfileGroups.findIndex(x => x.profileId === testProfileGroup.profileId)
            if (index >= 0) {
                showErrToast(
                    `${t("message:ExistedMessage", {
                        field: `${t(
                            "testProfilePage:Profile"
                        )} <span class='text-decoration-underline fw-bold'>${testProfileGroup["profileName"]
                            }</span>`,
                    })}`
                )
                return;
            }
        }
        testProfileGroup.id = new Date().getTime()
        testProfileGroup.new = true
        const results = [...testProfileGroups, testProfileGroup].map((_item, _idx) => {
            // _item.displayOrder = _idx
            return _item
        })
        yield put(addNewSubProfileSuccess(results))
        if (callback) callback(results)
    } catch (error) {
        console.log(error);
        yield put(addNewSubProfileFail(error))
    }
}

// SAVE sub-profile
function* onSaveTestProfileGroups({ payload: { profileId, testProfileGroups, callback } }) {
    try {
        const response = yield call(updateTestProfileGroupsById, profileId, testProfileGroups)

        yield put(saveTestProfileGroupsSuccess(testProfileGroups))

        showToast(
            `${t("message:UpdatedMessage", {
                field: `${t("testProfilePage:Sub Profile")}`,
            })}`
        )

        if (callback) callback()
    } catch (error) {
        console.log(error)
        yield put(saveTestProfileGroupsFail(error))
    }
}

//get testprofile tests
function* fetchTestProfileTests({ id }) {
    try {
        const response = yield call(getAllTestProfileTests, id)
        yield put(getTestProfileTestsSuccess(response))
    } catch (error) {
        console.log(error);
        yield put(getTestProfileTestsFail(error))
    }
}

//delete profile tests
function* onDeleteProfileTests({ payload: { testProfileTests } }) {
    try {
        const newProfileTests = yield select(state => state.testProfile.testProfileTests)
        const results = newProfileTests.filter(
            ProfileTest => testProfileTests.findIndex(_f => _f.id === ProfileTest.id) < 0
        )
        yield put(deleteProfileTestsSuccess(results))
    } catch (error) {
        console.log(error)
        yield put(deleteProfileTestsFail(error))
    }
}

// add profile test
function* onAddNewProfileTest({ payload: { testProfileTest, callback } }) {
    try {
        const testProfileTests = yield select(state => state.testProfile.testProfileTests)
        if (testProfileTests) {
            const index = testProfileTests.findIndex(x => x.testId === testProfileTest.testId)
            if (index >= 0) {
                showErrToast(
                    `${t("message:ExistedMessage", {
                        field: `${t(
                            "testProfilePage:Test"
                        )} <span class='text-decoration-underline fw-bold'>${testProfileTest["testName"]
                            }</span>`,
                    })}`
                )
                return;
            }
        }
        testProfileTest.id = new Date().getTime()
        testProfileTest.new = true
        const results = [...testProfileTests, testProfileTest].map((_item, _idx) => {
            // _item.displayOrder = _idx
            return _item
        })
        yield put(addNewProfileTestSuccess(results))
        if (callback) callback(results)
    } catch (error) {
        console.log(error);
        yield put(addNewProfileTestFail(error))
    }
}

// SAVE profile tests
function* onSaveTestProfileTests({ payload: { profileId, testProfileTests, callback } }) {
    try {
        const response = yield call(updateTestProfileTestsById, profileId, testProfileTests)

        yield put(saveProfileTestsSuccess(testProfileTests))

        showToast(
            `${t("message:UpdatedMessage", {
                field: `${t("testProfilePage:Test")}`,
            })}`
        )

        if (callback) callback()
    } catch (error) {
        console.log(error)
        yield put(saveProfileTestsFail(error))
    }
}

// edit subprofile
function* onEditSubProfile({ payload: { testProfileGroup, selectedEditItem, callback } }) {
    try {
        const testProfileGroups = yield select(state => state.testProfile.testProfileGroups)
        if (testProfileGroups) {
            const index = testProfileGroups.findIndex(x => x.profileId === testProfileGroup.profileId)
            if (index >= 0) {
                showErrToast(
                    `${t("message:ExistedMessage", {
                        field: `${t(
                            "testProfilePage:Profile"
                        )} <span class='text-decoration-underline fw-bold'>${testProfileGroup["profileName"]
                            }</span>`,
                    })}`
                )
                return;
            }
        }
        //luôn có giá trị
        const indexOld = testProfileGroups.findIndex(x => x.profileId === selectedEditItem.profileId)
        testProfileGroups[indexOld].id = new Date().getTime()
        testProfileGroups[indexOld].new = true
        testProfileGroups[indexOld].groupId = testProfileGroup.groupId
        testProfileGroups[indexOld].profileId = testProfileGroup.profileId
        testProfileGroups[indexOld].profileName = testProfileGroup.profileName
        testProfileGroups[indexOld].remark = testProfileGroup.remark
        testProfileGroups[indexOld].displayOrder = testProfileGroup.displayOrder
        const results = [...testProfileGroups].map((_item, _idx) => {
            return _item
        })
        yield put(editSubProfileSuccess(results))
        if (callback) callback()
    } catch (error) {
        console.log(error);
        yield put(editSubProfileFail(error))
    }
}

// add profile test
function* onEditProfileTest({ payload: { testProfileTest, checkSelectedItem, callback } }) {
    try {
        const testProfileTests = yield select(state => state.testProfile.testProfileTests)
        if (testProfileTests) {
            const index = testProfileTests.findIndex(x => x.testId === testProfileTest.testId)
            if (index >= 0) {
                showErrToast(
                    `${t("message:ExistedMessage", {
                        field: `${t(
                            "testProfilePage:Test"
                        )} <span class='text-decoration-underline fw-bold'>${testProfileTest["testName"]
                            }</span>`,
                    })}`
                )
                return;
            }
        }
        //luôn có giá trị
        const indexOld = testProfileTests.findIndex(x => x.testId === checkSelectedItem.testId)
        testProfileTests[indexOld].id = new Date().getTime()
        testProfileTests[indexOld].new = true
        testProfileTests[indexOld].testId = testProfileTest.testId
        testProfileTests[indexOld].profileId = testProfileTest.profileId
        testProfileTests[indexOld].testCode = testProfileTest.testCode
        testProfileTests[indexOld].testName = testProfileTest.testName
        testProfileTests[indexOld].remark = testProfileTest.remark
        testProfileTests[indexOld].displayOrder = testProfileTest.displayOrder
        const results = [...testProfileTests].map((_item, _idx) => {
            return _item
        })
        yield put(editProfileTestSuccess(results))
        if (callback) callback()
    } catch (error) {
        console.log(error);
        yield put(editProfileTestFail(error))
    }
}

function* onUpdateDisplayOrder({ payload, callback }) {
    try {
        const response = yield call(updateDisplayOrderProfile, payload)
        yield put(saveDisplayOrderSuccess(response))
        showToast(
            `${t("message:UpdatedMessage", {
                field: `${t("testProfilePage:Profile")}`,
            })}`
        )
        if (callback) callback()
    } catch (error) {
        console.log(error)
        yield put(saveDisplayOrderFail(error))
    }
}

function* onUpdateProfileVendor({ payload, callback }) {
    try {
        const response = yield call(updateProfileVendor, payload)
        yield put(updateProfileVendorSuccess(response))

        showToast(
            `${t("message:UpdatedMessage", {
                field: `${t(
                    "profilePage:Profile"
                )}`,
            })}`
        )
        callback()
    } catch (error) {
        yield put(updateProfileVendorFail(error))
    }
}

function* onUpdateInfoProfile({ payload, callback }) {
    try {
        const response = yield call(updateInfoProfile, payload)
        yield put(updateInfoProfileSuccess(response))

        showToast(
            `${t("message:UpdatedMessage", {
                field: `${t(
                    "profilePage:Profile"
                )}`,
            })}`
        )
        callback()
    } catch (error) {
        yield put(updateInfoProfileFail(error))
    }
}

function* onDeleteProfileVendor({ payload, callback }) {
    try {
        const response = yield call(deleteProfileVendor, payload)
        yield put(deleteProfileVendorSuccess(response))

        showToast(
            `${t("message:DeletedMessage", {
                field: `${t("profilePage:Profile")}`,
            })}`
        )
        if (callback)
            callback()
    } catch (error) {
        yield put(deleteProfileVendorFail(error))
    }
}


function* testProfileSaga() {
    yield takeEvery(GET_TESTPROFILES, fetchTestProfiles)
    yield takeEvery(GET_TESTPROFILE_DETAIL, fetchTestProfileDetail)
    yield takeEvery(ADD_NEW_TESTPROFILE, onAddNewTestProfile)
    yield takeEvery(UPDATE_TESTPROFILE, onUpdateTestProfile)
    yield takeEvery(DELETE_TESTPROFILES, onTestDeleteProfile)
    yield takeEvery(GET_TESTPROFILE_STATISTICS, fetchTestProfileStatistics)
    yield takeEvery(GET_TESTPROFILE_GROUP, fetchTestProfileGroups)
    yield takeEvery(DELETE_TESTPROFILE_GROUPS, onDeleteSubProfiles)
    yield takeEvery(ADD_TESTPROFILE_GROUP, onAddNewSubProfile)
    yield takeEvery(SAVE_TESTPROFILE_GROUP, onSaveTestProfileGroups)
    yield takeEvery(GET_TESTPROFILE_TESTS, fetchTestProfileTests)
    yield takeEvery(DELETE_TESTPROFILE_TESTS, onDeleteProfileTests)
    yield takeEvery(ADD_TESTPROFILE_TESTS, onAddNewProfileTest)
    yield takeEvery(SAVE_TESTPROFILE_TESTS, onSaveTestProfileTests)
    yield takeEvery(EDIT_TESTPROFILE_GROUP, onEditSubProfile)
    yield takeEvery(EDIT_TESTPROFILE_TEST, onEditProfileTest)
    yield takeEvery(SAVE_DISPLAY_ORDER, onUpdateDisplayOrder)
    yield takeEvery(UPDATE_PROFILE_VENDOR, onUpdateProfileVendor)
    yield takeEvery(DELETE_PROFILE_VENDOR, onDeleteProfileVendor)
    yield takeEvery(UPDATE_INFO_PROFILE, onUpdateInfoProfile)
}
export default testProfileSaga