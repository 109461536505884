import { AvForm } from "availity-reactstrap-validation"
import { CustomDatePicker } from "components/Common"
import { convertDateFormat } from "helpers/utilities"
import React from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"

const SampleDeliveryTableButtons = ({
  model, onChange, onSubmit, t
}) => {

  return (
    <React.Fragment>
      <AvForm>
        <div className="d-flex">
          <div style={{ marginLeft: '5px' }}>
            <CustomDatePicker
              conjunction=" - "
              name="requestDate"
              label={""}
              checkSameTime={true}
              format={"d-m-Y"}
              value={[model.start || new Date(), model.end || new Date()]}
              closeOnSelect={true}
              onKeyDown={e => {
                if (e.keyCode == 13) {
                  onSubmit && onSubmit()
                }
              }}
              onChangeHandler={date => {
                if (date.time.length == 2) {
                  // setDates([convertDateFormat(date.time[0], "YYYY-MM-DD"), convertDateFormat(date.time[1], "YYYY-MM-DD")])
                  onChange({
                    start: convertDateFormat(date.time[0], "YYYY-MM-DD"),
                    end: convertDateFormat(date.time[1], "YYYY-MM-DD"),
                  })
                }
                else {
                  // setDates([new Date(), new Date()])
                  onChange({
                    start: new Date(),
                    end: new Date(),
                  })
                }
              }}
              maxDate={new Date()}
              // minDate={minDate}
              mode="range"
            />
          </div>
        </div>
      </AvForm>

    </React.Fragment>
  )
}

const mapStateToProps = ({ hisConnector }) => ({

})

const mapDispatchToProps = dispatch => ({

})

SampleDeliveryTableButtons.displayName = 'SampleDeliveryTableButtons';
export default withTranslation(["testRequestPage", "common", "button"], { withRef: true })(connect(
  mapStateToProps,
  mapDispatchToProps, null, { forwardRef: true }
)(SampleDeliveryTableButtons))
