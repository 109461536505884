import { CustomAvField, CustomSelect, DatePicker } from "components/Common"
import { parameterCode } from "constant"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Row } from "reactstrap"

const FilterForm = ({ model, t }) => {
  return (
    <>
      <Row>
        <div className="mb-3">
          <CustomAvField
            label={t("Patient Search")}
            name="search"
            type="text"
            className="form-input"
            placeholder={t("Patient Search")}
            value={model.search || ""}
          />
        </div>
      </Row>
      <Row>
        <div className="mb-3">
          <CustomSelect
            label={t("common:Hospital")}
            portal
            name="managementCompanyId"
            value={model.managementCompanyId || ""}
            code={parameterCode.COMPANIES}
            onChange={(name, value, label, item) => {
              model.managementCompanyId = value[0];
          }}
          />
        </div>
      </Row>
      <Row>
        <div className="mb-3">
          <CustomSelect
            label={t("Organization")}
            portal
            name="parentId"
            value={model.parentId || ""}
            code={parameterCode.ORGANIZATIONS}
            onChange={(name, value, label, item) => {
              model.parentId = value[0];
          }}
          />
        </div>
      </Row>

      <Row>
        <div className="mb-3">
          <DatePicker
            name="start"
            label={t("Start date")}
            value={model.start || ""}
            placeholder={"YYYY-MM-DD"}
            format={"YYYY-MM-DD"}
          />
        </div>
      </Row>

      <Row>
        <div className="mb-3">
          <DatePicker
            name="end"
            label={t("End date")}
            value={model.end || ""}
            placeholder={"YYYY-MM-DD"}
            format={"YYYY-MM-DD"} />
        </div>
      </Row>
    </>
  )
}

FilterForm.propTypes = {
  model: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation(["partyPage", "common"])(FilterForm)
